import { z } from 'zod';

export const UserEmployee = z.object({
  id: z.string().default(''),
  preferredName: z.string().default(''),
  legalName: z.string().default(''),
  company: z.string().default(''),
  businessUnit: z.string().default(''),
  department: z.string().default(''),
});
export type UserEmployeeType = z.infer<typeof UserEmployee>;
