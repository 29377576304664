import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateSignInOAuthUrlQueryVariables = Types.Exact<{
  state: Types.Scalars['String']['input'];
  redirectUri: Types.InputMaybe<Types.Scalars['Url']['input']>;
}>;


export type GenerateSignInOAuthUrlQuery = { __typename?: 'Query', generateSignInOAuthUrl: any };


export const GenerateSignInOAuthUrlDocument = gql`
    query generateSignInOAuthUrl($state: String!, $redirectUri: Url) {
  generateSignInOAuthUrl(state: $state, redirectUri: $redirectUri)
}
    `;

/**
 * __useGenerateSignInOAuthUrlQuery__
 *
 * To run a query within a React component, call `useGenerateSignInOAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignInOAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSignInOAuthUrlQuery({
 *   variables: {
 *      state: // value for 'state'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useGenerateSignInOAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<GenerateSignInOAuthUrlQuery, GenerateSignInOAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateSignInOAuthUrlQuery, GenerateSignInOAuthUrlQueryVariables>(GenerateSignInOAuthUrlDocument, options);
      }
export function useGenerateSignInOAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateSignInOAuthUrlQuery, GenerateSignInOAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateSignInOAuthUrlQuery, GenerateSignInOAuthUrlQueryVariables>(GenerateSignInOAuthUrlDocument, options);
        }
export function useGenerateSignInOAuthUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateSignInOAuthUrlQuery, GenerateSignInOAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateSignInOAuthUrlQuery, GenerateSignInOAuthUrlQueryVariables>(GenerateSignInOAuthUrlDocument, options);
        }
export type GenerateSignInOAuthUrlQueryHookResult = ReturnType<typeof useGenerateSignInOAuthUrlQuery>;
export type GenerateSignInOAuthUrlLazyQueryHookResult = ReturnType<typeof useGenerateSignInOAuthUrlLazyQuery>;
export type GenerateSignInOAuthUrlSuspenseQueryHookResult = ReturnType<typeof useGenerateSignInOAuthUrlSuspenseQuery>;
export type GenerateSignInOAuthUrlQueryResult = Apollo.QueryResult<GenerateSignInOAuthUrlQuery, GenerateSignInOAuthUrlQueryVariables>;