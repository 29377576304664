import { EmployeeRoleRadioGroup } from '@/shared/components';
import { EmployeeRole } from '@/shared/graphql';
import { EmployeeAddFormDataType } from '@/shared/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CompanyEmployeeAssignment } from './CompanyEmployeeAssignment';

export const Permission = () => {
  const { watch } = useFormContext<EmployeeAddFormDataType>();
  const selectedRole = watch('role');

  const shouldShowCompanyAssignment = useMemo(
    () =>
      [EmployeeRole.AdminStandard, EmployeeRole.AdminPrivate].includes(
        selectedRole
      ),
    [selectedRole]
  );

  return (
    <>
      <EmployeeRoleRadioGroup />
      {shouldShowCompanyAssignment && <CompanyEmployeeAssignment />}
    </>
  );
};
