import { Table } from '@/shared/components';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useReportNumberOfEmployeeLocationQuery } from 'src/graphql/query';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';

type ClientReportNumberOfEmployeeCompany = {
  id: string;
  name: string;
  isCountry: boolean;
  total: number;
  yearMonth: string;
};

export const NumberOfEmployeesOfficeLocationTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeLocationQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = data?.reportNumberOfEmployeeLocation.yearMonthList;
  const countries = useMemo(
    () =>
      data?.reportNumberOfEmployeeLocation.items
        .map((item) => ({
          name: item.location.country?.name,
          id: item.location.country?.id,
        }))
        .filter((country, index, self) => {
          const firstIndex = self.findIndex((item) => item?.id === country?.id);
          return firstIndex === index;
        }),
    [data?.reportNumberOfEmployeeLocation.items]
  );

  let tableData: ClientReportNumberOfEmployeeCompany[] = [];

  countries?.forEach((country) => {
    const locations = data?.reportNumberOfEmployeeLocation.items
      .filter((item) => item.location.country?.id === country?.id)
      .map((item) => ({
        name: item.location.name,
        id: item.locationId,
      }))
      .filter((location, index, self) => {
        const firstIndex = self.findIndex((item) => item.id === location.id);
        return firstIndex === index;
      });

    tableData.push({
      id: country?.id || 'Remote',
      name: country?.name || 'Remote',
      isCountry: true,
      ...Object.fromEntries(
        (yearMonths || []).map((yearMonth) => [
          yearMonth,
          data?.reportNumberOfEmployeeLocation.items
            .filter(
              (item) =>
                item.yearMonth === yearMonth &&
                item.location.country?.id === country?.id
            )
            .reduce((acc, item) => acc + item.total, 0) || 0,
        ])
      ),
    });

    locations?.forEach((location) => {
      tableData.push({
        id: location.id || '',
        name: location.name || '',
        isCountry: false,
        ...Object.fromEntries(
          (yearMonths || []).map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeLocation.items.find(
              (item) =>
                item.yearMonth === yearMonth && item.locationId === location.id
            )?.total ?? 0,
          ])
        ),
      });
    });
  });

  const columns = useMemo(
    () => [
      {
        headerRender: () => 'Country',
        accessor: 'country',
        cellRender: (row: ClientReportNumberOfEmployeeCompany) =>
          row.isCountry ? (
            row.name
          ) : (
            <li
              className={classNames('list-disc', {
                'pl-6': !row.isCountry,
              })}
            >
              {row.name}
            </li>
          ),
      },
      ...(yearMonths || []).map((yearMonth) => ({
        headerRender: () => yearMonth,
        accessor: yearMonth,
        cellRender: (row: ClientReportNumberOfEmployeeCompany) => (
          <div className='w-full text-right'>
            {row[
              yearMonth as keyof ClientReportNumberOfEmployeeCompany
            ].toString()}
          </div>
        ),
      })),
    ],
    [yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeCompany>
        layout={columns || []}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
