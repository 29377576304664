import { Spinner } from '@/shared/components';
import {
  NumberOfEmployeesFilterType,
  ReportNumberOfEmployeesTab,
  ReportNumberOfEmployeesTabLabels,
} from '@/shared/types';
import { Tabs } from '@any-ui-react/core';
import { Suspense, lazy, useMemo } from 'react';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilters } from '../number-of-employees-filters';

const NumberOfEmployeesAllTable = lazy(() =>
  import('../number-of-employees-table/NumberOfEmployeesAllTable').then(
    (module) => ({
      default: module.NumberOfEmployeesAllTable,
    })
  )
);

const NumberOfEmployeesCompanyTable = lazy(() =>
  import('../number-of-employees-table/NumberOfEmployeesCompanyTable').then(
    (module) => ({
      default: module.NumberOfEmployeesCompanyTable,
    })
  )
);

const NumberOfEmployeesOfficeLocationTable = lazy(() =>
  import(
    '../number-of-employees-table/NumberOfEmployeesOfficeLocationTable'
  ).then((module) => ({
    default: module.NumberOfEmployeesOfficeLocationTable,
  }))
);

const NumberOfEmployeesBusinessUnitTable = lazy(() =>
  import(
    '../number-of-employees-table/NumberOfEmployeesBusinessUnitTable'
  ).then((module) => ({
    default: module.NumberOfEmployeesBusinessUnitTable,
  }))
);
const NumberOfEmployeesJobTable = lazy(() =>
  import('../number-of-employees-table/NumberOfEmployeesJobTable').then(
    (module) => ({
      default: module.NumberOfEmployeesJobTable,
    })
  )
);

export const NumberOfEmployeesTabs = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const TabComponents = {
    [ReportNumberOfEmployeesTab.ALL]: NumberOfEmployeesAllTable,
    [ReportNumberOfEmployeesTab.COMPANY]: NumberOfEmployeesCompanyTable,
    [ReportNumberOfEmployeesTab.OFFICE_LOCATION]:
      NumberOfEmployeesOfficeLocationTable,
    [ReportNumberOfEmployeesTab.BUSINESS_UNIT]:
      NumberOfEmployeesBusinessUnitTable,
    [ReportNumberOfEmployeesTab.JOB]: NumberOfEmployeesJobTable,
  };

  const tabs = useMemo(() => {
    return [
      ReportNumberOfEmployeesTab.ALL,
      ReportNumberOfEmployeesTab.COMPANY,
      ReportNumberOfEmployeesTab.OFFICE_LOCATION,
      ReportNumberOfEmployeesTab.BUSINESS_UNIT,
      ReportNumberOfEmployeesTab.JOB,
    ].map((tab) => ({
      tab: tab,
      value: tab,
    }));
  }, []);

  return (
    <Tabs
      value={filters.current.tab}
      dir='ltr'
      className={'tab-wrapper'}
      orientation='horizontal'
      keepMounted={false}
      onTabChange={(value) => filters.changeFilter('tab', value)}
    >
      {tabs.length > 1 && (
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Tab key={tab.value} value={tab.value}>
              {ReportNumberOfEmployeesTabLabels[tab.tab]}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      )}

      {tabs.map((tab) => {
        const Component = TabComponents[tab.value];
        return (
          <Tabs.Panel key={tab.value} value={tab.value}>
            <div>
              <Suspense
                fallback={
                  <div className='flex w-full items-center justify-center py-11'>
                    <Spinner />
                  </div>
                }
              >
                <NumberOfEmployeesFilters filters={filters} />
                <Component filters={filters} />
              </Suspense>
            </div>
          </Tabs.Panel>
        );
      })}
    </Tabs>
  );
};
