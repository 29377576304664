import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentEmployeeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentEmployeeQuery = { __typename?: 'Query', currentEmployee: { __typename?: 'Employee', id: string, status: Types.EmployeeStatus, permission: { __typename?: 'EmployeePermission', id: string, accessSpecificEmployees: boolean, companyIds: Array<string>, employeeIds: Array<string>, role: Types.EmployeeRole } | null, currentContract: { __typename?: 'EmployeeContract', company: { __typename?: 'Company', countryId: string } } | null } };


export const GetCurrentEmployeeDocument = gql`
    query getCurrentEmployee {
  currentEmployee {
    id
    status
    permission {
      id
      accessSpecificEmployees
      companyIds
      employeeIds
      role
    }
    currentContract {
      company {
        countryId
      }
    }
  }
}
    `;

/**
 * __useGetCurrentEmployeeQuery__
 *
 * To run a query within a React component, call `useGetCurrentEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentEmployeeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentEmployeeQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentEmployeeQuery, GetCurrentEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentEmployeeQuery, GetCurrentEmployeeQueryVariables>(GetCurrentEmployeeDocument, options);
      }
export function useGetCurrentEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentEmployeeQuery, GetCurrentEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentEmployeeQuery, GetCurrentEmployeeQueryVariables>(GetCurrentEmployeeDocument, options);
        }
export function useGetCurrentEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentEmployeeQuery, GetCurrentEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentEmployeeQuery, GetCurrentEmployeeQueryVariables>(GetCurrentEmployeeDocument, options);
        }
export type GetCurrentEmployeeQueryHookResult = ReturnType<typeof useGetCurrentEmployeeQuery>;
export type GetCurrentEmployeeLazyQueryHookResult = ReturnType<typeof useGetCurrentEmployeeLazyQuery>;
export type GetCurrentEmployeeSuspenseQueryHookResult = ReturnType<typeof useGetCurrentEmployeeSuspenseQuery>;
export type GetCurrentEmployeeQueryResult = Apollo.QueryResult<GetCurrentEmployeeQuery, GetCurrentEmployeeQueryVariables>;