import { PropsWithChildren, ReactElement } from 'react';

import { Checkbox } from '@any-ui-react/core';
import {
  HeaderTr,
  Table,
  TableContainer,
  TdBody,
  Th,
  Thead,
  TrBody,
} from '@any-ui-react/table';
import classNames from 'classnames';

import { DEFAULT_COLUMN_MAX_WIDTH } from '../constants';

import { TableSelectionBar, useTableSelectionCtx } from '../selection';

import { TableDesktopProps } from './TableDesktop';
import { useFloatingHeader } from '../../../../hooks';

export function TableDesktopContainer<T extends Record<string, unknown>>({
  layout,
  hoverable = true,
  data,
  headerClassName,
  rowClassName,
  numOfStickyCols = 1,
  children,
  allowFloatingHeader = false,
}: PropsWithChildren<TableDesktopProps<T>>): ReactElement {
  const {
    floatingContainerRefProps,
    tableContainerRef,
    scrollContainerRefProps,
    floatingHeaderRefProps,
    thProps,
    tableRef,
    showFloatingHeader,
  } = useFloatingHeader();
  const {
    selectableData,
    selection,
    selectable,
    toggleSelectAll,
    toggleSelection,
    currentPageSelection,
    checkAll,
  } = useTableSelectionCtx<T>();

  const hasFooter = layout.some((item) => item.footer);

  return (
    <TableContainer
      className={classNames('border-none shadow-none', {
        'rounded-b': data?.length,
      })}
      numOfStickyCols={numOfStickyCols}
      ref={tableContainerRef}
    >
      <div className='w-full overflow-x-auto' {...scrollContainerRefProps}>
        {allowFloatingHeader && showFloatingHeader && (
          <div className='fixed top-0 z-10' {...floatingContainerRefProps}>
            <div className='absolute flex w-full border-spacing-0 flex-col overflow-hidden'>
              <Table className='scrollbar-hide' {...floatingHeaderRefProps}>
                <Thead className='relative'>
                  <HeaderTr className={classNames(headerClassName)}>
                    {layout.map(
                      ({ headerRender, accessor, thClassName }, i) => {
                        const hasCheckbox =
                          i === 0 && selectable?.byField && selection;
                        return (
                          <Th
                            key={accessor}
                            index={i}
                            className={classNames(
                              'sticky top-0 whitespace-nowrap border-y border-t-0 border-gray-2 bg-inherit text-left text-2xs',
                              thClassName,
                              {
                                'z-[2]':
                                  !selection?.items.length &&
                                  i < numOfStickyCols,
                              }
                            )}
                            {...thProps(i)}
                          >
                            {hasCheckbox && selection.items.length === 0 ? (
                              <div className='flex items-center'>
                                <div
                                  className='-ml-4 cursor-pointer p-4'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (selection?.items.length === 0) {
                                      checkAll();
                                    }
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      (selection?.items?.length || 0) > 0
                                    }
                                    onChange={(e) => {
                                      if (e.currentTarget.checked) {
                                        checkAll();
                                      }
                                    }}
                                  />
                                </div>
                                {headerRender()}
                              </div>
                            ) : (
                              headerRender()
                            )}
                          </Th>
                        );
                      }
                    )}
                  </HeaderTr>
                  {(selection?.items?.length || 0) > 0 && (
                    <HeaderTr
                      className={classNames(
                        ['absolute top-0 h-full w-full', headerClassName].join(
                          ' '
                        )
                      )}
                    >
                      <TableSelectionBar
                        aside={selectable?.aside}
                        allPagesSelectable={selectable?.allPagesSelectable}
                        maxSelection={selectableData?.length || 0}
                        total={selectable?.total || 0}
                        onToggleSelection={toggleSelection}
                        onToggleAllPages={toggleSelectAll}
                        currentPageSelection={currentPageSelection.length}
                      />
                    </HeaderTr>
                  )}
                </Thead>
              </Table>
            </div>
          </div>
        )}
        <Table style={{ tableLayout: 'auto', zIndex: 0 }} ref={tableRef}>
          <Thead className='relative'>
            <HeaderTr className={classNames(headerClassName)}>
              {layout.map(
                ({ headerRender, accessor, thClassName, width }, i) => {
                  const hasCheckbox =
                    i === 0 && selectable?.byField && selection;
                  return (
                    <Th
                      key={accessor}
                      index={i}
                      width={width}
                      className={classNames(
                        'whitespace-nowrap border-y border-gray-2 text-left text-2xs',
                        thClassName,
                        {
                          'z-[2] border-t border-gray-2':
                            !selection?.items.length &&
                            allowFloatingHeader &&
                            i < numOfStickyCols,
                        }
                      )}
                    >
                      {hasCheckbox && selection.items.length === 0 ? (
                        <div className='flex items-center'>
                          <div
                            className='-ml-4 cursor-pointer p-4'
                            onClick={(e) => {
                              e.stopPropagation();
                              if (selection.items.length === 0) {
                                checkAll();
                              }
                            }}
                          >
                            <Checkbox
                              checked={selection.items.length > 0}
                              onChange={(e) => {
                                if (e.currentTarget.checked) {
                                  checkAll();
                                }
                              }}
                            />
                          </div>
                          {headerRender()}
                        </div>
                      ) : (
                        headerRender()
                      )}
                    </Th>
                  );
                }
              )}
            </HeaderTr>
            {(selection?.items?.length || 0) > 0 && (
              <HeaderTr
                className={classNames(
                  ['absolute top-0 h-full w-full', headerClassName].join(' ')
                )}
              >
                <TableSelectionBar
                  aside={selectable?.aside}
                  allPagesSelectable={selectable?.allPagesSelectable}
                  maxSelection={selectableData?.length || 0}
                  total={selectable?.total || 0}
                  onToggleSelection={toggleSelection}
                  onToggleAllPages={toggleSelectAll}
                  currentPageSelection={currentPageSelection.length}
                />
              </HeaderTr>
            )}
          </Thead>
          <tbody>{children}</tbody>
          {hasFooter && (
            <tfoot>
              <TrBody
                key={data.length}
                className={classNames({
                  'group hover:bg-gray-0': hoverable,
                  'hover:bg-white': !hoverable,
                })}
              >
                {layout.map(
                  ({
                    footer,
                    accessor,
                    minWidth,
                    maxWidth,
                    width,
                    tdClassName,
                    truncated = true,
                  }) => {
                    return (
                      <TdBody
                        key={accessor}
                        className={classNames(
                          [
                            rowClassName,
                            tdClassName,
                            truncated ? 'truncate' : '',
                          ].join(' ')
                        )}
                        style={{
                          minWidth: minWidth || 'none',
                          maxWidth: maxWidth || `${DEFAULT_COLUMN_MAX_WIDTH}px`,
                          width: width,
                          background: 'inherit',
                        }}
                      >
                        {footer?.()}
                      </TdBody>
                    );
                  }
                )}
              </TrBody>
            </tfoot>
          )}
        </Table>
      </div>
    </TableContainer>
  );
}
