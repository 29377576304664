export class StringUtils {
  static capitalizeAll = (s: string, delimiter: string = '') => {
    return s
      .split(delimiter)
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(' ');
  };
}
