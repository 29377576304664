import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeDependentMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeDependentInput;
}>;


export type UpdateEmployeeDependentMutation = { __typename?: 'Mutation', updateEmployeeDependent: { __typename?: 'EmployeeDependentOutput', id: string } };


export const UpdateEmployeeDependentDocument = gql`
    mutation updateEmployeeDependent($input: UpdateEmployeeDependentInput!) {
  updateEmployeeDependent(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeDependentMutationFn = Apollo.MutationFunction<UpdateEmployeeDependentMutation, UpdateEmployeeDependentMutationVariables>;

/**
 * __useUpdateEmployeeDependentMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeDependentMutation, { data, loading, error }] = useUpdateEmployeeDependentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeDependentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeDependentMutation, UpdateEmployeeDependentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeDependentMutation, UpdateEmployeeDependentMutationVariables>(UpdateEmployeeDependentDocument, options);
      }
export type UpdateEmployeeDependentMutationHookResult = ReturnType<typeof useUpdateEmployeeDependentMutation>;
export type UpdateEmployeeDependentMutationResult = Apollo.MutationResult<UpdateEmployeeDependentMutation>;
export type UpdateEmployeeDependentMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeDependentMutation, UpdateEmployeeDependentMutationVariables>;