import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeListCustomFilterMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeListCustomFilterInput;
}>;


export type UpdateEmployeeListCustomFilterMutation = { __typename?: 'Mutation', updateEmployeeListCustomFilter: { __typename?: 'EmployeeListCustomFilterOutput', id: string } };


export const UpdateEmployeeListCustomFilterDocument = gql`
    mutation updateEmployeeListCustomFilter($input: UpdateEmployeeListCustomFilterInput!) {
  updateEmployeeListCustomFilter(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeListCustomFilterMutationFn = Apollo.MutationFunction<UpdateEmployeeListCustomFilterMutation, UpdateEmployeeListCustomFilterMutationVariables>;

/**
 * __useUpdateEmployeeListCustomFilterMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeListCustomFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeListCustomFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeListCustomFilterMutation, { data, loading, error }] = useUpdateEmployeeListCustomFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeListCustomFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeListCustomFilterMutation, UpdateEmployeeListCustomFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeListCustomFilterMutation, UpdateEmployeeListCustomFilterMutationVariables>(UpdateEmployeeListCustomFilterDocument, options);
      }
export type UpdateEmployeeListCustomFilterMutationHookResult = ReturnType<typeof useUpdateEmployeeListCustomFilterMutation>;
export type UpdateEmployeeListCustomFilterMutationResult = Apollo.MutationResult<UpdateEmployeeListCustomFilterMutation>;
export type UpdateEmployeeListCustomFilterMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeListCustomFilterMutation, UpdateEmployeeListCustomFilterMutationVariables>;