import { Loader, useMantineTheme } from '@any-ui-react/core';

interface SpinnerProps {
  spinner?: React.ReactNode;
  className?: string;
}

export const Spinner = ({ spinner, className = '' }: SpinnerProps) => {
  const { other } = useMantineTheme();

  return (
    <div className={`flex flex-1 items-center justify-center ${className}`}>
      {spinner || other['spinner']?.() || <Loader color='gray' />}
    </div>
  );
};
