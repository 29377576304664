import { useAuthContext, useEmployeeDetailContext } from '@/shared/contexts';
import { EmployeeRole, GetEmployeeDocument } from '@/shared/graphql';
import {
  useEmployeeAccess,
  useEmployeeOffBoardingPersistence,
} from '@/shared/hooks';
import { apolloClient } from '@/shared/providers';
import {
  EmployeeOffBoardingFormData,
  EmployeeOffBoardingFormDataType,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDeleteEmployeeModal } from './delete-employee-modal/useDeleteEmployeeModal';
import { offBoardingFormValidator } from './off-boarding-form.validator';
import { OffBoardingFormFields } from './OffBoardingFormFields';

export const OffBoarding = ({
  onFormStateChange,
}: {
  onFormStateChange: (isDirty: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { authUser } = useAuthContext();
  const { employeeDetail: employee } = useEmployeeDetailContext();
  const { hasOffBoardingEditAccess } = useEmployeeAccess();
  const isPersonalPage = authUser.email === employee.generalInformation.email;
  const { openDeleteEmployeeModal } = useDeleteEmployeeModal();
  const form = useForm<EmployeeOffBoardingFormDataType>({
    defaultValues: EmployeeOffBoardingFormData.parse({
      ...employee.offBoarding,
    }),
    resolver: vestResolver(offBoardingFormValidator),
  });

  const { onOffBoardingUpdate, onOffBoardingRemove } =
    useEmployeeOffBoardingPersistence({
      employeeId: employee.id,
    });

  useEffect(() => {
    onFormStateChange(form.formState.isDirty);
  }, [form.formState.isDirty, onFormStateChange]);

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit((formData) => {
          onOffBoardingUpdate(formData, () => {
            form.reset(
              { ...form.getValues() },
              {
                keepValues: false,
                keepDirty: false,
                keepDefaultValues: false,
              }
            );
            apolloClient.refetchQueries({
              include: [GetEmployeeDocument],
            });
          });
        })}
      >
        <div className='p-6'>
          <OffBoardingFormFields />
        </div>
        <hr />
        <div className='flex px-6 pb-6 pt-4'>
          {authUser.role === EmployeeRole.SuperAdmin && !isPersonalPage && (
            <span
              className='cursor-pointer self-center font-semibold text-gray-7'
              onClick={() =>
                openDeleteEmployeeModal({
                  onSuccess: () => {
                    onOffBoardingRemove(employee.id, () => {
                      navigate('/employees');
                    });
                  },
                })
              }
            >
              Delete Employee
            </span>
          )}
          {hasOffBoardingEditAccess && (
            <Button className='ml-auto bg-blue-6' type='submit'>
              Save
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
