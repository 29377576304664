import { ContractTypeForReport } from '@/shared/graphql';
import { ObjectUtils } from '@/shared/utils';
import {
  ActionMeta,
  MultiValue,
  PropsValue,
  Select,
  useMultiSelect,
} from '@any-ui-react/select';

interface SelectProps {
  isMulti?: boolean;
  value:
    | string
    | number
    | boolean
    | PropsValue<{ label: string; value: ContractTypeForReport }>
    | undefined;
  onChange?:
    | ((
        newValue: MultiValue<{
          label: string;
          value: ContractTypeForReport;
        }>,
        actionMeta: ActionMeta<{
          label: string;
          value: ContractTypeForReport;
        }>
      ) => void)
    | undefined;
  error?: string;
  label?: string;
  required?: boolean;
}

export enum ReportContractText {
  FT_PERMANENT = 'FT Permanent',
  FT_FIXED_TERM = 'FT Fixed-term',
  FT_CONSULTANT = 'FT Consultant',
  FT_FREELANCER = 'FT Freelancer',
  PART_TIME = 'Part Time',
  INTERN = 'Intern',
}

export const ReportContractTypeSelect = ({
  onChange,
  value,
  isMulti = true,
  ...rest
}: SelectProps) => {
  const contractType = ObjectUtils.arrayFromEnumValues<ContractTypeForReport>(
    ContractTypeForReport
  );

  const options = Object.keys(ReportContractText)
    .filter(
      (item) => contractType.findIndex((indexItem) => item === indexItem) !== -1
    )
    .map((mapItem) => ({
      label: String(ReportContractText[mapItem as ContractTypeForReport]),
      value: mapItem as ContractTypeForReport,
    }));

  const multiSelectProps = useMultiSelect({
    getSelectedLabel: (count) => `Contract Types (${count})`,
    options: options,
  });

  const selectProps = isMulti ? multiSelectProps : { options: options };

  return (
    <Select
      placeholder='Select Contract Type'
      isSearchable={true}
      isClearable={true}
      onChange={onChange}
      value={value}
      menuPortalTarget={document.body}
      {...rest}
      {...selectProps}
    />
  );
};
