import { EmployeeRole } from '@/shared/graphql';
import { EmployeePermissionFormDataType } from '@/shared/types';
import { create, enforce, omitWhen, test } from 'vest';

export const permissionFormValidator = create(
  ({ role, companyIds }: EmployeePermissionFormDataType) => {
    const roleSelectsCompanies = [
      EmployeeRole.AdminPrivate,
      EmployeeRole.AdminStandard,
    ].includes(role);

    omitWhen(!roleSelectsCompanies, () => {
      test('companyIds', 'Companies is required', () => {
        enforce(companyIds).isNotEmpty();
      });
    });
  }
);
