
export const Header = () => {
  return (
    <div className='ml-2 flex flex-shrink-0 items-center justify-between gap-2 overflow-hidden p-2 pt-4'>
      <div className='flex'>
        <a className='flex' href='/'>
          <img src='/anymind-logo.svg' alt='me' width='122' height='32' />
          <img
            className='p-1'
            src='/med-logo.svg'
            alt='me'
            width='43'
            height='15'
          />
        </a>
      </div>
    </div>
  );
};
