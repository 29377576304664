import { EmergencyContactType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { EmergencyContactForm } from './EmergencyContactForm';
export const EmergencyContactModal = ({
  children,
  onSuccess,
  emergencyContact,
  className,
  isNewRecord = false,
}: {
  children: React.ReactNode;
  onSuccess: (data: EmergencyContactType) => void;
  emergencyContact?: EmergencyContactType;
  className?: string;
  isNewRecord?: boolean;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      modalId,
      title: `${!isNewRecord ? 'Update' : 'Add new'} Emergency Contact`,
      size: 'lg',
      children: (
        <EmergencyContactForm
          emergencyContact={emergencyContact}
          onSuccess={(data) => {
            onSuccess(data);
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
