import { Button, ModalFooter, TextInput, Textarea } from '@any-ui-react/core';

import { EmployeeRelationSelect, PhoneNumberInput } from '@/shared/components';
import { EmployeeRelationType } from '@/shared/graphql';
import {
  EmergencyContact,
  EmergencyContactFormDataType,
  EmergencyContactType,
} from '@/shared/types';
import { vestResolver } from '@hookform/resolvers/vest';
import { CountryCode } from 'libphonenumber-js/min';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { emergencyContactFormValidationSuite } from './emergency-contact-form.validator';

export interface EmergencyContactFormProps {
  onSuccess: (data: EmergencyContactType) => void;
  onCancel: () => void;
  emergencyContact?: EmergencyContactType;
}

export const EmergencyContactForm = ({
  onSuccess,
  onCancel,
  emergencyContact,
}: EmergencyContactFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
    clearErrors,
  } = useForm<EmergencyContactFormDataType>({
    defaultValues: { ...emergencyContact },
    resolver: vestResolver(emergencyContactFormValidationSuite),
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        onSuccess(
          EmergencyContact.parse({ ...formValues, id: emergencyContact?.id })
        );
      }),
    [emergencyContact?.id, handleSubmit, onSuccess]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          required
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Enter name'}
          className='col-span-2'
        />
        <EmployeeRelationSelect
          {...register('relation')}
          className='col-span-2'
          error={errors.relation?.message}
          required
          defaultValue={emergencyContact?.relation}
          label={'Relation'}
          placeholder={'Enter relation'}
          onChange={(value) => {
            setValue('relation', value as EmployeeRelationType);
            clearErrors('relation');
          }}
        />
        <PhoneNumberInput
          {...register('phoneNumber')}
          className='col-span-2'
          required
          defaultValue={{
            countryCode:
              (emergencyContact?.phoneNumberCountryCode as CountryCode) || 'JP',
            number: emergencyContact?.phoneNumber || '',
          }}
          onChange={({ number, countryCode }) => {
            setValue('phoneNumber', number);
            if (countryCode) setValue('phoneNumberCountryCode', countryCode);
          }}
          label={'Phone Number'}
          error={
            errors.phoneNumberCountryCode?.message ||
            errors.phoneNumber?.message
          }
          placeholder={'Enter phone number'}
        />
        <Textarea
          {...register('remarks')}
          label={'Remarks'}
          error={errors.remarks?.message}
          className='col-span-2'
        />
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
