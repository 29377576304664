import { EmployeeCompanyAssetType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { CompanyAssetForm } from './CompanyAssetForm';

export const CompanyAssetModal = ({
  children,
  onSuccess,
  companyAsset,
  className,
  isNewRecord = false,
}: {
  children: React.ReactNode;
  onSuccess: (data: EmployeeCompanyAssetType) => void;
  companyAsset?: EmployeeCompanyAssetType;
  className?: string;
  isNewRecord?: boolean;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${!isNewRecord ? 'Update' : 'Add new'} Company Asset Information`,
      size: 'lg',
      children: (
        <>
          <CompanyAssetForm
            companyAsset={companyAsset}
            onSuccess={(data) => {
              onSuccess(data);
              closeModal(modalId);
            }}
            onCancel={() => closeModal(modalId)}
          />
        </>
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
