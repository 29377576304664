import { useGenerateSignInOAuthUrlQuery } from '@/shared/graphql';
import { AppEnvironment, setStorageItem } from '@/shared/utils';
import { Button } from '@any-ui-react/core';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

/* eslint-disable-next-line */
export interface LoginProps {}

export const AUTH_STATE_KEY = 'AUTH-STATE-KEY';

export const Login = (props: LoginProps) => {
  const authState = setStorageItem(AUTH_STATE_KEY, uuid()) ?? '';

  const { data, loading } = useGenerateSignInOAuthUrlQuery({
    variables: {
      state: authState,
      redirectUri:
        process.env.REACT_APP_ENVIRONMENT === AppEnvironment.LOCAL
          ? process.env.REACT_APP_AUTH_CALLBACK_URL
          : undefined,
    },
  });

  return (
    <>
      <section className='flex justify-center rounded-lg bg-white shadow-card'>
        <div className='flex h-[11.813rem] w-[28rem] shrink-0 grow flex-col justify-evenly px-[20px]'>
          <div className='flex justify-center'>
            <img
              src='/anymind-logo.svg'
              alt='anymind-logo'
              width='189'
              height='50'
            />
          </div>
          <div>
            <p className='text-center text-sm'>
              Sign in with your company gmail address
            </p>
          </div>
          <div className='text-center'>
            <Link to={data?.generateSignInOAuthUrl}>
              <Button
                variant='default'
                className='h-11 w-[17.188rem]'
                loading={loading}
              >
                <img
                  src='/google-logo.svg'
                  alt='google-logo'
                  width='33'
                  height='33'
                />
                <span>Google</span>
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};
