import { z } from 'zod';

export const ReportNumberOfEmployeeAll = z.object({
  joined: z.number().nullable().default(0),
  left: z.number().nullable().default(0),
  total: z.number().nullable().default(0),
  yearMonth: z.string().nullable().default(''),
});

export type ReportNumberOfEmployeeAllType = z.infer<
  typeof ReportNumberOfEmployeeAll
>;
