import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportNumberOfEmployeeBusinessUnitQueryVariables = Types.Exact<{
  year: Types.Scalars['Year']['input'];
  contractTypes: Types.InputMaybe<Array<Types.ContractTypeForReport> | Types.ContractTypeForReport>;
}>;


export type ReportNumberOfEmployeeBusinessUnitQuery = { __typename?: 'Query', reportNumberOfEmployeeBusinessUnit: { __typename?: 'ReportNumberOfEmployeeByBusinessUnit', yearMonthList: Array<any>, items: Array<{ __typename?: 'ReportNumberOfEmployeeByBusinessUnitItem', total: number, yearMonth: any, businessUnit: { __typename?: 'BusinessUnit', name: string } }> } };


export const ReportNumberOfEmployeeBusinessUnitDocument = gql`
    query ReportNumberOfEmployeeBusinessUnit($year: Year!, $contractTypes: [ContractTypeForReport!]) {
  reportNumberOfEmployeeBusinessUnit(year: $year, contractTypes: $contractTypes) {
    items {
      businessUnit {
        name
      }
      total
      yearMonth
    }
    yearMonthList
  }
}
    `;

/**
 * __useReportNumberOfEmployeeBusinessUnitQuery__
 *
 * To run a query within a React component, call `useReportNumberOfEmployeeBusinessUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportNumberOfEmployeeBusinessUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportNumberOfEmployeeBusinessUnitQuery({
 *   variables: {
 *      year: // value for 'year'
 *      contractTypes: // value for 'contractTypes'
 *   },
 * });
 */
export function useReportNumberOfEmployeeBusinessUnitQuery(baseOptions: Apollo.QueryHookOptions<ReportNumberOfEmployeeBusinessUnitQuery, ReportNumberOfEmployeeBusinessUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportNumberOfEmployeeBusinessUnitQuery, ReportNumberOfEmployeeBusinessUnitQueryVariables>(ReportNumberOfEmployeeBusinessUnitDocument, options);
      }
export function useReportNumberOfEmployeeBusinessUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportNumberOfEmployeeBusinessUnitQuery, ReportNumberOfEmployeeBusinessUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportNumberOfEmployeeBusinessUnitQuery, ReportNumberOfEmployeeBusinessUnitQueryVariables>(ReportNumberOfEmployeeBusinessUnitDocument, options);
        }
export function useReportNumberOfEmployeeBusinessUnitSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportNumberOfEmployeeBusinessUnitQuery, ReportNumberOfEmployeeBusinessUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportNumberOfEmployeeBusinessUnitQuery, ReportNumberOfEmployeeBusinessUnitQueryVariables>(ReportNumberOfEmployeeBusinessUnitDocument, options);
        }
export type ReportNumberOfEmployeeBusinessUnitQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeBusinessUnitQuery>;
export type ReportNumberOfEmployeeBusinessUnitLazyQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeBusinessUnitLazyQuery>;
export type ReportNumberOfEmployeeBusinessUnitSuspenseQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeBusinessUnitSuspenseQuery>;
export type ReportNumberOfEmployeeBusinessUnitQueryResult = Apollo.QueryResult<ReportNumberOfEmployeeBusinessUnitQuery, ReportNumberOfEmployeeBusinessUnitQueryVariables>;