import { EMPTY_DEFAULT } from '@/shared/components';
import { EmployeeDetailType } from '@/shared/types';
import { EmployeeTable } from './EmployeeTable';

export const CompanyEmployeeAssignmentDetails = ({
  employee,
}: {
  employee: EmployeeDetailType;
}) => {
  return (
    <>
      <div className='mb-3 mt-6'>
        <h1 className='mb-2 text-sm font-semibold'>
          Company and Employee Access
        </h1>
        <span>You can view/edit employee data other than evaluation data.</span>
      </div>

      <div className='mb-12 mt-6'>
        <h1 className='mb-2 text-sm'>Companies</h1>
        <span>
          {employee.permission.companies.map((item) => item.name).join(', ') ||
            EMPTY_DEFAULT}
        </span>
      </div>
      <EmployeeTable
        items={employee.permission.employees ?? []}
        loading={false}
      />
    </>
  );
};
