import { UserEmployeeType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { AddEmployeeForm } from './AddEmployeeForm';

export const AddEmployeeModal = ({
  children,
  activeEmployees,
  companyIds,
  onSuccess,
  className,
}: {
  children: React.ReactNode;
  activeEmployees: UserEmployeeType[];
  companyIds: string[];
  onSuccess: (selection: UserEmployeeType[]) => void;
  className?: string;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      modalId,
      title: 'Add Employees',
      size: 'lg',
      children: (
        <AddEmployeeForm
          activeEmployees={activeEmployees}
          onSuccess={(selection: UserEmployeeType[]) => {
            onSuccess(selection);
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          companyIds={companyIds || []}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
