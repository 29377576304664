import {
  useCreateEmployeeListCustomFilterMutation,
  useUpdateEmployeeListCustomFilterMutation,
} from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { EmployeeListFilterType } from '@/shared/types';
import { Button, Checkbox, ModalFooter, TextInput } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SaveCustomFilterFormData,
  saveCustomFilterFormValidationSuite,
} from './save-custom-filter-form.validator';

export interface SaveCustomFilterFormProps {
  filters: FilterProps<EmployeeListFilterType>;
  onSuccess: () => void;
  onFailure: () => void;
  onCancel: () => void;
}

export const SaveCustomFilterForm = ({
  filters: filterProps,
  onSuccess,
  onFailure,
  onCancel,
}: SaveCustomFilterFormProps) => {
  const filters = filterProps.current;
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
  } = useForm<SaveCustomFilterFormData>({
    defaultValues: { name: filters.name ?? '', myFilter: filters.isOpenFilter },
    resolver: vestResolver(saveCustomFilterFormValidationSuite),
  });

  const myFilter = watch('myFilter');
  const [isCreate, setIsCreate] = useState(false);
  const [createRecord] = useCreateEmployeeListCustomFilterMutation();
  const [updateRecord] = useUpdateEmployeeListCustomFilterMutation();

  return (
    <form
      noValidate
      onSubmit={handleSubmit((formData) => {
        const variableInput = {
          businessUnitIds: filters.businessUnitIds,
          companyIds: filters.companyIds,
          contractTypes: filters.contractTypes,
          departmentIds: filters.departmentIds,
          managerIds: filters.managerIds,
          externalAccountGoogle: filters.externalAccountGoogle,
          externalAccountGreenhouse: filters.externalAccountGreenhouse,
          externalAccountHubspot: filters.externalAccountHubspot,
          externalAccountSlack: filters.externalAccountSlack,
          hiredDate: filters.hiredDate
            ? {
                start: filters.hiredDate.start,
                end: filters.hiredDate.end,
              }
            : null,
          employmentEndDate: filters.employeeEndDate
            ? {
                start: filters.employeeEndDate.start,
                end: filters.employeeEndDate.end,
              }
            : null,
          isLeave: filters.isLeave,
          isOnSecondment: filters.isOnSecondment,
          jobIds: filters.jobIds,
          keyword: filters.keyword,
          levels: filters.levels,
          locationIds: filters.locationIds,
          divisionIds: filters.divisionIds,
          nationalityIds: filters.nationalityIds,
          roles: filters.roles,
          statuses: filters.statuses,
          managerIncludesAllConnections: filters.managerIncludesAllConnections,
        };
        if (isCreate) {
          createRecord({
            variables: {
              input: {
                ...variableInput,
                name: formData.name,
                isOpenFilter: formData.myFilter,
              },
            },
            onCompleted: (data) => {
              onSuccess();
              filterProps.updateFilters({
                ...filterProps.current,
                name: formData.name,
                id: data.createEmployeeListCustomFilter.id,
              });
            },
            onError: () => onFailure(),
          });
        } else {
          updateRecord({
            variables: {
              input: {
                ...variableInput,
                name: formData.name,
                isOpenFilter: formData.myFilter,
                id: filters?.id ?? '',
              },
            },
            onCompleted: (data) => {
              onSuccess();
              filterProps.updateFilters({
                ...filterProps.current,
                name: formData.name,
                id: data.updateEmployeeListCustomFilter.id,
              });
            },
            onError: () => onFailure(),
          });
        }
      })}
    >
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          label={'Filter name'}
          required
          error={errors.name?.message}
          placeholder={'Enter name'}
          className='col-span-2'
        />
        <Checkbox
          {...register('myFilter')}
          error={errors.myFilter?.message}
          checked={myFilter}
          label={'My Filter'}
          onChange={(e) => {
            setValue('myFilter', e.currentTarget.checked, {
              shouldDirty: true,
            });
          }}
        />
      </fieldset>
      <ModalFooter>
        <Button
          className='bg-blue-6'
          type='submit'
          onClick={() => setIsCreate(false)}
          loading={isSubmitting}
          disabled={filterProps.current.id === ''}
        >
          Update
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          onClick={() => setIsCreate(true)}
          loading={isSubmitting}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
