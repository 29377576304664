import { z } from 'zod';

export const Department = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  enabled: z.boolean().default(false),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
});
export type DepartmentType = z.infer<typeof Department>;
