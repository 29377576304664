import { z } from 'zod';

export const EmployeeExternalAccountFormData = z.object({
  autoCreateGoogleWorkspace: z.boolean().default(false),
  autoCreateSlack: z.boolean().default(false),
  autoCreateHubspot: z.boolean().default(false),
  autoCreateGreenhouse: z.boolean().default(false),
  timingToCreateAccounts: z.boolean().default(false),
});
export type EmployeeExternalAccountFormDataType = z.infer<
  typeof EmployeeExternalAccountFormData
>;
