import { EmployeeRole } from '@/shared/graphql';
import { z } from 'zod';
import { UserEmployee } from '../user-employee';

export const EmployeePermissionFormData = z.object({
  role: z.nativeEnum(EmployeeRole).default(EmployeeRole.Basic),
  companyIds: z.array(z.string()).default([]),
  employeeIds: z.array(z.string()).default([]),
  employees: z.array(UserEmployee).default([]),
  isAllEmployees: z.boolean().default(true),
});
export type EmployeePermissionFormDataType = z.infer<
  typeof EmployeePermissionFormData
>;
