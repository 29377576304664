import { toast } from '@/shared/components';
import { ListBusinessUnitsDocument } from '@/shared/graphql';
import { BusinessUnitType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { BusinessUnitForm } from './BusinessUnitForm';

export const BusinessUnitModal = ({
  children,
  className,
  businessUnit,
}: {
  children: React.ReactNode;
  className?: string;
  businessUnit?: BusinessUnitType;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${businessUnit ? 'Edit' : 'Add New'} Business Unit`,
      size: 'lg',
      children: (
        <BusinessUnitForm
          onSuccess={async () => {
            toast.success();
            await client.refetchQueries({
              include: [ListBusinessUnitsDocument],
            });
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          defaultValues={businessUnit}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
