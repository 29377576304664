import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeePersonalInformationMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeePersonalInformationInput;
}>;


export type UpdateEmployeePersonalInformationMutation = { __typename?: 'Mutation', updateEmployeePersonalInformation: { __typename?: 'EmployeeOutput', id: string } };


export const UpdateEmployeePersonalInformationDocument = gql`
    mutation updateEmployeePersonalInformation($input: UpdateEmployeePersonalInformationInput!) {
  updateEmployeePersonalInformation(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeePersonalInformationMutationFn = Apollo.MutationFunction<UpdateEmployeePersonalInformationMutation, UpdateEmployeePersonalInformationMutationVariables>;

/**
 * __useUpdateEmployeePersonalInformationMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeePersonalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeePersonalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeePersonalInformationMutation, { data, loading, error }] = useUpdateEmployeePersonalInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeePersonalInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeePersonalInformationMutation, UpdateEmployeePersonalInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeePersonalInformationMutation, UpdateEmployeePersonalInformationMutationVariables>(UpdateEmployeePersonalInformationDocument, options);
      }
export type UpdateEmployeePersonalInformationMutationHookResult = ReturnType<typeof useUpdateEmployeePersonalInformationMutation>;
export type UpdateEmployeePersonalInformationMutationResult = Apollo.MutationResult<UpdateEmployeePersonalInformationMutation>;
export type UpdateEmployeePersonalInformationMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeePersonalInformationMutation, UpdateEmployeePersonalInformationMutationVariables>;