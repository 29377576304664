import {
  CompanyLazySelect,
  ContractTypeSelect,
  DEFAULT_PAGE_SIZE,
  DayPicker,
} from '@/shared/components';
import { ContractType } from '@/shared/graphql';
import { EmployeeGeneralInformationFormDataType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { TextInput } from '@any-ui-react/core';
import { Controller, useFormContext } from 'react-hook-form';

export const ContractInformationFormFields = () => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<EmployeeGeneralInformationFormDataType>();

  return (
    <fieldset className='form-fieldset'>
      <Controller
        control={control}
        name='contractType'
        render={({ field: { value } }) => (
          <>
            <ContractTypeSelect
              label='Contract Type'
              required={true}
              isMulti={false}
              error={errors.contractType?.message}
              onChange={(newValue) => {
                setValue(
                  'contractType',
                  (newValue as unknown as { value: ContractType }).value ??
                    null,
                  {
                    shouldDirty: true,
                  }
                );
              }}
              value={value}
            />
          </>
        )}
      />

      <TextInput
        {...register('employeeCode')}
        error={errors.employeeCode?.message}
        label='Employee Code'
        placeholder='Enter Employee Code'
      />

      <Controller
        control={control}
        name='companyId'
        render={({ field: { onChange, ...field } }) => (
          <CompanyLazySelect
            {...field}
            required
            isMulti={false}
            error={errors.companyId?.message}
            label='Company'
            valueFormatter={(item) => item.id}
            className='select-has-min-w-menu'
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                keyword: null,
                filterOnlyAccessible: true,
              },
            }}
            listQueryVariables={{
              filterOnlyAccessible: true,
            }}
            placeholder='Select Company'
            onChange={(option) => onChange(option?.value)}
          />
        )}
      />

      <Controller
        control={control}
        name='contractEndDate'
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <DayPicker
              {...field}
              label='Contract End Date'
              error={errors.contractEndDate?.message}
              placeholder='Select Contract End Date'
              value={value ? new Date(value).toISOString() : undefined}
              onChange={(value) => {
                onChange(
                  DateUtils.formatDate(
                    new Date(value || '').toISOString(),
                    'yyyy-MM-dd'
                  )
                );
              }}
            />
          );
        }}
      />
    </fieldset>
  );
};
