import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyEmailDomainMutationVariables = Types.Exact<{
  input: Types.UpdateCompanyEmailDomainInput;
}>;


export type UpdateCompanyEmailDomainMutation = { __typename?: 'Mutation', updateCompanyEmailDomain: { __typename?: 'CompanyEmailDomainOutput', id: string } };


export const UpdateCompanyEmailDomainDocument = gql`
    mutation updateCompanyEmailDomain($input: UpdateCompanyEmailDomainInput!) {
  updateCompanyEmailDomain(input: $input) {
    id
  }
}
    `;
export type UpdateCompanyEmailDomainMutationFn = Apollo.MutationFunction<UpdateCompanyEmailDomainMutation, UpdateCompanyEmailDomainMutationVariables>;

/**
 * __useUpdateCompanyEmailDomainMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyEmailDomainMutation, { data, loading, error }] = useUpdateCompanyEmailDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyEmailDomainMutation, UpdateCompanyEmailDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyEmailDomainMutation, UpdateCompanyEmailDomainMutationVariables>(UpdateCompanyEmailDomainDocument, options);
      }
export type UpdateCompanyEmailDomainMutationHookResult = ReturnType<typeof useUpdateCompanyEmailDomainMutation>;
export type UpdateCompanyEmailDomainMutationResult = Apollo.MutationResult<UpdateCompanyEmailDomainMutation>;
export type UpdateCompanyEmailDomainMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyEmailDomainMutation, UpdateCompanyEmailDomainMutationVariables>;