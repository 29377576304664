import { EmergencyContactFormDataType } from '@/shared/types';
import {
  CountryCode,
  isSupportedCountry,
  isValidPhoneNumber,
} from 'libphonenumber-js/min';
import { create, enforce, skipWhen, test } from 'vest';

export const emergencyContactFormValidationSuite = create(
  ({
    name,
    phoneNumber,
    phoneNumberCountryCode,
    relation,
  }: EmergencyContactFormDataType) => {
    test('name', 'Name is required', () => {
      enforce(name).isNotEmpty();
    });
    test('relation', 'Relation is required', () => {
      enforce(relation).isNotEmpty();
    });

    test('phoneNumber', 'Phone Number is required', () => {
      enforce(phoneNumber).isNotEmpty();
    });

    test(
      'phoneNumberCountryCode',
      'Phone Number Country Code is required',
      () => {
        enforce(phoneNumberCountryCode).isNotEmpty();
      }
    );

    skipWhen(!isSupportedCountry(phoneNumberCountryCode as string), () =>
      test('phoneNumber', 'Phone Number is in invalid format', () => {
        enforce(
          isValidPhoneNumber(
            phoneNumber as string,
            phoneNumberCountryCode as CountryCode
          )
        ).isTruthy();
      })
    );
  }
);
