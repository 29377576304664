import { toast } from '@/shared/components';
import { ListCompanyEmailDomainsDocument } from '@/shared/graphql';
import { CompanyEmailDomainType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { CompanyEmailDomainForm } from './CompanyEmailDomainForm';

export const CompanyEmailDomainModal = ({
  children,
  className,
  companyEmailDomain,
}: {
  children: React.ReactNode;
  className?: string;
  companyEmailDomain?: CompanyEmailDomainType;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${companyEmailDomain ? 'Edit' : 'Add New'} Company Email Domain`,
      size: 'lg',
      children: (
        <CompanyEmailDomainForm
          onSuccess={async () => {
            toast.success();
            await client.refetchQueries({
              include: [ListCompanyEmailDomainsDocument],
            });
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          defaultValues={companyEmailDomain}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
