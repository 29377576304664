import {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useMemo,
  useState,
} from 'react';

import {
  ActionMeta,
  AsyncPaginateSelectProps,
  GroupBase,
  MultiValue,
  OnChangeValue,
  PropsValue,
  SelectForwardRef,
  SingleValue,
  useAsyncPaginateMultiSelect,
} from '@any-ui-react/select';
import { QueryHookOptions } from '@apollo/client';
import { useAsyncEffect } from 'use-async-effect';

import {
  ListDivisionsQuery,
  ListDivisionsQueryVariables,
  useGetDivisionLazyQuery,
  useListDivisionsLazyQuery,
} from '@/shared/graphql';
import { LazySelect } from '../lazy-select';
import { DEFAULT_PAGE_SIZE } from '../table/constants';
import { useLoadOptions } from './useLoadOptions';

interface LoadOptionDefaultOption<T> {
  label: ReactNode;
  value: T;
}

type LoadOptionAdditional = {
  pageNumber: number;
};

interface BasicOption<T> {
  label: string;
  value: T;
}

type ItemType = {
  id: string;
  name: string;
};

interface DivisionSelectProps<T = string, IsMulti extends boolean = boolean>
  extends Omit<
    AsyncPaginateSelectProps<
      LoadOptionDefaultOption<T>,
      GroupBase<LoadOptionDefaultOption<T>>,
      LoadOptionAdditional,
      IsMulti
    >,
    'loadOptions' | 'components' | 'value' | 'wrapper'
  > {
  labelFormatter?: (item: ItemType) => ReactNode;
  valueFormatter?: (item: ItemType) => T;
  optionMapper?: (item: ItemType) => LoadOptionDefaultOption<T>;
  listQueryVariables?: ListDivisionsQueryVariables;
  listQueryOptions?: QueryHookOptions<
    ListDivisionsQuery,
    ListDivisionsQueryVariables
  >;
  defaultQueryOptions: QueryHookOptions<
    ListDivisionsQuery,
    ListDivisionsQueryVariables
  >;
  isMulti?: IsMulti;
  value?: PropsValue<LoadOptionDefaultOption<T> | T>;
  onChange?: (
    newValue: OnChangeValue<LoadOptionDefaultOption<T>, IsMulti>,
    actionMeta: ActionMeta<LoadOptionDefaultOption<T> | T>
  ) => void;
}
const DivisionSelectGn = <T = string, IsMulti extends boolean = false>(
  {
    labelFormatter = (item) => item.name,
    valueFormatter = (item) => item.id as unknown as T,
    value: variants,
    listQueryOptions,
    listQueryVariables,
    defaultQueryOptions,
    optionMapper,
    isMulti,
    isClearable = true,
    ...rest
  }: DivisionSelectProps<T, IsMulti>,
  ref: SelectForwardRef<
    LoadOptionDefaultOption<T>,
    IsMulti,
    GroupBase<LoadOptionDefaultOption<T>>
  >
) => {
  const arrayValue = useMemo(() => {
    const value = variants ? variants : [];
    return Array.isArray(value) ? value : [value];
  }, [variants]);

  const [values, setValues] = useState<BasicOption<T>[]>([]);

  const paginateMultiSelectProps = useAsyncPaginateMultiSelect<
    LoadOptionDefaultOption<T>,
    GroupBase<LoadOptionDefaultOption<T>>
  >({
    getSelectedLabel: () => `Multiple Divisions`,
  });

  const [queryVariants] = useListDivisionsLazyQuery(defaultQueryOptions);
  const [queryDivision] = useGetDivisionLazyQuery();

  const { loadOptions } = useLoadOptions<
    ItemType,
    T,
    Omit<ListDivisionsQuery, '__typename'>,
    ListDivisionsQueryVariables
  >({
    labelFormatter,
    valueFormatter,
    listQuery: {
      variables: {
        pageNumber: listQueryVariables?.pageNumber ?? 1,
        pageSize: listQueryVariables?.pageSize ?? DEFAULT_PAGE_SIZE,
        keyword: null,
        enabled: null,
      },
      options: listQueryOptions,
      lazyQuery: queryVariants,
    },
    optionMapper,
  });

  useAsyncEffect(
    async (isMounted) => {
      if (!arrayValue.length) {
        setValues([]);
        return;
      }

      if (defaultQueryOptions.skip) return;

      try {
        if (!isMounted()) return;

        const { data: divisionData } = await queryDivision({
          variables: { id: arrayValue[0] },
        });

        const selectedOption = arrayValue.map((value, index) => {
          return {
            label: index === 0 ? divisionData?.division?.name || '' : '',
            value,
          };
        });

        setValues(selectedOption);
      } catch (e) {
        if (!isMounted()) return;
      }
    },
    [arrayValue]
  );

  const multiProps = isMulti ? paginateMultiSelectProps : {};

  /**
   * Only use inner state if the selector is multi or is clearable
   * if not, leave the value to the parent component
   * to avoid setting the state when it's not necessary
   */

  const setNewValues = (
    value:
      | MultiValue<LoadOptionDefaultOption<T>>
      | SingleValue<LoadOptionDefaultOption<T>>
  ) => {
    if (!isMulti && !isClearable) return;
    let valueToSet: BasicOption<T>[] = [];

    if (value !== null) {
      valueToSet = Array.isArray(value) ? value : [value];
    }

    setValues(valueToSet);
  };

  return (
    <>
      <LazySelect
        {...multiProps}
        className='[&_.any-select\_\_value-container]:flex-nowrap [&_.option-label]:truncate [&_label]:truncate'
        // TODO this line should not be needed
        // It is currently used to redraw the component so it can trigger the query again
        // This whole Brand/Supplier/Account/Variant selector need to be cleaned up
        key={`${JSON.stringify(listQueryVariables)}`}
        isClearable={isClearable}
        isSearchable
        {...rest}
        onChange={(value, meta) => {
          rest?.onChange?.(value, meta);
          setNewValues(value);
        }}
        value={values}
        ref={ref}
        loadOptions={loadOptions}
        menuPortalTarget={document.body}
      />
    </>
  );
};

export const DivisionLazySelect = forwardRef(DivisionSelectGn) as <
  T = string,
  IsMulti extends boolean = boolean
>(
  p: DivisionSelectProps<T, IsMulti> & {
    ref?: Ref<
      SelectForwardRef<
        LoadOptionDefaultOption<T>,
        IsMulti,
        GroupBase<LoadOptionDefaultOption<T>>
      >
    >;
  }
) => ReactElement;
