import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListBusinessUnitsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  enabled: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  keyword: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ListBusinessUnitsQuery = { __typename?: 'Query', listBusinessUnits: { __typename?: 'ListBusinessUnits', totalCount: number, items: Array<{ __typename?: 'BusinessUnit', id: string, name: string, enabled: boolean, createdAt: any, updatedAt: any, divisionId: string, division: { __typename?: 'Division', id: string, name: string } }> } };


export const ListBusinessUnitsDocument = gql`
    query listBusinessUnits($pageNumber: Int!, $pageSize: Int!, $enabled: Boolean, $keyword: String) {
  listBusinessUnits(
    pageNumber: $pageNumber
    pageSize: $pageSize
    enabled: $enabled
    keyword: $keyword
  ) {
    items {
      id
      name
      enabled
      createdAt
      updatedAt
      division {
        id
        name
      }
      divisionId
    }
    totalCount
  }
}
    `;

/**
 * __useListBusinessUnitsQuery__
 *
 * To run a query within a React component, call `useListBusinessUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBusinessUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBusinessUnitsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      enabled: // value for 'enabled'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useListBusinessUnitsQuery(baseOptions: Apollo.QueryHookOptions<ListBusinessUnitsQuery, ListBusinessUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBusinessUnitsQuery, ListBusinessUnitsQueryVariables>(ListBusinessUnitsDocument, options);
      }
export function useListBusinessUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBusinessUnitsQuery, ListBusinessUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBusinessUnitsQuery, ListBusinessUnitsQueryVariables>(ListBusinessUnitsDocument, options);
        }
export function useListBusinessUnitsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListBusinessUnitsQuery, ListBusinessUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListBusinessUnitsQuery, ListBusinessUnitsQueryVariables>(ListBusinessUnitsDocument, options);
        }
export type ListBusinessUnitsQueryHookResult = ReturnType<typeof useListBusinessUnitsQuery>;
export type ListBusinessUnitsLazyQueryHookResult = ReturnType<typeof useListBusinessUnitsLazyQuery>;
export type ListBusinessUnitsSuspenseQueryHookResult = ReturnType<typeof useListBusinessUnitsSuspenseQuery>;
export type ListBusinessUnitsQueryResult = Apollo.QueryResult<ListBusinessUnitsQuery, ListBusinessUnitsQueryVariables>;