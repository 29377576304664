import { ReactNode } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

export type SkeletonLoaderProps = {
  loading?: boolean;
  children?: ReactNode;
} & SkeletonProps;

export const SkeletonLoader = ({
  loading,
  children,
  ...rest
}: SkeletonLoaderProps) => {
  return <>{loading ? <Skeleton {...rest} /> : children}</>;
};
