import {
  GoogleLogoIcon,
  GreenhouseLogoIcon,
  HubspotLogoIcon,
  NoResult,
  SlackLogoIcon,
  Table,
  TableLayout,
  TableProps,
} from '@/shared/components';
import {
  EmployeeExternalAccountStatus,
  EmployeeExternalAccountType as NativeEmployeeExternalAccountType,
} from '@/shared/graphql';
import { EmployeeExternalAccountType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';
import { Badge } from '@any-ui-react/core';
import { useMemo } from 'react';

interface ExternalAccountTableProps {
  items: EmployeeExternalAccountType[];
  loading?: boolean;
}

export const ExternalAccountTable = ({
  items,
  loading = false,
}: ExternalAccountTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Account Type',
          thClassName: 'pl-10',
          tdClassName: 'pl-10 py-3.5',
          accessor: 'accountType',
          cellRender: ({ accountType }: EmployeeExternalAccountType) => {
            const getIcon = (
              accountType: NativeEmployeeExternalAccountType | null
            ) => {
              switch (accountType) {
                case NativeEmployeeExternalAccountType.Google:
                  return (
                    <GoogleLogoIcon
                      className='mr-2 inline'
                      height={16}
                      width={16}
                    />
                  );
                case NativeEmployeeExternalAccountType.Greenhouse:
                  return (
                    <GreenhouseLogoIcon
                      className='mr-2 inline'
                      height={16}
                      width={16}
                    />
                  );
                case NativeEmployeeExternalAccountType.Hubspot:
                  return (
                    <HubspotLogoIcon
                      className='mr-2 inline'
                      height={16}
                      width={16}
                    />
                  );
                case NativeEmployeeExternalAccountType.Slack:
                  return (
                    <SlackLogoIcon
                      className='mr-2 inline'
                      height={16}
                      width={16}
                    />
                  );
                default:
                  return (
                    <GoogleLogoIcon
                      className='mr-2 inline'
                      height={16}
                      width={16}
                    />
                  );
              }
            };
            return (
              <>
                <span>
                  {getIcon(accountType)}
                  {StringUtils.capitalizeAll(accountType || '', '_')}
                </span>
              </>
            );
          },
        },
        {
          headerRender: () => 'Status',
          accessor: 'status',
          cellRender: ({ status }: EmployeeExternalAccountType) => {
            const getStatusColor = (
              status: EmployeeExternalAccountStatus | null
            ) => {
              switch (status) {
                case EmployeeExternalAccountStatus.Active:
                  return 'green';
                case EmployeeExternalAccountStatus.Inactive:
                  return 'red';
                case EmployeeExternalAccountStatus.NotExisting:
                  return 'gray';
                default:
                  return 'gray';
              }
            };
            return (
              <Badge color={getStatusColor(status)}>
                {StringUtils.capitalizeAll(status || '', '_')}
              </Badge>
            );
          },
        },
        {
          headerRender: () => 'Email',
          accessor: 'email',
          cellRender: ({ email }: EmployeeExternalAccountType) => {
            return <span>{email}</span>;
          },
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: ({ userType }: EmployeeExternalAccountType) => {
            return (
              <span>{StringUtils.capitalizeAll(userType || '', '_')}</span>
            );
          },
        },
      ] as TableLayout<EmployeeExternalAccountType>[],
    []
  );

  const tableCommonProps: TableProps<EmployeeExternalAccountType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<EmployeeExternalAccountType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
