import { z } from 'zod';

export const Location = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  enabled: z.boolean().default(false),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  countryId: z.string().nullable().default(''),
});
export type LocationType = z.infer<typeof Location>;
