import {
  Button,
  ModalFooter,
  OnOffSwitch,
  TextInput,
} from '@any-ui-react/core';

import { toast } from '@/shared/components';
import {
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
} from '@/shared/graphql';
import { DepartmentType } from '@/shared/types';
import { DepartmentError } from '@/shared/utils';
import { ApolloError, BaseMutationOptions } from '@apollo/client';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  DepartmentFormData,
  departmentFormValidationSuite,
} from './department-form.validator';

export interface DepartmentFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  defaultValues?: DepartmentType;
}

export const DepartmentForm = ({
  defaultValues,
  onSuccess,
  onCancel,
}: DepartmentFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    setError,
  } = useForm<DepartmentFormData>({
    defaultValues,
    resolver: vestResolver(departmentFormValidationSuite),
  });

  const onError = (error: ApolloError, clientOptions?: BaseMutationOptions) => {
    if (error.message === DepartmentError.DEPARTMENT_NAME_IS_NOT_UNIQUE) {
      setError('name', { message: 'Name exists already' });
    } else {
      toast.error(error.message);
    }
  };

  const [createDepartment] = useCreateDepartmentMutation({
    onError,
    onCompleted: async (data) => {
      if (data.createDepartment.id) {
        onSuccess();
      }
    },
  });
  const [updateDepartment] = useUpdateDepartmentMutation({
    onError,
    onCompleted: (data) => {
      if (data.updateDepartment.id) {
        onSuccess();
      }
    },
  });

  const [enabled] = watch(['enabled']);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        const isNewRecord = defaultValues === undefined;
        if (isNewRecord) {
          await createDepartment({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled || false,
              },
            },
          });
        } else {
          await updateDepartment({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled,
                id: defaultValues.id,
              },
            },
          });
        }
      }),
    [createDepartment, defaultValues, handleSubmit, updateDepartment]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Enter name'}
          className='col-span-2'
          required
        />
        <OnOffSwitch
          key={'12'}
          checked={enabled}
          onChange={(e) => {
            setValue('enabled', e.currentTarget.checked);
          }}
          label='Show on the dropdown'
        />
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
