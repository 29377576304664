import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmployeeJobHistoryMutationVariables = Types.Exact<{
  input: Types.CreateEmployeeJobHistoryInput;
}>;


export type CreateEmployeeJobHistoryMutation = { __typename?: 'Mutation', createEmployeeJobHistory: { __typename?: 'EmployeeJobHistoryOutput', id: string } };


export const CreateEmployeeJobHistoryDocument = gql`
    mutation createEmployeeJobHistory($input: CreateEmployeeJobHistoryInput!) {
  createEmployeeJobHistory(input: $input) {
    id
  }
}
    `;
export type CreateEmployeeJobHistoryMutationFn = Apollo.MutationFunction<CreateEmployeeJobHistoryMutation, CreateEmployeeJobHistoryMutationVariables>;

/**
 * __useCreateEmployeeJobHistoryMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeJobHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeJobHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeJobHistoryMutation, { data, loading, error }] = useCreateEmployeeJobHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeJobHistoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeJobHistoryMutation, CreateEmployeeJobHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeJobHistoryMutation, CreateEmployeeJobHistoryMutationVariables>(CreateEmployeeJobHistoryDocument, options);
      }
export type CreateEmployeeJobHistoryMutationHookResult = ReturnType<typeof useCreateEmployeeJobHistoryMutation>;
export type CreateEmployeeJobHistoryMutationResult = Apollo.MutationResult<CreateEmployeeJobHistoryMutation>;
export type CreateEmployeeJobHistoryMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeJobHistoryMutation, CreateEmployeeJobHistoryMutationVariables>;