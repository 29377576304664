import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeJobHistoryMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeJobHistoryInput;
}>;


export type UpdateEmployeeJobHistoryMutation = { __typename?: 'Mutation', updateEmployeeJobHistory: { __typename?: 'EmployeeJobHistoryOutput', id: string } };


export const UpdateEmployeeJobHistoryDocument = gql`
    mutation updateEmployeeJobHistory($input: UpdateEmployeeJobHistoryInput!) {
  updateEmployeeJobHistory(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeJobHistoryMutationFn = Apollo.MutationFunction<UpdateEmployeeJobHistoryMutation, UpdateEmployeeJobHistoryMutationVariables>;

/**
 * __useUpdateEmployeeJobHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeJobHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeJobHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeJobHistoryMutation, { data, loading, error }] = useUpdateEmployeeJobHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeJobHistoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeJobHistoryMutation, UpdateEmployeeJobHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeJobHistoryMutation, UpdateEmployeeJobHistoryMutationVariables>(UpdateEmployeeJobHistoryDocument, options);
      }
export type UpdateEmployeeJobHistoryMutationHookResult = ReturnType<typeof useUpdateEmployeeJobHistoryMutation>;
export type UpdateEmployeeJobHistoryMutationResult = Apollo.MutationResult<UpdateEmployeeJobHistoryMutation>;
export type UpdateEmployeeJobHistoryMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeJobHistoryMutation, UpdateEmployeeJobHistoryMutationVariables>;