import { useGetEmployeeQuery } from '@/shared/graphql';
import { EmployeeDetail, EmployeeDetailType } from '@/shared/types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useEmployeeInParams = () => {
  const { id = '' } = useParams();

  const { data: employeeData, loading } = useGetEmployeeQuery({
    variables: { id },
    skip: !id,
  });

  const [employee, setEmployee] = useState<EmployeeDetailType | null>(null);

  useEffect(() => {
    if (!employeeData?.employee) return;

    const currentEmployee = employeeData?.employee;
    setEmployee(
      EmployeeDetail.parse({
        id: currentEmployee.id,
        generalInformation: {
          ...currentEmployee,
          preferredName: currentEmployee.generalInformation.preferredName,
          legalFullName: currentEmployee.generalInformation.legalFullName,
          nameInLocalLanguage:
            currentEmployee.generalInformation.nameInLocalLanguage,
          employeeId: currentEmployee.id,
          employeeCode: currentEmployee.currentContract?.employeeCode,
          email: currentEmployee.generalInformation?.companyEmail?.fullAddress,
          pictureUrl: currentEmployee.pictureUrl,
          hiredDate: currentEmployee.generalInformation.hiredDate,
          contractType: currentEmployee.currentContract?.contractType,
          contractEndDate: currentEmployee.currentContract?.effectiveDateUntil,
          officeLocation: currentEmployee.currentPosition?.location.name,
          company: currentEmployee.currentContract?.company.name,
          businessUnit: currentEmployee.currentPosition?.businessUnit.name,
          department: currentEmployee.currentPosition?.department.name,
          job: currentEmployee.currentPosition?.job.name,
          level: currentEmployee.currentPosition?.level,
          primaryManagerId:
            currentEmployee.generalInformation.primaryManager?.id,
          primaryManager:
            currentEmployee.generalInformation.primaryManager
              ?.generalInformation.preferredName,
          dottedLineManagerId:
            currentEmployee.generalInformation.dottedLineManager?.id,
          dottedLineManager:
            currentEmployee.generalInformation.dottedLineManager
              ?.generalInformation.preferredName,
          needsProbationReview:
            currentEmployee.generalInformation.needsProbationReview,
          isNewGraduate: currentEmployee.generalInformation.isNewGraduate,
          emailUsername:
            currentEmployee.generalInformation.companyEmail?.account,
          emailDomain:
            currentEmployee.generalInformation.companyEmail?.domainId,
          emailAddress:
            currentEmployee.generalInformation.companyEmail?.fullAddress,
          nationalityId: currentEmployee.generalInformation?.nationality.id,
          nationality: currentEmployee.generalInformation?.nationality.name,
          lastWorkingDate: currentEmployee.offBoarding?.lastWorkingDate,
          terminationDate: currentEmployee.offBoarding?.employmentEndDate,
          typeOfAttrition: currentEmployee.offBoarding?.typeOfAttrition?.name,
          reasonForLeaving: currentEmployee.offBoarding?.reasonForLeaving?.name,
          hiringSource: currentEmployee.generalInformation.hiringSource,
          jobTitle: currentEmployee.currentPosition?.jobTitle,
          team: currentEmployee.currentPosition?.team,
          location: currentEmployee.currentPosition?.location.name,
          greenhouseLink: currentEmployee.generalInformation.greenhouseLink,
          directReports: currentEmployee.directReports.map((item) => ({
            name: item.generalInformation.preferredName,
            company: item.currentContract?.company.name,
            location: item.currentPosition?.location.name,
            businessUnit: item.currentPosition?.businessUnit.name,
            job: item.currentPosition?.job.name,
            level: item.currentPosition?.level,
            jobTitle: item.currentPosition?.jobTitle,
          })),
        },
        personalInformation: {
          id: currentEmployee.id,
          gender: currentEmployee.personalInformation?.gender,
          dateOfBirth: currentEmployee.personalInformation?.dateOfBirth,
          personalPhoneNumber:
            currentEmployee.personalInformation?.personalPhoneNumber?.number,
          personalPhoneNumberCountryCode:
            currentEmployee.personalInformation?.personalPhoneNumber?.countryId,
          personalEmail: currentEmployee.personalInformation?.personalEmail,
          currentAddress: currentEmployee.personalInformation?.currentAddress,
          maritalStatus: currentEmployee.personalInformation?.maritalStatus,
          permanentAddress:
            currentEmployee.personalInformation?.permanentAddress,
          residentialCountryId:
            currentEmployee.personalInformation?.residentialCountryId,
          emergencyContacts:
            currentEmployee.personalInformation?.emergencyContacts.map(
              (item) => ({
                ...item,
                phoneNumber: item.phoneNumber.number,
                phoneNumberCountryCode: item.phoneNumber.countryId,
              })
            ),
          visas: currentEmployee.personalInformation?.visas.map((item) => item),
          dependents: currentEmployee.personalInformation?.dependencies.map(
            (item) => item
          ),
          companyAsset: currentEmployee.personalInformation?.companyAssets.map(
            (item) => ({
              ...item,
              assetType: item.type,
              assetName: item.name,
              assetNumber: item.number,
            })
          ),
          // ID
          idType: currentEmployee.personalInformation?.idType,
          idNumber: currentEmployee.personalInformation?.idNumber,
          idIssuedDate: currentEmployee.personalInformation?.idIssuedDate,
          idExpiryDate: currentEmployee.personalInformation?.idExpiryDate,
          // Passport
          passportNumber: currentEmployee.personalInformation?.passportNumber,
          passportName: currentEmployee.personalInformation?.passportName,
          passportIssuedPlace:
            currentEmployee.personalInformation?.passportIssuedPlace,
          passportIssuedDate:
            currentEmployee.personalInformation?.passportIssuedDate,
          passportExpiryDate:
            currentEmployee.personalInformation?.passportExpiryDate,
          placeOfBirth: currentEmployee.personalInformation?.placeOfBirth,
          // Social Insurance
          taxCode: currentEmployee.personalInformation?.taxCode,
          socialInsuranceNumber:
            currentEmployee.personalInformation?.socialInsuranceNumber,
          socialInsuranceNotes:
            currentEmployee.personalInformation?.socialInsuranceNotes,
          pensionVoluntaryContributionRate:
            currentEmployee.personalInformation
              ?.pensionVoluntaryContributionRate,
          taxWithholdingRate:
            currentEmployee.personalInformation?.taxWithholdingRate,
          nhiDependents: currentEmployee.personalInformation?.nhiDependents,
          // Bank
          bankCountryCode: currentEmployee.personalInformation?.bank?.countryId,
          bankName: currentEmployee.personalInformation?.bank?.name,
          branchName: currentEmployee.personalInformation?.bank?.branchName,
          accountNumber:
            currentEmployee.personalInformation?.bank?.accountNumber,
          accountHolderName:
            currentEmployee.personalInformation?.bank?.accountHolderName,
          // Consulting Fee
          consultingFeeBankCountryCode:
            currentEmployee.personalInformation?.consultingFeeBank?.countryId,
          consultingFeeBankCurrencyId:
            currentEmployee.personalInformation?.consultingFeeBank?.currencyId,
          consultingFeeBankName:
            currentEmployee.personalInformation?.consultingFeeBank?.name,
          consultingFeeBranchName:
            currentEmployee.personalInformation?.consultingFeeBank?.branchName,
          consultingFeeAccountNumber:
            currentEmployee.personalInformation?.consultingFeeBank
              ?.accountNumber,
          consultingFeeAccountHolderName:
            currentEmployee.personalInformation?.consultingFeeBank
              ?.accountHolderName,
        },
        offBoarding: {
          employeeId: currentEmployee.id,
          detailReasonForLeaving:
            currentEmployee.offBoarding?.detailReasonForLeaving,
          employmentEndDate: currentEmployee.offBoarding?.employmentEndDate,
          lastWorkingDate: currentEmployee.offBoarding?.lastWorkingDate,
          reasonForLeavingId: currentEmployee.offBoarding?.reasonForLeaving?.id,
          typeOfAttritionId: currentEmployee.offBoarding?.typeOfAttrition?.id,
          deactivateGreenhouse:
            currentEmployee.offBoarding?.doNotDeactivate.greenhouse,
        },
        jobHistories: currentEmployee.jobHistories.map((item) => ({
          id: item.id,
          type: item.type,
          effectiveDateStart: item.effectiveDate,
          effectiveDateEnd: item.effectiveDateUntil,
          location: item.location.name,
          department: item.department.name,
          businessUnit: item.businessUnit.name,
          job: item.job.name,
          level: item.level,
          jobId: item.jobId,
          departmentId: item.departmentId,
          businessUnitId: item.businessUnitId,
          locationId: item.locationId,
          jobTitle: item.jobTitle,
          team: item.team,
          regionalRole: item.regionalRole,
        })),
        contractHistories: currentEmployee.contracts.map((item) => ({
          id: item.id,
          type: item.contractType,
          companyId: item.companyId,
          companyName: item.company.name,
          effectiveDateStart: item.effectiveDate,
          effectiveDateEnd: item.effectiveDateUntil,
          isOnSecondment: item.onSecondment,
          employeeCode: item.employeeCode,
          isMain: item.isMain,
        })),
        jobHistoryNotes: currentEmployee.jobHistoryNote,
        contractNotes: currentEmployee.contractNote,
        externalAccounts: currentEmployee.externalAccounts.map((item) => ({
          ...item,
        })),
        permission: {
          ...currentEmployee.permission,
          employees: currentEmployee.permission?.employees.map((item) => ({
            id: item.id,
            company: item.currentContract?.company.name ?? '',
            legalName: item.generalInformation.legalFullName,
            preferredName: item.generalInformation.preferredName,
            businessUnit: item.currentPosition?.businessUnit.name ?? '',
            department: item.currentPosition?.department.name ?? '',
          })),
        },
      })
    );
  }, [employeeData?.employee]);

  return { id, employee, employeeLoading: loading };
};
