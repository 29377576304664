import { CardTitle } from '@/shared/components';
import { SettingNotificationType } from '@/shared/types';
import { useFormContext } from 'react-hook-form';
import { NotificationItem } from '../notification-item';

export const PersonalInfo = () => {
  const { watch } = useFormContext<SettingNotificationType>();
  const [
    idExpiryDate,
    passportExpiryDate,
    visaExpiryDate,
    workPermitExpiryDate,
  ] = watch([
    'idExpiryDate',
    'passportExpiryDate',
    'visaExpiryDate',
    'workPermitExpiryDate',
  ]);

  return (
    <div className='py-6'>
      <CardTitle>Personal Info</CardTitle>

      <div className='flex flex-col space-y-6'>
        <NotificationItem
          label='ID expiry date'
          name='idExpiryDate'
          value={idExpiryDate}
        />

        <NotificationItem
          label='Passport expiry date'
          name='passportExpiryDate'
          value={passportExpiryDate}
        />

        <NotificationItem
          label='Visa expiry date'
          name='visaExpiryDate'
          value={visaExpiryDate}
        />

        <NotificationItem
          label='Work permit expiry date'
          name='workPermitExpiryDate'
          value={workPermitExpiryDate}
        />
      </div>
    </div>
  );
};
