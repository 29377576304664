import { createContext, useContext } from 'react';
import { EmployeeDetail, EmployeeDetailType } from '../types';

export const EmployeeDetailContextInitialState = EmployeeDetail.parse({});

export type EmployeeDetailContextType = {
  employeeDetail: EmployeeDetailType;
  setEmployeeDetail: (authUser: EmployeeDetailType) => void;
};

export const EmployeeDetailContext = createContext<EmployeeDetailContextType>({
  employeeDetail: EmployeeDetailContextInitialState,
  setEmployeeDetail: () => console.warn('No Employee Detail provider'),
});

export const useEmployeeDetailContext = (): EmployeeDetailContextType =>
  useContext(EmployeeDetailContext);
