import { EmployeeRole } from '@/shared/graphql';
import { ObjectUtils, StringUtils } from '@/shared/utils';
import {
  ActionMeta,
  MultiValue,
  PropsValue,
  Select,
  useMultiSelect,
} from '@any-ui-react/select';

interface SelectProps {
  className?: string;
  isMulti?: boolean;
  value:
    | string
    | number
    | boolean
    | PropsValue<{ label: string; value: EmployeeRole }>
    | undefined;
  onChange:
    | ((
        newValue: MultiValue<{
          label: string;
          value: EmployeeRole;
        }>,
        actionMeta: ActionMeta<{
          label: string;
          value: EmployeeRole;
        }>
      ) => void)
    | undefined;
}

export const EmployeeRoleSelect = ({
  onChange,
  value,
  className,
  isMulti = true,
}: SelectProps) => {
  const statuses = ObjectUtils.arrayFromEnumValues<EmployeeRole>(EmployeeRole);
  const options = statuses.map((item) => ({
    label: StringUtils.capitalizeAll(item, '_'),
    value: item,
  }));

  const multiSelectProps = useMultiSelect({
    getSelectedLabel: (count) => `Roles (${count})`,
    options: options,
  });

  const selectProps = isMulti ? multiSelectProps : { options: options };

  return (
    <Select
      placeholder='Role'
      isSearchable={true}
      isClearable={true}
      onChange={onChange}
      value={value}
      className={className}
      {...selectProps}
    />
  );
};
