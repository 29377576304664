import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeOffBoardingMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeOffBoardingInput;
}>;


export type UpdateEmployeeOffBoardingMutation = { __typename?: 'Mutation', updateEmployeeOffBoarding: { __typename?: 'EmployeeOutput', id: string } };


export const UpdateEmployeeOffBoardingDocument = gql`
    mutation updateEmployeeOffBoarding($input: UpdateEmployeeOffBoardingInput!) {
  updateEmployeeOffBoarding(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeOffBoardingMutationFn = Apollo.MutationFunction<UpdateEmployeeOffBoardingMutation, UpdateEmployeeOffBoardingMutationVariables>;

/**
 * __useUpdateEmployeeOffBoardingMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeOffBoardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeOffBoardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeOffBoardingMutation, { data, loading, error }] = useUpdateEmployeeOffBoardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeOffBoardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeOffBoardingMutation, UpdateEmployeeOffBoardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeOffBoardingMutation, UpdateEmployeeOffBoardingMutationVariables>(UpdateEmployeeOffBoardingDocument, options);
      }
export type UpdateEmployeeOffBoardingMutationHookResult = ReturnType<typeof useUpdateEmployeeOffBoardingMutation>;
export type UpdateEmployeeOffBoardingMutationResult = Apollo.MutationResult<UpdateEmployeeOffBoardingMutation>;
export type UpdateEmployeeOffBoardingMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeOffBoardingMutation, UpdateEmployeeOffBoardingMutationVariables>;