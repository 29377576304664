import { CompanyAssetFormDataType } from '@/shared/types';
import { create, enforce, test } from 'vest';

export const companyAssetFormValidationSuite = create(
  ({
    assetName,
    assetNumber,
    assetType,
    assignedDate,
    returnedDate,
  }: CompanyAssetFormDataType) => {
    test('assetName', 'Asset Name is required', () => {
      enforce(assetName).isNotEmpty();
    });
    test('assetNumber', 'Asset Number is required', () => {
      enforce(assetNumber).isNotEmpty();
    });
    test('assetType', 'Asset Type is required', () => {
      enforce(assetType).isNotEmpty();
    });
    test('assignedDate', 'Assigned Date is required', () => {
      enforce(assignedDate).isNotEmpty();
    });
  }
);
