import { Checkbox, Radio } from '@any-ui-react/core';
import { Controller, useFormContext } from 'react-hook-form';
import {
  GoogleLogoIcon,
  GreenhouseLogoIcon,
  HubspotLogoIcon,
  SlackLogoIcon,
} from '../../../../../components/shared';
import { EmployeeAddFormDataType } from '../../../../../types/form';

export const ExternalAccount = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<EmployeeAddFormDataType>();

  const [
    autoCreateGoogleWorkspace,
    autoCreateSlack,
    autoCreateHubspot,
    autoCreateGreenhouse,
  ] = watch([
    'autoCreateGoogleWorkspace',
    'autoCreateSlack',
    'autoCreateHubspot',
    'autoCreateGreenhouse',
  ]);

  return (
    <fieldset className='form-fieldset'>
      <Controller
        control={control}
        name='timingToCreateAccounts'
        render={({ field: { value, ...fields } }) => {
          return (
            <Radio.Group
              label='Timing to create accounts'
              {...fields}
              value={value ? '1' : '0'}
              onChange={(newValue) => {
                setValue('timingToCreateAccounts', newValue === '1', {
                  shouldDirty: true,
                });
              }}
              className='col-span-2'
            >
              <div className='mt-4 flex gap-x-4'>
                <Radio className='inline' label={'Today'} value={'1'} />
                <Radio
                  className='inline'
                  label={'7 days before the planned hired date'}
                  value={'0'}
                />
              </div>
            </Radio.Group>
          );
        }}
      />
      <div className='col-span-2'>
        <p className='mb-4'>Auto-create accounts for platforms below.</p>
        <div className='flex'>
          <Checkbox
            wrapperProps={{ classNames: 'sss' }}
            error={errors.autoCreateGoogleWorkspace?.message}
            checked={autoCreateGoogleWorkspace}
            label={
              <div className='flex'>
                <GoogleLogoIcon />
                <span className='ml-1'>Google Workspace</span>
              </div>
            }
            onChange={(e) => {
              setValue('autoCreateGoogleWorkspace', e.currentTarget.checked, {
                shouldDirty: true,
              });
            }}
          />
          <Checkbox
            className='ml-10'
            error={errors.autoCreateSlack?.message}
            checked={autoCreateSlack}
            label={
              <div className='flex'>
                <SlackLogoIcon />
                <span className='ml-1'>Slack</span>
              </div>
            }
            onChange={(e) => {
              setValue('autoCreateSlack', e.currentTarget.checked, {
                shouldDirty: true,
              });
            }}
          />
          <Checkbox
            className='ml-10'
            error={errors.autoCreateHubspot?.message}
            checked={autoCreateHubspot}
            label={
              <div className='flex'>
                <HubspotLogoIcon />
                <span className='ml-1'>Hubspot</span>
              </div>
            }
            onChange={(e) => {
              setValue('autoCreateHubspot', e.currentTarget.checked, {
                shouldDirty: true,
              });
            }}
          />
          <Checkbox
            className='ml-10'
            error={errors.autoCreateGreenhouse?.message}
            checked={autoCreateGreenhouse}
            label={
              <div className='flex'>
                <GreenhouseLogoIcon />
                <span className='ml-1'>Greenhouse</span>
              </div>
            }
            onChange={(e) => {
              setValue('autoCreateGreenhouse', e.currentTarget.checked, {
                shouldDirty: true,
              });
            }}
          />
        </div>
      </div>
    </fieldset>
  );
};
