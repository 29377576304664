import { ReactNode, useCallback } from 'react';

import { GroupBase, LoadOptions, OptionsOrGroups } from '@any-ui-react/select';
import {
  LazyQueryExecFunction,
  OperationVariables,
  QueryHookOptions,
} from '@apollo/client';
import { DEFAULT_PAGE_SIZE } from '../table/constants';

export type LoadOptionAdditional = {
  pageNumber: number;
};

export interface LoadOptionDefaultOption<T> {
  label: ReactNode;
  value: T;
}

interface RequiredResponse<I> {
  [key: string]: {
    totalCount: number;
    items: readonly I[];
  };
}

interface RequiredVariables {
  pageNumber: number;
  pageSize: number;
  keyword: string | null;
}

interface LoadSelectOptionsProps<I, V, LQ, LV extends OperationVariables> {
  labelFormatter: (item: I) => ReactNode;
  valueFormatter: (item: I) => V;
  optionMapper?: (item: I) => LoadOptionDefaultOption<V>;
  listQuery: {
    lazyQuery: LazyQueryExecFunction<LQ, LV>;
    options?: QueryHookOptions<LQ, LV>;
    variables?: Omit<LV, 'pageName'>;
    hasMoreChecker?: (
      loadedOptions: LoadOptionDefaultOption<V>[],
      options: OptionsOrGroups<
        LoadOptionDefaultOption<V>,
        GroupBase<LoadOptionDefaultOption<V>>
      >,
      data?: LQ
    ) => boolean;
  };
}

export const useLoadOptions = <
  I, // Type used in formatter Functions,
  V, // Type used has select value
  LQ extends RequiredResponse<I>, // Type of List Query
  LV extends RequiredVariables // Variables of List Query
>({
  labelFormatter,
  valueFormatter,
  optionMapper = (item) => ({
    label: labelFormatter(item),
    value: valueFormatter(item),
  }),
  listQuery: {
    options: listOptions,
    variables: listVariables,
    // scope: listScope,
    lazyQuery: listQuery,
    hasMoreChecker = (loadedOptions, newOptions, data) => {
      return (
        loadedOptions.length + newOptions.length <
        (data?.listCurrencies.totalCount || 0)
      );
    },
  },
}: LoadSelectOptionsProps<I, V, LQ, LV>) => {
  const loadOptions: LoadOptions<
    LoadOptionDefaultOption<V>,
    GroupBase<LoadOptionDefaultOption<V>>,
    LoadOptionAdditional
  > = useCallback(
    async (name, loadedOptions = [], additional = { pageNumber: 1 }) => {
      try {
        const requiredVariables = {
          keyword: name ?? null,
          pageNumber: additional.pageNumber,
          pageSize: DEFAULT_PAGE_SIZE,
        };

        const { data } = await listQuery({
          ...listOptions,
          variables: {
            ...listVariables,
            ...requiredVariables,
          } as LV,
        });

        const newItems = data?.listCurrencies?.items || [];

        const mappedOptions = newItems.map((item) => optionMapper(item));
        const hasMore = hasMoreChecker(mappedOptions, loadedOptions, data);
        return {
          options: !additional.pageNumber ? [...mappedOptions] : mappedOptions,
          hasMore,
          additional: {
            pageNumber: Number(requiredVariables.pageNumber) + 1,
          },
        };
      } catch (_) {
        return {
          options: [],
          hasMore: false,
          additional,
        };
      }
    },
    [hasMoreChecker, listOptions, listQuery, listVariables, optionMapper]
  );

  return { loadOptions };
};
