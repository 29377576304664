import { Tooltip } from '@any-ui-react/core';
import { HelpIcon } from '../icons';

export const TooltipAlert = ({
  tooltipContent,
  title,
  isTooltipVisible = true,
  titleClassName,
}: {
  tooltipContent: string;
  title?: string;
  titleClassName?: string;
  isTooltipVisible?: boolean;
}) => {
  return (
    <div className='flex items-center'>
      {isTooltipVisible && (
        <Tooltip
          label={<div className='max-w-xs'>{tooltipContent}</div>}
          position='bottom'
          multiline
          withinPortal
        >
          <span>
            <HelpIcon className='mr-1 h-4 w-4 cursor-pointer fill-gray-5' />
          </span>
        </Tooltip>
      )}
      <p className={titleClassName}>{title}</p>
    </div>
  );
};
