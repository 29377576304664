import { z } from 'zod';

export const EmployeeCustomFilter = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  isOpenFilter: z.boolean().default(false),
  createdBy: z.string().default(''),
  createdAt: z.string().default(''),
});
export type EmployeeCustomFilterType = z.infer<typeof EmployeeCustomFilter>;
