import { Button, Checkbox, ModalFooter, TextInput } from '@any-ui-react/core';

import {
  CompanyLazySelect,
  ContractTypeSelect,
  DayPicker,
  DEFAULT_PAGE_SIZE,
} from '@/shared/components';
import { ContractType } from '@/shared/graphql';
import {
  EmployeeContractHistory,
  EmployeeContractHistoryFormData,
  EmployeeContractHistoryFormDataType,
  EmployeeContractHistoryType,
} from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ContractTypeRadioGroup } from '../contract-type-radio-group';
import { contractHistoryFormValidationSuite } from './contract-history-form.validator';

export interface ContractHistoryFormProps {
  onSuccess: (data: EmployeeContractHistoryType) => void;
  onCancel: () => void;
  contractHistory?: EmployeeContractHistoryType;
  latestContractHistory?: EmployeeContractHistoryType;
  employmentEndDate: string | null;
  formTitle: string;
}

export const ContractHistoryForm = ({
  onSuccess,
  onCancel,
  contractHistory,
  latestContractHistory,
  formTitle,
  employmentEndDate,
}: ContractHistoryFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setValue,
    clearErrors,
    watch,
    control,
    reset,
  } = useForm<EmployeeContractHistoryFormDataType>({
    defaultValues: EmployeeContractHistoryFormData.parse({
      ...contractHistory,
    }),
    resolver: vestResolver(contractHistoryFormValidationSuite),
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        onSuccess(
          EmployeeContractHistory.parse({
            ...formValues,
            id: contractHistory?.id,
          })
        );
      }),
    [contractHistory?.id, handleSubmit, onSuccess]
  );

  const [effectiveDateStart, effectiveDateEnd, isOnSecondment, isMain] = watch([
    'effectiveDateStart',
    'effectiveDateEnd',
    'isOnSecondment',
    'isMain',
  ]);

  return (
    <>
      <div className='mb-5 flex'>
        <h1 className='self-center text-lg font-semibold'>{formTitle}</h1>
        <Button
          variant='default'
          className='ml-auto'
          onClick={() => {
            if (!latestContractHistory) return;
            reset({
              ...latestContractHistory,
            });
          }}
        >
          Copy Latest History
        </Button>
      </div>
      <form noValidate>
        <fieldset className='form-fieldset w-full'>
          <DayPicker
            wrapper={{
              className: 'z-10',
            }}
            error={errors.effectiveDateStart?.message}
            label='Contract Start Date'
            required
            placeholder='Effective Date Start'
            value={
              effectiveDateStart
                ? new Date(effectiveDateStart).toISOString()
                : undefined
            }
            onChange={(value) => {
              setValue(
                'effectiveDateStart',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('effectiveDateStart');
            }}
          />
          <DayPicker
            wrapper={{
              className: 'effective-end-date-picker',
            }}
            error={errors.effectiveDateEnd?.message}
            label='Contract End Date'
            placeholder='Effective Date End'
            value={
              effectiveDateEnd
                ? new Date(effectiveDateEnd).toISOString()
                : undefined
            }
            onChange={(value) => {
              setValue(
                'effectiveDateEnd',
                DateUtils.formatDate(
                  new Date(value || '').toISOString(),
                  'yyyy-MM-dd'
                ),
                {
                  shouldDirty: true,
                }
              );
              clearErrors('effectiveDateEnd');
            }}
          />

          <Controller
            control={control}
            name='type'
            render={({ field: { value } }) => (
              <ContractTypeSelect
                label={'Contract Type'}
                required={true}
                isMulti={false}
                error={errors.type?.message}
                onChange={(newValue) => {
                  setValue(
                    'type',
                    (newValue as unknown as { value: ContractType }).value ??
                      null,
                    {
                      shouldDirty: true,
                    }
                  );
                }}
                value={value}
              />
            )}
          />

          <TextInput
            {...register('employeeCode')}
            error={errors.employeeCode?.message}
            label='Employee Code'
            placeholder='Employee Code'
          />

          <Controller
            control={control}
            name='companyId'
            render={({ field: { ...field } }) => (
              <CompanyLazySelect
                isMulti={false}
                label={'Company'}
                required
                error={errors.companyId?.message}
                valueFormatter={(item) => item.id}
                className='select-has-min-w-menu'
                defaultQueryOptions={{
                  variables: {
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    keyword: null,
                    filterOnlyAccessible: true,
                  },
                }}
                placeholder={'Company'}
                {...field}
                onChange={(option) => {
                  setValue('companyId', option?.value as string, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />

          <div className='col-span-2 my-4'>
            <Controller
              control={control}
              name='isMain'
              render={({ field: { value, ...fields } }) => {
                return (
                  <ContractTypeRadioGroup
                    value={isMain}
                    onChange={(isMain) => setValue('isMain', isMain)}
                  />
                );
              }}
            />
          </div>

          <div className='col-span-2'>
            <Checkbox
              {...register('isOnSecondment')}
              className='col-start-1'
              error={errors.isOnSecondment?.message}
              checked={isOnSecondment}
              label='出向 (Applies to Employees in Tokyo Office Only)'
              onChange={(e) => {
                setValue('isOnSecondment', e.currentTarget.checked, {
                  shouldDirty: true,
                });
              }}
            />
          </div>
        </fieldset>

        <ModalFooter>
          <Button variant='default' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className='bg-blue-6'
            type='submit'
            loading={isSubmitting}
            onClick={onSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};
