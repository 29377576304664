import { Checkbox } from '@any-ui-react/core';

import { useTableSelectionCtx } from '.';

export interface TableSelectionBarProps {
  aside: React.ReactNode;
  maxSelection: number;
  total: number;
  currentPageSelection: number;
  onToggleSelection: () => void;
  onToggleAllPages: () => void;
  allPagesSelectable?: boolean;
}

export function TableSelectionBar<T extends Record<string, unknown>>({
  aside,
  maxSelection,
  total,
  onToggleSelection,
  onToggleAllPages,
  currentPageSelection,
  allPagesSelectable = true,
}: TableSelectionBarProps) {
  const { selection } = useTableSelectionCtx<T>();
  const allChecked = currentPageSelection === maxSelection;
  const indeterminateState = currentPageSelection !== maxSelection;
  const count = selection?.allPages ? total : selection?.items.length;

  return (
    <td className='flex h-full flex-row items-center bg-gray-9'>
      <div className='sticky left-0 flex w-full flex-row items-center gap-x-4 whitespace-nowrap p-4'>
        <Checkbox
          indeterminate={indeterminateState}
          checked={allChecked || indeterminateState}
          label={
            <span className='ml-1 w-full font-semibold text-white'>
              {`Selected: ${count}`}
            </span>
          }
          onChange={onToggleSelection}
        />
        {allPagesSelectable && (
          <button onClick={onToggleAllPages} className='text-white underline'>
            {selection?.allPages ? 'Select All' : 'Deselect All'}
          </button>
        )}
        {aside}
      </div>
    </td>
  );
}
