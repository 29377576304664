import { CardSection } from '@/shared/components';
import { EmployeeDetailType } from '@/shared/types';

export const CurrentPosition = ({
  employee,
}: {
  employee: EmployeeDetailType;
}) => {
  const employeeGeneralInformation = employee.generalInformation;
  return (
    <CardSection>
      <h1 className='text-lg font-semibold'>Current Job</h1>
      <p className='mb-6 text-[0.75rem] text-gray-7'>
        You can see and edit histories at "Job history" section
      </p>
      <div className='form-fieldset'>
        <div>
          <p className='mb-2 font-semibold'>Level</p>
          <p className='text-gray-7'>{employeeGeneralInformation.level}</p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Office Location</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.location || '-'}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Business Unit</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.businessUnit || '-'}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Department</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.department || '-'}
          </p>
        </div>{' '}
        <div>
          <p className='mb-2 font-semibold'>Team</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.team || '-'}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Job</p>
          <p className='text-gray-7'>{employeeGeneralInformation.job || '-'}</p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Job Title</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.jobTitle || '-'}
          </p>
        </div>
      </div>
    </CardSection>
  );
};
