import {
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
  useDeleteRecordModal,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeeCompanyAssetType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';
import { useMemo } from 'react';
import { CompanyAssetModal } from './CompanyAssetModal';

interface CompanyAssetTableProps {
  loading: boolean;
  items: EmployeeCompanyAssetType[];
  onRemove: (id: string) => void;
  onUpdate: (data: EmployeeCompanyAssetType) => void;
  showSelected?: boolean;
}

export const CompanyAssetTable = ({
  items,
  loading,
  onUpdate,
  onRemove,
}: CompanyAssetTableProps) => {
  const { openDeleteRecordModal } = useDeleteRecordModal();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationDeleteAccess } = useEmployeeAccess();
  const isRemovable = hasPersonalInformationDeleteAccess || isNewRecord;

  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Asset Type',
          accessor: 'assetType',
          cellRender: (companyAsset: EmployeeCompanyAssetType) => {
            return (
              <CompanyAssetModal
                className='ml-auto'
                onSuccess={onUpdate}
                companyAsset={companyAsset}
              >
                <span className='text-blue-6 hover:cursor-pointer'>
                  {StringUtils.capitalizeAll(companyAsset.assetType || '', '_')}
                </span>
              </CompanyAssetModal>
            );
          },
        },
        {
          headerRender: () => 'Asset Name',
          accessor: 'assetName',
          cellRender: (companyAsset: EmployeeCompanyAssetType) => {
            return <span>{companyAsset.assetName}</span>;
          },
        },
        {
          headerRender: () => 'Asset Number',
          accessor: 'assetNumber',
          cellRender: (companyAsset: EmployeeCompanyAssetType) => {
            return <span>{companyAsset.assetNumber}</span>;
          },
        },
        {
          headerRender: () => 'Assigned date',
          accessor: 'assignedDate',
          cellRender: (companyAsset: EmployeeCompanyAssetType) => {
            return <span>{companyAsset.assignedDate}</span>;
          },
        },
        {
          headerRender: () => 'Returned date',
          accessor: 'returnedDate',
          cellRender: (companyAsset: EmployeeCompanyAssetType) => {
            return <span>{companyAsset.returnedDate || '-'}</span>;
          },
        },
        ...(isRemovable
          ? [
              {
                headerRender: () => '',
                accessor: 'delete',
                cellRender: (companyAsset: EmployeeCompanyAssetType) => {
                  return (
                    <TrashIcon
                      onClick={() =>
                        openDeleteRecordModal({
                          onSuccess: () => onRemove(companyAsset.id),
                        })
                      }
                      className='ml-auto h-4 w-4  text-gray-7'
                    />
                  );
                },
              },
            ]
          : []),
      ] as TableLayout<EmployeeCompanyAssetType>[],
    [isRemovable, onRemove, onUpdate, openDeleteRecordModal]
  );

  const tableCommonProps: TableProps<EmployeeCompanyAssetType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<EmployeeCompanyAssetType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
