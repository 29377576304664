import { z } from 'zod';
import {
  NotificationDate,
  NotificationUser,
  UpdateNotificationSettingInput,
} from '../graphql';

export const NotificationUserLabels: Record<NotificationUser, string> = {
  [NotificationUser.TheEmployee]: 'The Employee',
  [NotificationUser.PrimaryManager]: 'Primary Manager',
  [NotificationUser.AdminStandard]: 'Admin (Standard)',
  [NotificationUser.AdminPrivate]: 'Admin (Private)',
  [NotificationUser.SuperAdmin]: 'Super Admin',
};

export const NotificationDateLabels: Record<NotificationDate, string> = {
  [NotificationDate.OnTheDay]: 'On the day',
  [NotificationDate.Before_14Days]: '14 days before',
  [NotificationDate.Before_30Days]: '30 days before',
  [NotificationDate.Before_45Days]: '45 days before',
  [NotificationDate.Before_90Days]: '90 days before',
  [NotificationDate.Before_6Months]: '6 months after',
};

const optionCustomDate = z.object({
  enabled: z.boolean().default(false),
  notifiedUsers: z.array(z.nativeEnum(NotificationUser)).catch([]),
  notifiedDates: z.array(z.nativeEnum(NotificationDate)).catch([]),
});

const optionStaticDate = z.object({
  enabled: z.boolean().default(false),
  notifiedUsers: z.array(z.nativeEnum(NotificationUser)).catch([]),
});

export const SettingNotificationData = z.object({
  countryId: z.string().catch(''),
  contractEndDate: optionCustomDate,
  employmentEndDate: optionCustomDate,
  idExpiryDate: optionCustomDate,
  lastWorkingDate: optionCustomDate,
  passportExpiryDate: optionCustomDate,
  probationReviewDeadlineForEach: optionCustomDate,
  probationReviewNeedMoreConsideration: optionStaticDate,
  probationReviewScheduledEndDate: optionCustomDate,
  returnFromExtendedLeave: optionCustomDate,
  sixMonthServiceCompletion: optionStaticDate.optional(),
  visaExpiryDate: optionCustomDate,
  workPermitExpiryDate: optionCustomDate,
}) satisfies z.ZodType<UpdateNotificationSettingInput, z.ZodTypeDef, unknown>;

export type SettingNotificationType = z.infer<typeof SettingNotificationData>;
