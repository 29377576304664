import { JobHistoryLevel, JobHistoryType } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeJobHistoryFormData = z.object({
  id: z.string().default(''),
  effectiveDateStart: z.string().nullable().default(null),
  effectiveDateEnd: z.string().nullable().default(null),
  level: z.nativeEnum(JobHistoryLevel).nullable().default(null),
  countryId: z.string().nullable().default(null),
  locationId: z.string().nullable().default(null),
  divisionId: z.string().nullable().default(null),
  departmentId: z.string().nullable().default(null),
  businessUnitId: z.string().nullable().default(null),
  jobId: z.string().nullable().default(null),
  team: z.string().nullable().default(null),
  jobTitle: z.string().nullable().default(null),
  type: z.nativeEnum(JobHistoryType).nullable().default(null),
  regionalRole: z.boolean().default(false),
});
export type EmployeeJobHistoryFormDataType = z.infer<
  typeof EmployeeJobHistoryFormData
>;
