export enum AppEnvironment {
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  LOCAL = 'LOCAL',
}

export enum BusinessUnitError {
  BUSINESS_UNIT_NAME_IS_NOT_UNIQUE = 'BUSINESS_UNIT_NAME_IS_NOT_UNIQUE',
}

export enum CompanyError {
  COMPANY_NAME_IS_NOT_UNIQUE = 'COMPANY_NAME_IS_NOT_UNIQUE',
}

export enum DepartmentError {
  DEPARTMENT_NAME_IS_NOT_UNIQUE = 'DEPARTMENT_NAME_IS_NOT_UNIQUE',
}

export enum DivisionError {
  DIVISION_NAME_IS_NOT_UNIQUE = 'DIVISION_NAME_IS_NOT_UNIQUE',
}

export enum CompanyEmailDomainError {
  COMPANY_EMAIL_DOMAIN_NAME_IS_NOT_UNIQUE = 'COMPANY_EMAIL_DOMAIN_NAME_IS_NOT_UNIQUE',
}

export enum JobError {
  JOB_NAME_IS_NOT_UNIQUE = 'JOB_NAME_IS_NOT_UNIQUE',
}

export enum LocationError {
  LOCATION_NAME_IS_NOT_UNIQUE = 'LOCATION_NAME_IS_NOT_UNIQUE',
}

export enum ReasonForLeavingError {
  REASON_FOR_LEAVING_NAME_IS_NOT_UNIQUE = 'REASON_FOR_LEAVING_NAME_IS_NOT_UNIQUE',
}

export enum TypeOfAttritionError {
  TYPE_OF_ATTRITION_NAME_IS_NOT_UNIQUE = 'TYPE_OF_ATTRITION_NAME_IS_NOT_UNIQUE',
}

export enum ApiError {
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
}

export enum AlertType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum GeneralType {
  BUSINESS_UNIT = 'Business Unit',
  DIVISION = 'Division',
  COMPANY = 'Company',
  DEPARTMENT = 'Department',
  JOB = 'Job',
  COMPANY_EMAIL_DOMAIN = 'Email Domain',
  LOCATION = 'Location',
  REASON_FOR_LEAVING = 'Reason for Leaving',
  TYPE_OF_ATTRITION = 'Type Of Attribution',
}

export enum Division {
  CORPORATE = 'Corporate',
  BRAND_COMMERCE = 'Brand Commerce',
  PARTNER_GROWTH = 'Partner Growth',
  PRODUCT_DEVELOPMENT = 'Product Development',
}
