import { CompanyProbationReviewTermMode } from '@/shared/graphql';
import { z } from 'zod';

export const Company = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  country: z.object({
    id: z.string().default(''),
    name: z.string().default(''),
  }),
  locationId: z.string().default(''),
  probationReviewTerm: z.object({
    length: z.number().default(0),
    mode: z
      .nativeEnum(CompanyProbationReviewTermMode)
      .default(CompanyProbationReviewTermMode.Day),
  }),
  probationReviewTimes: z.number().default(0),
  enabled: z.boolean().default(false),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
});
export type CompanyType = z.infer<typeof Company>;
