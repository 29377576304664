import { Accordion, Button, Menu } from '@any-ui-react/core';

import { useEffect, useState } from 'react';
import { EmployeeInfoFilters } from './EmployeeInfoFilters';

import { MoreFilterIcon } from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import {
  EmployeeInfoFilter,
  EmployeeListFilterType,
  EmployeeOtherFilter,
  ExternalAccountsFilter,
} from '@/shared/types';
import { EmployeeOtherFilters } from './EmployeeOtherFilters';
import { ExternalAccountFilters } from './ExternalAccountFilters';

export const MoreFilterButton = ({
  filters,
}: {
  filters: FilterProps<EmployeeListFilterType>;
}) => {
  const currentFilters = filters.current;
  const [employeeInfoFilter, setEmployeeInfoFilter] = useState(
    EmployeeInfoFilter.parse({ ...currentFilters })
  );
  const [externalAccountsFilters, setExternalAccountsFilters] = useState(
    ExternalAccountsFilter.parse({ ...currentFilters })
  );
  const [employeeOtherFilters, setEmployeeOtherFilters] = useState(
    EmployeeOtherFilter.parse({ ...currentFilters })
  );

  const handleSubmit = () => {
    filters.updateFilters({
      ...filters.current,
      ...employeeInfoFilter,
      ...externalAccountsFilters,
      ...employeeOtherFilters,
    });
  };

  const handleClear = () => {
    const clearedEmployeeInfoFilters = EmployeeInfoFilter.parse({
      hiredDate: null,
      employeeEndDate: null,
    });
    setEmployeeInfoFilter({
      ...employeeInfoFilter,
      ...clearedEmployeeInfoFilters,
    });

    const clearedExternalAccountsFilters = ExternalAccountsFilter.parse({});
    setExternalAccountsFilters({
      ...externalAccountsFilters,
      ...clearedExternalAccountsFilters,
    });

    const clearedEmployeeOtherFilters = EmployeeOtherFilter.parse({});
    setEmployeeOtherFilters({
      ...employeeOtherFilters,
      ...clearedEmployeeOtherFilters,
    });

    filters.updateFilters({
      ...filters.current,
      ...clearedEmployeeInfoFilters,
      ...clearedExternalAccountsFilters,
      ...clearedEmployeeOtherFilters,
    });
  };

  useEffect(() => {
    setEmployeeInfoFilter(EmployeeInfoFilter.parse({ ...currentFilters }));
    setExternalAccountsFilters(
      ExternalAccountsFilter.parse({ ...currentFilters })
    );
  }, [currentFilters]);

  return (
    <div className='more-filter-container w-full'>
      <Menu shadow='md' width={320} position='bottom-start'>
        <Menu.Target>
          <Button className='flex w-full justify-start pl-3' variant='default'>
            <MoreFilterIcon height={24} width={24} />
            <span className='ml-1'>More filter</span>
          </Button>
        </Menu.Target>

        <Menu.Dropdown defaultValue={'Business Profile'}>
          <Menu.Label className='mt-1 text-sm font-semibold text-black'>
            Filters
          </Menu.Label>
          <Menu.Divider className='m-0' />

          <Accordion defaultValue='Business Profile' className='more-filter'>
            <Accordion.Item key={'Business Profile'} value={'Business Profile'}>
              <Accordion.Control className='px-3 hover:bg-white '>
                <span className='text-sm font-semibold'>{'Employee Info'}</span>
              </Accordion.Control>
              <Accordion.Panel className='bg-muted'>
                <EmployeeInfoFilters
                  employeeInfoFilter={employeeInfoFilter}
                  setEmployeeInfoFilter={setEmployeeInfoFilter}
                />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item key={'External Account'} value={'External Account'}>
              <Accordion.Control className='px-3 hover:bg-white '>
                <span className='text-sm font-semibold'>
                  {'External Account'}
                </span>
              </Accordion.Control>
              <Accordion.Panel className='bg-muted'>
                <ExternalAccountFilters
                  externalAccountsFilter={externalAccountsFilters}
                  setExternalAccountsFilter={setExternalAccountsFilters}
                />
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item key={'Others'} value={'Others'}>
              <Accordion.Control className='px-3 hover:bg-white '>
                <span className='text-sm font-semibold'>{'Others'}</span>
              </Accordion.Control>
              <Accordion.Panel className='bg-muted'>
                <EmployeeOtherFilters
                  employeeOtherFilter={employeeOtherFilters}
                  setEmployeeOtherFilter={setEmployeeOtherFilters}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <div className='flex justify-end p-2'>
            <Button onClick={() => handleClear()} variant='default'>
              All Clear
            </Button>
            <Button onClick={() => handleSubmit()} className='ml-2 bg-blue-6'>
              Search
            </Button>
          </div>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};
