import {
  createHttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuthToken, ApiError, removeAuthUserInSession } from '../utils';

// TODO: Change when Production URL available
const DEFAULT_URI = 'https://api.stg.v2.med.anymindgroup.com/graphql';

const httpLink = createHttpLink({
  uri: DEFAULT_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const authenticationAfterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const apiError = response.errors?.[0].message;
    if (apiError === ApiError.AUTHENTICATION_FAILED) {
      removeAuthUserInSession();
      window.location.replace('/login');
    }
    return response;
  });
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(authenticationAfterware.concat(httpLink)),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
});
