import { EmployeeRole } from '@/shared/graphql';
import { z } from 'zod';

export const AuthUser = z.object({
  name: z.string().default(''),
  email: z.string().default(''),
  role: z.nativeEnum(EmployeeRole).default(EmployeeRole.Basic),
  pictureUrl: z.string().default(''),
  exp: z.number().default(0),
});

export type AuthUserType = z.infer<typeof AuthUser>;
