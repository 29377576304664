import { EmployeeContractHistoryFormDataType } from '@/shared/types';
import { isValid } from 'date-fns';
import { create, enforce, omitWhen, test } from 'vest';

export const contractHistoryFormValidationSuite = create(
  ({
    type,
    effectiveDateStart,
    effectiveDateEnd,
    companyId,
  }: EmployeeContractHistoryFormDataType) => {
    test('type', 'Type is required', () => {
      enforce(type).isNotEmpty();
    });
    test('effectiveDateStart', 'Effective Date Start is required', () => {
      enforce(effectiveDateStart).isNotEmpty();
    });
    test('companyId', 'Company is required', () => {
      enforce(companyId).isNotEmpty();
    });

    const fromDate = new Date(effectiveDateStart ?? '');

    test(
      'effectiveDateStart',
      'Effective Date Start must be a valid date',
      () => {
        enforce(isValid(new Date(fromDate))).isTruthy();
      }
    );

    omitWhen(!effectiveDateEnd, () => {
      const toDate = new Date(effectiveDateEnd ?? '');

      test(
        'effectiveDateEnd',
        'Effective Date End must be a valid date',
        () => {
          enforce(isValid(new Date(toDate))).isTruthy();
        }
      );

      test(
        'effectiveDateStart',
        'Effective Date Start must be a less than end date',
        () => {
          const isEndDateGreater = toDate > fromDate;
          enforce(isEndDateGreater).isTruthy();
        }
      );

      test(
        'effectiveDateEnd',
        'Effective Date End must be a greater than start date',
        () => {
          const isStartDateLess = fromDate < toDate;
          enforce(isStartDateLess).isTruthy();
        }
      );
    });
  }
);
