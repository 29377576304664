import { StringUtils } from '@/shared/utils';

export const EmployeeRoleDetails = ({ role }: { role: string }) => {
  return (
    <>
      <h1 className='text-md pb-4 font-semibold'>Role</h1>
      <span>{StringUtils.capitalizeAll(role, '_')}</span>
    </>
  );
};
