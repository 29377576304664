import { Input, Popover } from '@any-ui-react/core';
import { YearPicker as MantimeYearPicker } from '@mantine/dates';
import { format, isValid } from 'date-fns';
import { useState } from 'react';

interface Props {
  value: string;
  onChange: (value: string) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const YearPicker = ({ value, onChange, minDate, maxDate }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateInput, setDateInput] = useState(value);

  return (
    <Popover
      position='bottom-start'
      closeOnClickOutside
      offset={0}
      opened={isOpen}
      onChange={(v) => {
        setIsOpen(v);

        if (!v && dateInput.length && isValid(new Date(dateInput))) {
          onChange(dateInput);
          setIsOpen(false);
        }
      }}
    >
      <Popover.Target>
        <Input.Wrapper onClick={() => setIsOpen((prev) => !prev)}>
          <Input
            onClick={(prev) => setIsOpen(!prev)}
            value={dateInput}
            onChange={(e) => {
              setDateInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && isValid(new Date(dateInput))) {
                onChange(dateInput);
                setIsOpen(false);
              }
            }}
          />
        </Input.Wrapper>
      </Popover.Target>

      <Popover.Dropdown className='absolute max-h-[26rem] rounded border border-gray-2 bg-white shadow-sm'>
        <MantimeYearPicker
          className='bg-white'
          value={dateInput ? new Date(dateInput) : undefined}
          defaultDate={dateInput ? new Date(dateInput) : undefined}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(v) => {
            if (v) {
              setDateInput(format(v, 'yyyy'));
              onChange(format(v, 'yyyy'));
              setIsOpen(false);
            }
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
