import { VisaFormDataType } from '@/shared/types';
import { create, enforce, test } from 'vest';

export const visaFormValidationSuite = create(
  ({
    visaType,
    visaNumber,
    visaIssuedDate,
    visaExpiryDate,
    workPermitNumber,
    workPermitIssuedDate,
    workPermitExpiryDate,
  }: VisaFormDataType) => {
    test('visaType', 'Visa Type is required', () => {
      enforce(visaType).isNotEmpty();
    });
    test('visaNumber', 'Visa Number is required', () => {
      enforce(visaNumber).isNotEmpty();
    });
    test('visaIssuedDate', 'Visa Issued Date is required', () => {
      enforce(visaIssuedDate).isNotEmpty();
    });
    test('visaExpiryDate', 'Visa Expiry Date is required', () => {
      enforce(visaExpiryDate).isNotEmpty();
    });
  }
);
