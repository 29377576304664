import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEmployeeContractMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteEmployeeContractMutation = { __typename?: 'Mutation', deleteEmployeeContract: boolean };


export const DeleteEmployeeContractDocument = gql`
    mutation deleteEmployeeContract($id: ID!) {
  deleteEmployeeContract(id: $id)
}
    `;
export type DeleteEmployeeContractMutationFn = Apollo.MutationFunction<DeleteEmployeeContractMutation, DeleteEmployeeContractMutationVariables>;

/**
 * __useDeleteEmployeeContractMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeContractMutation, { data, loading, error }] = useDeleteEmployeeContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeContractMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeContractMutation, DeleteEmployeeContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeContractMutation, DeleteEmployeeContractMutationVariables>(DeleteEmployeeContractDocument, options);
      }
export type DeleteEmployeeContractMutationHookResult = ReturnType<typeof useDeleteEmployeeContractMutation>;
export type DeleteEmployeeContractMutationResult = Apollo.MutationResult<DeleteEmployeeContractMutation>;
export type DeleteEmployeeContractMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeContractMutation, DeleteEmployeeContractMutationVariables>;