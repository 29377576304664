import { Fragment, PropsWithChildren, ReactElement, useState } from 'react';

import { Checkbox, Tooltip } from '@any-ui-react/core';
import { TdBody, TrBody } from '@any-ui-react/table';
import classNames from 'classnames';

import { useTableSelectionCtx } from '../selection';
import { TableLayout } from '../Table';

import { TableCollapseRow } from './TableCollapseRow';
import { EMPTY_DEFAULT, DEFAULT_COLUMN_MAX_WIDTH } from '../constants';

export interface TableDesktopProps<T> {
  hoverable?: boolean;
  layout: ReadonlyArray<TableLayout<T>>;
  loading?: boolean;
  headerClassName?: string;
  rowClassName?: string;
  numOfStickyCols?: number;
  onClick?: (row: T) => void;
  allowFloatingHeader?: boolean;
  data: ReadonlyArray<T>;
  renderDetailPanel?: (row: T, index: number) => React.ReactNode;
}

export function TableDesktop<T extends Record<string, unknown>>({
  layout,
  hoverable = true,
  data,
  rowClassName,
  onClick,
  renderDetailPanel,
  numOfStickyCols = 1,
}: PropsWithChildren<TableDesktopProps<T>>): ReactElement {
  const { check, uncheck, selection, selectable, isChecked } =
    useTableSelectionCtx<T>();

  const [openPanels, setOpenPanels] = useState<string[]>([]);
  return (
    <>
      {data.map((row, index) => {
        const isSelected = isChecked(row);
        const collapsePanelId = `collapse-panel-${index}`;

        const toggleDetailPanel = () => {
          setOpenPanels((prev) => {
            const current = [...prev];

            if (current.includes(collapsePanelId)) {
              return current.filter((id) => id !== collapsePanelId);
            }

            return [...current, collapsePanelId];
          });
        };

        return (
          <Fragment key={index}>
            <TrBody
              onClick={() => onClick?.(row)}
              className={classNames('h-10', {
                'group hover:bg-gray-0': hoverable,
                'hover:bg-white': !hoverable,
                'bg-gray-0': isSelected,
                '[&:not(:last-of-type)]:border-b-0': !!renderDetailPanel,
              })}
            >
              {layout.map(
                (
                  {
                    cellRender,
                    checkboxTooltip,
                    accessor,
                    minWidth,
                    maxWidth,
                    width,
                    tdClassName,
                    truncated = true,
                  },
                  i
                ) => {
                  const hasCheckbox =
                    i === 0 && selectable?.byField && selection;
                  const disabled =
                    typeof selectable?.rowDisabled === 'function' &&
                    selectable.rowDisabled(row);
                  const { label, ...restTooltipProps } =
                    checkboxTooltip?.(row) || {};

                  const cellNode =
                    cellRender(row, index, {
                      expanded: openPanels.includes(collapsePanelId),
                      toggleDetailPanel,
                    }) || EMPTY_DEFAULT;

                  return (
                    <TdBody
                      key={accessor}
                      index={i}
                      className={classNames(rowClassName, tdClassName, {
                        truncate: truncated,
                      })}
                      style={{
                        minWidth: minWidth || 'none',
                        maxWidth: maxWidth || `${DEFAULT_COLUMN_MAX_WIDTH}px`,
                        width: width,
                        background: 'inherit',
                        zIndex: i < numOfStickyCols ? 1 : 0,
                      }}
                    >
                      {hasCheckbox ? (
                        <div className='flex items-center'>
                          <div
                            className='-ml-4 px-4'
                            onClick={(e) => {
                              if (disabled) {
                                return;
                              }
                              e.stopPropagation();
                            }}
                          >
                            <Tooltip
                              key={accessor}
                              multiline
                              withinPortal
                              disabled={!disabled}
                              label={label}
                              {...restTooltipProps}
                            >
                              <span key={accessor}>
                                <Checkbox
                                  disabled={disabled}
                                  checked={isSelected}
                                  onChange={(e) =>
                                    e.currentTarget.checked
                                      ? check(row)
                                      : uncheck(row)
                                  }
                                />
                              </span>
                            </Tooltip>
                          </div>
                          {cellNode}
                        </div>
                      ) : (
                        cellNode
                      )}
                    </TdBody>
                  );
                }
              )}
            </TrBody>
            {renderDetailPanel && (
              <TableCollapseRow
                colSpan={layout.length}
                isOpen={openPanels.includes(collapsePanelId)}
              >
                {renderDetailPanel(row, index)}
              </TableCollapseRow>
            )}
          </Fragment>
        );
      })}
    </>
  );
}
