import { toast } from '@/shared/components';
import { useUpdateEmployeePermissionMutation } from '@/shared/graphql';
import { EmployeePermissionFormDataType } from '@/shared/types';

export const useUpdateEmployeePermission = ({
  onCompleted,
}: {
  onCompleted: VoidFunction;
}) => {
  const [updateRecord] = useUpdateEmployeePermissionMutation();

  const handleUpdateEmployeePermission = (
    formData: EmployeePermissionFormDataType,
    employeeId: string
  ) => {
    updateRecord({
      variables: {
        input: {
          companyIds: formData.companyIds,
          employeeIds: formData.isAllEmployees ? [] : formData.employeeIds,
          role: formData.role,
          employeeId,
        },
      },
      onError: () => {
        toast.error();
      },
      onCompleted: (data) => {
        if (data.updateEmployeePermission) {
          toast.success();
          onCompleted();
        }
      },
    });
  };

  return {
    handleUpdateEmployeePermission,
  };
};
