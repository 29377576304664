import { GeneralType } from '@/shared/utils';
import { Select, TablePagination } from '@any-ui-react/core';
import { useWindowScroll } from '@mantine/hooks';
import { useEffect } from 'react';
import { Card } from '../../../components';
import { PageTitle } from '../../../components/shared/layout/page-title';
import { DEFAULT_PAGE_SIZE } from '../../../components/shared/table/constants';
import { useFilters } from '../../../hooks';
import { useGeneralComponents } from '../../../hooks/general/useGeneralComponents';
import { GeneralFilter, GeneralFilterType } from '../../../types';

export const GeneralList = () => {
  const [, scrollTo] = useWindowScroll();

  const generalTypes = Object.values(GeneralType).map((value) => ({
    label: value,
    value,
  }));

  const { ...filters } = useFilters<GeneralFilterType>(
    {
      page: 1,
      type: GeneralType.BUSINESS_UNIT,
    },
    {
      parser: GeneralFilter.parse,
    }
  );
  const { page, type: generalType } = filters.current;

  useEffect(() => {
    filters.changeFilter('page', 1, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalType]);

  const GeneralComponents = useGeneralComponents({ generalType, page });

  return (
    <>
      <PageTitle text='General' />
      <Card withPadding={false}>
        <div className='flex h-16 p-4'>
          <Select
            data={generalTypes}
            value={generalType}
            onChange={(value) => {
              filters.changeFilter('type', value as GeneralType, false);
            }}
          />
          {GeneralComponents.modalButton}
        </div>
        {GeneralComponents.table}
      </Card>
      <TablePagination
        className='px-4 py-3 lg:px-0'
        value={page}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        withItems={true}
        count={GeneralComponents.count || 0}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
          scrollTo({ y: 0 });
        }}
      />
    </>
  );
};
