import {
  SettingNotificationData,
  SettingNotificationType,
} from '@/shared/types';
import { CountryCode } from 'src/utils/country-codes';
import { create, enforce, skipWhen, test } from 'vest';

export const notificationSettingFormValidationSuite = create(
  (data: SettingNotificationType) => {
    // Contract / Job Info
    skipWhen(!data.contractEndDate.enabled, () => {
      test('contractEndDate.notifiedDates', 'Notified date is required', () => {
        enforce(data.contractEndDate.notifiedDates).isNotEmpty();
      });

      test('contractEndDate.notifiedUsers', 'Notified user is required', () => {
        enforce(data.contractEndDate.notifiedUsers).isNotEmpty();
      });
    });

    skipWhen(!data.employmentEndDate.enabled, () => {
      test(
        'employmentEndDate.notifiedDates',
        'Notified date is required',
        () => {
          enforce(data.employmentEndDate.notifiedDates).isNotEmpty();
        }
      );

      test(
        'employmentEndDate.notifiedUsers',
        'Notified user is required',
        () => {
          enforce(data.employmentEndDate.notifiedUsers).isNotEmpty();
        }
      );
    });

    skipWhen(!data.lastWorkingDate.enabled, () => {
      test('lastWorkingDate.notifiedDates', 'Notified date is required', () => {
        enforce(data.lastWorkingDate.notifiedDates).isNotEmpty();
      });

      test('lastWorkingDate.notifiedUsers', 'Notified user is required', () => {
        enforce(data.lastWorkingDate.notifiedUsers).isNotEmpty();
      });
    });

    skipWhen(!data.returnFromExtendedLeave.enabled, () => {
      test(
        'returnFromExtendedLeave.notifiedDates',
        'Notified date is required',
        () => {
          enforce(data.returnFromExtendedLeave.notifiedDates).isNotEmpty();
        }
      );

      test(
        'returnFromExtendedLeave.notifiedUsers',
        'Notified user is required',
        () => {
          enforce(data.returnFromExtendedLeave.notifiedUsers).isNotEmpty();
        }
      );
    });

    // Probation
    skipWhen(!data.probationReviewScheduledEndDate.enabled, () => {
      test(
        'probationReviewScheduledEndDate.notifiedDates',
        'Notified date is required',
        () => {
          enforce(
            data.probationReviewScheduledEndDate.notifiedDates
          ).isNotEmpty();
        }
      );

      test(
        'probationReviewScheduledEndDate.notifiedUsers',
        'Notified user is required',
        () => {
          enforce(
            data.probationReviewScheduledEndDate.notifiedUsers
          ).isNotEmpty();
        }
      );
    });

    skipWhen(!data.probationReviewDeadlineForEach.enabled, () => {
      test(
        'probationReviewDeadlineForEach.notifiedDates',
        'Notified date is required',
        () => {
          enforce(
            data.probationReviewDeadlineForEach.notifiedDates
          ).isNotEmpty();
        }
      );

      test(
        'probationReviewDeadlineForEach.notifiedUsers',
        'Notified user is required',
        () => {
          enforce(
            data.probationReviewDeadlineForEach.notifiedUsers
          ).isNotEmpty();
        }
      );
    });

    skipWhen(!data.probationReviewNeedMoreConsideration.enabled, () => {
      test(
        'probationReviewNeedMoreConsideration.notifiedUsers',
        'Notified user is required',
        () => {
          enforce(
            data.probationReviewNeedMoreConsideration.notifiedUsers
          ).isNotEmpty();
        }
      );
    });

    // Personal Info
    skipWhen(!data.idExpiryDate.enabled, () => {
      test('idExpiryDate.notifiedDates', 'Notified date is required', () => {
        enforce(data.idExpiryDate.notifiedDates).isNotEmpty();
      });

      test('idExpiryDate.notifiedUsers', 'Notified user is required', () => {
        enforce(data.idExpiryDate.notifiedUsers).isNotEmpty();
      });
    });

    skipWhen(!data.passportExpiryDate.enabled, () => {
      test(
        'passportExpiryDate.notifiedDates',
        'Notified date is required',
        () => {
          enforce(data.passportExpiryDate.notifiedDates).isNotEmpty();
        }
      );

      test(
        'passportExpiryDate.notifiedUsers',
        'Notified user is required',
        () => {
          enforce(data.passportExpiryDate.notifiedUsers).isNotEmpty();
        }
      );
    });

    skipWhen(!data.visaExpiryDate.enabled, () => {
      test('visaExpiryDate.notifiedDates', 'Notified date is required', () => {
        enforce(data.visaExpiryDate.notifiedDates).isNotEmpty();
      });

      test('visaExpiryDate.notifiedUsers', 'Notified user is required', () => {
        enforce(data.visaExpiryDate.notifiedUsers).isNotEmpty();
      });
    });

    skipWhen(!data.workPermitExpiryDate.enabled, () => {
      test(
        'workPermitExpiryDate.notifiedDates',
        'Notified date is required',
        () => {
          enforce(data.workPermitExpiryDate.notifiedDates).isNotEmpty();
        }
      );

      test(
        'workPermitExpiryDate.notifiedUsers',
        'Notified user is required',
        () => {
          enforce(data.workPermitExpiryDate.notifiedUsers).isNotEmpty();
        }
      );
    });

    // Six Month Service Completion
    skipWhen(
      !data.sixMonthServiceCompletion?.enabled ||
        data.countryId !== CountryCode.JP,
      () => {
        test(
          'sixMonthServiceCompletion.notifiedUsers',
          'Notified user is required',
          () => {
            enforce(data.sixMonthServiceCompletion?.notifiedUsers).isNotEmpty();
          }
        );
      }
    );
  }
);

const EMPTY_SETTING_NOTIFICATION = {
  enabled: false,
  notifiedDates: [],
  notifiedUsers: [],
};

export const defaultValues: SettingNotificationType =
  SettingNotificationData.parse({
    contractEndDate: EMPTY_SETTING_NOTIFICATION,
    employmentEndDate: EMPTY_SETTING_NOTIFICATION,
    lastWorkingDate: EMPTY_SETTING_NOTIFICATION,
    returnFromExtendedLeave: EMPTY_SETTING_NOTIFICATION,
    probationReviewScheduledEndDate: EMPTY_SETTING_NOTIFICATION,
    probationReviewDeadlineForEach: EMPTY_SETTING_NOTIFICATION,
    probationReviewNeedMoreConsideration: EMPTY_SETTING_NOTIFICATION,
    sixMonthServiceCompletion: EMPTY_SETTING_NOTIFICATION,
    idExpiryDate: EMPTY_SETTING_NOTIFICATION,
    passportExpiryDate: EMPTY_SETTING_NOTIFICATION,
    visaExpiryDate: EMPTY_SETTING_NOTIFICATION,
    workPermitExpiryDate: EMPTY_SETTING_NOTIFICATION,
  });
