import { ContractTypeForReport } from '@/shared/graphql';
import { z } from 'zod';

export enum ReportNumberOfEmployeesTab {
  ALL = 'all',
  COMPANY = 'company',
  OFFICE_LOCATION = 'office-location',
  BUSINESS_UNIT = 'business-unit',
  JOB = 'job',
}

export const ReportNumberOfEmployeesTabLabels: Record<
  ReportNumberOfEmployeesTab,
  string
> = {
  [ReportNumberOfEmployeesTab.ALL]: 'All',
  [ReportNumberOfEmployeesTab.COMPANY]: 'Company',
  [ReportNumberOfEmployeesTab.OFFICE_LOCATION]: 'Office Location',
  [ReportNumberOfEmployeesTab.BUSINESS_UNIT]: 'Business Unit',
  [ReportNumberOfEmployeesTab.JOB]: 'Job',
};

export const NumberOfEmployeesFilter = z.object({
  tab: z
    .nativeEnum(ReportNumberOfEmployeesTab)
    .default(ReportNumberOfEmployeesTab.ALL),
  year: z.string().optional().default(''),
  contractTypes: z.array(z.nativeEnum(ContractTypeForReport)).catch([]),
});

export type NumberOfEmployeesFilterType = z.infer<
  typeof NumberOfEmployeesFilter
>;
