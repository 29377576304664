import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMinimalCompanyQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetMinimalCompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string } | null };


export const GetMinimalCompanyDocument = gql`
    query getMinimalCompany($id: ID!) {
  company(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetMinimalCompanyQuery__
 *
 * To run a query within a React component, call `useGetMinimalCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMinimalCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMinimalCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMinimalCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetMinimalCompanyQuery, GetMinimalCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMinimalCompanyQuery, GetMinimalCompanyQueryVariables>(GetMinimalCompanyDocument, options);
      }
export function useGetMinimalCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMinimalCompanyQuery, GetMinimalCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMinimalCompanyQuery, GetMinimalCompanyQueryVariables>(GetMinimalCompanyDocument, options);
        }
export function useGetMinimalCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMinimalCompanyQuery, GetMinimalCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMinimalCompanyQuery, GetMinimalCompanyQueryVariables>(GetMinimalCompanyDocument, options);
        }
export type GetMinimalCompanyQueryHookResult = ReturnType<typeof useGetMinimalCompanyQuery>;
export type GetMinimalCompanyLazyQueryHookResult = ReturnType<typeof useGetMinimalCompanyLazyQuery>;
export type GetMinimalCompanySuspenseQueryHookResult = ReturnType<typeof useGetMinimalCompanySuspenseQuery>;
export type GetMinimalCompanyQueryResult = Apollo.QueryResult<GetMinimalCompanyQuery, GetMinimalCompanyQueryVariables>;