import React, { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, CardSection, CardTitle } from '.';
import { AlertIcon } from '../icons';

export interface CardLoaderProps {
  errorNode?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  loaderId?: string;
  message?: ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  skeletonChildrenCount?: number;
  loaderClassNames?: {
    card?: string;
    cardWrapper?: string;
    error?: string;
    skeletonWrapper?: string;
  };
}

export const CardLoader = ({
  error,
  loading,
  loaderClassNames,
  children,
  loaderId,
  errorNode = (
    <div className={loaderClassNames?.error}>
      <div className='flex flex-1 items-center justify-center'>
        <AlertIcon className='fill-error' />
      </div>
    </div>
  ),
  skeletonChildrenCount = 7,
}: CardLoaderProps) => {
  const node = error ? errorNode : children;
  return (
    <>
      {loading ? (
        <article
          data-loader-id={loaderId}
          className={loaderClassNames?.cardWrapper}
        >
          <Card className={`lg:rounded-t-none ${loaderClassNames?.card}`}>
            <CardSection withMargin={false}>
              <fieldset>
                <CardTitle>
                  <Skeleton containerClassName='inline-flex h-[1.25rem] w-40' />
                </CardTitle>
                <div
                  className={`grid grid-cols-1 gap-x-4 gap-y-1 lg:grid-cols-2 lg:gap-x-6 ${loaderClassNames?.skeletonWrapper}`}
                >
                  {Array(skeletonChildrenCount)
                    .fill(null)
                    .map((_, i) => (
                      <div className='flex flex-col gap-2' key={i}>
                        <Skeleton containerClassName='inline-flex h-4 w-20' />
                        <Skeleton containerClassName='inline-flex h-8 w-full mb-[1.25rem]' />
                      </div>
                    ))}
                </div>
              </fieldset>
            </CardSection>
          </Card>
        </article>
      ) : (
        node
      )}
    </>
  );
};
