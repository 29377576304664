import { EmployeeOtherFilterType } from '@/shared/types';
import { Checkbox } from '@any-ui-react/core';

export const EmployeeOtherFilters = ({
  employeeOtherFilter,
  setEmployeeOtherFilter,
}: {
  employeeOtherFilter: EmployeeOtherFilterType;
  setEmployeeOtherFilter: React.Dispatch<
    React.SetStateAction<EmployeeOtherFilterType>
  >;
}) => {
  return (
    <div className='py-3'>
      <Checkbox
        className='mx-2'
        checked={employeeOtherFilter.isOnSecondment}
        label={
          <>
            <span>出向 </span>
            <span className='text-[0.625rem]'>
              (Applies to Employees in Tokyo Office Only)
            </span>
          </>
        }
        onChange={(e) => {
          setEmployeeOtherFilter({
            ...employeeOtherFilter,
            isOnSecondment: e.currentTarget.checked,
          });
        }}
      />
      <Checkbox
        className='mx-2 mt-3'
        checked={employeeOtherFilter.isLeave}
        label={
          <>
            <span>休業 </span>
            <span className='text-[0.625rem]'>
              (Applies to Employees in Tokyo Office Only)
            </span>
          </>
        }
        onChange={(e) => {
          setEmployeeOtherFilter({
            ...employeeOtherFilter,
            isLeave: e.currentTarget.checked,
          });
        }}
      />
    </div>
  );
};
