import { EmployeeRole } from '@/shared/graphql';
import { AuthUserType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { useCallback } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = ({
  user,
  redirectPath = '/landing',
  allowedTypes = [],
  children,
}: {
  user: AuthUserType;
  allowedTypes?: EmployeeRole[];
  redirectPath: string;
  children?: JSX.Element;
}) => {
  const isTokenValid = useCallback(() => {
    try {
      const minuteDifference = DateUtils.getMinuteDifference(
        new Date(),
        new Date(user.exp * 1000)
      );
      return minuteDifference >= 0;
    } catch (e) {
      console.error(`Token Validation ${user.exp}`, e);
      return false;
    }
  }, [user.exp]);

  if (!isTokenValid()) {
    return <Navigate to={redirectPath} />;
  }

  if (allowedTypes.length > 0 && !allowedTypes.includes(user.role)) {
    return <Navigate to={redirectPath} />;
  }

  return children ? children : <Outlet />;
};
