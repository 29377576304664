import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportDemographicQueryVariables = Types.Exact<{
  yearMonth: Types.Scalars['YearMonth']['input'];
  filter: Types.InputMaybe<Types.ReportDemographicFilterInput>;
}>;


export type ReportDemographicQuery = { __typename?: 'Query', reportDemographic: { __typename?: 'ReportDemographic', total: number, yearMonth: any, age: { __typename?: 'ReportDemographicAge', average: any | null, median: any | null, oldest: number | null, youngest: number | null }, gender: { __typename?: 'ReportDemographicGender', female: { __typename?: 'ReportDemographicGenderCount', count: number, percentage: any | null }, male: { __typename?: 'ReportDemographicGenderCount', count: number, percentage: any | null }, other: { __typename?: 'ReportDemographicGenderCount', count: number, percentage: any | null } } } };


export const ReportDemographicDocument = gql`
    query ReportDemographic($yearMonth: YearMonth!, $filter: ReportDemographicFilterInput) {
  reportDemographic(yearMonth: $yearMonth, filter: $filter) {
    age {
      average
      median
      oldest
      youngest
    }
    gender {
      female {
        count
        percentage
      }
      male {
        count
        percentage
      }
      other {
        count
        percentage
      }
    }
    total
    yearMonth
  }
}
    `;

/**
 * __useReportDemographicQuery__
 *
 * To run a query within a React component, call `useReportDemographicQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDemographicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDemographicQuery({
 *   variables: {
 *      yearMonth: // value for 'yearMonth'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useReportDemographicQuery(baseOptions: Apollo.QueryHookOptions<ReportDemographicQuery, ReportDemographicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportDemographicQuery, ReportDemographicQueryVariables>(ReportDemographicDocument, options);
      }
export function useReportDemographicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportDemographicQuery, ReportDemographicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportDemographicQuery, ReportDemographicQueryVariables>(ReportDemographicDocument, options);
        }
export function useReportDemographicSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportDemographicQuery, ReportDemographicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportDemographicQuery, ReportDemographicQueryVariables>(ReportDemographicDocument, options);
        }
export type ReportDemographicQueryHookResult = ReturnType<typeof useReportDemographicQuery>;
export type ReportDemographicLazyQueryHookResult = ReturnType<typeof useReportDemographicLazyQuery>;
export type ReportDemographicSuspenseQueryHookResult = ReturnType<typeof useReportDemographicSuspenseQuery>;
export type ReportDemographicQueryResult = Apollo.QueryResult<ReportDemographicQuery, ReportDemographicQueryVariables>;