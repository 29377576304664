import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeePermissionMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeePermissionInput;
}>;


export type UpdateEmployeePermissionMutation = { __typename?: 'Mutation', updateEmployeePermission: { __typename?: 'EmployeeOutput', id: string } };


export const UpdateEmployeePermissionDocument = gql`
    mutation updateEmployeePermission($input: UpdateEmployeePermissionInput!) {
  updateEmployeePermission(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeePermissionMutationFn = Apollo.MutationFunction<UpdateEmployeePermissionMutation, UpdateEmployeePermissionMutationVariables>;

/**
 * __useUpdateEmployeePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeePermissionMutation, { data, loading, error }] = useUpdateEmployeePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeePermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeePermissionMutation, UpdateEmployeePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeePermissionMutation, UpdateEmployeePermissionMutationVariables>(UpdateEmployeePermissionDocument, options);
      }
export type UpdateEmployeePermissionMutationHookResult = ReturnType<typeof useUpdateEmployeePermissionMutation>;
export type UpdateEmployeePermissionMutationResult = Apollo.MutationResult<UpdateEmployeePermissionMutation>;
export type UpdateEmployeePermissionMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeePermissionMutation, UpdateEmployeePermissionMutationVariables>;