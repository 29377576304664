import {
  CountryLazySelect,
  DEFAULT_PAGE_SIZE,
  toast,
} from '@/shared/components';
import {
  useCreateLocationMutation,
  useUpdateLocationMutation,
} from '@/shared/graphql';
import { LocationType } from '@/shared/types';
import { LocationError } from '@/shared/utils';
import {
  Button,
  ModalFooter,
  OnOffSwitch,
  TextInput,
} from '@any-ui-react/core';
import { ApolloError, BaseMutationOptions } from '@apollo/client';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  LocationFormData,
  locationFormValidationSuite,
} from './location-form.validator';

export interface LocationFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  defaultValues?: LocationType;
}

export const LocationForm = ({
  defaultValues,
  onSuccess,
  onCancel,
}: LocationFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    control,
    setError,
  } = useForm<LocationFormData>({
    defaultValues,
    resolver: vestResolver(locationFormValidationSuite),
  });

  const onError = (error: ApolloError, clientOptions?: BaseMutationOptions) => {
    if (error.message === LocationError.LOCATION_NAME_IS_NOT_UNIQUE) {
      setError('name', { message: 'Name exists already' });
    } else {
      toast.error(error.message);
    }
  };

  const [createLocation] = useCreateLocationMutation({
    onError,
    onCompleted: async (data) => {
      if (data.createLocation.id) {
        onSuccess();
      }
    },
  });
  const [updateLocation] = useUpdateLocationMutation({
    onError,
    onCompleted: (data) => {
      if (data.updateLocation.id) {
        onSuccess();
      }
    },
  });

  const [enabled] = watch(['enabled']);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        const isNewRecord = defaultValues === undefined;
        if (isNewRecord) {
          await createLocation({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled || false,
                countryId: formValues.countryId,
              },
            },
          });
        } else {
          await updateLocation({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled,
                id: defaultValues.id,
                countryId: formValues.countryId,
              },
            },
          });
        }
      }),
    [createLocation, defaultValues, handleSubmit, updateLocation]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Enter name'}
          required
        />
        <Controller
          control={control}
          name='countryId'
          render={({ field: { ...field } }) => (
            <>
              <CountryLazySelect
                {...field}
                isMulti={false}
                label={'Country'}
                error={errors.countryId?.message}
                className='select-has-min-w-menu'
                defaultQueryOptions={{
                  variables: {
                    keyword: null,
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    hasCompanies: null,
                  },
                }}
                placeholder={'Select Country'}
                onChange={(option) => {
                  setValue('countryId', option?.value as string, {
                    shouldDirty: true,
                  });
                }}
              />
            </>
          )}
        />
      </fieldset>

      <OnOffSwitch
        key={'12'}
        checked={enabled}
        onChange={(e) => {
          setValue('enabled', e.currentTarget.checked);
        }}
        label='Show on the dropdown'
      />
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
