import {
  ContractText,
  NoResult,
  Table,
  TableLayout,
  TableProps,
} from '@/shared/components';
import { EmployeeStatus } from '@/shared/graphql';
import { useResendEmailInvitation } from '@/shared/hooks';
import { EmployeeType, TableSelection } from '@/shared/types';
import { StringUtils, formatDate } from '@/shared/utils';
import { Badge, Button } from '@any-ui-react/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface UserTableProps {
  loading: boolean;
  items: EmployeeType[];
  selection: TableSelection<EmployeeType>;
  setSelection: (selection: TableSelection<EmployeeType>) => void;
  total: number;
}

export const EmployeeListTable = ({
  items,
  loading,
  setSelection,
  selection,
  total,
}: UserTableProps) => {
  const { resendEmail } = useResendEmailInvitation();
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Preferred Full Name',
          tdClassName: '!max-w-[182px] !min-w-[182px]',
          thClassName: '!max-w-[182px] !min-w-[182px]',
          accessor: 'preferredFullName',
          truncated: true,
          sticky: true,
          cellRender: (employee: EmployeeType) => {
            return (
              <Link to={`/employees/edit/${employee.id}`}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {employee.preferredName}
                </span>
              </Link>
            );
          },
        },
        {
          headerRender: () => 'ID',
          accessor: 'employeeId',
          truncated: true,
          sticky: true,
          cellRender: ({ employeeId }: EmployeeType) => {
            return <span>{employeeId}</span>;
          },
        },
        {
          headerRender: () => 'Status',
          accessor: 'status',
          cellRender: ({ status }: EmployeeType) => {
            const getStatusColor = (status: EmployeeStatus) => {
              switch (status) {
                case EmployeeStatus.Active:
                  return 'blue';
                case EmployeeStatus.Inactive:
                  return 'red';
                case EmployeeStatus.PreOnboarding:
                  return 'gray';
                default:
                  return 'gray';
              }
            };
            return (
              <Badge className='capitalize' color={getStatusColor(status)}>
                {StringUtils.capitalizeAll(status, '_')}
              </Badge>
            );
          },
        },

        {
          headerRender: () => 'Legal Full Name',
          accessor: 'legalFullName',
          cellRender: ({ legalFullName }: EmployeeType) => {
            return <span>{legalFullName}</span>;
          },
        },
        {
          headerRender: () => 'Email',
          accessor: 'email',
          cellRender: ({ email }: EmployeeType) => {
            return <span>{email || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Hired Date',
          accessor: 'hiredDate',
          cellRender: ({ hiredDate }: EmployeeType) => {
            return <span>{hiredDate ? formatDate(hiredDate) : '-'}</span>;
          },
        },
        {
          headerRender: () => 'Contract Type',
          accessor: 'contractType',
          cellRender: ({ contractType }: EmployeeType) => {
            return <span>{String(ContractText[contractType])}</span>;
          },
        },
        {
          headerRender: () => 'Office Location',
          accessor: 'officeLocation',
          cellRender: ({ officeLocation }: EmployeeType) => {
            return <span>{officeLocation || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Company',
          accessor: 'company',
          cellRender: ({ company }: EmployeeType) => {
            return <span>{company || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: ({ businessUnit }: EmployeeType) => {
            return <span>{businessUnit || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Department',
          accessor: 'department',
          cellRender: ({ department }: EmployeeType) => {
            return <span>{department || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Job Title',
          accessor: 'jobTitle',
          cellRender: ({ jobTitle }: EmployeeType) => {
            return <span>{jobTitle || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Job',
          accessor: 'job',
          cellRender: ({ job }: EmployeeType) => {
            return <span>{job || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Level',
          accessor: 'level',
          cellRender: ({ level }: EmployeeType) => {
            return <span>{level}</span>;
          },
        },
        {
          headerRender: () => 'Primary Manager',
          accessor: 'primaryManager',
          cellRender: ({ primaryManager }: EmployeeType) => {
            return <span>{primaryManager || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Dotted Line Manager',
          accessor: 'dottedLineManager',
          cellRender: ({ dottedLineManager }: EmployeeType) => {
            return <span>{dottedLineManager || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Nationality',
          accessor: 'nationality',
          cellRender: ({ nationality }: EmployeeType) => {
            return <span>{nationality}</span>;
          },
        },
        {
          headerRender: () => 'Google',
          accessor: 'google',
          cellRender: ({ isGoogleEnabled }: EmployeeType) => {
            return (
              <Badge color={isGoogleEnabled ? 'blue' : 'red'}>
                {isGoogleEnabled ? 'Active' : 'Deactivated'}
              </Badge>
            );
          },
        },
        {
          headerRender: () => 'greenhouse',
          accessor: 'isGreenhouseEnabled',
          cellRender: ({ isGreenhouseEnabled }: EmployeeType) => {
            return (
              <Badge color={isGreenhouseEnabled ? 'blue' : 'red'}>
                {isGreenhouseEnabled ? 'Active' : 'Deactivated'}
              </Badge>
            );
          },
        },
        {
          headerRender: () => 'Last Working Date',
          accessor: 'lastWorkingDate',
          cellRender: ({ lastWorkingDate }: EmployeeType) => {
            return (
              <span>{lastWorkingDate ? formatDate(lastWorkingDate) : '-'}</span>
            );
          },
        },
        {
          headerRender: () => 'Termination Date',
          accessor: 'terminationDate',
          cellRender: ({ terminationDate }: EmployeeType) => {
            return (
              <span>{terminationDate ? formatDate(terminationDate) : '-'}</span>
            );
          },
        },
        {
          headerRender: () => 'Reason for Attrition',
          accessor: 'typeOfAttrition',
          cellRender: ({ typeOfAttrition }: EmployeeType) => {
            return <span>{typeOfAttrition || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Reason For Leaving',
          accessor: 'reasonForLeaving',
          cellRender: ({ reasonForLeaving }: EmployeeType) => {
            return <span>{reasonForLeaving || '-'}</span>;
          },
        },
      ] as TableLayout<EmployeeType>[],
    []
  );

  const tableCommonProps: TableProps<EmployeeType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Employees Item Title'}
        description={'There are no Employees Item Desc'}
      />
    ),
  };

  return (
    <>
      <Table<EmployeeType>
        {...tableCommonProps}
        selection={selection}
        page={1}
        numOfStickyCols={2}
        allowFloatingHeader
        onSelectionChange={setSelection}
        selectable={{
          byField: 'id',
          total: total,
          aside: (
            <Button
              className='bg-blue-6'
              onClick={() =>
                resendEmail({ ids: selection.items.map((item) => item.id) })
              }
            >
              Resend Invitation
            </Button>
          ),
        }}
      />
    </>
  );
};
