import { JobHistoryType } from '@/shared/graphql';
import { StringUtils } from '@/shared/utils';
import { Select, SelectProps } from '@any-ui-react/core';
import { forwardRef } from 'react';

interface JobHistoryTypeSelectProps extends Omit<SelectProps, 'data'> {}

export const JobHistoryTypeSelect = forwardRef<
  HTMLInputElement,
  JobHistoryTypeSelectProps
>((props, ref) => {
  const types = Object.values(JobHistoryType).map((value) => ({
    label: StringUtils.capitalizeAll(value, '_'),
    value: value,
  }));

  return (
    <Select
      data={types}
      withinPortal={true}
      dropdownPosition='bottom'
      ref={ref}
      {...props}
    />
  );
});
