import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDivisionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetDivisionQuery = { __typename?: 'Query', division: { __typename?: 'Division', id: string, name: string } | null };


export const GetDivisionDocument = gql`
    query getDivision($id: ID!) {
  division(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetDivisionQuery__
 *
 * To run a query within a React component, call `useGetDivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDivisionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDivisionQuery(baseOptions: Apollo.QueryHookOptions<GetDivisionQuery, GetDivisionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDivisionQuery, GetDivisionQueryVariables>(GetDivisionDocument, options);
      }
export function useGetDivisionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDivisionQuery, GetDivisionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDivisionQuery, GetDivisionQueryVariables>(GetDivisionDocument, options);
        }
export function useGetDivisionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDivisionQuery, GetDivisionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDivisionQuery, GetDivisionQueryVariables>(GetDivisionDocument, options);
        }
export type GetDivisionQueryHookResult = ReturnType<typeof useGetDivisionQuery>;
export type GetDivisionLazyQueryHookResult = ReturnType<typeof useGetDivisionLazyQuery>;
export type GetDivisionSuspenseQueryHookResult = ReturnType<typeof useGetDivisionSuspenseQuery>;
export type GetDivisionQueryResult = Apollo.QueryResult<GetDivisionQuery, GetDivisionQueryVariables>;