import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportNumberOfEmployeeCompanyQueryVariables = Types.Exact<{
  year: Types.Scalars['Year']['input'];
  contractTypes: Types.InputMaybe<Array<Types.ContractTypeForReport> | Types.ContractTypeForReport>;
}>;


export type ReportNumberOfEmployeeCompanyQuery = { __typename?: 'Query', reportNumberOfEmployeeCompany: { __typename?: 'ReportNumberOfEmployeeByCompany', yearMonthList: Array<any>, items: Array<{ __typename?: 'ReportNumberOfEmployeeByCompanyItem', companyId: string, total: number, yearMonth: any, company: { __typename?: 'Company', name: string } }> } };


export const ReportNumberOfEmployeeCompanyDocument = gql`
    query ReportNumberOfEmployeeCompany($year: Year!, $contractTypes: [ContractTypeForReport!]) {
  reportNumberOfEmployeeCompany(year: $year, contractTypes: $contractTypes) {
    items {
      companyId
      total
      yearMonth
      company {
        name
      }
    }
    yearMonthList
  }
}
    `;

/**
 * __useReportNumberOfEmployeeCompanyQuery__
 *
 * To run a query within a React component, call `useReportNumberOfEmployeeCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportNumberOfEmployeeCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportNumberOfEmployeeCompanyQuery({
 *   variables: {
 *      year: // value for 'year'
 *      contractTypes: // value for 'contractTypes'
 *   },
 * });
 */
export function useReportNumberOfEmployeeCompanyQuery(baseOptions: Apollo.QueryHookOptions<ReportNumberOfEmployeeCompanyQuery, ReportNumberOfEmployeeCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportNumberOfEmployeeCompanyQuery, ReportNumberOfEmployeeCompanyQueryVariables>(ReportNumberOfEmployeeCompanyDocument, options);
      }
export function useReportNumberOfEmployeeCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportNumberOfEmployeeCompanyQuery, ReportNumberOfEmployeeCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportNumberOfEmployeeCompanyQuery, ReportNumberOfEmployeeCompanyQueryVariables>(ReportNumberOfEmployeeCompanyDocument, options);
        }
export function useReportNumberOfEmployeeCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportNumberOfEmployeeCompanyQuery, ReportNumberOfEmployeeCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportNumberOfEmployeeCompanyQuery, ReportNumberOfEmployeeCompanyQueryVariables>(ReportNumberOfEmployeeCompanyDocument, options);
        }
export type ReportNumberOfEmployeeCompanyQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeCompanyQuery>;
export type ReportNumberOfEmployeeCompanyLazyQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeCompanyLazyQuery>;
export type ReportNumberOfEmployeeCompanySuspenseQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeCompanySuspenseQuery>;
export type ReportNumberOfEmployeeCompanyQueryResult = Apollo.QueryResult<ReportNumberOfEmployeeCompanyQuery, ReportNumberOfEmployeeCompanyQueryVariables>;