import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmployeeVisaMutationVariables = Types.Exact<{
  input: Types.CreateEmployeeVisaInput;
}>;


export type CreateEmployeeVisaMutation = { __typename?: 'Mutation', createEmployeeVisa: { __typename?: 'EmployeeVisaOutput', id: string } };


export const CreateEmployeeVisaDocument = gql`
    mutation createEmployeeVisa($input: CreateEmployeeVisaInput!) {
  createEmployeeVisa(input: $input) {
    id
  }
}
    `;
export type CreateEmployeeVisaMutationFn = Apollo.MutationFunction<CreateEmployeeVisaMutation, CreateEmployeeVisaMutationVariables>;

/**
 * __useCreateEmployeeVisaMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeVisaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeVisaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeVisaMutation, { data, loading, error }] = useCreateEmployeeVisaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeVisaMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeVisaMutation, CreateEmployeeVisaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeVisaMutation, CreateEmployeeVisaMutationVariables>(CreateEmployeeVisaDocument, options);
      }
export type CreateEmployeeVisaMutationHookResult = ReturnType<typeof useCreateEmployeeVisaMutation>;
export type CreateEmployeeVisaMutationResult = Apollo.MutationResult<CreateEmployeeVisaMutation>;
export type CreateEmployeeVisaMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeVisaMutation, CreateEmployeeVisaMutationVariables>;