export const setStorageItem = (key: string, value: string) => {
  if (!getStorageItem(key)) localStorage.setItem(key, value);

  return getStorageItem(key);
};

export const getStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key);
};
