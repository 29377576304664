import { useEffect, useState } from 'react';
import { useLocation, Location, useNavigationType } from 'react-router-dom';

import { LocationContext } from './LocationContext';

export const LocationProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const [previous, setPrevious] = useState<Location | undefined>();
  const [current, setCurrent] = useState(location);

  useEffect(() => {
    if (navigationType !== 'POP') {
      setPrevious(current);
      setCurrent(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <LocationContext.Provider
      value={{
        previous,
        current,
        historyAction: navigationType,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
