import { useModals } from '@any-ui-react/modals';
import { v4 as uuid } from 'uuid';
import { ModalFooter, Button } from '@any-ui-react/core';

export const deleteModalId = uuid();

export const useDeleteRecordModal = () => {
  const modalId = deleteModalId;
  const { openModal, closeModal } = useModals();

  const openDeleteRecordModal = ({ onSuccess }: { onSuccess: () => void }) => {
    openModal({
      modalId,
      title: 'Are you sure to delete it?',
      size: 'lg',
      children: (
        <div>
          <span>The process cannot be undone</span>
          <ModalFooter>
            <Button variant='default' onClick={() => closeModal(modalId)}>
              Cancel
            </Button>
            <Button
              className='bg-[#FF5F5F] hover:bg-[#FF5F5F]'
              type='submit'
              onClick={() => {
                onSuccess();
                closeModal(modalId);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </div>
      ),
    });
  };

  return { openDeleteRecordModal };
};
