import { toast } from '@/shared/components';
import {
  useCreateCompanyEmailDomainMutation,
  useUpdateCompanyEmailDomainMutation,
} from '@/shared/graphql';
import { CompanyEmailDomainType } from '@/shared/types';
import { CompanyEmailDomainError } from '@/shared/utils';
import {
  Button,
  ModalFooter,
  OnOffSwitch,
  TextInput,
} from '@any-ui-react/core';
import { ApolloError } from '@apollo/client';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  CompanyEmailDomainFormData,
  companyEmailDomainFormValidationSuite,
} from './company-email-domain-form.validator';

export interface CompanyEmailDomainFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  defaultValues?: CompanyEmailDomainType;
}

export const CompanyEmailDomainForm = ({
  defaultValues,
  onSuccess,
  onCancel,
}: CompanyEmailDomainFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    setError,
  } = useForm<CompanyEmailDomainFormData>({
    defaultValues: defaultValues ? defaultValues : { enabled: true },
    resolver: vestResolver(companyEmailDomainFormValidationSuite),
  });

  const onError = (error: ApolloError) => {
    if (
      error.message ===
      CompanyEmailDomainError.COMPANY_EMAIL_DOMAIN_NAME_IS_NOT_UNIQUE
    ) {
      setError('id', { message: 'Name exists already' });
    } else {
      toast.error(error.message);
    }
  };

  const [createCompanyEmailDomain] = useCreateCompanyEmailDomainMutation({
    onError,
    onCompleted: async (data) => {
      if (data.createCompanyEmailDomain.id) {
        onSuccess();
      }
    },
  });
  const [updateCompanyEmailDomain] = useUpdateCompanyEmailDomainMutation({
    onError,
    onCompleted: (data) => {
      if (data.updateCompanyEmailDomain.id) {
        onSuccess();
      }
    },
  });

  const [enabled, disableExternalAccount] = watch([
    'enabled',
    'disableExternalAccount',
  ]);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        const isNewRecord = defaultValues === undefined;
        if (isNewRecord) {
          await createCompanyEmailDomain({
            variables: {
              input: {
                id: formValues.id,
                enabled: formValues.enabled || false,
                disableExternalAccount:
                  formValues.disableExternalAccount || false,
              },
            },
          });
        } else {
          await updateCompanyEmailDomain({
            variables: {
              input: {
                id: formValues.id,
                enabled: formValues.enabled,
                disableExternalAccount:
                  formValues.disableExternalAccount || false,
              },
            },
          });
        }
      }),
    [
      createCompanyEmailDomain,
      defaultValues,
      handleSubmit,
      updateCompanyEmailDomain,
    ]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('id')}
          label={'Name'}
          error={errors.id?.message}
          placeholder={'Enter a company email domain'}
          disabled={false}
          className='col-span-2 cursor-not-allowed'
          required
        />
        <OnOffSwitch
          className='col-span-2'
          key={'12'}
          checked={enabled}
          onChange={(e) => {
            setValue('enabled', e.currentTarget.checked);
          }}
          label='Show on the dropdown'
        />
        <OnOffSwitch
          key={'13'}
          className='col-span-2'
          checked={!disableExternalAccount}
          onChange={(e) => {
            setValue('disableExternalAccount', !e.currentTarget.checked);
          }}
          label='Create External Account'
        />
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
