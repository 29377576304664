import { useMemo } from 'react';

import {
  DefaultRangeValue,
  GetDefaultDateRangeOptions,
} from '@any-ui-react/dates';
import { today } from '@internationalized/date';
import { TimezoneUtils } from '../../utils';

enum CustomRangeValue {
  LAST_90_DAYS = 'LAST_90_DAYS',
}
const RangeValue = { ...DefaultRangeValue, ...CustomRangeValue } as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type RangeValue = (typeof RangeValue)[keyof typeof RangeValue];

export const useDateRangeListOptions = ({
  locale = 'en-US',
  timezone = TimezoneUtils.getCurrentTimezone(),
  only,
}: {
  locale?: string;
  timezone?: string;
  only?: RangeValue[];
} = {}) => {
  const { LAST_7_DAYS, LAST_30_DAYS } = GetDefaultDateRangeOptions(
    locale,
    timezone
  );
  const yesterday = today(timezone).subtract({ days: 1 });

  const customOptions = {
    [RangeValue.LAST_7_DAYS]: {
      ...LAST_7_DAYS,
      end: yesterday,
    },
    [RangeValue.LAST_30_DAYS]: {
      ...LAST_30_DAYS,
      end: yesterday,
    },
    [RangeValue.LAST_90_DAYS]: {
      start: today(timezone).subtract({ days: 90 }),
      end: yesterday,
    },
  };

  const rangeOptions = {
    ...GetDefaultDateRangeOptions(locale, timezone),
    ...customOptions,
  };

  return useMemo(
    () =>
      [
        {
          label: 'Today',
          value: rangeOptions.TODAY,
          key: RangeValue.TODAY,
        },
        {
          label: 'Yesterday',
          value: rangeOptions.YESTERDAY,
          key: RangeValue.YESTERDAY,
        },
        {
          label: 'Last 7 Days',
          value: rangeOptions.LAST_7_DAYS,
          key: RangeValue.LAST_7_DAYS,
        },
        {
          label: 'Last 30 Days',
          value: rangeOptions.LAST_30_DAYS,
          key: RangeValue.LAST_30_DAYS,
        },
        {
          label: 'Last 90 Days',
          value: rangeOptions.LAST_90_DAYS,
          key: RangeValue.LAST_90_DAYS,
        },
        {
          label: 'Last Week',
          value: rangeOptions.LAST_WEEK,
          key: RangeValue.LAST_WEEK,
        },
        {
          label: 'Last Month',
          value: rangeOptions.LAST_MONTH,
          key: RangeValue.LAST_MONTH,
        },
        {
          label: 'Last Year',
          value: rangeOptions.LAST_YEAR,
          key: RangeValue.LAST_YEAR,
        },
        {
          label: 'Week To Date',
          value: rangeOptions.WEEK_TO_DATE,
          key: RangeValue.WEEK_TO_DATE,
        },
        {
          label: 'Month To Date',
          value: rangeOptions.MONTH_TO_DATE,
          key: RangeValue.MONTH_TO_DATE,
        },
        {
          label: 'Quarter To Date',
          value: rangeOptions.QUARTER_TO_DATE,
          key: RangeValue.QUARTER_TO_DATE,
        },
        {
          label: 'Year To Date',
          value: rangeOptions.YEAR_TO_DATE,
          key: RangeValue.YEAR_TO_DATE,
        },
        {
          label: 'Quarter 1',
          value: rangeOptions.QUARTER_1,
          key: RangeValue.QUARTER_1,
        },
        {
          label: 'Quarter 2',
          value: rangeOptions.QUARTER_2,
          key: RangeValue.QUARTER_2,
        },
        {
          label: 'Quarter 3',
          value: rangeOptions.QUARTER_3,
          key: RangeValue.QUARTER_3,
        },
        {
          label: 'Quarter 4',
          value: rangeOptions.QUARTER_4,
          key: RangeValue.QUARTER_4,
        },
      ].filter((range) =>
        (only || []).length > 0 ? only?.includes(range.key) : true
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [only]
  );
};
