import { ChevronDownIcon, FilterFunnelIcon } from '@/shared/components';
import { useAuthContext } from '@/shared/contexts';
import {
  EmployeeRole,
  useListEmployeeListCustomFiltersQuery,
} from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { EmployeeListFilter, EmployeeListFilterType } from '@/shared/types';
import { Button, CrossCircleFill, Menu } from '@any-ui-react/core';
import classNames from 'classnames';
import { ManageCustomFilterModal } from './manage-custom-filter-modal';
import { SaveCustomFilterUnitModal } from './save-custom-filter-modal/SaveCustomFilterModal';

export const CustomFilterButton = ({
  filters,
}: {
  filters: FilterProps<EmployeeListFilterType>;
}) => {
  const { authUser } = useAuthContext();
  const isOpenFilterSavable = authUser.role === EmployeeRole.SuperAdmin;
  const currentFilters = filters.current;
  const { data } = useListEmployeeListCustomFiltersQuery();
  return (
    <div className='custom-filter-container'>
      <Menu
        shadow='md'
        width={320}
        position='bottom-end'
        classNames={{
          dropdown: '!w-56 !py-2',
        }}
      >
        <Menu.Target>
          <Button
            className='flex w-full justify-start pl-3'
            variant='default'
            rightIcon={
              filters.current.id ? (
                <CrossCircleFill
                  height={20}
                  width={20}
                  fill='#C5D0DA'
                  onClick={(event) => {
                    event.stopPropagation();
                    filters.resetFilters();
                  }}
                />
              ) : (
                <ChevronDownIcon height={16} width={16} />
              )
            }
          >
            <FilterFunnelIcon height={16} width={16} />
            <span
              className={classNames('ml-1', {
                'font-semibold': currentFilters.name !== '',
              })}
            >
              {currentFilters.name ? currentFilters.name : 'Custom Filter'}
            </span>
          </Button>
        </Menu.Target>

        <Menu.Dropdown defaultValue={'Business Profile'}>
          <Menu.Label className=' text-sm font-semibold text-black'>
            Manage custom filters
          </Menu.Label>

          {isOpenFilterSavable && (
            <SaveCustomFilterUnitModal filters={filters}>
              <Menu.Item>Save Current filters</Menu.Item>
            </SaveCustomFilterUnitModal>
          )}
          <ManageCustomFilterModal filters={filters}>
            <Menu.Item>Manage Current filters</Menu.Item>
          </ManageCustomFilterModal>
          <Menu.Label className='mt-1 text-sm font-semibold text-black'>
            Saved custom filters
          </Menu.Label>

          {data?.listEmployeeListCustomFilters.items.map((item) => {
            return (
              <Menu.Item
                key={item.id}
                className={classNames('', {
                  'bg-gray-1': item.id === currentFilters.id,
                })}
                onClick={() => {
                  filters.updateFilters(
                    EmployeeListFilter.parse({
                      ...item,
                      hiredDate: item.hiredDate
                        ? {
                            start: item.hiredDate.start,
                            end: item.hiredDate.end,
                          }
                        : null,
                      employeeEndDate: item.employmentEndDate
                        ? {
                            start: item.employmentEndDate.start,
                            end: item.employmentEndDate.end,
                          }
                        : null,
                    })
                  );
                }}
              >
                {item.name}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
      {/* {currentFilters.id && (
        <SaveCustomFilterUnitModal filters={filters} isNewRecord={false}>
          <span className='float-right mt-2 cursor-pointer text-xs text-blue-6'>
            Save the changes
          </span>
        </SaveCustomFilterUnitModal>
      )} */}
    </div>
  );
};
