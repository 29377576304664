import {
  ContractType,
  EmployeeRole,
  EmployeeStatus,
  JobHistoryLevel,
} from '@/shared/graphql';
import { z } from 'zod';
import { ExternalAccountsFilter } from './external-accounts-filter';

export const EmployeeListFilter = z
  .object({
    id: z.string().optional().nullable().default(null),
    name: z.string().optional().nullable().default(null),
    page: z.number().catch(1),
    keyword: z.string().nullable().optional().catch(null),
    statuses: z.array(z.nativeEnum(EmployeeStatus)).catch([]),
    hiredDate: z
      .object({
        start: z.string().nullable().default(''),
        end: z.string().nullable().default(''),
      })
      .nullable()
      .optional()
      .catch(null),
    employeeEndDate: z
      .object({
        start: z.string().nullable().default(''),
        end: z.string().nullable().default(''),
      })
      .nullable()
      .optional()
      .catch(null),
    contractTypes: z.array(z.nativeEnum(ContractType)).catch([]),
    levels: z.array(z.nativeEnum(JobHistoryLevel)).catch([]),
    roles: z.array(z.nativeEnum(EmployeeRole)).catch([]),
    companyIds: z.array(z.string()).catch([]),
    locationIds: z.array(z.string()).catch([]),
    divisionIds: z.array(z.string()).catch([]),
    businessUnitIds: z.array(z.string()).catch([]),
    managerIds: z.array(z.string()).catch([]),
    departmentIds: z.array(z.string()).catch([]),
    jobIds: z.array(z.string()).catch([]),
    nationalityIds: z.array(z.string()).catch([]),
    isOnSecondment: z.boolean().default(false),
    isLeave: z.boolean().default(false),
    isOpenFilter: z.boolean().default(false),
    managerIncludesAllConnections: z.boolean().default(false),
  })
  .merge(ExternalAccountsFilter);
export type EmployeeListFilterType = z.infer<typeof EmployeeListFilter>;
