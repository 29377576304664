import { Group, Radio } from '@any-ui-react/core';

enum ContractType {
  Main = 'Main',
  SubContract = 'Sub',
}

export const ContractTypeRadioGroup = ({
  value,
  onChange,
}: {
  value: boolean;
  onChange: (isMain: boolean) => void;
}) => {
  return (
    <Radio.Group
      value={value ? ContractType.Main : ContractType.SubContract}
      className='flex flex-col'
      labelProps={{ className: 'pb-4' }}
      onChange={(value) => onChange(value === ContractType.Main)}
    >
      <Group className='flex space-x-4'>
        <Radio label='Main Contract' value={ContractType.Main} />
        <Radio label='Sub Contract' value={ContractType.SubContract} />
      </Group>
    </Radio.Group>
  );
};
