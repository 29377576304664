import { z } from 'zod';

export const CompanyEmailDomain = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  enabled: z.boolean().default(false),
  disableExternalAccount: z.boolean().default(false),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
});
export type CompanyEmailDomainType = z.infer<typeof CompanyEmailDomain>;
