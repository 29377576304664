import { create, enforce, test } from 'vest';

export interface CompanyEmailDomainFormData {
  id: string;
  enabled: boolean;
  disableExternalAccount: boolean;
}

export const companyEmailDomainFormValidationSuite = create(
  ({ id }: CompanyEmailDomainFormData) => {
    test('id', 'Name is required', () => {
      enforce(id).isNotEmpty();
    });
  }
);
