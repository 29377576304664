import {
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
  useDeleteRecordModal,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeeVisaType } from '@/shared/types';
import { useMemo } from 'react';
import { VisaModal } from './VisaModal';

interface VisaTableProps {
  loading: boolean;
  items: EmployeeVisaType[];
  onRemove: (id: string) => void;
  onUpdate: (data: EmployeeVisaType) => void;
}

export const VisaTable = ({
  items,
  loading,
  onRemove,
  onUpdate,
}: VisaTableProps) => {
  const { openDeleteRecordModal } = useDeleteRecordModal();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationDeleteAccess } = useEmployeeAccess();
  const isRemovable = hasPersonalInformationDeleteAccess || isNewRecord;

  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Visa Type',
          accessor: 'visaType',
          cellRender: (visa: EmployeeVisaType) => {
            return (
              <VisaModal className='ml-auto' onSuccess={onUpdate} visa={visa}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {visa.visaType}
                </span>
              </VisaModal>
            );
          },
        },
        {
          headerRender: () => 'Visa Number',
          accessor: 'visaNumber',
          cellRender: (visa: EmployeeVisaType) => {
            return <span>{visa.visaNumber}</span>;
          },
        },
        {
          headerRender: () => 'Visa Issued Date',
          accessor: 'visaIssuedDate',
          cellRender: (visa: EmployeeVisaType) => {
            return <span>{visa.visaIssuedDate}</span>;
          },
        },

        {
          headerRender: () => 'Visa Expiry Date',
          accessor: 'visaExpiryDate',
          cellRender: (visa: EmployeeVisaType) => {
            return <span>{visa.visaExpiryDate}</span>;
          },
        },

        {
          headerRender: () => 'Work Permit Number',
          accessor: 'workPermitNumber',
          cellRender: (visa: EmployeeVisaType) => {
            return <span>{visa.workPermitNumber || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Work Permit Issued Date',
          accessor: 'workPermitIssuedDate',
          cellRender: (visa: EmployeeVisaType) => {
            return <span>{visa.workPermitIssuedDate || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Work Permit Expiry Date',
          accessor: 'workPermitExpiryDate',
          cellRender: (visa: EmployeeVisaType) => {
            return <span>{visa.workPermitExpiryDate || '-'}</span>;
          },
        },
        ...(isRemovable
          ? [
              {
                headerRender: () => '',
                accessor: 'delete',
                cellRender: (visa: EmployeeVisaType) => {
                  return (
                    <TrashIcon
                      onClick={() => {
                        openDeleteRecordModal({
                          onSuccess: () => onRemove(visa.id),
                        });
                      }}
                      className='ml-auto h-4 w-4  text-gray-7'
                    />
                  );
                },
              },
            ]
          : []),
      ] as TableLayout<EmployeeVisaType>[],
    [isRemovable, onRemove, onUpdate, openDeleteRecordModal]
  );

  const tableCommonProps: TableProps<EmployeeVisaType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<EmployeeVisaType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
