interface Props {
  label: string;
  value: string;
  suffix?: string;
}

export const DemographicData = ({ label, value, suffix }: Props) => {
  return (
    <div className='w-1/4 border-l-2 px-2 py-0.5'>
      <div className='flex flex-col space-y-0.5'>
        <span className='text-2xs font-semibold'>{label}</span>
        <div className='flex items-end space-x-1'>
          <span className='text-2xl font-semibold'>{value}</span>
          <span className='py-1 text-6xs font-semibold'>{suffix}</span>
        </div>
      </div>
    </div>
  );
};
