import { CardTitle } from '@/shared/components';
import { SettingNotificationType } from '@/shared/types';
import { useFormContext } from 'react-hook-form';
import { NotificationItem } from '../notification-item';

export const Probation = () => {
  const { watch } = useFormContext<SettingNotificationType>();
  const [
    probationReviewScheduledEndDate,
    probationReviewDeadlineForEach,
    probationReviewNeedMoreConsideration,
  ] = watch([
    'probationReviewScheduledEndDate',
    'probationReviewDeadlineForEach',
    'probationReviewNeedMoreConsideration',
  ]);

  return (
    <div className='border-b py-6'>
      <CardTitle>Probation</CardTitle>

      <div className='flex flex-col space-y-6'>
        <NotificationItem
          label='Scheduled Probation End Date (Only when it is not submitted after the scheduled probation end date)'
          name='probationReviewScheduledEndDate'
          value={probationReviewScheduledEndDate}
        />

        <NotificationItem
          label='Review deadline for each probation review'
          name='probationReviewDeadlineForEach'
          value={probationReviewDeadlineForEach}
        />

        <NotificationItem
          label='Probation review is submitted as "Need more consideration"'
          name='probationReviewNeedMoreConsideration'
          value={probationReviewNeedMoreConsideration}
          withDate={false}
        />
      </div>
    </div>
  );
};
