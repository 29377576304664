import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListCompanyEmailDomainsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  enabled: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ListCompanyEmailDomainsQuery = { __typename?: 'Query', listCompanyEmailDomains: { __typename?: 'ListCompanyEmailDomains', totalCount: number, items: Array<{ __typename?: 'CompanyEmailDomain', id: string, enabled: boolean, createdAt: any, updatedAt: any, disableExternalAccount: boolean }> } };


export const ListCompanyEmailDomainsDocument = gql`
    query listCompanyEmailDomains($pageNumber: Int!, $pageSize: Int!, $enabled: Boolean) {
  listCompanyEmailDomains(
    pageNumber: $pageNumber
    pageSize: $pageSize
    enabled: $enabled
  ) {
    items {
      id
      enabled
      createdAt
      updatedAt
      disableExternalAccount
    }
    totalCount
  }
}
    `;

/**
 * __useListCompanyEmailDomainsQuery__
 *
 * To run a query within a React component, call `useListCompanyEmailDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyEmailDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyEmailDomainsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useListCompanyEmailDomainsQuery(baseOptions: Apollo.QueryHookOptions<ListCompanyEmailDomainsQuery, ListCompanyEmailDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompanyEmailDomainsQuery, ListCompanyEmailDomainsQueryVariables>(ListCompanyEmailDomainsDocument, options);
      }
export function useListCompanyEmailDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompanyEmailDomainsQuery, ListCompanyEmailDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompanyEmailDomainsQuery, ListCompanyEmailDomainsQueryVariables>(ListCompanyEmailDomainsDocument, options);
        }
export function useListCompanyEmailDomainsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCompanyEmailDomainsQuery, ListCompanyEmailDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCompanyEmailDomainsQuery, ListCompanyEmailDomainsQueryVariables>(ListCompanyEmailDomainsDocument, options);
        }
export type ListCompanyEmailDomainsQueryHookResult = ReturnType<typeof useListCompanyEmailDomainsQuery>;
export type ListCompanyEmailDomainsLazyQueryHookResult = ReturnType<typeof useListCompanyEmailDomainsLazyQuery>;
export type ListCompanyEmailDomainsSuspenseQueryHookResult = ReturnType<typeof useListCompanyEmailDomainsSuspenseQuery>;
export type ListCompanyEmailDomainsQueryResult = Apollo.QueryResult<ListCompanyEmailDomainsQuery, ListCompanyEmailDomainsQueryVariables>;