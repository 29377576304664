import { DayPicker, EmployeeIdTypeSelect } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { EmployeeIdType } from '@/shared/graphql';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeePersonalInformationFormDataType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { TextInput } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';

export const IdentificationDetails = () => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<EmployeePersonalInformationFormDataType>();

  const [idIssuedDate, idExpiryDate, idType] = watch([
    'idIssuedDate',
    'idExpiryDate',
    'idType',
  ]);

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationEditAccess } = useEmployeeAccess();
  const isEditable = hasPersonalInformationEditAccess || isNewRecord;

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <EmployeeIdTypeSelect
          placeholder='Select Identification'
          value={idType}
          error={errors.idType?.message}
          disabled={!isEditable}
          onChange={(value) => {
            setValue('idType', value as EmployeeIdType);
          }}
        />
        <TextInput
          {...register('idNumber')}
          disabled={!isEditable}
          placeholder='ID Number'
          label='ID Number'
        />
        <DayPicker
          error={errors.idIssuedDate?.message}
          placeholder='Select ID Issued Date'
          disabled={!isEditable}
          label='ID Issued Date'
          required
          value={
            idIssuedDate ? new Date(idIssuedDate).toISOString() : undefined
          }
          onChange={(value) => {
            setValue(
              'idIssuedDate',
              DateUtils.formatDate(
                new Date(value || '').toISOString(),
                'yyyy-MM-dd'
              ),
              {
                shouldDirty: true,
              }
            );
          }}
        />
        <DayPicker
          error={errors.idExpiryDate?.message}
          disabled={!isEditable}
          required
          placeholder='Select ID Expiry Date'
          label='ID Expiry Date'
          value={
            idExpiryDate ? new Date(idExpiryDate).toISOString() : undefined
          }
          onChange={(value) => {
            setValue(
              'idExpiryDate',
              DateUtils.formatDate(
                new Date(value || '').toISOString(),
                'yyyy-MM-dd'
              ),
              {
                shouldDirty: true,
              }
            );
          }}
        />
      </fieldset>
    </div>
  );
};
