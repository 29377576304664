import { toast } from '@/shared/components';
import { useUpdateEmployeeGeneralInformationMutation } from '@/shared/graphql';
import { EmployeeGeneralInformationFormDataType } from '@/shared/types';

export const useUpdateEmployeeGeneralInformation = ({
  onCompleted,
}: {
  onCompleted: VoidFunction;
}) => {
  const [updateRecord] = useUpdateEmployeeGeneralInformationMutation();

  const handleUpdateEmployeeGeneralInformation = (
    formData: EmployeeGeneralInformationFormDataType,
    employeeId: string
  ) => {
    updateRecord({
      variables: {
        input: {
          companyEmail: {
            account: formData.emailUsername || '',
            domainId: formData.emailDomain || '',
          },
          employeeId: employeeId,
          hiringSource: formData.hiringSource,
          isNewGraduate: formData.isNewGraduate,
          hiredDate: formData.hiredDate,
          legalFullName: formData.legalFullName || '',
          nameInLocalLanguage: formData.nameInLocalLanguage,
          preferredName: formData.preferredFullName || '',
          primaryManagerId: formData.primaryManagerId || '',
          nationalityId: formData.nationalityId || '',
          needsProbationReview: formData.needsProbationReview,
        },
      },
      onError: () => {
        toast.error();
      },
      onCompleted: (data) => {
        if (data.updateEmployeeGeneralInformation) {
          toast.success();
          onCompleted();
        }
      },
    });
  };

  return {
    handleUpdateEmployeeGeneralInformation,
  };
};
