import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmployeeCompanyAssetMutationVariables = Types.Exact<{
  input: Types.CreateEmployeeCompanyAssetInput;
}>;


export type CreateEmployeeCompanyAssetMutation = { __typename?: 'Mutation', createEmployeeCompanyAsset: { __typename?: 'EmployeeCompanyAssetOutput', id: string } };


export const CreateEmployeeCompanyAssetDocument = gql`
    mutation createEmployeeCompanyAsset($input: CreateEmployeeCompanyAssetInput!) {
  createEmployeeCompanyAsset(input: $input) {
    id
  }
}
    `;
export type CreateEmployeeCompanyAssetMutationFn = Apollo.MutationFunction<CreateEmployeeCompanyAssetMutation, CreateEmployeeCompanyAssetMutationVariables>;

/**
 * __useCreateEmployeeCompanyAssetMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeCompanyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeCompanyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeCompanyAssetMutation, { data, loading, error }] = useCreateEmployeeCompanyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeCompanyAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeCompanyAssetMutation, CreateEmployeeCompanyAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeCompanyAssetMutation, CreateEmployeeCompanyAssetMutationVariables>(CreateEmployeeCompanyAssetDocument, options);
      }
export type CreateEmployeeCompanyAssetMutationHookResult = ReturnType<typeof useCreateEmployeeCompanyAssetMutation>;
export type CreateEmployeeCompanyAssetMutationResult = Apollo.MutationResult<CreateEmployeeCompanyAssetMutation>;
export type CreateEmployeeCompanyAssetMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeCompanyAssetMutation, CreateEmployeeCompanyAssetMutationVariables>;