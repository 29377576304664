import { toast } from '@/shared/components';
import { ListReasonForLeavingsDocument } from '@/shared/graphql';
import { ReasonForLeavingType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { ReasonForLeavingForm } from './ReasonForLeavingForm';

export const ReasonForLeavingModal = ({
  children,
  className,
  reasonForLeaving,
}: {
  children: React.ReactNode;
  className?: string;
  reasonForLeaving?: ReasonForLeavingType;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${reasonForLeaving ? 'Edit' : 'Add New'} Reason For Leaving`,
      size: 'lg',
      children: (
        <ReasonForLeavingForm
          onSuccess={async () => {
            toast.success();
            await client.refetchQueries({
              include: [ListReasonForLeavingsDocument],
            });
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          defaultValues={reasonForLeaving}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
