import { useEmployeeDetailContext } from '@/shared/contexts';
import { EmployeeContractHistoryType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { ContractHistoryForm } from './ContractHistoryForm';
export const ContractHistoryModal = ({
  children,
  onSuccess,
  jobHistory,
  className,
  isNewRecord = false,
}: {
  children: React.ReactNode;
  onSuccess: (data: EmployeeContractHistoryType) => Promise<boolean>;
  jobHistory?: EmployeeContractHistoryType;
  className?: string;
  isNewRecord?: boolean;
}) => {
  const modalId = uuid();
  const { employeeDetail } = useEmployeeDetailContext();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      size: 'lg',
      children: (
        <ContractHistoryForm
          contractHistory={jobHistory}
          onSuccess={async (data) => {
            const isSuccess = await onSuccess(data);
            if (isSuccess) closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          latestContractHistory={employeeDetail.contractHistories[0]}
          employmentEndDate={employeeDetail.offBoarding.employmentEndDate}
          formTitle={`${!isNewRecord ? 'Update' : 'Add New'} Contract History`}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
