import {
  JobHistoryLevel,
  JobHistoryType as JobHistoryNativeType,
} from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeJobHistory = z.object({
  id: z.string().default(''),
  effectiveDateStart: z.string().default(''),
  effectiveDateEnd: z.string().nullable().default(''),
  type: z.nativeEnum(JobHistoryNativeType).nullable().default(null),
  location: z.string().default(''),
  division: z.string().default(''),
  department: z.string().default(''),
  businessUnit: z.string().default(''),
  job: z.string().default(''),
  level: z.nativeEnum(JobHistoryLevel).nullable().default(null),
  jobId: z.string().default(''),
  countryId: z.string().default(''),
  departmentId: z.string().default(''),
  businessUnitId: z.string().default(''),
  locationId: z.string().default(''),
  divisionId: z.string().default(''),
  jobTitle: z.string().default(''),
  team: z.string().nullable().default(''),
  regionalRole: z.boolean().default(false),
});
export type EmployeeJobHistoryType = z.infer<typeof EmployeeJobHistory>;
