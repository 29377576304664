import { EmployeeExternalAccountStatus } from '@/shared/graphql';
import { z } from 'zod';

export const ExternalAccountsFilter = z.object({
  externalAccountGoogle: z
    .array(z.nativeEnum(EmployeeExternalAccountStatus))
    .catch([]),
  externalAccountGreenhouse: z
    .array(z.nativeEnum(EmployeeExternalAccountStatus))
    .catch([]),
  externalAccountHubspot: z
    .array(z.nativeEnum(EmployeeExternalAccountStatus))
    .catch([]),
  externalAccountSlack: z
    .array(z.nativeEnum(EmployeeExternalAccountStatus))
    .catch([]),
});
export type ExternalAccountsFilterType = z.infer<typeof ExternalAccountsFilter>;
