import {
  ReportContractText,
  ReportContractTypeSelect,
  YearPicker,
} from '@/shared/components';
import { FilterProps } from '@/shared/hooks';
import { NumberOfEmployeesFilterType } from '@/shared/types';

export const NumberOfEmployeesFilters = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { changeFilter, current } = filters;

  return (
    <div className='flex space-x-2 p-4'>
      <div className='w-[232px]'>
        <YearPicker
          value={current.year}
          onChange={(newValue) => changeFilter('year', newValue)}
          minDate={new Date(2016, 3, 1)}
          maxDate={new Date()}
        />
      </div>

      <div className='w-[232px]'>
        <ReportContractTypeSelect
          isMulti={true}
          value={current.contractTypes.map((item) => ({
            label: String(ReportContractText[item]),
            value: item,
          }))}
          onChange={(newValue) => {
            changeFilter(
              'contractTypes',
              newValue.map(({ value }) => value)
            );
          }}
        />
      </div>
    </div>
  );
};
