import { EmployeeExternalAccountStatusSelect } from '@/shared/components';
import { ExternalAccountsFilterType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';

export const ExternalAccountFilters = ({
  externalAccountsFilter,
  setExternalAccountsFilter,
}: {
  externalAccountsFilter: ExternalAccountsFilterType;
  setExternalAccountsFilter: React.Dispatch<
    React.SetStateAction<ExternalAccountsFilterType>
  >;
}) => {
  return (
    <div className='py-3'>
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='Google'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            externalAccountGoogle: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.externalAccountGoogle.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
      <hr className='my-2' />
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='Slack'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            externalAccountSlack: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.externalAccountSlack.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
      <hr className='my-2' />
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='HubSpot'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            externalAccountHubspot: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.externalAccountHubspot.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
      <hr className='my-2' />
      <EmployeeExternalAccountStatusSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        placeholder='Greenhouse'
        onChange={(newValue) =>
          setExternalAccountsFilter({
            ...externalAccountsFilter,
            externalAccountGreenhouse: newValue.map(({ value }) => value),
          })
        }
        value={externalAccountsFilter.externalAccountGreenhouse.map((item) => ({
          label: StringUtils.capitalizeAll(item, '_'),
          value: item,
        }))}
      />
    </div>
  );
};
