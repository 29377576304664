import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import {
  EmployeePersonalInformationFormDataType,
  EmployeeVisaType,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';
import { VisaModal } from './VisaModal';
import { VisaTable } from './VisaTable';

interface VisaProps {
  onCreate: (data: EmployeeVisaType) => void;
  onUpdate: (data: EmployeeVisaType) => void;
  onRemove: (id: string) => void;
}

export const Visa = ({ onCreate, onUpdate, onRemove }: VisaProps) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<EmployeePersonalInformationFormDataType>();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const [visas] = watch(['visas']);
  const { hasPersonalInformationEditAccess } = useEmployeeAccess();

  return (
    <div className='mb-6'>
      <div className='mb-4 flex flex-row'>
        <h1 className='self-center'>Visa</h1>
        {(hasPersonalInformationEditAccess || isNewRecord) && (
          <VisaModal
            isNewRecord={true}
            className='ml-auto'
            onSuccess={onCreate}
          >
            <Button variant='default'>Add New Visa</Button>
          </VisaModal>
        )}
      </div>
      <VisaTable
        items={visas}
        loading={false}
        onRemove={onRemove}
        onUpdate={onUpdate}
      />
      {errors.visas && (
        <span className='text-xs text-red-6'>{errors.visas.message}</span>
      )}
    </div>
  );
};
