import { toast } from '@/shared/components';
import {
  useCreateReasonForLeavingMutation,
  useUpdateReasonForLeavingMutation,
} from '@/shared/graphql';
import { ReasonForLeavingType } from '@/shared/types';
import { ReasonForLeavingError } from '@/shared/utils';
import {
  Button,
  ModalFooter,
  OnOffSwitch,
  TextInput,
} from '@any-ui-react/core';
import { ApolloError, BaseMutationOptions } from '@apollo/client';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  ReasonForLeavingFormData,
  reasonForLeavingFormValidationSuite,
} from './reason-for-leaving-modal-form.validator';

export interface ReasonForLeavingFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  defaultValues?: ReasonForLeavingType;
}

export const ReasonForLeavingForm = ({
  defaultValues,
  onSuccess,
  onCancel,
}: ReasonForLeavingFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    setError,
  } = useForm<ReasonForLeavingFormData>({
    defaultValues,
    resolver: vestResolver(reasonForLeavingFormValidationSuite),
  });

  const onError = (error: ApolloError, clientOptions?: BaseMutationOptions) => {
    if (
      error.message ===
      ReasonForLeavingError.REASON_FOR_LEAVING_NAME_IS_NOT_UNIQUE
    ) {
      setError('name', { message: 'Name exists already' });
    } else {
      toast.error(error.message);
    }
  };

  const [createReasonForLeaving] = useCreateReasonForLeavingMutation({
    onError,
    onCompleted: async (data) => {
      if (data.createReasonForLeaving.id) {
        onSuccess();
      }
    },
  });
  const [updateReasonForLeaving] = useUpdateReasonForLeavingMutation({
    onError,
    onCompleted: (data) => {
      if (data.updateReasonForLeaving.id) {
        onSuccess();
      }
    },
  });

  const [enabled] = watch(['enabled']);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        const isNewRecord = defaultValues === undefined;
        if (isNewRecord) {
          await createReasonForLeaving({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled || false,
              },
            },
          });
        } else {
          await updateReasonForLeaving({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled,
                id: defaultValues.id,
              },
            },
          });
        }
      }),
    [
      createReasonForLeaving,
      defaultValues,
      handleSubmit,
      updateReasonForLeaving,
    ]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Enter name'}
          className='col-span-2'
          required
        />
        <OnOffSwitch
          key={'12'}
          checked={enabled}
          onChange={(e) => {
            setValue('enabled', e.currentTarget.checked);
          }}
          label='Show on the dropdown'
        />
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
