import { useEmployeeDetailContext } from '@/shared/contexts';
import { EmployeeJobHistoryType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { JobHistoryForm } from './JobHistoryForm';

export const JobHistoryModal = ({
  children,
  onSuccess,
  jobHistory,
  className,
  isNewRecord = false,
}: {
  children: React.ReactNode;
  onSuccess: (data: EmployeeJobHistoryType) => Promise<boolean>;
  jobHistory?: EmployeeJobHistoryType;
  className?: string;
  isNewRecord?: boolean;
}) => {
  const modalId = uuid();
  const { employeeDetail } = useEmployeeDetailContext();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      size: 'lg',
      children: (
        <>
          <JobHistoryForm
            jobHistory={jobHistory}
            onSuccess={async (data) => {
              const isSuccess = await onSuccess(data);
              if (isSuccess) closeModal(modalId);
            }}
            onCancel={() => closeModal(modalId)}
            latestJobHistory={employeeDetail.jobHistories[0]}
            employmentEndDate={employeeDetail.offBoarding.employmentEndDate}
            formTitle={`${!isNewRecord ? 'Update' : 'Add New'} Job History`}
          />
        </>
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
