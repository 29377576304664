import { EmployeeCompanyAssetType } from '@/shared/graphql';
import { StringUtils } from '@/shared/utils';
import { Select, SelectProps } from '@any-ui-react/core';
import { forwardRef } from 'react';

interface EmployeeCompanyAssetSelectProps extends Omit<SelectProps, 'data'> {}

export type Ref = HTMLInputElement;

export const EmployeeCompanyAssetSelect = forwardRef<
  Ref,
  EmployeeCompanyAssetSelectProps
>((props, ref) => {
  const types = Object.values(EmployeeCompanyAssetType).map((value) => ({
    label: StringUtils.capitalizeAll(value, '_'),
    value: value,
  }));

  return (
    <Select
      data={types}
      withinPortal={true}
      dropdownPosition='bottom'
      ref={ref}
      {...props}
    />
  );
});
