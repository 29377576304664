import { JobHistoryLevel } from '@/shared/graphql';
import {
  ActionMeta,
  MultiValue,
  PropsValue,
  Select,
  useMultiSelect,
} from '@any-ui-react/select';

interface SelectProps {
  isMulti?: boolean;
  value:
    | string
    | number
    | boolean
    | PropsValue<{ label: string; value: JobHistoryLevel }>
    | undefined;
  onChange:
    | ((
        newValue: MultiValue<{
          label: string;
          value: JobHistoryLevel;
        }>,
        actionMeta: ActionMeta<{
          label: string;
          value: JobHistoryLevel;
        }>
      ) => void)
    | undefined;
  error?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
}

export const JobLevelSelect = ({
  onChange,
  value,
  isMulti = true,
  placeholder = 'Select Level',
  ...rest
}: SelectProps) => {
  const options = Object.entries(JobHistoryLevel).map(([key, value]) => {
    return {
      label: key,
      value: value,
    };
  });

  const multiSelectProps = useMultiSelect({
    getSelectedLabel: (count) => `Levels (${count})`,
    options: options,
  });

  const selectProps = isMulti ? multiSelectProps : { options: options };

  return (
    <Select
      placeholder={placeholder}
      isSearchable={true}
      isClearable={true}
      onChange={onChange}
      value={value}
      {...rest}
      {...selectProps}
    />
  );
};
