import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  createSearchParams,
  useBeforeUnload,
  useLocation,
  useMatches,
  useSearchParams,
} from 'react-router-dom';

import { useLocalStorage } from '@mantine/hooks';

import { useLocationCtx } from '../../contexts';
import { FilterValue, NextFilters, StoredFilters } from '../../types';
import { ObjectUtils, RouterUtils } from '../../utils';

interface FilterPropsOptions<T> {
  // Required as this method will be responsible to assign
  // required parameters and default values when the URL or
  // storage filters are not provided fully.
  parser: (filters: Partial<T>) => T;
  replace?: boolean;
  withPagination?: boolean;
  withLocalStorage?: boolean;
  withLocation?: boolean;
  scope?: string;
  onChange?: (
    oldFilter: Partial<T>,
    newFilter: Partial<T>,
    resetPage: boolean
  ) => void;
}

export type FilterProps<T> = {
  current: T;
  updateFilters: (nextFilter?: NextFilters<T>, resetPage?: boolean) => void;
  changeFilter: (key: keyof T, value: FilterValue, resetPage?: boolean) => void;
  removeFilter: (key: keyof T, resetPage?: boolean) => void;
  removeMultipleFilters: (keys: (keyof T)[], resetPage?: boolean) => void;
  resetFilters: () => void;
};

export const FILTER_STORAGE_KEY = `med|filters`;

export function useFilters<T extends Record<string, unknown>>(
  initialValue: T,
  {
    replace = false,
    withPagination = true,
    withLocation = true,
    withLocalStorage = true,
    parser,
    onChange,
    scope,
  }: FilterPropsOptions<T>
): FilterProps<T> {
  const location = useLocation();
  const { previous: previousLocation, historyAction } = useLocationCtx();

  const matches = useMatches();
  const filterScope = useMemo(() => {
    return scope || matches?.[1]?.pathname || '';
  }, [scope, matches]);
  const [searchParams, setSearchParams] = useSearchParams();
  const allSearchParams = useMemo(() => {
    return RouterUtils.decodeSearchParams(searchParams);
  }, [searchParams]);
  const [storedFilters, setStoredFilters] =
    useLocalStorage<StoredFilters<T> | null>({
      getInitialValueInEffect: false,
      key: FILTER_STORAGE_KEY,
      defaultValue: null,
    });

  const [filters, setFilterValues] = useState<T>(
    parser({ ...initialValue, ...(withLocation ? allSearchParams : {}) })
  );

  useEffect(() => {
    if (!withLocalStorage) return;

    if (storedFilters) {
      const stored = storedFilters[location.pathname];

      if (ObjectUtils.objectsEqual(stored?.data || {}, filters)) {
        return;
      }

      updateFilters(
        {
          ...filters,
          ...parser(stored?.data || {}),
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedFilters]);

  // Save Filters in Local Storage
  const saveFilters = useCallback(
    (nextFilter: NextFilters<T>) => {
      if (!withLocalStorage) return;

      setStoredFilters((prevValue) => ({
        ...prevValue,
        ...{
          [location.pathname]: {
            path: location.pathname,
            data: nextFilter,
          },
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setStoredFilters, withLocalStorage]
  );

  // Update the search parameters to include the filters
  const changeSearch = useCallback(
    (nextFilter: NextFilters<T>, replaceSearch?: boolean) => {
      if (!withLocation) return;
      setSearchParams(RouterUtils.encodeSearchParams(nextFilter), {
        replace: replaceSearch,
      });
    },
    [setSearchParams, withLocation]
  );

  // Update multiple filters value at the same time
  const updateFilters = useCallback(
    (
      nextFilter: NextFilters<T> = initialValue,
      resetPage = true,
      replaceSearch = replace
    ) => {
      const newFilter = {
        ...initialValue,
        ...parser({
          ...nextFilter,
          ...(withPagination && resetPage && { page: 1 }),
        }),
      };
      saveFilters(newFilter);
      setFilterValues(newFilter);
      changeSearch(newFilter, replaceSearch);
      onChange?.(filters, newFilter, resetPage);
    },
    [
      initialValue,
      replace,
      parser,
      withPagination,
      saveFilters,
      changeSearch,
      onChange,
      filters,
    ]
  );

  // Remove a single filter value
  const removeFilter = useCallback(
    (key?: keyof T, resetPage = true) => {
      if (!key) {
        return updateFilters({ ...initialValue });
      }
      const tmpFilters = { ...filters };
      delete tmpFilters[key];
      updateFilters(tmpFilters, resetPage);
    },
    [initialValue, filters, updateFilters]
  );

  // Remove multiple filters value
  const removeMultipleFilters = useCallback(
    (keys?: (keyof T)[], resetPage = true) => {
      if (!keys) {
        return updateFilters({ ...initialValue });
      }
      const tmpFilters = { ...filters };

      keys.forEach((key) => {
        delete tmpFilters[key];
      });

      updateFilters(tmpFilters, resetPage);
    },
    [initialValue, filters, updateFilters]
  );

  // Change a single filter value
  const changeFilter = useCallback(
    (key: keyof T, value: FilterValue = null, resetPage = true) => {
      updateFilters({ ...filters, [key]: value }, resetPage);
    },
    [filters, updateFilters]
  );

  // Clear all stored filers in the storage
  const clearSavedFilters = useCallback(() => {
    if (!withLocalStorage) return;

    setStoredFilters(null);
  }, [setStoredFilters, withLocalStorage]);

  // Reset the filter to initial values
  const resetFilters = useCallback(() => {
    setFilterValues({ ...initialValue, ...parser({ ...initialValue }) });
    if (!withLocation) return;

    setSearchParams(createSearchParams(), { replace: true });
  }, [initialValue, parser, withLocation, setSearchParams]);

  useEffect(() => {
    if (!withLocation || historyAction !== 'POP') return;

    const newFilters = { ...initialValue, ...allSearchParams };
    saveFilters(newFilters);
    setFilterValues(parser({ ...initialValue, ...allSearchParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSearchParams]);

  // Clear all filters on reload so we always prioritize URL
  useBeforeUnload(
    useCallback(() => {
      clearSavedFilters();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  // When navigating away from the page,
  // if the next doesn't match our scope, clear the stored filter
  useEffect(() => {
    if (previousLocation && historyAction) {
      if (historyAction === 'POP') return;
      if (!previousLocation.pathname.includes(filterScope)) {
        setStoredFilters(null);
      }
    }
  }, [
    filterScope,
    historyAction,
    location,
    previousLocation,
    setStoredFilters,
  ]);

  return {
    current: filters,
    updateFilters,
    removeFilter,
    removeMultipleFilters,
    changeFilter,
    resetFilters,
  };
}
