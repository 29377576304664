import { EmployeeCompanyAssetType as NativeEmployeeCompanyAssetType } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeCompanyAsset = z.object({
  id: z.string().default(''),
  assetType: z
    .nativeEnum(NativeEmployeeCompanyAssetType)
    .nullable()
    .default(null),
  assetName: z.string().default(''),
  assetNumber: z.string().default(''),
  assignedDate: z.string().nullable().default(null),
  returnedDate: z.string().nullable().default(null),
});
export type EmployeeCompanyAssetType = z.infer<typeof EmployeeCompanyAsset>;
