import { EmployeeCompanyAssetType as NativeEmployeeCompanyAssetType } from '@/shared/graphql';
import { z } from 'zod';

export const CompanyAssetFormData = z.object({
  assetType: z
    .nativeEnum(NativeEmployeeCompanyAssetType)
    .nullable()
    .default(null),
  assetName: z.string().default(''),
  assetNumber: z.string().default(''),
  assignedDate: z.string().nullable().default(null),
  returnedDate: z.string().nullable().default(null),
});
export type CompanyAssetFormDataType = z.infer<typeof CompanyAssetFormData>;
