import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeCompanyAssetMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeCompanyAssetInput;
}>;


export type UpdateEmployeeCompanyAssetMutation = { __typename?: 'Mutation', updateEmployeeCompanyAsset: { __typename?: 'EmployeeCompanyAssetOutput', id: string } };


export const UpdateEmployeeCompanyAssetDocument = gql`
    mutation updateEmployeeCompanyAsset($input: UpdateEmployeeCompanyAssetInput!) {
  updateEmployeeCompanyAsset(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeCompanyAssetMutationFn = Apollo.MutationFunction<UpdateEmployeeCompanyAssetMutation, UpdateEmployeeCompanyAssetMutationVariables>;

/**
 * __useUpdateEmployeeCompanyAssetMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeCompanyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeCompanyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeCompanyAssetMutation, { data, loading, error }] = useUpdateEmployeeCompanyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeCompanyAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeCompanyAssetMutation, UpdateEmployeeCompanyAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeCompanyAssetMutation, UpdateEmployeeCompanyAssetMutationVariables>(UpdateEmployeeCompanyAssetDocument, options);
      }
export type UpdateEmployeeCompanyAssetMutationHookResult = ReturnType<typeof useUpdateEmployeeCompanyAssetMutation>;
export type UpdateEmployeeCompanyAssetMutationResult = Apollo.MutationResult<UpdateEmployeeCompanyAssetMutation>;
export type UpdateEmployeeCompanyAssetMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeCompanyAssetMutation, UpdateEmployeeCompanyAssetMutationVariables>;