import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportNumberOfEmployeeLocationQueryVariables = Types.Exact<{
  year: Types.Scalars['Year']['input'];
  contractTypes: Types.InputMaybe<Array<Types.ContractTypeForReport> | Types.ContractTypeForReport>;
}>;


export type ReportNumberOfEmployeeLocationQuery = { __typename?: 'Query', reportNumberOfEmployeeLocation: { __typename?: 'ReportNumberOfEmployeeByLocation', yearMonthList: Array<any>, items: Array<{ __typename?: 'ReportNumberOfEmployeeByLocationItem', yearMonth: any, total: number, locationId: string, location: { __typename?: 'Location', name: string, country: { __typename?: 'Country', id: string, name: string } | null } }> } };


export const ReportNumberOfEmployeeLocationDocument = gql`
    query ReportNumberOfEmployeeLocation($year: Year!, $contractTypes: [ContractTypeForReport!]) {
  reportNumberOfEmployeeLocation(year: $year, contractTypes: $contractTypes) {
    items {
      yearMonth
      total
      locationId
      location {
        name
        country {
          id
          name
        }
      }
    }
    yearMonthList
  }
}
    `;

/**
 * __useReportNumberOfEmployeeLocationQuery__
 *
 * To run a query within a React component, call `useReportNumberOfEmployeeLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportNumberOfEmployeeLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportNumberOfEmployeeLocationQuery({
 *   variables: {
 *      year: // value for 'year'
 *      contractTypes: // value for 'contractTypes'
 *   },
 * });
 */
export function useReportNumberOfEmployeeLocationQuery(baseOptions: Apollo.QueryHookOptions<ReportNumberOfEmployeeLocationQuery, ReportNumberOfEmployeeLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportNumberOfEmployeeLocationQuery, ReportNumberOfEmployeeLocationQueryVariables>(ReportNumberOfEmployeeLocationDocument, options);
      }
export function useReportNumberOfEmployeeLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportNumberOfEmployeeLocationQuery, ReportNumberOfEmployeeLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportNumberOfEmployeeLocationQuery, ReportNumberOfEmployeeLocationQueryVariables>(ReportNumberOfEmployeeLocationDocument, options);
        }
export function useReportNumberOfEmployeeLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportNumberOfEmployeeLocationQuery, ReportNumberOfEmployeeLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportNumberOfEmployeeLocationQuery, ReportNumberOfEmployeeLocationQueryVariables>(ReportNumberOfEmployeeLocationDocument, options);
        }
export type ReportNumberOfEmployeeLocationQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeLocationQuery>;
export type ReportNumberOfEmployeeLocationLazyQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeLocationLazyQuery>;
export type ReportNumberOfEmployeeLocationSuspenseQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeLocationSuspenseQuery>;
export type ReportNumberOfEmployeeLocationQueryResult = Apollo.QueryResult<ReportNumberOfEmployeeLocationQuery, ReportNumberOfEmployeeLocationQueryVariables>;