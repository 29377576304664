import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListDivisionsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  enabled: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  keyword: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ListDivisionsQuery = { __typename?: 'Query', listDivisions: { __typename?: 'ListDivisions', totalCount: number, items: Array<{ __typename?: 'Division', id: string, name: string, enabled: boolean, createdAt: any, updatedAt: any }> } };


export const ListDivisionsDocument = gql`
    query listDivisions($pageNumber: Int!, $pageSize: Int!, $enabled: Boolean, $keyword: String) {
  listDivisions(
    pageNumber: $pageNumber
    pageSize: $pageSize
    enabled: $enabled
    keyword: $keyword
  ) {
    items {
      id
      name
      enabled
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useListDivisionsQuery__
 *
 * To run a query within a React component, call `useListDivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDivisionsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      enabled: // value for 'enabled'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useListDivisionsQuery(baseOptions: Apollo.QueryHookOptions<ListDivisionsQuery, ListDivisionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDivisionsQuery, ListDivisionsQueryVariables>(ListDivisionsDocument, options);
      }
export function useListDivisionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDivisionsQuery, ListDivisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDivisionsQuery, ListDivisionsQueryVariables>(ListDivisionsDocument, options);
        }
export function useListDivisionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListDivisionsQuery, ListDivisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDivisionsQuery, ListDivisionsQueryVariables>(ListDivisionsDocument, options);
        }
export type ListDivisionsQueryHookResult = ReturnType<typeof useListDivisionsQuery>;
export type ListDivisionsLazyQueryHookResult = ReturnType<typeof useListDivisionsLazyQuery>;
export type ListDivisionsSuspenseQueryHookResult = ReturnType<typeof useListDivisionsSuspenseQuery>;
export type ListDivisionsQueryResult = Apollo.QueryResult<ListDivisionsQuery, ListDivisionsQueryVariables>;