import { EmployeeMaritalStatus } from '@/shared/graphql';
import { EmployeeAddFormDataType } from '@/shared/types';
import { Group, Radio } from '@any-ui-react/core';
import { Controller, useFormContext } from 'react-hook-form';

export const EmployeeMaritalStatusRadioGroup = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EmployeeAddFormDataType>();

  return (
    <>
      <Controller
        control={control}
        name='maritalStatus'
        render={({ field: { value, ...fields } }) => {
          return (
            <Radio.Group
              {...fields}
              value={value ?? undefined}
              error={errors.role?.message}
              className='flex flex-col'
              label='Marital Status'
              labelProps={{ className: 'pb-4' }}
            >
              <Group className='flex space-x-4'>
                <Radio label='Single' value={EmployeeMaritalStatus.Single} />
                <Radio label='Married' value={EmployeeMaritalStatus.Married} />
              </Group>
            </Radio.Group>
          );
        }}
      />
    </>
  );
};
