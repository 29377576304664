import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmployeeDependentMutationVariables = Types.Exact<{
  input: Types.CreateEmployeeDependentInput;
}>;


export type CreateEmployeeDependentMutation = { __typename?: 'Mutation', createEmployeeDependent: { __typename?: 'EmployeeDependentOutput', id: string } };


export const CreateEmployeeDependentDocument = gql`
    mutation createEmployeeDependent($input: CreateEmployeeDependentInput!) {
  createEmployeeDependent(input: $input) {
    id
  }
}
    `;
export type CreateEmployeeDependentMutationFn = Apollo.MutationFunction<CreateEmployeeDependentMutation, CreateEmployeeDependentMutationVariables>;

/**
 * __useCreateEmployeeDependentMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeDependentMutation, { data, loading, error }] = useCreateEmployeeDependentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeDependentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeDependentMutation, CreateEmployeeDependentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeDependentMutation, CreateEmployeeDependentMutationVariables>(CreateEmployeeDependentDocument, options);
      }
export type CreateEmployeeDependentMutationHookResult = ReturnType<typeof useCreateEmployeeDependentMutation>;
export type CreateEmployeeDependentMutationResult = Apollo.MutationResult<CreateEmployeeDependentMutation>;
export type CreateEmployeeDependentMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeDependentMutation, CreateEmployeeDependentMutationVariables>;