import {
  CountryLazySelect,
  DayPicker,
  DEFAULT_PAGE_SIZE,
  EmployeeLazySelect,
  EmployeeRoleSelect,
  JobLazySelect,
} from '@/shared/components';
import { EmployeeInfoFilterType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { Checkbox } from '@any-ui-react/core';

export const EmployeeInfoFilters = ({
  employeeInfoFilter,
  setEmployeeInfoFilter,
}: {
  employeeInfoFilter: EmployeeInfoFilterType;
  setEmployeeInfoFilter: React.Dispatch<
    React.SetStateAction<EmployeeInfoFilterType>
  >;
}) => {
  return (
    <div className='py-3'>
      <div className='mx-2 flex items-center justify-between'>
        <DayPicker
          clearable
          wrapper={{
            className: 'w-[8.9rem]',
          }}
          required
          placeholder='Hired Start'
          value={
            employeeInfoFilter.hiredDate?.start
              ? new Date(employeeInfoFilter.hiredDate.start).toISOString()
              : undefined
          }
          onChange={(value) => {
            setEmployeeInfoFilter({
              ...employeeInfoFilter,
              hiredDate: {
                start: value
                  ? DateUtils.formatDate(
                      new Date(value || '').toISOString(),
                      'yyyy-MM-dd'
                    )
                  : null,
                end: employeeInfoFilter.hiredDate?.end ?? null,
              },
            });
          }}
        />
        <span className='mx-1'>-</span>
        <DayPicker
          clearable
          wrapper={{
            className: 'w-[8.9rem]',
          }}
          required
          placeholder='Hired End'
          value={
            employeeInfoFilter.hiredDate?.end
              ? new Date(employeeInfoFilter.hiredDate.end).toISOString()
              : undefined
          }
          onChange={(value) => {
            setEmployeeInfoFilter({
              ...employeeInfoFilter,
              hiredDate: {
                end: value
                  ? DateUtils.formatDate(
                      new Date(value || '').toISOString(),
                      'yyyy-MM-dd'
                    )
                  : null,
                start: employeeInfoFilter.hiredDate?.start ?? null,
              },
            });
          }}
        />
      </div>
      <hr className='my-2' />
      <div className='mx-2 flex items-center justify-between'>
        <DayPicker
          clearable
          wrapper={{
            className: 'w-[8.9rem]',
          }}
          required
          placeholder='Termination Start'
          value={
            employeeInfoFilter.employeeEndDate?.start
              ? new Date(employeeInfoFilter.employeeEndDate.start).toISOString()
              : undefined
          }
          onChange={(value) => {
            setEmployeeInfoFilter({
              ...employeeInfoFilter,
              employeeEndDate: {
                start: value
                  ? DateUtils.formatDate(
                      new Date(value || '').toISOString(),
                      'yyyy-MM-dd'
                    )
                  : null,
                end: employeeInfoFilter.employeeEndDate?.end ?? null,
              },
            });
          }}
        />
        <span className='mx-1'>-</span>
        <DayPicker
          clearable
          wrapper={{
            className: 'w-[8.9rem]',
          }}
          required
          placeholder='Termination End'
          value={
            employeeInfoFilter.employeeEndDate?.end
              ? new Date(employeeInfoFilter.employeeEndDate.end).toISOString()
              : undefined
          }
          onChange={(value) => {
            setEmployeeInfoFilter({
              ...employeeInfoFilter,
              employeeEndDate: {
                end: value
                  ? DateUtils.formatDate(
                      new Date(value || '').toISOString(),
                      'yyyy-MM-dd'
                    )
                  : null,
                start: employeeInfoFilter.employeeEndDate?.start ?? null,
              },
            });
          }}
        />
      </div>
      <hr className='my-2' />
      <EmployeeLazySelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        value={employeeInfoFilter.managerIds}
        valueFormatter={(item) => item.id}
        onChange={(newValue) => {
          setEmployeeInfoFilter({
            ...employeeInfoFilter,
            managerIds: newValue.map(({ value }) => value),
          });
        }}
        defaultQueryOptions={{
          variables: {
            pageNumber: 1,
            pageSize: DEFAULT_PAGE_SIZE,
            filter: {
              keyword: null,
              departmentIds: [],
              companyIds: [],
              businessUnitIds: [],
              excludeAuthUser: false,
              contractTypes: [],
              jobIds: [],
              levels: [],
              locationIds: [],
              divisionIds: [],
              managerIds: [],
              statuses: [],
              isLeave: false,
              isOnSecondment: false,
              managerIncludesAllConnections: true,
              nationalityIds: [],
              roles: [],
            },
          },
        }}
        placeholder={'Primary Manager/Dotted Line Manager'}
      />
      <Checkbox
        className='mx-2 mt-2'
        checked={employeeInfoFilter.managerIncludesAllConnections}
        label='Show direct reports only'
        onChange={(e) => {
          setEmployeeInfoFilter({
            ...employeeInfoFilter,
            managerIncludesAllConnections: e.currentTarget.checked,
          });
        }}
      />
      <hr className='my-2' />
      <JobLazySelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        value={employeeInfoFilter.jobIds}
        valueFormatter={(item) => item.id}
        onChange={(newValue) => {
          setEmployeeInfoFilter({
            ...employeeInfoFilter,
            jobIds: newValue.map(({ value }) => value),
          });
        }}
        defaultQueryOptions={{
          variables: {
            pageNumber: 1,
            pageSize: DEFAULT_PAGE_SIZE,
            enabled: null,
          },
        }}
        placeholder={'Job'}
      />
      <hr className='my-2' />
      <CountryLazySelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        value={employeeInfoFilter.nationalityIds}
        onChange={(newValue) => {
          setEmployeeInfoFilter({
            ...employeeInfoFilter,
            nationalityIds: newValue.map(({ value }) => value),
          });
        }}
        defaultQueryOptions={{
          variables: {
            keyword: null,
            pageNumber: 1,
            pageSize: DEFAULT_PAGE_SIZE,
            hasCompanies: null,
          },
        }}
        placeholder={'Nationality'}
      />
      <hr className='my-2' />
      <EmployeeRoleSelect
        isMulti={true}
        className='select-has-min-w-menu mx-2'
        onChange={(newValue) => {
          setEmployeeInfoFilter({
            ...employeeInfoFilter,
            roles: newValue.map(({ value }) => value),
          });
        }}
        value={
          employeeInfoFilter.roles.length > 0
            ? employeeInfoFilter.roles.map((item) => ({
                label: item.replace(/([a-z](?=[A-Z]))/g, '$1 '),
                value: item,
              }))
            : undefined
        }
      />
    </div>
  );
};
