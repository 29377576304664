import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListMinimalEmployeesQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter: Types.InputMaybe<Types.ListEmployeesFilterInput>;
}>;


export type ListMinimalEmployeesQuery = { __typename?: 'Query', listEmployees: { __typename?: 'ListEmployees', totalCount: number, items: Array<{ __typename?: 'Employee', id: string, generalInformation: { __typename?: 'EmployeeGeneralInformation', preferredName: string, legalFullName: string } }> } };


export const ListMinimalEmployeesDocument = gql`
    query listMinimalEmployees($pageNumber: Int!, $pageSize: Int!, $filter: ListEmployeesFilterInput) {
  listEmployees(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter) {
    items {
      id
      generalInformation {
        preferredName
        legalFullName
      }
    }
    totalCount
  }
}
    `;

/**
 * __useListMinimalEmployeesQuery__
 *
 * To run a query within a React component, call `useListMinimalEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMinimalEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMinimalEmployeesQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListMinimalEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListMinimalEmployeesQuery, ListMinimalEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMinimalEmployeesQuery, ListMinimalEmployeesQueryVariables>(ListMinimalEmployeesDocument, options);
      }
export function useListMinimalEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMinimalEmployeesQuery, ListMinimalEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMinimalEmployeesQuery, ListMinimalEmployeesQueryVariables>(ListMinimalEmployeesDocument, options);
        }
export function useListMinimalEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListMinimalEmployeesQuery, ListMinimalEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListMinimalEmployeesQuery, ListMinimalEmployeesQueryVariables>(ListMinimalEmployeesDocument, options);
        }
export type ListMinimalEmployeesQueryHookResult = ReturnType<typeof useListMinimalEmployeesQuery>;
export type ListMinimalEmployeesLazyQueryHookResult = ReturnType<typeof useListMinimalEmployeesLazyQuery>;
export type ListMinimalEmployeesSuspenseQueryHookResult = ReturnType<typeof useListMinimalEmployeesSuspenseQuery>;
export type ListMinimalEmployeesQueryResult = Apollo.QueryResult<ListMinimalEmployeesQuery, ListMinimalEmployeesQueryVariables>;