import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyEmailDomainQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetCompanyEmailDomainQuery = { __typename?: 'Query', companyEmailDomain: { __typename?: 'CompanyEmailDomain', id: string } | null };


export const GetCompanyEmailDomainDocument = gql`
    query getCompanyEmailDomain($id: ID!) {
  companyEmailDomain(id: $id) {
    id
  }
}
    `;

/**
 * __useGetCompanyEmailDomainQuery__
 *
 * To run a query within a React component, call `useGetCompanyEmailDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyEmailDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyEmailDomainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyEmailDomainQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyEmailDomainQuery, GetCompanyEmailDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyEmailDomainQuery, GetCompanyEmailDomainQueryVariables>(GetCompanyEmailDomainDocument, options);
      }
export function useGetCompanyEmailDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyEmailDomainQuery, GetCompanyEmailDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyEmailDomainQuery, GetCompanyEmailDomainQueryVariables>(GetCompanyEmailDomainDocument, options);
        }
export function useGetCompanyEmailDomainSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyEmailDomainQuery, GetCompanyEmailDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyEmailDomainQuery, GetCompanyEmailDomainQueryVariables>(GetCompanyEmailDomainDocument, options);
        }
export type GetCompanyEmailDomainQueryHookResult = ReturnType<typeof useGetCompanyEmailDomainQuery>;
export type GetCompanyEmailDomainLazyQueryHookResult = ReturnType<typeof useGetCompanyEmailDomainLazyQuery>;
export type GetCompanyEmailDomainSuspenseQueryHookResult = ReturnType<typeof useGetCompanyEmailDomainSuspenseQuery>;
export type GetCompanyEmailDomainQueryResult = Apollo.QueryResult<GetCompanyEmailDomainQuery, GetCompanyEmailDomainQueryVariables>;