import { TooltipAlert } from '@/shared/components';
import { EmployeeRole } from '@/shared/graphql';
import { EmployeePermissionFormDataType } from '@/shared/types';
import { Radio } from '@any-ui-react/core';

import { Controller, useFormContext } from 'react-hook-form';

export const EmployeeRoleRadioGroup = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EmployeePermissionFormDataType>();

  return (
    <>
      <span className='text-md block py-4 font-semibold'>Role</span>
      <Controller
        control={control}
        name='role'
        render={({ field: { ...fields } }) => {
          return (
            <Radio.Group
              {...fields}
              error={errors.role?.message}
              className='flex gap-x-4'
            >
              <Radio
                label={
                  <div className='flex'>
                    <span className='mr-1'>Basic</span>
                    <TooltipAlert
                      titleClassName='line-clamp-2 break-words'
                      tooltipContent={'Basic users who has limited access'}
                    />
                  </div>
                }
                value={EmployeeRole.Basic}
              />
              <Radio
                label={
                  <div className='flex'>
                    <span className='mr-1'>Advanced</span>
                    <TooltipAlert
                      titleClassName='line-clamp-2 break-words'
                      tooltipContent={
                        'User who has access to Reports (e.g. Finance, IR team)'
                      }
                    />
                  </div>
                }
                value={EmployeeRole.Advanced}
              />
              <Radio
                label={
                  <div className='flex'>
                    <span className='mr-1'>Admin (Standard)</span>
                    <TooltipAlert
                      titleClassName='line-clamp-2 break-words'
                      tooltipContent={
                        'User who has access to personal information (e.g. Office admins)'
                      }
                    />
                  </div>
                }
                value={EmployeeRole.AdminStandard}
              />
              <Radio
                label={
                  <div className='flex'>
                    <span className='mr-1'>Admin (Private)</span>
                    <TooltipAlert
                      titleClassName='line-clamp-2 break-words'
                      tooltipContent={
                        'User who has access to Reason for leaving. (e.g. HR managers)'
                      }
                    />
                  </div>
                }
                value={EmployeeRole.AdminPrivate}
              />
              <Radio
                label={
                  <div className='flex'>
                    <span className='mr-1'>Super Admin</span>
                    <TooltipAlert
                      titleClassName='line-clamp-2 break-words'
                      tooltipContent={'User who has access to all features'}
                    />
                  </div>
                }
                value={EmployeeRole.SuperAdmin}
              />
            </Radio.Group>
          );
        }}
      />
    </>
  );
};
