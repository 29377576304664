import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrencyQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetCurrencyQuery = { __typename?: 'Query', currency: { __typename?: 'Currency', id: string, name: string } | null };


export const GetCurrencyDocument = gql`
    query getCurrency($id: ID!) {
  currency(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrencyQuery(baseOptions: Apollo.QueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
      }
export function useGetCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
        }
export function useGetCurrencySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
        }
export type GetCurrencyQueryHookResult = ReturnType<typeof useGetCurrencyQuery>;
export type GetCurrencyLazyQueryHookResult = ReturnType<typeof useGetCurrencyLazyQuery>;
export type GetCurrencySuspenseQueryHookResult = ReturnType<typeof useGetCurrencySuspenseQuery>;
export type GetCurrencyQueryResult = Apollo.QueryResult<GetCurrencyQuery, GetCurrencyQueryVariables>;