import { create, enforce, test } from 'vest';

export interface SaveCustomFilterFormData {
  name: string;
  myFilter: boolean;
}

export const saveCustomFilterFormValidationSuite = create(
  ({ name }: SaveCustomFilterFormData) => {
    test('name', 'Name is required', () => {
      enforce(name).isNotEmpty();
    });
  }
);
