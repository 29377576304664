import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBusinessUnitMutationVariables = Types.Exact<{
  input: Types.CreateBusinessUnitInput;
}>;


export type CreateBusinessUnitMutation = { __typename?: 'Mutation', createBusinessUnit: { __typename?: 'BusinessUnitOutput', id: string } };


export const CreateBusinessUnitDocument = gql`
    mutation createBusinessUnit($input: CreateBusinessUnitInput!) {
  createBusinessUnit(input: $input) {
    id
  }
}
    `;
export type CreateBusinessUnitMutationFn = Apollo.MutationFunction<CreateBusinessUnitMutation, CreateBusinessUnitMutationVariables>;

/**
 * __useCreateBusinessUnitMutation__
 *
 * To run a mutation, you first call `useCreateBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessUnitMutation, { data, loading, error }] = useCreateBusinessUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessUnitMutation, CreateBusinessUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessUnitMutation, CreateBusinessUnitMutationVariables>(CreateBusinessUnitDocument, options);
      }
export type CreateBusinessUnitMutationHookResult = ReturnType<typeof useCreateBusinessUnitMutation>;
export type CreateBusinessUnitMutationResult = Apollo.MutationResult<CreateBusinessUnitMutation>;
export type CreateBusinessUnitMutationOptions = Apollo.BaseMutationOptions<CreateBusinessUnitMutation, CreateBusinessUnitMutationVariables>;