import { toast } from '@/shared/components';
import { ListTypeOfAttritionDocument } from '@/shared/graphql';
import { TypeOfAttritionType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { TypeOfAttritionForm } from './TypeOfAttritionForm';

export const TypeOfAttritionModal = ({
  children,
  className,
  typeOfAttrition,
}: {
  children: React.ReactNode;
  className?: string;
  typeOfAttrition?: TypeOfAttritionType;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${typeOfAttrition ? 'Edit' : 'Add New'} Type Of Attrition`,
      size: 'lg',
      children: (
        <TypeOfAttritionForm
          onSuccess={async () => {
            toast.success();
            await client.refetchQueries({
              include: [ListTypeOfAttritionDocument],
            });
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          defaultValues={typeOfAttrition}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
