import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import 'country-flag-icons/3x2/flags.css';
import { TextInputProps, TextInput, Select } from '@any-ui-react/core';
import {
  CountryCode,
  formatIncompletePhoneNumber,
  getCountries,
  isSupportedCountry,
} from 'libphonenumber-js/min';

import { PhoneNumberItem } from './PhoneNumberItem';
import { formatPhone, getIsd } from '../../../utils/phone-number.utils';

export type PhoneDataChange = {
  countryCode?: CountryCode;
  isd?: string;
  number: string;
};

export type PhoneData = {
  countryCode?: CountryCode;
  isd?: string | null;
  number?: string | null;
};

export type PhoneNumberInputProps = Omit<
  TextInputProps,
  'value' | 'onChange' | 'defaultValue'
> & {
  language?: string;
  // override
  defaultValue?: PhoneData | null;
  value?: PhoneData | null;
  onChange?: (data: PhoneDataChange) => void;
};

export const PhoneNumberInput = forwardRef<
  HTMLInputElement,
  PhoneNumberInputProps
>(
  (
    {
      language = 'en-US',
      disabled,
      error,
      value,
      defaultValue,
      onChange,
      ...rest
    }: PhoneNumberInputProps,
    ref
  ) => {
    const [state, setState] = useState<PhoneData>(
      formatPhone(value || defaultValue)
    );

    const handleReformating = useCallback(() => {
      setState(formatPhone(state));
    }, [state]);

    useEffect(() => {
      setState(formatPhone(value || defaultValue));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value?.countryCode, defaultValue?.countryCode]);

    useEffect(() => {
      onChange?.(state as PhoneDataChange);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const countries = useMemo(() => {
      return getCountries().map((country) => ({
        value: country,
        isd: getIsd(country),
        language,
        country,
        label: `(${getIsd(country)})`,
      }));
    }, [language]);

    return (
      <TextInput
        {...rest}
        error={error}
        classNames={{
          icon: 'w-auto pointer-events-auto pl-0',
          ...rest.classNames,
        }}
        onBlur={handleReformating}
        disabled={disabled || !state?.isd}
        value={state?.number || ''}
        iconWidth={140}
        ref={ref}
        onChange={(e) => {
          setState({
            countryCode: state?.countryCode,
            isd: state?.isd,
            number: isSupportedCountry(state?.countryCode || '')
              ? formatIncompletePhoneNumber(
                  e.target.value || '',
                  state?.countryCode as CountryCode
                )
              : e.target.value,
          });
        }}
        icon={
          <Select
            withinPortal
            searchable
            disabled={disabled}
            error={!!error}
            itemComponent={PhoneNumberItem}
            data={countries}
            icon={
              state.countryCode && (
                <div className='-mr-1 flex'>
                  <span className={`flag:${state.countryCode}`} />
                </div>
              )
            }
            onChange={(e: CountryCode) => {
              const newValue = formatPhone({
                countryCode: e,
                isd: e,
                number: isSupportedCountry(e)
                  ? formatIncompletePhoneNumber(
                      state.number || '',
                      state?.countryCode as CountryCode
                    )
                  : state.number,
              });
              setState(newValue);
            }}
            className='border-r border-gray-2'
            classNames={{
              input:
                'w-32 h-6 border-none min-h-[1.75rem] shadow-none ml-px pr-6 !pl-8',
            }}
            value={state?.countryCode}
            filter={(value, item) => {
              return `${new Intl.DisplayNames([language || 'en'], {
                type: 'region',
              }).of(item['country'])} ${item['country']} ${item['isd']}`
                .toLowerCase()
                .includes(value);
            }}
          />
        }
      />
    );
  }
);
