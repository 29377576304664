import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListCountriesQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  keyword: Types.InputMaybe<Types.Scalars['String']['input']>;
  hasCompanies: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ListCountriesQuery = { __typename?: 'Query', listCountries: { __typename?: 'ListCountries', totalCount: number, items: Array<{ __typename?: 'Country', id: string, name: string }> } };


export const ListCountriesDocument = gql`
    query listCountries($pageNumber: Int!, $pageSize: Int!, $keyword: String, $hasCompanies: Boolean) {
  listCountries(
    pageNumber: $pageNumber
    pageSize: $pageSize
    keyword: $keyword
    hasCompanies: $hasCompanies
  ) {
    items {
      id
      name
    }
    totalCount
  }
}
    `;

/**
 * __useListCountriesQuery__
 *
 * To run a query within a React component, call `useListCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCountriesQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      keyword: // value for 'keyword'
 *      hasCompanies: // value for 'hasCompanies'
 *   },
 * });
 */
export function useListCountriesQuery(baseOptions: Apollo.QueryHookOptions<ListCountriesQuery, ListCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCountriesQuery, ListCountriesQueryVariables>(ListCountriesDocument, options);
      }
export function useListCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCountriesQuery, ListCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCountriesQuery, ListCountriesQueryVariables>(ListCountriesDocument, options);
        }
export function useListCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCountriesQuery, ListCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCountriesQuery, ListCountriesQueryVariables>(ListCountriesDocument, options);
        }
export type ListCountriesQueryHookResult = ReturnType<typeof useListCountriesQuery>;
export type ListCountriesLazyQueryHookResult = ReturnType<typeof useListCountriesLazyQuery>;
export type ListCountriesSuspenseQueryHookResult = ReturnType<typeof useListCountriesSuspenseQuery>;
export type ListCountriesQueryResult = Apollo.QueryResult<ListCountriesQuery, ListCountriesQueryVariables>;