import {
  BusinessUnitLazySelect,
  DEFAULT_PAGE_SIZE,
  DepartmentLazySelect,
  JobLazySelect,
  JobLevelSelect,
  LocationLazySelect,
} from '@/shared/components';
import { JobHistoryLevel } from '@/shared/graphql';
import { EmployeeJobInformationFormDataType } from '@/shared/types';
import { Checkbox, TextInput } from '@any-ui-react/core';
import { Controller, useFormContext } from 'react-hook-form';

export const JobInformationFormFields = () => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<EmployeeJobInformationFormDataType>();

  const [regionalRole] = watch(['regionalRole']);

  return (
    <fieldset className='form-fieldset'>
      <Controller
        control={control}
        name='level'
        render={({ field: { value } }) => (
          <>
            <JobLevelSelect
              isMulti={false}
              error={errors.level?.message}
              label='Level'
              required
              onChange={(newValue) => {
                setValue(
                  'level',
                  (newValue as unknown as { value: JobHistoryLevel }).value ??
                    null,
                  {
                    shouldDirty: true,
                  }
                );
              }}
              value={value}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name='locationId'
        render={({ field: { ...field } }) => (
          <>
            <LocationLazySelect
              isMulti={false}
              label='Office Location'
              required
              error={errors.locationId?.message}
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  keyword: null,
                  enabled: null,
                },
              }}
              placeholder='Select Location'
              {...field}
              onChange={(option) => {
                setValue('locationId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name='businessUnitId'
        render={({ field: { ...field } }) => (
          <>
            <BusinessUnitLazySelect
              isMulti={false}
              label={'Business Unit'}
              required
              error={errors.businessUnitId?.message}
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  keyword: null,
                  enabled: null,
                },
              }}
              placeholder='Select Business Unit'
              {...field}
              onChange={(option) => {
                setValue('businessUnitId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />
      <Controller
        control={control}
        name='departmentId'
        render={({ field: { ...field } }) => (
          <>
            <DepartmentLazySelect
              isMulti={false}
              required
              label={'Department'}
              error={errors.departmentId?.message}
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  enabled: null,
                },
              }}
              placeholder='Select Department'
              {...field}
              onChange={(option) => {
                setValue('departmentId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />

      <TextInput
        {...register('team')}
        error={errors.team?.message}
        label='Team'
        placeholder='Enter Team'
      />

      <Controller
        control={control}
        name='jobId'
        render={({ field: { ...field } }) => (
          <>
            <JobLazySelect
              isMulti={false}
              label={'Job'}
              required
              error={errors.jobId?.message}
              valueFormatter={(item) => item.id}
              className='select-has-min-w-menu'
              defaultQueryOptions={{
                variables: {
                  pageNumber: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                  enabled: null,
                },
              }}
              placeholder='Select Job Type'
              {...field}
              onChange={(option) => {
                setValue('jobId', option?.value as string, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />
      <TextInput
        {...register('jobTitle')}
        error={errors.jobTitle?.message}
        required
        label='Job Title'
        placeholder='Enter Job Title'
      />

      <div className='col-span-2'>
        <Checkbox
          {...register('regionalRole')}
          error={errors.regionalRole?.message}
          checked={regionalRole}
          label='Regional Role (Check this box if this person’s cost is allocated to another entity)'
          onChange={(e) => {
            setValue('regionalRole', e.currentTarget.checked, {
              shouldDirty: true,
            });
          }}
        />
      </div>
    </fieldset>
  );
};
