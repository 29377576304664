import { toast } from '@/shared/components';
import {
  GetEmployeeDocument,
  useDeleteEmployeeJobHistoryMutation,
  useUpdateEmployeeOffBoardingMutation,
} from '@/shared/graphql';
import { EmployeeOffBoardingType } from '@/shared/types';
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

interface OffBoardingPersistence {
  employeeId: string;
}

export const useEmployeeOffBoardingPersistence = ({
  employeeId,
}: OffBoardingPersistence) => {
  const client = useApolloClient();
  const onCompleted = useCallback(() => {
    toast.success();
    client.refetchQueries({
      include: [GetEmployeeDocument],
    });
  }, [client]);
  const onError = () => toast.error();

  const [updateRecord] = useUpdateEmployeeOffBoardingMutation({
    onCompleted,
    onError,
  });
  const [deleteRecord] = useDeleteEmployeeJobHistoryMutation({
    onCompleted,
    onError,
  });

  const onOffBoardingUpdate = useCallback(
    (formData: EmployeeOffBoardingType, onSuccess: VoidFunction) => {
      const { deactivateGreenhouse, suspendGoogleAccount, ...restFormData } =
        formData;
      updateRecord({
        variables: {
          input: {
            ...restFormData,
            employeeId: employeeId,
            doNotDeactivate: {
              greenhouse: deactivateGreenhouse,
              google: suspendGoogleAccount,
            },
          },
        },
        onCompleted: (data) => {
          if (!data.updateEmployeeOffBoarding) {
            toast.error();
            return;
          }
          onCompleted();
          onSuccess();
        },
      });
    },
    [updateRecord, employeeId, onCompleted]
  );

  const onOffBoardingRemove = useCallback(
    (id: string, onSuccess: VoidFunction) => {
      deleteRecord({
        variables: { id: id },
        onCompleted: (data) => {
          if (!data.deleteEmployeeJobHistory) {
            toast.error();
            return;
          }

          onCompleted();
          onSuccess();
        },
      });
    },
    [deleteRecord, onCompleted]
  );

  return {
    onOffBoardingUpdate,
    onOffBoardingRemove,
  };
};
