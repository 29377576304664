import { z } from 'zod';

export enum EmployeeEditTab {
  GENERAL_INFORMATION = 'general-information',
  PERSONAL_INFORMATION = 'personal-information',
  OFF_BOARDING = 'off-boarding',
  JOB_HISTORY = 'job-history',
  EXTERNAL_ACCOUNTS = 'external-accounts',
  PERMISSION = 'permission',
  PERFORMANCE_REVIEW = 'performance-review',
  PROBATION_REVIEW = 'probation-review',
}

export const EmployeeEditTabLabels = {
  [EmployeeEditTab.GENERAL_INFORMATION]: 'General Information',
  [EmployeeEditTab.PERSONAL_INFORMATION]: 'Personal Information',
  [EmployeeEditTab.OFF_BOARDING]: 'Off-boarding',
  [EmployeeEditTab.JOB_HISTORY]: 'Job / Contract History',
  [EmployeeEditTab.EXTERNAL_ACCOUNTS]: 'External Accounts',
  [EmployeeEditTab.PERMISSION]: 'Permission',
  [EmployeeEditTab.PERFORMANCE_REVIEW]: 'Performance Review',
  [EmployeeEditTab.PROBATION_REVIEW]: 'Probation Review',
};

export const EmployeeTabParser = z.object({
  tab: z
    .nativeEnum(EmployeeEditTab)
    .optional()
    .default(EmployeeEditTab.GENERAL_INFORMATION),
}) satisfies z.ZodType<
  {
    tab?: EmployeeEditTab;
  },
  z.ZodTypeDef,
  unknown
>;

export type EmployeeEditTabFilterType = z.infer<typeof EmployeeTabParser>;
