import { Button, ModalFooter } from '@any-ui-react/core';
import { useModals } from '@any-ui-react/modals';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

export const NoCountryModal = () => {
  const modalId = uuid();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModals();

  const onClose = () => {
    closeModal(modalId);
    navigate('/404');
  };

  useEffect(() => {
    openModal({
      modalId,
      title: 'You do not have access to any country',
      onClose: onClose,
      children: (
        <div className='flex flex-col'>
          <span>
            You currently do not have the necessary access to set up
            Notifications for any countries.
          </span>
          <span>
            Please ensure that your Job and Contract history are correctly
            configured.
          </span>
          <span>
            To enable Notifications, your Contract history must be properly set.
          </span>
          <span>
            If the issue persists, please contact Minnie Poolsuk or Shiori
            Matsuyama for further assistance.
          </span>

          <ModalFooter>
            <Button variant='default' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </div>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
