import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportNumberOfEmployeeAllQueryVariables = Types.Exact<{
  year: Types.Scalars['Year']['input'];
  contractTypes: Types.InputMaybe<Array<Types.ContractTypeForReport> | Types.ContractTypeForReport>;
}>;


export type ReportNumberOfEmployeeAllQuery = { __typename?: 'Query', reportNumberOfEmployeeAll: { __typename?: 'ReportNumberOfEmployeeAll', yearMonthList: Array<any>, items: Array<{ __typename?: 'ReportNumberOfEmployeeAllYearMonth', joined: number, left: number, total: number, yearMonth: any }>, subtotal: { __typename?: 'ReportNumberOfEmployeeAllSubtotal', h1TurnOverRate: any | null, h2TurnOverRate: any | null, joined: number, left: number, total: number, turnOverRate: any | null, year: any } } };


export const ReportNumberOfEmployeeAllDocument = gql`
    query ReportNumberOfEmployeeAll($year: Year!, $contractTypes: [ContractTypeForReport!]) {
  reportNumberOfEmployeeAll(year: $year, contractTypes: $contractTypes) {
    items {
      joined
      left
      total
      yearMonth
    }
    subtotal {
      h1TurnOverRate
      h2TurnOverRate
      joined
      left
      total
      turnOverRate
      year
    }
    yearMonthList
  }
}
    `;

/**
 * __useReportNumberOfEmployeeAllQuery__
 *
 * To run a query within a React component, call `useReportNumberOfEmployeeAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportNumberOfEmployeeAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportNumberOfEmployeeAllQuery({
 *   variables: {
 *      year: // value for 'year'
 *      contractTypes: // value for 'contractTypes'
 *   },
 * });
 */
export function useReportNumberOfEmployeeAllQuery(baseOptions: Apollo.QueryHookOptions<ReportNumberOfEmployeeAllQuery, ReportNumberOfEmployeeAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportNumberOfEmployeeAllQuery, ReportNumberOfEmployeeAllQueryVariables>(ReportNumberOfEmployeeAllDocument, options);
      }
export function useReportNumberOfEmployeeAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportNumberOfEmployeeAllQuery, ReportNumberOfEmployeeAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportNumberOfEmployeeAllQuery, ReportNumberOfEmployeeAllQueryVariables>(ReportNumberOfEmployeeAllDocument, options);
        }
export function useReportNumberOfEmployeeAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportNumberOfEmployeeAllQuery, ReportNumberOfEmployeeAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportNumberOfEmployeeAllQuery, ReportNumberOfEmployeeAllQueryVariables>(ReportNumberOfEmployeeAllDocument, options);
        }
export type ReportNumberOfEmployeeAllQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeAllQuery>;
export type ReportNumberOfEmployeeAllLazyQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeAllLazyQuery>;
export type ReportNumberOfEmployeeAllSuspenseQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeAllSuspenseQuery>;
export type ReportNumberOfEmployeeAllQueryResult = Apollo.QueryResult<ReportNumberOfEmployeeAllQuery, ReportNumberOfEmployeeAllQueryVariables>;