import { EmployeeRelationType } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeDependent = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  relation: z
    .nativeEnum(EmployeeRelationType)
    .default(EmployeeRelationType.Other),
  dateOfBirth: z.string().default(''),
});
export type EmployeeDependentType = z.infer<typeof EmployeeDependent>;
