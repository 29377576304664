import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeContractNoteMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeContractNoteInput;
}>;


export type UpdateEmployeeContractNoteMutation = { __typename?: 'Mutation', updateEmployeeContractNote: { __typename?: 'EmployeeOutput', id: string } };


export const UpdateEmployeeContractNoteDocument = gql`
    mutation updateEmployeeContractNote($input: UpdateEmployeeContractNoteInput!) {
  updateEmployeeContractNote(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeContractNoteMutationFn = Apollo.MutationFunction<UpdateEmployeeContractNoteMutation, UpdateEmployeeContractNoteMutationVariables>;

/**
 * __useUpdateEmployeeContractNoteMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeContractNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeContractNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeContractNoteMutation, { data, loading, error }] = useUpdateEmployeeContractNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeContractNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeContractNoteMutation, UpdateEmployeeContractNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeContractNoteMutation, UpdateEmployeeContractNoteMutationVariables>(UpdateEmployeeContractNoteDocument, options);
      }
export type UpdateEmployeeContractNoteMutationHookResult = ReturnType<typeof useUpdateEmployeeContractNoteMutation>;
export type UpdateEmployeeContractNoteMutationResult = Apollo.MutationResult<UpdateEmployeeContractNoteMutation>;
export type UpdateEmployeeContractNoteMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeContractNoteMutation, UpdateEmployeeContractNoteMutationVariables>;