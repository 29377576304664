import { useModals } from '@any-ui-react/modals';
import { v4 as uuid } from 'uuid';
import { ModalFooter, Button } from '@any-ui-react/core';

export const deleteModal = uuid();

export const useDeleteEmployeeModal = () => {
  const modalId = deleteModal;
  const { openModal, closeModal } = useModals();

  const openDeleteEmployeeModal = ({
    onSuccess,
  }: {
    onSuccess: () => void;
  }) => {
    openModal({
      modalId,
      title: 'Are you sure you want to delete this employee?',
      size: 'lg',
      children: (
        <div>
          <ul className='list-disc'>
            <li>
              If the employee is resigning, you do NOT need to delete the
              employee. Please select their 'Reason for leaving' to
              automatically set the employee status to 'inactive'.
            </li>
            <li>
              Deleting an employee will permanently remove all associated
              evaluations and job history, and cannot be recovered.
            </li>
          </ul>
          <ModalFooter>
            <Button variant='default' onClick={() => closeModal(modalId)}>
              Cancel
            </Button>
            <Button
              className='bg-[#FF5F5F] hover:bg-[#FF5F5F]'
              type='submit'
              onClick={() => {
                onSuccess();
                closeModal(modalId);
              }}
            >
              Sure
            </Button>
          </ModalFooter>
        </div>
      ),
    });
  };

  return { openDeleteEmployeeModal };
};
