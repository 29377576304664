import { useMemo } from 'react';

import { CompanyType, TableSelection } from '@/shared/types';
import { formatDatetime } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { CompanyModal } from '../company-modal/CompanyModal';

interface CompanyTableProps {
  loading: boolean;
  items: CompanyType[];
  selection: TableSelection<CompanyType>;
  setSelection: (selection: TableSelection<CompanyType>) => void;
  total: number;
}

export const CompanyTable = ({
  items,
  loading,
  selection,
}: CompanyTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (company: CompanyType) => {
            return (
              <CompanyModal company={company}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {company.name}
                </span>
              </CompanyModal>
            );
          },
        },
        {
          headerRender: () => 'Country',
          accessor: 'country',
          cellRender: ({ country }: CompanyType) => {
            return <span>{country.name}</span>;
          },
        },
        {
          headerRender: () => 'Total Probation Period',
          accessor: 'defaultProbationPeriod',
          cellRender: ({ probationReviewTerm }: CompanyType) => {
            return (
              <span>
                Permanent: {probationReviewTerm.length}{' '}
                {probationReviewTerm.mode.toLowerCase()}
              </span>
            );
          },
        },
        {
          headerRender: () => 'Single Probation Period Duration',
          accessor: 'defaultProbationDateInterval',
          cellRender: ({ probationReviewTimes }: CompanyType) => {
            return <span>Permanent: {probationReviewTimes} days</span>;
          },
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: CompanyType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: CompanyType) => {
            return <span>{formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: CompanyType) => {
            return <span>{formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<CompanyType>[],
    []
  );

  const tableCommonProps: TableProps<CompanyType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Business Unit Item Title'}
        description={'There are no Business Unit Item Desc'}
      />
    ),
  };

  return (
    <>
      <Table<CompanyType>
        {...tableCommonProps}
        selection={selection}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
