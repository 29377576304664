import {
  DEFAULT_PAGE_SIZE,
  DivisionLazySelect,
  toast,
} from '@/shared/components';
import {
  useCreateBusinessUnitMutation,
  useUpdateBusinessUnitMutation,
} from '@/shared/graphql';
import { BusinessUnitType } from '@/shared/types';
import { BusinessUnitError } from '@/shared/utils';
import {
  Button,
  ModalFooter,
  OnOffSwitch,
  TextInput,
} from '@any-ui-react/core';
import { ApolloError, BaseMutationOptions } from '@apollo/client';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  BusinessUnitFormData,
  businessUnitFormValidationSuite,
} from './business-unit-form.validator';

export interface BusinessUnitFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  defaultValues?: BusinessUnitType;
}

export const BusinessUnitForm = ({
  defaultValues,
  onSuccess,
  onCancel,
}: BusinessUnitFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    setError,
    control,
  } = useForm<BusinessUnitFormData>({
    defaultValues: defaultValues ? defaultValues : { enabled: true },
    resolver: vestResolver(businessUnitFormValidationSuite),
  });

  const onError = (error: ApolloError, clientOptions?: BaseMutationOptions) => {
    if (error.message === BusinessUnitError.BUSINESS_UNIT_NAME_IS_NOT_UNIQUE) {
      setError('name', { message: 'Name exists already' });
    } else {
      toast.error(error.message);
    }
  };

  const [createBusinessUnit] = useCreateBusinessUnitMutation({
    onError,
    onCompleted: async (data) => {
      if (data.createBusinessUnit.id) {
        onSuccess();
      }
    },
  });
  const [updateBusinessUnit] = useUpdateBusinessUnitMutation({
    onError,
    onCompleted: (data) => {
      if (data.updateBusinessUnit.id) {
        onSuccess();
      }
    },
  });

  const [enabled] = watch(['enabled']);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        const isNewRecord = defaultValues === undefined;
        if (isNewRecord) {
          await createBusinessUnit({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled || false,
                divisionId: formValues.divisionId,
              },
            },
          });
        } else {
          await updateBusinessUnit({
            variables: {
              input: {
                name: formValues.name,
                enabled: formValues.enabled,
                id: defaultValues.id,
                divisionId: formValues.divisionId,
              },
            },
          });
        }
      }),
    [createBusinessUnit, defaultValues, handleSubmit, updateBusinessUnit]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Enter name'}
          required
        />
        <Controller
          control={control}
          name='divisionId'
          render={({ field: { ...field } }) => (
            <>
              <DivisionLazySelect
                isMulti={false}
                label={'Division'}
                required
                error={errors.divisionId?.message}
                valueFormatter={(item) => item.id}
                className='select-has-min-w-menu'
                defaultQueryOptions={{
                  variables: {
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    keyword: null,
                    enabled: null,
                  },
                }}
                placeholder={'Division'}
                {...field}
                onChange={(option) => {
                  setValue('divisionId', option?.value as string, {
                    shouldDirty: true,
                  });
                }}
              />
            </>
          )}
        />
      </fieldset>
      <OnOffSwitch
        key={'12'}
        checked={enabled}
        onChange={(e) => {
          setValue('enabled', e.currentTarget.checked);
        }}
        label='Show on the dropdown'
      />
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
