import { toast } from '@/shared/components';
import {
  EmployeeCompanyAssetType as NativeEmployeeCompanyAssetType,
  useCreateEmployeeCompanyAssetMutation,
  useDeleteEmployeeCompanyAssetMutation,
  useUpdateEmployeeCompanyAssetMutation,
} from '@/shared/graphql';
import { EmployeeCompanyAssetType } from '@/shared/types';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

interface CompanyAssetPersistence {
  employeeId: string;
  companyAssets: EmployeeCompanyAssetType[];
  onCompanyAssetsChange: (data: EmployeeCompanyAssetType[]) => void;
  // this triggers Mutation to be triggered, otherwise we let user
  // handle manually the data that was modified (e.g. to be saved later)
  autoPersistInDatabase?: boolean;
}

export const useCompanyAssetPersistence = ({
  employeeId,
  companyAssets = [],
  onCompanyAssetsChange,
  autoPersistInDatabase = true,
}: CompanyAssetPersistence) => {
  const [createRecord] = useCreateEmployeeCompanyAssetMutation();
  const [updateRecord] = useUpdateEmployeeCompanyAssetMutation();
  const [deleteRecord] = useDeleteEmployeeCompanyAssetMutation();

  const onCompanyAssetCreate = useCallback(
    (formData: EmployeeCompanyAssetType) => {
      if (autoPersistInDatabase) {
        createRecord({
          variables: {
            input: {
              employeeId: employeeId,
              name: formData.assetName,
              number: formData.assetNumber,
              type: formData.assetType as NativeEmployeeCompanyAssetType,
              assignedDate: formData.assignedDate,
              returnedDate: formData.returnedDate,
            },
          },
          onCompleted: (data) => {
            toast.success();
            onCompanyAssetsChange([
              ...companyAssets,
              { ...formData, id: data.createEmployeeCompanyAsset.id },
            ]);
          },
          onError: () => toast.error(),
        });
      } else {
        onCompanyAssetsChange([...companyAssets, { ...formData, id: uuid() }]);
      }
    },
    [
      autoPersistInDatabase,
      companyAssets,
      createRecord,
      employeeId,
      onCompanyAssetsChange,
    ]
  );

  const onCompanyAssetUpdate = useCallback(
    (formData: EmployeeCompanyAssetType) => {
      if (autoPersistInDatabase) {
        updateRecord({
          variables: {
            input: {
              id: formData.id,
              name: formData.assetName,
              number: formData.assetNumber,
              type: formData.assetType as NativeEmployeeCompanyAssetType,
              assignedDate: formData.assignedDate,
              returnedDate: formData.returnedDate,
            },
          },
          onCompleted: () => {
            toast.success();
            onCompanyAssetsChange([
              ...companyAssets.map((item) =>
                item.id === formData.id ? { ...item, ...formData } : item
              ),
            ]);
          },
          onError: () => toast.error(),
        });
      } else {
        onCompanyAssetsChange([
          ...companyAssets.map((item) =>
            item.id === formData.id ? { ...item, ...formData } : item
          ),
        ]);
      }
    },
    [autoPersistInDatabase, companyAssets, onCompanyAssetsChange, updateRecord]
  );

  const onCompanyAssetRemove = useCallback(
    (id: string) => {
      if (autoPersistInDatabase) {
        deleteRecord({
          variables: { id: id },
          onCompleted: () => {
            toast.success();
            onCompanyAssetsChange(
              companyAssets.filter((item) => item.id !== id)
            );
          },
          onError: () => toast.error(),
        });
      } else {
        onCompanyAssetsChange(companyAssets.filter((item) => item.id !== id));
      }
    },
    [autoPersistInDatabase, companyAssets, deleteRecord, onCompanyAssetsChange]
  );

  return {
    onCompanyAssetCreate,
    onCompanyAssetUpdate,
    onCompanyAssetRemove,
  };
};
