import { createRef, useEffect, useState } from 'react';

import { ActionIcon, TextInput, TextInputProps } from '@any-ui-react/core';
import { SearchIcon, ClearIcon } from '../icons';

export interface InputSearchProps extends Omit<TextInputProps, 'value'> {
  initialValue?: string | null;
  value?: string | null;
  searchOnType?: boolean;
  onClear?: () => void;
  onSearch?: (text: string) => void;
}

export const InputSearch = ({
  onClear,
  onSearch,
  initialValue,
  value,
  searchOnType = false,
  ...rest
}: InputSearchProps) => {
  const ref = createRef<HTMLInputElement>();
  const [searchValue, setSearchValue] = useState<string>(initialValue || '');

  useEffect(() => {
    setSearchValue(value || '');
  }, [value]);

  useEffect(() => {
    const search = (e: Event) => {
      const sValue = (e.target as HTMLInputElement)?.value;
      setSearchValue(sValue);
      onSearch?.(sValue);
    };

    if (ref?.current) {
      const input = ref.current;
      input.addEventListener('search', search, false);
      return () => {
        input.removeEventListener('search', search, false);
      };
    }

    return () => null;
  }, [onSearch, ref]);

  const clear = () => {
    setSearchValue('');
    onClear?.();
  };

  return (
    <TextInput
      {...rest}
      type='search'
      onChange={(e) => {
        setSearchValue(e.target.value);
        if (searchOnType) {
          onSearch?.(e.target.value);
        }
      }}
      icon={<SearchIcon />}
      rightSection={
        searchValue && (
          <ActionIcon onClick={clear} variant="'light'">
            <ClearIcon className='h-4 w-4 cursor-pointer fill-gray-5' />
          </ActionIcon>
        )
      }
      classNames={{
        rightSection: 'border-gray-2 border-l border-collapse mr-0.5 my-px w-6',
      }}
      value={searchValue}
      ref={ref}
    />
  );
};
