import { create, enforce, test } from 'vest';

export interface DepartmentFormData {
  name: string;
  enabled: boolean;
}

export const departmentFormValidationSuite = create(
  ({ name }: DepartmentFormData) => {
    test('name', 'Name is required', () => {
      enforce(name).isNotEmpty();
    });
  }
);
