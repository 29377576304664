import { toast } from '@/shared/components';
import { ListJobsDocument } from '@/shared/graphql';
import { JobType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { JobForm } from './JobForm';

export const JobModal = ({
  children,
  className,
  job,
}: {
  children: React.ReactNode;
  className?: string;
  job?: JobType;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${job ? 'Edit' : 'Add New'} Job`,
      size: 'lg',
      children: (
        <JobForm
          onSuccess={async () => {
            toast.success();
            await client.refetchQueries({
              include: [ListJobsDocument],
            });
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          defaultValues={job}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
