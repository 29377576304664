import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignInCallbackMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  redirectUri: Types.InputMaybe<Types.Scalars['Url']['input']>;
}>;


export type SignInCallbackMutation = { __typename?: 'Mutation', signInCallback: string };


export const SignInCallbackDocument = gql`
    mutation signInCallback($code: String!, $redirectUri: Url) {
  signInCallback(code: $code, redirectUri: $redirectUri)
}
    `;
export type SignInCallbackMutationFn = Apollo.MutationFunction<SignInCallbackMutation, SignInCallbackMutationVariables>;

/**
 * __useSignInCallbackMutation__
 *
 * To run a mutation, you first call `useSignInCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInCallbackMutation, { data, loading, error }] = useSignInCallbackMutation({
 *   variables: {
 *      code: // value for 'code'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useSignInCallbackMutation(baseOptions?: Apollo.MutationHookOptions<SignInCallbackMutation, SignInCallbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInCallbackMutation, SignInCallbackMutationVariables>(SignInCallbackDocument, options);
      }
export type SignInCallbackMutationHookResult = ReturnType<typeof useSignInCallbackMutation>;
export type SignInCallbackMutationResult = Apollo.MutationResult<SignInCallbackMutation>;
export type SignInCallbackMutationOptions = Apollo.BaseMutationOptions<SignInCallbackMutation, SignInCallbackMutationVariables>;