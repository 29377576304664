import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListDepartmentsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  enabled: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ListDepartmentsQuery = { __typename?: 'Query', listDepartments: { __typename?: 'ListDepartments', totalCount: number, items: Array<{ __typename?: 'Department', id: string, name: string, enabled: boolean, createdAt: any, updatedAt: any }> } };


export const ListDepartmentsDocument = gql`
    query listDepartments($pageNumber: Int!, $pageSize: Int!, $enabled: Boolean) {
  listDepartments(pageNumber: $pageNumber, pageSize: $pageSize, enabled: $enabled) {
    items {
      id
      name
      enabled
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useListDepartmentsQuery__
 *
 * To run a query within a React component, call `useListDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDepartmentsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useListDepartmentsQuery(baseOptions: Apollo.QueryHookOptions<ListDepartmentsQuery, ListDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDepartmentsQuery, ListDepartmentsQueryVariables>(ListDepartmentsDocument, options);
      }
export function useListDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDepartmentsQuery, ListDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDepartmentsQuery, ListDepartmentsQueryVariables>(ListDepartmentsDocument, options);
        }
export function useListDepartmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListDepartmentsQuery, ListDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDepartmentsQuery, ListDepartmentsQueryVariables>(ListDepartmentsDocument, options);
        }
export type ListDepartmentsQueryHookResult = ReturnType<typeof useListDepartmentsQuery>;
export type ListDepartmentsLazyQueryHookResult = ReturnType<typeof useListDepartmentsLazyQuery>;
export type ListDepartmentsSuspenseQueryHookResult = ReturnType<typeof useListDepartmentsSuspenseQuery>;
export type ListDepartmentsQueryResult = Apollo.QueryResult<ListDepartmentsQuery, ListDepartmentsQueryVariables>;