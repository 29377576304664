import { ReactNode, forwardRef } from 'react';

import { Group } from '@any-ui-react/core';
import { CountryCode } from 'libphonenumber-js/min';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  country: CountryCode;
  isd: string;
  language: string;
  label: ReactNode;
}

export const PhoneNumberItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ isd, country, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <span className={`flag:${country}`} />
        <div className='text-xs'>({isd})</div>
      </Group>
    </div>
  )
);
