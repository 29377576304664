import {
  EmployeeExternalAccountStatus,
  EmployeeExternalAccountUserType,
  EmployeeExternalAccountType as NativeEmployeeExternalAccountType,
} from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeExternalAccount = z.object({
  employeeId: z.string().default(''),
  email: z.string().default(''),
  accountType: z
    .nativeEnum(NativeEmployeeExternalAccountType)
    .nullable()
    .default(null),
  status: z.nativeEnum(EmployeeExternalAccountStatus).nullable().default(null),
  userType: z
    .nativeEnum(EmployeeExternalAccountUserType)
    .nullable()
    .default(null),
});
export type EmployeeExternalAccountType = z.infer<
  typeof EmployeeExternalAccount
>;
