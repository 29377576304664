'use client';

import classNames from 'classnames';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { LocationProvider } from '../../../../contexts';
import { Navigation } from './navigation';

export const AppLayout = memo(() => {
  return (
    <LocationProvider>
      <div className='flex min-h-screen  flex-row items-stretch'>
        {/* {withNavigation && navigation} */}
        <Navigation />

        <div
          className={classNames('flex flex-1 flex-col', {
            // 'max-w-clamped lg:mx-auto': contentClamped,
            // 'overflow-scroll': isSafari,
            // 'overflow-auto': !isSafari,
            'max-w-clamped lg:mx-auto': false,
            'overflow-scroll': false,
            'overflow-auto': true,
          })}
        >
          {/* {header()} */}
          <main
            className={classNames('relative flex w-full flex-1 flex-col', {
              // 'lg:px-6 lg:pt-4': !fullPage,
              'px-6 py-4': true,
            })}
          >
            <Outlet />
          </main>
        </div>
      </div>
    </LocationProvider>
  );
});
