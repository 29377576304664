import { Button, ModalFooter, TextInput } from '@any-ui-react/core';

import { DayPicker, EmployeeRelationSelect } from '@/shared/components';
import { EmployeeRelationType } from '@/shared/graphql';
import {
  DependentFormData,
  DependentFormDataType,
  EmployeeDependent,
  EmployeeDependentType,
} from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { dependentFormValidationSuite } from './dependent-form.validator';

export interface DependentFormProps {
  dependent?: EmployeeDependentType;
  onSuccess: (data: EmployeeDependentType) => void;
  onCancel: () => void;
}

export const DependentForm = ({
  dependent,
  onSuccess,
  onCancel,
}: DependentFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    register,
    setValue,
    clearErrors,
  } = useForm<DependentFormDataType>({
    defaultValues: DependentFormData.parse({ ...dependent }),
    resolver: vestResolver(dependentFormValidationSuite),
  });

  const [dateOfBirth] = watch(['dateOfBirth']);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (formValues) => {
        onSuccess(
          EmployeeDependent.parse({ ...formValues, id: dependent?.id })
        );
      }),
    [dependent?.id, handleSubmit, onSuccess]
  );

  return (
    <form noValidate>
      <fieldset className='form-fieldset w-full'>
        <TextInput
          {...register('name')}
          required
          label={'Name'}
          error={errors.name?.message}
          placeholder={'Name'}
          className='col-span-2'
        />
        <EmployeeRelationSelect
          {...register('relation')}
          className='col-span-2'
          defaultValue={dependent?.relation}
          error={errors.relation?.message}
          required
          label={'Relation'}
          placeholder={'Enter relation'}
          onChange={(value) => {
            setValue('relation', value as EmployeeRelationType);
            clearErrors('relation');
          }}
        />
        <DayPicker
          wrapper={{
            className: 'col-span-2 z-10',
          }}
          error={errors.dateOfBirth?.message}
          label='Date of Birth'
          required
          placeholder='Select Date of Birth'
          value={dateOfBirth ? new Date(dateOfBirth).toISOString() : undefined}
          onChange={(value) => {
            setValue(
              'dateOfBirth',
              DateUtils.formatDate(
                new Date(value || '').toISOString(),
                'yyyy-MM-dd'
              ),
              {
                shouldDirty: true,
              }
            );
            clearErrors('dateOfBirth');
          }}
        />
      </fieldset>
      <ModalFooter>
        <Button variant='default' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className='bg-blue-6'
          type='submit'
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </form>
  );
};
