import { EmployeeRole } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeePermission = z.object({
  id: z.string().default(''),
  accessSpecificEmployees: z.boolean().default(false),
  companyIds: z.array(z.string()).default([]),
  employeeIds: z.array(z.string()).default([]),
  role: z.nativeEnum(EmployeeRole).nullable().default(null),
  companies: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    )
    .default([]),
  employees: z
    .array(
      z.object({
        id: z.string(),
        preferredName: z.string().default(''),
        legalName: z.string().default(''),
        company: z.string(),
        businessUnit: z.string(),
        department: z.string(),
      })
    )
    .default([]),
});
export type EmployeePermissionType = z.infer<typeof EmployeePermission>;
