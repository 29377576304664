import { useCallback, useEffect, useMemo, useState } from 'react';

import { TableSelection } from '@/shared/types';
import {
  TableSelectionContext,
  TableSelectionContextType,
} from './TableSelectionContext';

export interface TableSelectionProps<T> {
  page?: number;
  data?: readonly T[];
  selection?: TableSelection<T>;
  selectable?: {
    byField: keyof T;
    aside?: React.ReactNode;
    total: number;
    rowDisabled?: (row: T) => boolean;
    allPagesSelectable?: boolean;
  };
  onSelectionChange?: (selection: TableSelection<T>) => void;
  children: React.ReactNode | React.ReactNode[];
}

export const TableSelectionProvider = <T extends Record<string, unknown>>({
  data = [],
  selection,
  selectable,
  page = 1,
  onSelectionChange,
  children,
}: TableSelectionProps<T>) => {
  /** Map total selected checkboxes by page - with 'selection.items' prop have no idea what page selected items are connected to */
  const [accumulatedSelection, setAccumulatedSelection] = useState<
    Record<string, T[]>
  >({});
  const [initialSelection, setInitialSelection] = useState(selection?.items);
  const selected = selection?.items.length || 0;

  /** Existing items available on current page or when individual checkbox is touched/updated -
   * needed when user is traversing pages clicking on individual checkboxes, not just selection whole page */
  const currentPageSelection = useMemo(
    () =>
      data
        .map((row) => {
          return (
            selection &&
            selectable?.byField &&
            selection.items.find(
              (item) => item[selectable?.byField] === row[selectable?.byField]
            )
          );
        })
        .filter((item) => item !== undefined) as T[],
    [data, selectable?.byField, selection]
  );

  /** All potential items if page selected */
  const selectableData = useMemo(() => {
    return data?.filter((row) => !selectable?.rowDisabled?.(row)) || [];
  }, [data, selectable]);

  /** If selected items already exist on-mount (see Inbound Add Product dialog) we need to add to selection when checkAll() called.
   * When checkAll() called, if pre-existing selected items exist on current page (not other pages), remove from initialSelection.
   * If item exists on non-current page, add to selection, do not remove from initialSelection. Do not add to accumulated data. */
  const initialSelectionData = useMemo(() => {
    if (!initialSelection) return [];
    return initialSelection.filter((item) => {
      return (
        selectableData &&
        selectable?.byField &&
        !selectableData?.find(
          (itm) => itm[selectable?.byField] === item[selectable?.byField]
        )
      );
    });
  }, [initialSelection, selectable?.byField, selectableData]);

  /** Format accumulated data into an array and wipe current page checked items -
   * needed to provide a clean slate for each page onto which we can add new data */
  const formatAccumulatedData = useMemo(() => {
    let formattedData: T[] = [];
    for (const existingPage in accumulatedSelection) {
      const removePageData = existingPage === page.toString();
      const existingData = removePageData
        ? []
        : accumulatedSelection[existingPage] || [];
      formattedData = [...formattedData, ...existingData];
    }
    return formattedData;
  }, [accumulatedSelection, page]);

  /** Select all on current page */
  const checkAll = useCallback(() => {
    if (!selection || !selectable?.byField) return;

    const newSelection = [
      ...selection.items,
      ...formatAccumulatedData,
      ...selectableData,
      ...initialSelectionData,
    ].reduce((accumulator, current) => {
      if (
        !accumulator.find(
          (itm) => itm[selectable?.byField] === current[selectable?.byField]
        )
      ) {
        accumulator.push(current);
      }
      return accumulator;
    }, [] as T[]);

    onSelectionChange?.({
      items: newSelection,
      allPages: selection.allPages,
    });
  }, [
    formatAccumulatedData,
    initialSelectionData,
    onSelectionChange,
    selectable?.byField,
    selectableData,
    selection,
  ]);

  /** Deselect all on current page */
  const uncheckAll = useCallback(() => {
    if (!selection || !selectable?.byField) return;
    onSelectionChange?.({
      items: formatAccumulatedData,
      allPages: false,
    });
  }, [
    formatAccumulatedData,
    onSelectionChange,
    selectable?.byField,
    selection,
  ]);

  /** Toggle current page */
  const toggleSelection = useCallback(() => {
    if (!selection) return;
    const indeterminateState =
      selected > 0 && selectableData.length > currentPageSelection.length;
    if (indeterminateState) {
      checkAll();
      return;
    }
    uncheckAll();
  }, [
    checkAll,
    currentPageSelection.length,
    selectableData.length,
    selected,
    selection,
    uncheckAll,
  ]);

  /** Toggle all pages */
  const toggleSelectAll = useCallback(() => {
    if (!selection) return;
    onSelectionChange?.({
      items: selection.allPages ? [] : [...(selectableData || [])],
      allPages: !selection.allPages,
    });
  }, [onSelectionChange, selectableData, selection]);

  /** If all pages have been selected, when changing page expect to see all items checked */
  useEffect(() => {
    if (
      selection?.allPages &&
      selectableData.length !== currentPageSelection.length
    ) {
      onSelectionChange?.({
        items: [],
        allPages: true,
      });
      checkAll();
    }
  }, [
    page,
    selection?.allPages,
    selectableData,
    currentPageSelection.length,
    onSelectionChange,
    checkAll,
  ]);

  /** Save current page checked items on page change or update */
  useEffect(() => {
    /** On filter change xxxListTable will wipe selection, so need to wipe acc data here */
    if (!selected || selection?.allPages) {
      setAccumulatedSelection({});
      setInitialSelection([]);
      return;
    }
    setInitialSelection(initialSelectionData);
    setAccumulatedSelection((prevState) => ({
      ...prevState,
      [page]: currentPageSelection,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageSelection,
    page,
    setAccumulatedSelection,
    selected,
    selection?.allPages,
  ]);

  const check = useCallback(
    (item: T) => {
      if (!selection || !selectable?.byField) return;
      if (
        !selection.items.some(
          (i) => i[selectable.byField] === item[selectable.byField]
        )
      ) {
        onSelectionChange?.({
          items: [...selection.items, item],
          allPages: selection.allPages,
        });
      }
    },
    [onSelectionChange, selectable?.byField, selection]
  );

  const uncheck = useCallback(
    (item: T) => {
      if (!selection || !selectable?.byField) return;
      if (selection.allPages) {
        onSelectionChange?.({
          items: currentPageSelection.filter(
            (i) => i[selectable.byField] !== item[selectable.byField]
          ),
          allPages: false,
        });
      } else {
        onSelectionChange?.({
          items: selection.items.filter(
            (i) => i[selectable.byField] !== item[selectable.byField]
          ),
          allPages: false,
        });
      }
    },
    [currentPageSelection, onSelectionChange, selectable?.byField, selection]
  );

  const isChecked = (item: T) => {
    return (
      !!selection &&
      !!selectable?.byField &&
      selection.items.some(
        (selectedItems) =>
          selectedItems[selectable?.byField] === item[selectable?.byField]
      )
    );
  };

  return (
    <TableSelectionContext.Provider
      value={
        {
          currentPageSelection,
          selectableData,
          checkAll,
          check,
          uncheckAll,
          uncheck,
          toggleSelection,
          toggleSelectAll,
          selection,
          selectable,
          isChecked,
        } as TableSelectionContextType<T>
      }
    >
      {children}
    </TableSelectionContext.Provider>
  );
};
