import { Card, PageTitle, Spinner } from '@/shared/components';
import { EmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeInParams } from '@/shared/hooks';
import { EmployeeDetail } from '@/shared/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeeEditTabs } from './components';

export const EmployeeEdit = () => {
  const navigate = useNavigate();
  const { employee } = useEmployeeInParams();
  const employeeGeneralInformation = employee?.generalInformation;

  const [employeeDetail, setEmployeeDetail] = useState(
    EmployeeDetail.parse({ ...employee }) ?? EmployeeDetail.parse({})
  );

  useEffect(() => {
    setEmployeeDetail(EmployeeDetail.parse({ ...employee }));
  }, [employee]);

  if (!employee) {
    return <Spinner />;
  }

  return (
    <>
      <PageTitle text={`Edit Employee`} onBack={() => navigate('/employees')} />
      <Card>
        <div className='inline-block h-16 max-h-16 w-16 border'>
          <img
            src={employeeGeneralInformation?.pictureUrl || ''}
            alt=''
            className='h-16 w-16'
            referrerPolicy='no-referrer'
          />
        </div>
        <div className='ml-6 inline-block'>
          <span className='block h-6 text-2xl font-semibold !leading-[normal]'>{`${
            employeeGeneralInformation?.legalFullName
          } ${
            employeeGeneralInformation?.employeeId
              ? `#${employeeGeneralInformation.id}`
              : ''
          } `}</span>
          <span className='my-2 block h-3.5 !leading-[normal] text-gray-7'>
            {employeeGeneralInformation?.nameInLocalLanguage || '-'}
          </span>
          <span className='block h-3 text-xs !leading-[normal]'>
            {`${employeeGeneralInformation?.company} - ${employeeGeneralInformation?.department} - ${employeeGeneralInformation?.job}`}
          </span>
        </div>
      </Card>
      <Card withPadding={false} className='mb-6 mt-6'>
        <EmployeeDetailContext.Provider
          value={{ employeeDetail, setEmployeeDetail }}
        >
          <EmployeeEditTabs />
        </EmployeeDetailContext.Provider>
      </Card>
    </>
  );
};
