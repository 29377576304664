import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateNotificationSettingMutationVariables = Types.Exact<{
  input: Types.UpdateNotificationSettingInput;
}>;


export type UpdateNotificationSettingMutation = { __typename?: 'Mutation', updateNotificationSetting: { __typename?: 'NotificationSettingOutput', id: string } };


export const UpdateNotificationSettingDocument = gql`
    mutation updateNotificationSetting($input: UpdateNotificationSettingInput!) {
  updateNotificationSetting(input: $input) {
    id
  }
}
    `;
export type UpdateNotificationSettingMutationFn = Apollo.MutationFunction<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>;

/**
 * __useUpdateNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingMutation, { data, loading, error }] = useUpdateNotificationSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>(UpdateNotificationSettingDocument, options);
      }
export type UpdateNotificationSettingMutationHookResult = ReturnType<typeof useUpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationResult = Apollo.MutationResult<UpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>;