import { toast } from '@/shared/components';
import {
  ListEmployeeListCustomFiltersDocument,
  useDeleteEmployeeListCustomFilterMutation,
  useListEmployeeListCustomFiltersQuery,
} from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { EmployeeCustomFilter, EmployeeListFilterType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { CustomFilterTable } from './CustomFilterTable';

export const ManageCustomFilterModal = ({
  children,
  filters,
  className,
}: {
  children: React.ReactNode;
  filters: FilterProps<EmployeeListFilterType>;
  className?: string;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const [deleteRecord] = useDeleteEmployeeListCustomFilterMutation();
  const { data, loading } = useListEmployeeListCustomFiltersQuery();

  const onClick = () => {
    openModal({
      modalId,
      title: `Manage custom filters`,
      size: 'lg',
      children: (
        <div className='max-h-96 overflow-auto rounded border-x border-b shadow-none'>
          <CustomFilterTable
            items={
              data?.listEmployeeListCustomFilters.items.map((item) =>
                EmployeeCustomFilter.parse({
                  id: item.id,
                  name: item.name,
                  isOpenFilter: item.isOpenFilter,
                  createdBy:
                    item.createdBy?.generalInformation.preferredName ?? '-',
                  createdAt: item.createdAt,
                })
              ) || []
            }
            loading={loading}
            onRemove={(id) => {
              deleteRecord({
                variables: { id: id },
                onCompleted: () => {
                  toast.success();

                  client.refetchQueries({
                    include: [ListEmployeeListCustomFiltersDocument],
                  });

                  if (id === filters.current.id) {
                    filters.resetFilters();
                  }

                  closeModal(modalId);
                },
                onError: () => toast.error(),
              });
            }}
          />
        </div>
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
