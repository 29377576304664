import { DependentFormDataType } from '@/shared/types';
import { create, enforce, test } from 'vest';

export const dependentFormValidationSuite = create(
  ({ name, relation, dateOfBirth }: DependentFormDataType) => {
    test('name', 'Name is required', () => {
      enforce(name).isNotEmpty();
    });
    test('relation', 'Relation is required', () => {
      enforce(relation).isNotEmpty();
    });
    test('dateOfBirth', 'Date of Birth is required', () => {
      enforce(dateOfBirth).isNotEmpty();
    });
  }
);
