import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeJobHistoryNoteMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeJobHistoryNoteInput;
}>;


export type UpdateEmployeeJobHistoryNoteMutation = { __typename?: 'Mutation', updateEmployeeJobHistoryNote: { __typename?: 'EmployeeOutput', id: string } };


export const UpdateEmployeeJobHistoryNoteDocument = gql`
    mutation updateEmployeeJobHistoryNote($input: UpdateEmployeeJobHistoryNoteInput!) {
  updateEmployeeJobHistoryNote(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeJobHistoryNoteMutationFn = Apollo.MutationFunction<UpdateEmployeeJobHistoryNoteMutation, UpdateEmployeeJobHistoryNoteMutationVariables>;

/**
 * __useUpdateEmployeeJobHistoryNoteMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeJobHistoryNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeJobHistoryNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeJobHistoryNoteMutation, { data, loading, error }] = useUpdateEmployeeJobHistoryNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeJobHistoryNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeJobHistoryNoteMutation, UpdateEmployeeJobHistoryNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeJobHistoryNoteMutation, UpdateEmployeeJobHistoryNoteMutationVariables>(UpdateEmployeeJobHistoryNoteDocument, options);
      }
export type UpdateEmployeeJobHistoryNoteMutationHookResult = ReturnType<typeof useUpdateEmployeeJobHistoryNoteMutation>;
export type UpdateEmployeeJobHistoryNoteMutationResult = Apollo.MutationResult<UpdateEmployeeJobHistoryNoteMutation>;
export type UpdateEmployeeJobHistoryNoteMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeJobHistoryNoteMutation, UpdateEmployeeJobHistoryNoteMutationVariables>;