import { toast } from '@/shared/components';
import {
  ContractType,
  GetEmployeeDocument,
  useCreateEmployeeContractMutation,
  useDeleteEmployeeContractMutation,
  useUpdateEmployeeContractMutation,
  useUpdateEmployeeContractNoteMutation,
} from '@/shared/graphql';
import { EmployeeContractHistoryType } from '@/shared/types';
import { ApolloError, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

interface ContractHistoryPersistence {
  employeeId: string;
}

export const useContractHistoryPersistence = ({
  employeeId,
}: ContractHistoryPersistence) => {
  const client = useApolloClient();
  const onCompleted = () => {
    toast.success();
    client.refetchQueries({
      include: [GetEmployeeDocument],
    });
  };
  const onError = (error: ApolloError) => toast.error(error.message);

  const [createRecord] = useCreateEmployeeContractMutation({
    onCompleted,
    onError,
  });
  const [updateRecord] = useUpdateEmployeeContractMutation({
    onCompleted,
    onError,
  });
  const [deleteRecord] = useDeleteEmployeeContractMutation({
    onCompleted,
    onError,
  });
  const [updateNote] = useUpdateEmployeeContractNoteMutation({
    onCompleted,
    onError,
  });

  const onContractHistoryCreate = useCallback(
    async (formData: EmployeeContractHistoryType) => {
      const { data } = await createRecord({
        variables: {
          input: {
            employeeId,
            companyId: formData.companyId || '',
            contractType: formData.type as ContractType,
            effectiveDate: formData.effectiveDateStart,
            effectiveDateUntil: formData.effectiveDateEnd
              ? formData.effectiveDateEnd
              : null,
            employeeCode: formData.employeeCode ? formData.employeeCode : null,
            onSecondment: formData.isOnSecondment,
            isMain: formData.isMain,
          },
        },
      });

      return data?.createEmployeeContract !== undefined;
    },
    [createRecord, employeeId]
  );

  const onContractHistoryUpdate = useCallback(
    async (formData: EmployeeContractHistoryType) => {
      const { data } = await updateRecord({
        variables: {
          input: {
            companyId: formData.companyId || '',
            contractType: formData.type as ContractType,
            effectiveDate: formData.effectiveDateStart,
            effectiveDateUntil: formData.effectiveDateEnd
              ? formData.effectiveDateEnd
              : null,
            employeeCode: formData.employeeCode ? formData.employeeCode : null,
            onSecondment: formData.isOnSecondment,
            id: formData.id,
            isMain: formData.isMain,
          },
        },
      });

      return data?.updateEmployeeContract !== undefined;
    },
    [updateRecord]
  );

  const onContractHistoryRemove = useCallback(
    (id: string) => {
      deleteRecord({
        variables: { id: id },
      });
    },
    [deleteRecord]
  );

  const onContractHistoryNoteUpdate = useCallback(
    (note: string, employeeId: string) => {
      updateNote({
        variables: {
          input: {
            employeeId,
            note,
          },
        },
      });
    },
    [updateNote]
  );

  return {
    onContractHistoryCreate,
    onContractHistoryUpdate,
    onContractHistoryRemove,
    onContractHistoryNoteUpdate,
  };
};
