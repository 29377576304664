import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess, useJobHistoryPersistence } from '@/shared/hooks';
import { Button, Textarea } from '@any-ui-react/core';
import { useEffect, useState } from 'react';
import { JobHistoryModal } from './job-history-modal';
import { JobHistoryTable } from './job-history-table/JobHistoryTable';

export const JobHistory = ({
  onFormStateChange,
}: {
  onFormStateChange: (isDirty: boolean) => void;
}) => {
  const { employeeDetail } = useEmployeeDetailContext();
  const { hasJobContractHistoryEditAccess } = useEmployeeAccess();
  const [note, setNote] = useState(employeeDetail.jobHistoryNotes || '');

  const {
    onJobHistoryCreate,
    onJobHistoryUpdate,
    onJobHistoryRemove,
    onJobHistoryNoteUpdate,
  } = useJobHistoryPersistence({ employeeId: employeeDetail.id });

  useEffect(() => {
    const isDirty = note !== (employeeDetail.jobHistoryNotes || '');
    onFormStateChange(isDirty);
  }, [note, employeeDetail.jobHistoryNotes, onFormStateChange]);

  return (
    <>
      <div className='mb-4 flex flex-row'>
        <h1 className='self-center text-lg font-semibold'>Job History</h1>{' '}
        {hasJobContractHistoryEditAccess && (
          <JobHistoryModal
            className='ml-auto'
            isNewRecord={true}
            onSuccess={onJobHistoryCreate}
          >
            <Button variant='default'>Add Job History</Button>
          </JobHistoryModal>
        )}
      </div>
      <JobHistoryTable
        items={employeeDetail.jobHistories ?? []}
        loading={false}
        onRemove={onJobHistoryRemove}
        onUpdate={onJobHistoryUpdate}
      />
      <div className='mb-10 mt-6'>
        <Textarea
          value={note}
          label='Note'
          minRows={5}
          onChange={(e) => setNote(e.target.value)}
          disabled={!hasJobContractHistoryEditAccess}
        />
      </div>
      {hasJobContractHistoryEditAccess && (
        <>
          <hr />
          <div className='flex'>
            <Button
              onClick={() => onJobHistoryNoteUpdate(note, employeeDetail.id)}
              className='my-4 ml-auto bg-blue-6'
            >
              Save
            </Button>
          </div>
        </>
      )}
    </>
  );
};
