import { CardSection } from '@/shared/components';
import { EmployeeDetailType } from '@/shared/types';
import { DateUtils, StringUtils } from '@/shared/utils';

export const CurrentContract = ({
  employee,
}: {
  employee: EmployeeDetailType;
}) => {
  const employeeGeneralInformation = employee.generalInformation;
  return (
    <CardSection className='mt-10'>
      <h1 className='text-lg font-semibold'>Current Contract</h1>
      <p className='mb-6 text-[0.75rem] text-gray-7'>
        You can see and edit contract histories in "Contract / Job History tab"
      </p>
      <div className='form-fieldset'>
        <div>
          <p className='mb-2 font-semibold'>Contract Type</p>
          <p className='text-gray-7'>
            {StringUtils.capitalizeAll(
              employeeGeneralInformation.contractType,
              '_'
            )}
          </p>
        </div>

        <div>
          <p className='mb-2 font-semibold'>Employee Code</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.employeeCode || '-'}
          </p>
        </div>

        <div>
          <p className='mb-2 font-semibold'>Company</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.company || '-'}
          </p>
        </div>

        <div>
          <p className='mb-2 font-semibold'>Contract End Date</p>
          <p className='text-gray-7'>
            {employeeGeneralInformation.effectiveDateUntil
              ? DateUtils.formatDate(
                  new Date(
                    employeeGeneralInformation.effectiveDateUntil || ''
                  ).toISOString(),
                  'yyyy-MM-dd'
                )
              : '-'}
          </p>
        </div>
      </div>
    </CardSection>
  );
};
