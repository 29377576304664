import { EmployeeHiringSource } from '@/shared/graphql';
import { ObjectUtils, StringUtils } from '@/shared/utils';
import {
  ActionMeta,
  MultiValue,
  PropsValue,
  Select,
  SingleValue,
  useMultiSelect,
} from '@any-ui-react/select';

interface SelectProps {
  isMulti?: boolean;
  value:
    | string
    | number
    | boolean
    | PropsValue<{ label: string; value: EmployeeHiringSource }>
    | undefined;
  onChange:
    | ((
        newValue:
          | MultiValue<{
              label: string;
              value: EmployeeHiringSource;
            }>
          | SingleValue<{
              label: string;
              value: EmployeeHiringSource;
            }>,
        actionMeta: ActionMeta<{
          label: string;
          value: EmployeeHiringSource;
        }>
      ) => void)
    | undefined;
  error: string | undefined;
}

export const EmployeeHiringSourceSelect = ({
  onChange,
  value,
  isMulti = true,
  error,
}: SelectProps) => {
  const statuses =
    ObjectUtils.arrayFromEnumValues<EmployeeHiringSource>(EmployeeHiringSource);
  const options = statuses.map((item) => ({
    label: StringUtils.capitalizeAll(item, '_'),
    value: item,
  }));

  const multiSelectProps = useMultiSelect({
    getSelectedLabel: (count) => `Statuses (${count})`,
    options: options,
  });

  const selectProps = isMulti ? multiSelectProps : { options: options };

  return (
    <Select
      label='Hiring Source'
      placeholder='Status'
      isSearchable={true}
      isClearable={true}
      onChange={onChange}
      value={value}
      required
      error={error}
      {...selectProps}
    />
  );
};
