import { toast } from '@/shared/components';
import {
  GetEmployeeDocument,
  JobHistoryLevel,
  JobHistoryType,
  useCreateEmployeeJobHistoryMutation,
  useDeleteEmployeeJobHistoryMutation,
  useUpdateEmployeeJobHistoryMutation,
  useUpdateEmployeeJobHistoryNoteMutation,
} from '@/shared/graphql';
import { EmployeeJobHistoryType } from '@/shared/types';
import { ApolloError, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

interface JobHistoryPersistence {
  employeeId: string;
}

export const useJobHistoryPersistence = ({
  employeeId,
}: JobHistoryPersistence) => {
  const client = useApolloClient();
  const onCompleted = () => {
    toast.success();
    client.refetchQueries({
      include: [GetEmployeeDocument],
    });
  };
  const onError = (error: ApolloError) => toast.error(error.message);

  const [createRecord] = useCreateEmployeeJobHistoryMutation({
    onCompleted,
    onError,
  });
  const [updateRecord] = useUpdateEmployeeJobHistoryMutation({
    onCompleted,
    onError,
  });
  const [deleteRecord] = useDeleteEmployeeJobHistoryMutation({
    onCompleted,
    onError,
  });
  const [updateNote] = useUpdateEmployeeJobHistoryNoteMutation({
    onCompleted,
    onError,
  });

  const onJobHistoryCreate = useCallback(
    async ({ id, ...formData }: EmployeeJobHistoryType) => {
      const { data } = await createRecord({
        variables: {
          input: {
            level: formData.level as JobHistoryLevel,
            locationId: formData.locationId,
            businessUnitId: formData.businessUnitId,
            departmentId: formData.departmentId,
            effectiveDate: formData.effectiveDateStart,
            effectiveDateUntil: formData.effectiveDateEnd
              ? formData.effectiveDateEnd
              : null,
            employeeId,
            jobId: formData.jobId,
            jobTitle: formData.jobTitle,
            team: formData.team,
            type: formData.type as JobHistoryType,
            regionalRole: formData.regionalRole,
          },
        },
      });

      return data?.createEmployeeJobHistory !== undefined;
    },
    [createRecord, employeeId]
  );

  const onJobHistoryUpdate = useCallback(
    async (formData: EmployeeJobHistoryType) => {
      const { data } = await updateRecord({
        variables: {
          input: {
            level: formData.level as JobHistoryLevel,
            locationId: formData.locationId,
            businessUnitId: formData.businessUnitId,
            departmentId: formData.departmentId,
            effectiveDate: formData.effectiveDateStart,
            effectiveDateUntil: formData.effectiveDateEnd
              ? formData.effectiveDateEnd
              : null,
            jobId: formData.jobId,
            jobTitle: formData.jobTitle,
            team: formData.team,
            type: formData.type as JobHistoryType,
            id: formData.id,
            regionalRole: formData.regionalRole,
          },
        },
      });

      return data?.updateEmployeeJobHistory !== undefined;
    },
    [updateRecord]
  );

  const onJobHistoryRemove = useCallback(
    (id: string) => {
      deleteRecord({
        variables: { id: id },
      });
    },
    [deleteRecord]
  );

  const onJobHistoryNoteUpdate = useCallback(
    (note: string, employeeId: string) => {
      updateNote({
        variables: {
          input: {
            employeeId,
            note,
          },
        },
      });
    },
    [updateNote]
  );

  return {
    onJobHistoryCreate,
    onJobHistoryUpdate,
    onJobHistoryRemove,
    onJobHistoryNoteUpdate,
  };
};
