import { EmployeeStatus } from '@/shared/graphql';
import { ObjectUtils } from '@/shared/utils';
import {
  ActionMeta,
  MultiValue,
  PropsValue,
  Select,
  useMultiSelect,
} from '@any-ui-react/select';

interface SelectProps {
  isMulti?: boolean;
  value:
    | string
    | number
    | boolean
    | PropsValue<{ label: string; value: EmployeeStatus }>
    | undefined;
  onChange:
    | ((
        newValue: MultiValue<{
          label: string;
          value: EmployeeStatus;
        }>,
        actionMeta: ActionMeta<{
          label: string;
          value: EmployeeStatus;
        }>
      ) => void)
    | undefined;
}

export enum EmployeeStatusText {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PRE_ONBOARDING = 'Pre-onboarding',
}

export const EmployeeStatusSelect = ({
  onChange,
  value,
  isMulti = true,
}: SelectProps) => {
  const statuses =
    ObjectUtils.arrayFromEnumValues<EmployeeStatus>(EmployeeStatus);
  const options = statuses.map((item) => ({
    label: String(EmployeeStatusText[item]),
    value: item,
  }));

  const multiSelectProps = useMultiSelect({
    getSelectedLabel: (count) => `Statuses (${count})`,
    options: options,
  });

  const selectProps = isMulti ? multiSelectProps : { options: options };

  return (
    <Select
      placeholder='Status'
      isSearchable={true}
      isClearable={true}
      onChange={onChange}
      value={value}
      {...selectProps}
    />
  );
};
