import { EmployeeIdType } from '@/shared/graphql';
import { StringUtils } from '@/shared/utils';
import { Select, SelectProps } from '@any-ui-react/core';

interface EmployeeIdTypeSelectSelectProps extends Omit<SelectProps, 'data'> {}

export const EmployeeIdTypeSelect = ({
  ...props
}: EmployeeIdTypeSelectSelectProps) => {
  const types = Object.values(EmployeeIdType).map((value) => ({
    label: StringUtils.capitalizeAll(value, '_'),
    value: value,
  }));

  return <Select label='ID Type' data={types} {...props} />;
};
