/* eslint-disable react-hooks/rules-of-hooks */
import {
  ListBusinessUnitsQuery,
  ListCompaniesQuery,
  ListCompanyEmailDomainsQuery,
  ListDepartmentsQuery,
  ListDivisionsQuery,
  ListJobsQuery,
  ListLocationsQuery,
  ListReasonForLeavingsQuery,
  ListTypeOfAttritionQuery,
  useListBusinessUnitsQuery,
  useListCompaniesQuery,
  useListCompanyEmailDomainsQuery,
  useListDepartmentsQuery,
  useListDivisionsQuery,
  useListJobsQuery,
  useListLocationsQuery,
  useListReasonForLeavingsQuery,
  useListTypeOfAttritionQuery,
} from '@/shared/graphql';
import {
  BusinessUnit,
  Company,
  CompanyEmailDomain,
  CompanyType,
  Department,
  Division,
  Job,
  Location,
  ReasonForLeaving,
  TableSelection,
  TypeOfAttrition,
} from '@/shared/types';
import { GeneralType } from '@/shared/utils';
import { Button } from '@any-ui-react/core';
import { useState } from 'react';
import { z } from 'zod';
import {
  BusinessUnitModal,
  BusinessUnitTable,
  CompanyEmailDomainModal,
  CompanyEmailDomainTable,
  DepartmentModal,
  DepartmentTable,
  DivisionModal,
  DivisionTable,
  LocationModal,
  LocationTable,
  ReasonForLeavingModal,
  ReasonForLeavingTable,
  TypeOfAttritionModal,
  TypeOfAttritionTable,
} from '../../components';
import { CompanyModal } from '../../components/settings/general/company-modal/CompanyModal';
import { CompanyTable } from '../../components/settings/general/company-table';
import { JobModal } from '../../components/settings/general/job-modal';
import { JobTable } from '../../components/settings/general/job-table';

interface GeneralComponents {
  modalButton: JSX.Element | undefined;
  table: JSX.Element | undefined;
  count: number;
}

const useGetTableType = (generalType: GeneralType) => {
  switch (generalType) {
    case GeneralType.BUSINESS_UNIT:
      return BusinessUnit;
    case GeneralType.DIVISION:
      return Division;
    case GeneralType.COMPANY:
      return Company;
    case GeneralType.DEPARTMENT:
      return Department;
    case GeneralType.COMPANY_EMAIL_DOMAIN:
      return CompanyEmailDomain;
    case GeneralType.JOB:
      return Job;
    case GeneralType.LOCATION:
      return Location;
    case GeneralType.REASON_FOR_LEAVING:
      return ReasonForLeaving;
    case GeneralType.TYPE_OF_ATTRITION:
      return TypeOfAttrition;
    default:
      return BusinessUnit;
  }
};

const useGetModalButton = (generalType: GeneralType) => {
  switch (generalType) {
    case GeneralType.BUSINESS_UNIT:
      return (
        <BusinessUnitModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Business Unit</span>
          </Button>
        </BusinessUnitModal>
      );
    case GeneralType.DIVISION:
      return (
        <DivisionModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Division</span>
          </Button>
        </DivisionModal>
      );
    case GeneralType.COMPANY:
      return (
        <CompanyModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Company</span>
          </Button>
        </CompanyModal>
      );
    case GeneralType.DEPARTMENT:
      return (
        <DepartmentModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Department</span>
          </Button>
        </DepartmentModal>
      );
    case GeneralType.COMPANY_EMAIL_DOMAIN:
      return (
        <CompanyEmailDomainModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Company Email Domain</span>
          </Button>
        </CompanyEmailDomainModal>
      );
    case GeneralType.JOB:
      return (
        <JobModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Job</span>
          </Button>
        </JobModal>
      );
    case GeneralType.LOCATION:
      return (
        <LocationModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Location</span>
          </Button>
        </LocationModal>
      );
    case GeneralType.REASON_FOR_LEAVING:
      return (
        <ReasonForLeavingModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Reason For Leaving</span>
          </Button>
        </ReasonForLeavingModal>
      );
    case GeneralType.TYPE_OF_ATTRITION:
      return (
        <TypeOfAttritionModal className='ml-auto'>
          <Button variant='default'>
            <span>Add Type of Attrition</span>
          </Button>
        </TypeOfAttritionModal>
      );
    default:
      <>-</>;
  }
};

const useGetTable = (
  generalType: GeneralType,
  queryData: unknown | undefined,
  loading: boolean
) => {
  const tableType = useGetTableType(generalType);
  const [selection, setSelection] = useState<
    TableSelection<z.infer<typeof tableType>>
  >({
    items: [],
    allPages: false,
  });

  switch (generalType) {
    case GeneralType.BUSINESS_UNIT:
      const businessUnitData = queryData as ListBusinessUnitsQuery | undefined;
      return (
        <BusinessUnitTable
          items={
            businessUnitData?.listBusinessUnits.items.map((item: unknown) =>
              BusinessUnit.parse(item)
            ) ?? []
          }
          loading={loading}
        />
      );
    case GeneralType.DIVISION:
      const divisionData = queryData as ListDivisionsQuery | undefined;
      return (
        <DivisionTable
          items={
            divisionData?.listDivisions.items.map((item: unknown) =>
              Division.parse(item)
            ) ?? []
          }
          loading={loading}
          selection={selection}
          setSelection={setSelection}
          total={divisionData?.listDivisions.totalCount || 0}
        />
      );
    case GeneralType.COMPANY:
      const companyData = queryData as ListCompaniesQuery | undefined;
      return (
        <CompanyTable
          items={
            companyData?.listCompanies.items.map((item: unknown) =>
              Company.parse(item)
            ) ?? []
          }
          loading={loading}
          selection={selection as TableSelection<CompanyType>}
          setSelection={setSelection}
          total={companyData?.listCompanies.totalCount || 0}
        />
      );
    case GeneralType.DEPARTMENT:
      const departmentData = queryData as ListDepartmentsQuery | undefined;
      return (
        <DepartmentTable
          items={
            departmentData?.listDepartments.items.map((item: unknown) =>
              Department.parse(item)
            ) ?? []
          }
          loading={loading}
          selection={selection}
          setSelection={setSelection}
          total={departmentData?.listDepartments.totalCount || 0}
        />
      );
    case GeneralType.COMPANY_EMAIL_DOMAIN:
      const companyEmailDomainData = queryData as
        | ListCompanyEmailDomainsQuery
        | undefined;
      return (
        <CompanyEmailDomainTable
          items={
            companyEmailDomainData?.listCompanyEmailDomains.items.map(
              (item: unknown) => CompanyEmailDomain.parse(item)
            ) ?? []
          }
          loading={loading}
        />
      );
    case GeneralType.JOB:
      const jobData = queryData as ListJobsQuery | undefined;
      return (
        <JobTable
          items={
            jobData?.listJobs.items.map((item: unknown) => Job.parse(item)) ??
            []
          }
          loading={loading}
          selection={selection}
          setSelection={setSelection}
          total={jobData?.listJobs.totalCount || 0}
        />
      );
    case GeneralType.LOCATION:
      const locationData = queryData as ListLocationsQuery | undefined;
      return (
        <LocationTable
          items={
            locationData?.listLocations.items.map((item: unknown) =>
              Location.parse(item)
            ) ?? []
          }
          loading={loading}
        />
      );
    case GeneralType.REASON_FOR_LEAVING:
      const reasonForLeavingData = queryData as
        | ListReasonForLeavingsQuery
        | undefined;
      return (
        <ReasonForLeavingTable
          items={
            reasonForLeavingData?.listReasonForLeavings.items.map(
              (item: unknown) => Job.parse(item)
            ) ?? []
          }
          loading={loading}
          selection={selection}
          setSelection={setSelection}
          total={reasonForLeavingData?.listReasonForLeavings.totalCount || 0}
        />
      );
    case GeneralType.TYPE_OF_ATTRITION:
      const typeOfAttritionData = queryData as
        | ListTypeOfAttritionQuery
        | undefined;
      return (
        <TypeOfAttritionTable
          items={
            typeOfAttritionData?.listTypeOfAttrition.items.map(
              (item: unknown) => Job.parse(item)
            ) ?? []
          }
          loading={loading}
          selection={selection}
          setSelection={setSelection}
          total={typeOfAttritionData?.listTypeOfAttrition.totalCount || 0}
        />
      );
    default:
      <>-</>;
  }
};

const useGetQueryData = (generalType: GeneralType, page: number) => {
  switch (generalType) {
    case GeneralType.BUSINESS_UNIT:
      const { data: businessUnitData, loading: businessUnitDataLoading } =
        useListBusinessUnitsQuery({
          variables: {
            pageNumber: page,
            pageSize: 20,
            keyword: null,
            enabled: null,
          },
        });

      return {
        data: businessUnitData,
        count: businessUnitData?.listBusinessUnits.totalCount || 0,
        loading: businessUnitDataLoading,
      };
    case GeneralType.DIVISION:
      const { data: divisionData, loading: divisionDataLoading } =
        useListDivisionsQuery({
          variables: {
            pageNumber: page,
            pageSize: 20,
            keyword: null,
            enabled: null,
          },
        });

      return {
        data: divisionData,
        count: divisionData?.listDivisions.totalCount || 0,
        loading: divisionDataLoading,
      };
    case GeneralType.COMPANY:
      const { data: companyData, loading: companyDataDataLoading } =
        useListCompaniesQuery({
          variables: {
            pageNumber: page,
            pageSize: 20,
          },
        });

      return {
        data: companyData,
        count: companyData?.listCompanies.totalCount || 0,
        loading: companyDataDataLoading,
      };
    case GeneralType.DEPARTMENT:
      const { data: departmentData, loading: departmentDataLoading } =
        useListDepartmentsQuery({
          variables: {
            pageNumber: page,
            pageSize: 20,
            enabled: null,
          },
        });
      return {
        data: departmentData,
        count: departmentData?.listDepartments.totalCount || 0,
        loading: departmentDataLoading,
      };
    case GeneralType.COMPANY_EMAIL_DOMAIN:
      const {
        data: companyEmailDomainData,
        loading: companyEmailDomainDataLoading,
      } = useListCompanyEmailDomainsQuery({
        variables: {
          pageNumber: page,
          pageSize: 20,
          enabled: null,
        },
      });
      return {
        data: companyEmailDomainData,
        count: companyEmailDomainData?.listCompanyEmailDomains.totalCount || 0,
        loading: companyEmailDomainDataLoading,
      };
    case GeneralType.JOB:
      const { data: jobData, loading: jobDataLoading } = useListJobsQuery({
        variables: {
          pageNumber: page,
          pageSize: 20,
          enabled: null,
        },
      });
      return {
        data: jobData,
        count: jobData?.listJobs.totalCount || 0,
        loading: jobDataLoading,
      };
    case GeneralType.LOCATION:
      const { data: locationData, loading: locationDataLoading } =
        useListLocationsQuery({
          variables: {
            pageNumber: page,
            pageSize: 20,
            keyword: null,
            enabled: null,
          },
        });
      return {
        data: locationData,
        count: locationData?.listLocations.totalCount || 0,
        loading: locationDataLoading,
      };
    case GeneralType.REASON_FOR_LEAVING:
      const {
        data: reasonForLeavingData,
        loading: reasonForLeavingDataLoading,
      } = useListReasonForLeavingsQuery({
        variables: {
          pageNumber: page,
          pageSize: 20,
          enabled: null,
        },
      });
      return {
        data: reasonForLeavingData,
        count: reasonForLeavingData?.listReasonForLeavings.totalCount || 0,
        loading: reasonForLeavingDataLoading,
      };

    case GeneralType.TYPE_OF_ATTRITION:
      const { data: typeOfAttritionData, loading: typeOfAttritionDataLoading } =
        useListTypeOfAttritionQuery({
          variables: {
            pageNumber: page,
            pageSize: 20,
            enabled: null,
          },
        });
      return {
        data: typeOfAttritionData,
        count: typeOfAttritionData?.listTypeOfAttrition.totalCount || 0,
        loading: typeOfAttritionDataLoading,
      };
    default:
      return { data: undefined, count: 0, loading: true };
  }
};

// TODO: Consider Generics
export const useGeneralComponents = ({
  generalType,
  page,
}: {
  generalType: GeneralType;
  page: number;
}): GeneralComponents => {
  const queryData = useGetQueryData(generalType, page);
  return {
    modalButton: useGetModalButton(generalType),
    table: useGetTable(generalType, queryData.data, queryData.loading),
    count: queryData.count,
  };
};
