import {
  BusinessUnitLazySelect,
  DEFAULT_PAGE_SIZE,
  DepartmentLazySelect,
  InputSearch,
} from '@/shared/components';
import { useListUserEmployeesQuery } from '@/shared/graphql';
import {
  AddUserEmployeeFilter,
  AddUserEmployeeFilterType,
  TableSelection,
  UserEmployee,
  UserEmployeeType,
} from '@/shared/types';
import { Button, ModalFooter, TablePagination } from '@any-ui-react/core';
import { useState } from 'react';
import { AddEmployeeTable } from './AddEmployeeTable';

export const AddEmployeeForm = ({
  activeEmployees,
  onSuccess,
  onCancel,
  companyIds = [],
}: {
  activeEmployees: UserEmployeeType[];
  onSuccess: (selection: UserEmployeeType[]) => void;
  onCancel: () => void;
  companyIds?: string[];
}) => {
  const [filters, setFilters] = useState<AddUserEmployeeFilterType>(
    AddUserEmployeeFilter.parse({ companyIds })
  );

  const { data: employeesData } = useListUserEmployeesQuery({
    variables: {
      pageNumber: filters.page,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: {
        keyword: filters.keyword ?? null,
        departmentIds: filters.departmentId ? [filters.departmentId] : [],
        companyIds: filters.companyIds ? filters.companyIds : [],
        businessUnitIds: filters.businessUnitId ? [filters.businessUnitId] : [],
        excludeAuthUser: true,
        contractTypes: [],
        jobIds: [],
        levels: [],
        locationIds: [],
        managerIds: [],
        statuses: [],
        isLeave: false,
        isOnSecondment: false,
        managerIncludesAllConnections: true,
        nationalityIds: [],
        roles: [],
        divisionIds: [],
      },
    },
  });

  const [selection, setSelection] = useState<TableSelection<UserEmployeeType>>({
    items: activeEmployees,
    allPages: false,
  });

  const employeesDataCount = employeesData?.listEmployees.totalCount || 0;

  return (
    <div>
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          onSuccess(selection.items);
        }}
      >
        <fieldset className='form-fieldset w-full'>
          <InputSearch
            className='col-span-2'
            onClear={() =>
              setFilters({
                ...filters,
                keyword: null,
              })
            }
            onSearch={(text: string) =>
              setFilters({
                ...filters,
                keyword: text,
              })
            }
            placeholder={'Keyword search'}
            value={filters.keyword}
          />
          <BusinessUnitLazySelect
            isMulti={false}
            label={'Business Units'}
            valueFormatter={(item) => item.id}
            onChange={(newValue) => {
              setFilters({
                ...filters,
                businessUnitId: newValue?.value,
              });
            }}
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                keyword: null,
                enabled: null,
              },
            }}
            placeholder={'Select Business Unit'}
          />
          <DepartmentLazySelect
            isMulti={false}
            label={'Departments'}
            valueFormatter={(item) => item.id}
            onChange={(newValue) => {
              setFilters({
                ...filters,
                departmentId: newValue?.value,
              });
            }}
            defaultQueryOptions={{
              variables: {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE,
                enabled: null,
              },
            }}
            placeholder={'Select Department'}
          />
        </fieldset>
        <AddEmployeeTable
          items={
            employeesData?.listEmployees.items.map((item) => ({
              ...UserEmployee.parse({
                id: item.id,
                preferredName: item.generalInformation.preferredName,
                legalName: item.generalInformation.legalFullName,
                company: item.currentContract?.company.name,
                businessUnit: item.currentPosition?.businessUnit.name,
                department: item.currentPosition?.department.name,
              }),
            })) ?? []
          }
          selection={selection}
          setSelection={setSelection}
          loading={false}
          total={employeesData?.listEmployees.totalCount}
          showSelected={true}
        />
        <TablePagination
          className='px-4 pt-8 lg:px-0'
          value={filters.page}
          rowsPerPage={DEFAULT_PAGE_SIZE}
          withItems={true}
          count={employeesDataCount}
          onChange={(newPage) => {
            setFilters({
              ...filters,
              page: newPage,
            });
          }}
        />
        <ModalFooter>
          <Button variant='default' onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            className='bg-blue-6'
            type='submit'
            disabled={employeesDataCount === 0}
            loading={false}
            onClick={() => {
              onSuccess(selection.items);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </form>
    </div>
  );
};
