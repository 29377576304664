import { CardTitle } from '@/shared/components';
import { SettingNotificationType } from '@/shared/types';
import { useFormContext } from 'react-hook-form';
import { NotificationItem } from '../notification-item';

export const ContractJobInfo = () => {
  const { watch } = useFormContext<SettingNotificationType>();
  const [
    contractEndDate,
    employmentEndDate,
    lastWorkingDate,
    returnFromExtendedLeave,
  ] = watch([
    'contractEndDate',
    'employmentEndDate',
    'lastWorkingDate',
    'returnFromExtendedLeave',
  ]);

  return (
    <div className='border-b py-6'>
      <CardTitle>Contract / Job Info</CardTitle>

      <div className='flex flex-col space-y-6 '>
        <NotificationItem
          label='Contract End Date'
          name='contractEndDate'
          value={contractEndDate}
        />

        <NotificationItem
          label='Termination Date'
          name='employmentEndDate'
          value={employmentEndDate}
        />

        <NotificationItem
          label='Last Working Date'
          name='lastWorkingDate'
          value={lastWorkingDate}
        />

        <NotificationItem
          label='Extended Leave'
          name='returnFromExtendedLeave'
          value={returnFromExtendedLeave}
        />
      </div>
    </div>
  );
};
