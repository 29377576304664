import { useState } from 'react';
import { ApolloProvider } from '@apollo/client';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';

import './styles/globals.css';

import { RouterProvider } from 'react-router-dom';

import { AnyUIProvider } from '@any-ui-react/core';
import { ModalsProvider } from '@any-ui-react/modals';
import { AuthContext } from './contexts/AuthContext';
import { getAuthUserInSession } from './utils';
import { Toaster } from './components/shared/toaster';
import { apolloClient, getRouter } from './providers';

function App() {
  const documentTitle = 'AnyMind MED';
  const [authUser, setAuthUser] = useState(getAuthUserInSession());

  return (
    <HelmetProvider>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <AnyUIProvider
        theme={{
          fontFamily:
            '"Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
        }}
        label={{
          noOptions: 'No Option',
        }}
      >
        <ApolloProvider client={apolloClient}>
          <AuthContext.Provider value={{ authUser, setAuthUser }}>
            <ModalsProvider>
              <Toaster />
              <RouterProvider router={getRouter({ user: authUser })} />
            </ModalsProvider>
          </AuthContext.Provider>
        </ApolloProvider>
      </AnyUIProvider>
    </HelmetProvider>
  );
}

export default App;
