import { ContractType, EmployeeHiringSource } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeGeneralInformationFormData = z.object({
  preferredFullName: z.string().nullable().default(null),
  legalFullName: z.string().nullable().default(null),
  nameInLocalLanguage: z.string().nullable().default(null),
  nationalityId: z.string().nullable().default(null),
  primaryManagerId: z.string().nullable().default(null),
  dottedLineManagerId: z.string().nullable().default(null),
  hiredDate: z.string().nullable().default(null),
  contractEndDate: z.string().nullable().default(null),
  needsProbationReview: z.boolean().default(false),
  companyEmail: z.string().nullable().default(null),
  emailUsername: z.string().nullable().default(null),
  emailDomain: z.string().nullable().default(null),
  hiringSource: z.nativeEnum(EmployeeHiringSource).nullable().default(null),
  greenhouseLink: z.string().nullable().default(null),
  isNewGraduate: z.boolean().default(false),
  contractType: z.nativeEnum(ContractType).nullable().default(null),
  companyId: z.string().nullable().default(null),
  employeeCode: z.string().nullable().default(null),
});
export type EmployeeGeneralInformationFormDataType = z.infer<
  typeof EmployeeGeneralInformationFormData
>;
