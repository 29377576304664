import {
  CalendarDate,
  CalendarDateTime,
  toCalendarDateTime,
  ZonedDateTime,
} from '@any-ui-react/dates';
import { utcToZonedTime } from 'date-fns-tz';

import { TimezoneUtils } from './timezone.utils';
import { DateUtils } from './date.utils';

export interface Time {
  hour: number;
  minute: number;
  second?: number;
  millisecond?: number;
}

export interface TimeRange {
  from?: Time;
  to?: Time;
}

export class TimeUtils {
  static readonly MILLISECONDS = 1000;
  static readonly SECONDS = 60;
  static readonly MINUTES = 60;
  static readonly HOURS = 24;

  static formatDuration(duration: number | null) {
    return duration !== null ? TimeUtils.formatNumberToMss(duration) : '';
  }

  static formatNumberToMss(duration: number) {
    const minutes = Math.floor(duration / TimeUtils.MINUTES);
    const seconds = (duration - minutes * TimeUtils.MINUTES)
      .toString()
      .padStart(2, '0');

    return `${minutes}:${seconds}`;
  }

  static getHourMinuteFromString(time: string): Time {
    const splitTime = (time || '').split(':');
    return {
      hour: Number((splitTime[0] || '').trim()),
      minute: Number((splitTime[1] || '').trim()),
    };
  }

  static getStringFromRange(time: Time): string {
    const formattedHours = time.hour.toString().padStart(2, '0');
    const formattedMinutes = time.minute.toString().padStart(2, '0');
    return [formattedHours, formattedMinutes].join(':');
  }

  static secondsFromMilliseconds(
    milliseconds: number | string,
    decimals?: number
  ): number {
    const result = +milliseconds / TimeUtils.MILLISECONDS;
    if (decimals !== undefined) {
      return +result.toFixed(decimals);
    }

    return result;
  }

  static toISOStringWithTimezone = (
    date: Date,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    const zonedTime = utcToZonedTime(date, timezone);
    const utc = DateUtils.formatDateInTimeZone(zonedTime, {
      timeZone: timezone,
      formatStr: 'XXX',
    });
    try {
      const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
      return (
        zonedTime.getFullYear() +
        '-' +
        pad(zonedTime.getMonth() + 1) +
        '-' +
        pad(zonedTime.getDate()) +
        'T' +
        pad(zonedTime.getHours()) +
        ':' +
        pad(zonedTime.getMinutes()) +
        ':' +
        pad(zonedTime.getSeconds()) +
        utc
      );
    } catch (e) {
      throw new Error('toISOStringWithTimezone could not parse date');
    }
  };

  static toISOStringWithoutTimezone = (
    date: Date,
    timezone: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    const zonedTime = utcToZonedTime(date, timezone);
    try {
      const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
      return (
        zonedTime.getFullYear() +
        '-' +
        pad(zonedTime.getMonth() + 1) +
        '-' +
        pad(zonedTime.getDate())
      );
    } catch (e) {
      throw new Error('toISOStringWithoutTimezone could not parse date');
    }
  };

  static toISOStringStartOfDay = (
    date: CalendarDate | CalendarDateTime | ZonedDateTime,
    tz: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    try {
      return toCalendarDateTime(date)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toDate(tz)
        .toISOString();
    } catch (e) {
      throw new Error('toISOStringStartOfDay couldnt parse date');
    }
  };

  static toISOStringEndOfDay = (
    date: CalendarDate | CalendarDateTime | ZonedDateTime,
    tz: string = TimezoneUtils.getCurrentTimezone()
  ) => {
    try {
      return toCalendarDateTime(date)
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .toDate(tz)
        .toISOString();
    } catch (e) {
      throw new Error('toISOStringEndOfDay couldnt parse date');
    }
  };

  static generateTimeRangeOptions = () => {
    // Time ranges multiples of 30 minutes
    return Array.from({ length: 24 }, (_, hours) => {
      return Array.from({ length: 2 }, (_, index) => {
        const minutes = index * 30;
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const label = `${formattedHours}:${formattedMinutes}`;
        const value = hours * 60 + minutes;
        return { label, value };
      });
    }).flat();
  };

  static getMinutesValueFromTime = (time: Time): number => {
    return time.hour * 60 + time.minute;
  };

  static getTimeFromValue = (value: number): Time => {
    return { hour: Math.floor(value / 60), minute: value % 60 };
  };
}
