import { Table } from '@/shared/components';
import { useMemo } from 'react';
import { useReportNumberOfEmployeeCompanyQuery } from 'src/graphql/query';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';

type ClientReportNumberOfEmployeeCompany = {
  companyId: string;
  total: number;
  yearMonth: string;
  company: {
    name: string;
  };
};

export const NumberOfEmployeesCompanyTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeCompanyQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = data?.reportNumberOfEmployeeCompany.yearMonthList;
  const companies = useMemo(
    () =>
      data?.reportNumberOfEmployeeCompany.items.map(
        (item) => item.company.name
      ),
    [data?.reportNumberOfEmployeeCompany.items]
  );

  const tableData: ClientReportNumberOfEmployeeCompany[] = useMemo(
    () =>
      ([...new Set(companies)] || []).map((company: string) => ({
        company: {
          name: company,
        },
        ...Object.fromEntries(
          (yearMonths || []).map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeCompany.items.find(
              (item) =>
                item.yearMonth === yearMonth && item.company.name === company
            )?.total ?? 0,
          ])
        ),
      })),
    [companies, data?.reportNumberOfEmployeeCompany.items, yearMonths]
  );

  const columns = useMemo(
    () => [
      {
        headerRender: () => null,
        accessor: 'company',
        cellRender: (row: ClientReportNumberOfEmployeeCompany) => (
          <span>{row.company.name}</span>
        ),
      },
      ...(yearMonths || []).map((yearMonth) => ({
        headerRender: () => yearMonth,
        accessor: yearMonth,
        cellRender: (row: ClientReportNumberOfEmployeeCompany) => (
          <div className='w-full text-right'>
            {row[
              yearMonth as keyof ClientReportNumberOfEmployeeCompany
            ].toString()}
          </div>
        ),
      })),
    ],
    [yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeCompany>
        layout={columns || []}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
