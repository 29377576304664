import {
  Bank,
  CompanyAsset,
  ConsultingFee,
  Dependent,
  EmergencyContacts,
  IdentificationDetails,
  Passport,
  PersonalInformationFormFields,
  SocialInsurance,
  Spinner,
  Visa,
} from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { GetEmployeeDocument } from '@/shared/graphql';
import {
  useCompanyAssetPersistence,
  useDependentPersistence,
  useEmergencyContactPersistence,
  useEmployeeAccess,
  useUpdateEmployeePersonalInformation,
  useVisaPersistence,
} from '@/shared/hooks';
import { apolloClient } from '@/shared/providers';
import {
  EmployeeCompanyAssetType,
  EmployeePersonalInformationFormData,
  EmployeePersonalInformationFormDataType,
} from '@/shared/types';
import { Button } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { personalInformationFormValidationSuite } from './personal-information-form.validator';
import { PersonalInformationDetails } from './PersonalInformationDetails';

export const PersonalInformation = ({
  onFormStateChange,
}: {
  onFormStateChange: (isDirty: boolean) => void;
}) => {
  const { hasPersonalInformationEditAccess, loading } = useEmployeeAccess();
  const { employeeDetail: employee } = useEmployeeDetailContext();
  const form = useForm<EmployeePersonalInformationFormDataType>({
    defaultValues: EmployeePersonalInformationFormData.parse(
      employee.personalInformation
    ),
    resolver: vestResolver(personalInformationFormValidationSuite),
  });

  const { handleUpdateEmployeePersonalInformation } =
    useUpdateEmployeePersonalInformation({
      onCompleted: () => {
        form.reset(
          { ...form.getValues() },
          {
            keepValues: false,
            keepDirty: false,
            keepDefaultValues: false,
          }
        );
        apolloClient.refetchQueries({
          include: [GetEmployeeDocument],
        });
      },
    });

  useEffect(() => {
    onFormStateChange(form.formState.isDirty);
  }, [form.formState.isDirty, onFormStateChange]);

  useEffect(() => {
    form.reset(
      EmployeePersonalInformationFormData.parse(employee.personalInformation)
    );
  }, [employee, form]);

  const { onCompanyAssetCreate, onCompanyAssetUpdate, onCompanyAssetRemove } =
    useCompanyAssetPersistence({
      employeeId: employee.id,
      companyAssets: form.getValues('companyAsset'),
      onCompanyAssetsChange: (data: EmployeeCompanyAssetType[]) =>
        form.setValue('companyAsset', data),
    });

  const { onVisaCreate, onVisaUpdate, onVisaRemove } = useVisaPersistence({
    employeeId: employee.id,
    visas: form.getValues('visas'),
    onVisasChange: (data) => form.setValue('visas', data),
  });

  const { onDependentCreate, onDependentUpdate, onDependentRemove } =
    useDependentPersistence({
      employeeId: employee.id,
      dependents: form.getValues('dependents'),
      onDependentsChange: (data) => form.setValue('dependents', data),
    });

  const {
    onEmergencyContactCreate,
    onEmergencyContactUpdate,
    onEmergencyContactRemove,
  } = useEmergencyContactPersistence({
    employeeId: employee.id,
    emergencyContacts: form.getValues('emergencyContacts'),
    onEmergencyContactsChange: (data) =>
      form.setValue('emergencyContacts', data),
  });

  if (loading) return <Spinner className='my-52' />;

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit((formData) => {
          handleUpdateEmployeePersonalInformation(formData, employee.id);
        })}
      >
        <div className='p-6'>
          {hasPersonalInformationEditAccess ? (
            <PersonalInformationFormFields />
          ) : (
            <PersonalInformationDetails employee={employee} />
          )}
          <EmergencyContacts
            onCreate={onEmergencyContactCreate}
            onUpdate={onEmergencyContactUpdate}
            onRemove={onEmergencyContactRemove}
          />
          <IdentificationDetails />
          <Passport />
          <Visa
            onCreate={onVisaCreate}
            onUpdate={onVisaUpdate}
            onRemove={onVisaRemove}
          />
          <SocialInsurance />
          <Dependent
            onCreate={onDependentCreate}
            onUpdate={onDependentUpdate}
            onRemove={onDependentRemove}
          />
          <Bank />
          <ConsultingFee />
          <CompanyAsset
            onCreate={onCompanyAssetCreate}
            onUpdate={onCompanyAssetUpdate}
            onRemove={onCompanyAssetRemove}
          />
        </div>
        <hr />
        {hasPersonalInformationEditAccess && (
          <div className='flex px-6 pb-6 pt-4'>
            <Button className='ml-auto bg-blue-6' type='submit'>
              Save
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
