import { CardFooter, PageTitle, Spinner } from '@/shared/components';
import { useNotificationSettingQuery } from '@/shared/graphql';
import {
  useEmployeeAccess,
  useFilters,
  useUpdateNotificationSetting,
} from '@/shared/hooks';
import {
  CountryFilterType,
  CountryParser,
  SettingNotificationData,
  SettingNotificationType,
} from '@/shared/types';
import { CountryCode } from '@/shared/utils';
import { Button, Card } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ContractJobInfo } from '../contract-job-info';
import { NotificationCountrySelect } from '../notification-item';
import { PersonalInfo } from '../personal-info';
import { Probation } from '../probation';
import { ServiceCompletion } from '../service-completion';
import {
  defaultValues,
  notificationSettingFormValidationSuite,
} from './notification-setting-form.validator';

export const NotificationSettingForm = () => {
  const { updateNotificationSetting } = useUpdateNotificationSetting();
  const { currentContractCountryId } = useEmployeeAccess();

  const form = useForm<SettingNotificationType>({
    defaultValues: {
      ...defaultValues,
      countryId: currentContractCountryId ?? '',
    },
    resolver: vestResolver(notificationSettingFormValidationSuite),
  });

  const filters = useFilters<CountryFilterType>(
    {
      countryId: currentContractCountryId ?? '',
    },
    {
      parser: CountryParser.parse,
    }
  );

  const countryId = useMemo(
    () =>
      filters.current.countryId?.length
        ? filters.current.countryId
        : currentContractCountryId ?? '',
    [currentContractCountryId, filters]
  );

  const { loading } = useNotificationSettingQuery({
    skip: !countryId,
    variables: { countryId },
    onCompleted: (data) => {
      if (data?.notificationSetting === null) {
        form.reset(defaultValues);
        filters.changeFilter('countryId', currentContractCountryId);
        return;
      }

      const nextCountryId =
        filters.current.countryId ??
        data?.notificationSetting?.countryId ??
        currentContractCountryId;
      const isJP = nextCountryId === CountryCode.JP;

      filters.changeFilter('countryId', nextCountryId);

      if (data?.notificationSetting) {
        form.reset(
          SettingNotificationData.parse({
            countryId: nextCountryId,
            contractEndDate: data?.notificationSetting?.contractEndDate,
            employmentEndDate: data?.notificationSetting?.employmentEndDate,
            lastWorkingDate: data?.notificationSetting?.lastWorkingDate,
            returnFromExtendedLeave:
              data?.notificationSetting?.returnFromExtendedLeave,
            probationReviewScheduledEndDate:
              data?.notificationSetting?.probationReviewScheduledEndDate,
            probationReviewDeadlineForEach:
              data?.notificationSetting?.probationReviewDeadlineForEach,
            probationReviewNeedMoreConsideration:
              data?.notificationSetting?.probationReviewNeedMoreConsideration,

            idExpiryDate: data?.notificationSetting?.idExpiryDate,
            passportExpiryDate: data?.notificationSetting?.passportExpiryDate,
            visaExpiryDate: data?.notificationSetting?.visaExpiryDate,
            workPermitExpiryDate:
              data?.notificationSetting?.workPermitExpiryDate,
            ...(isJP
              ? {
                  sixMonthServiceCompletion: data?.notificationSetting
                    ?.sixMonthServiceCompletion ?? {
                    enabled: false,
                    notifiedDates: [],
                    notifiedUsers: [],
                  },
                }
              : undefined),
          })
        );
      }
    },
  });

  const onSubmit = async (data: SettingNotificationType) => {
    const isJP = filters.current.countryId === CountryCode.JP;
    const { sixMonthServiceCompletion: _, ...rest } = data;
    await updateNotificationSetting({
      data: isJP ? data : rest,
    });
  };

  if (loading) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <FormProvider {...form}>
      <PageTitle
        text='Notification'
        aside={() => <NotificationCountrySelect filters={filters} />}
      />
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <ContractJobInfo />
          <Probation />
          <PersonalInfo />
          {filters.current.countryId === CountryCode.JP && (
            <ServiceCompletion />
          )}
          <CardFooter>
            <Button
              className='ml-auto bg-blue-6'
              type='submit'
              loading={form.formState.isSubmitting}
            >
              Save
            </Button>
          </CardFooter>
        </Card>
      </form>
    </FormProvider>
  );
};
