import { z } from 'zod';

export const EmployeeJobInformationFormData = z.object({
  level: z.string().nullable().default(null),
  countryId: z.string().nullable().default(null),
  locationId: z.string().nullable().default(null),
  businessUnitId: z.string().nullable().default(null),
  departmentId: z.string().nullable().default(null),
  team: z.string().nullable().default(null),
  jobId: z.string().nullable().default(null),
  jobTitle: z.string().nullable().default(null),
  regionalRole: z.boolean().default(false),
});
export type EmployeeJobInformationFormDataType = z.infer<
  typeof EmployeeJobInformationFormData
>;
