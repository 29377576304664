import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeGeneralInformationMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeGeneralInformationInput;
}>;


export type UpdateEmployeeGeneralInformationMutation = { __typename?: 'Mutation', updateEmployeeGeneralInformation: { __typename?: 'EmployeeOutput', id: string } };


export const UpdateEmployeeGeneralInformationDocument = gql`
    mutation updateEmployeeGeneralInformation($input: UpdateEmployeeGeneralInformationInput!) {
  updateEmployeeGeneralInformation(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeGeneralInformationMutationFn = Apollo.MutationFunction<UpdateEmployeeGeneralInformationMutation, UpdateEmployeeGeneralInformationMutationVariables>;

/**
 * __useUpdateEmployeeGeneralInformationMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeGeneralInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeGeneralInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeGeneralInformationMutation, { data, loading, error }] = useUpdateEmployeeGeneralInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeGeneralInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeGeneralInformationMutation, UpdateEmployeeGeneralInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeGeneralInformationMutation, UpdateEmployeeGeneralInformationMutationVariables>(UpdateEmployeeGeneralInformationDocument, options);
      }
export type UpdateEmployeeGeneralInformationMutationHookResult = ReturnType<typeof useUpdateEmployeeGeneralInformationMutation>;
export type UpdateEmployeeGeneralInformationMutationResult = Apollo.MutationResult<UpdateEmployeeGeneralInformationMutation>;
export type UpdateEmployeeGeneralInformationMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeGeneralInformationMutation, UpdateEmployeeGeneralInformationMutationVariables>;