import { EmployeeMaritalStatusRadioGroup } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeePersonalInformationFormDataType } from '@/shared/types';
import { TextInput, Textarea } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';

export const SocialInsurance = () => {
  const { register } =
    useFormContext<EmployeePersonalInformationFormDataType>();

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationEditAccess } = useEmployeeAccess();
  const isEditable = hasPersonalInformationEditAccess || isNewRecord;

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <TextInput
          {...register('taxCode')}
          disabled={!isEditable}
          placeholder='Enter Tax Code'
          label='Social Insurance Type'
        />
        <TextInput
          {...register('socialInsuranceNumber')}
          disabled={!isEditable}
          placeholder='Enter Social Insurance Number'
          label='Social Insurance Number'
        />
        <TextInput
          {...register('pensionVoluntaryContributionRate')}
          disabled={!isEditable}
          placeholder='Enter pension voluntary contribution rate'
          label='Pension voluntary contribution rate'
        />
        <TextInput
          {...register('taxWithholdingRate')}
          disabled={!isEditable}
          placeholder='Enter Tax withholding rate'
          label='Tax withholding rate'
        />

        <Textarea
          disabled={!isEditable}
          {...register('socialInsuranceNotes')}
          className='col-span-2 col-start-1'
          minRows={5}
          placeholder='Enter Notes'
          label='Notes'
        />

        <EmployeeMaritalStatusRadioGroup />
      </fieldset>
    </div>
  );
};
