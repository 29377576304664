import { useMemo } from 'react';

import { ReasonForLeavingType, TableSelection } from '@/shared/types';
import { formatDatetime } from '@/shared/utils';
import { GreenCheckIcon, RedCrossIcon } from '../../../shared/icons';
import { NoResult } from '../../../shared/no-result';
import { Table, TableLayout, TableProps } from '../../../shared/table';
import { ReasonForLeavingModal } from '../reason-for-leaving-modal';

interface ReasonForLeavingTableProps {
  loading: boolean;
  items: ReasonForLeavingType[];
  selection: TableSelection<ReasonForLeavingType>;
  setSelection: (selection: TableSelection<ReasonForLeavingType>) => void;
  total: number;
}

export const ReasonForLeavingTable = ({
  items,
  loading,
  total,
  selection,
}: ReasonForLeavingTableProps) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (reasonForLeaving: ReasonForLeavingType) => {
            return (
              <ReasonForLeavingModal reasonForLeaving={reasonForLeaving}>
                <span className='text-blue-6 hover:cursor-pointer'>
                  {reasonForLeaving.name}
                </span>
              </ReasonForLeavingModal>
            );
          },
        },
        {
          headerRender: () => 'Enabled',
          accessor: 'enabled',
          cellRender: ({ enabled }: ReasonForLeavingType) => {
            return (
              <span>{enabled ? <GreenCheckIcon /> : <RedCrossIcon />}</span>
            );
          },
        },
        {
          headerRender: () => 'Created Date',
          accessor: 'createdDate',
          cellRender: ({ createdAt }: ReasonForLeavingType) => {
            return <span>{formatDatetime(createdAt)}</span>;
          },
        },
        {
          headerRender: () => 'Updated Date',
          accessor: 'updatedAt',
          cellRender: ({ updatedAt }: ReasonForLeavingType) => {
            return <span>{formatDatetime(updatedAt)}</span>;
          },
        },
      ] as TableLayout<ReasonForLeavingType>[],
    []
  );

  const tableCommonProps: TableProps<ReasonForLeavingType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Business Unit Item Title'}
        description={'There are no Business Unit Item Desc'}
      />
    ),
  };

  return (
    <>
      <Table<ReasonForLeavingType>
        {...tableCommonProps}
        selection={selection}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
