import { Spinner } from '@/shared/components';
import { useEmployeeAccess } from '@/shared/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoCountryModal, NotificationSettingForm } from './components';

export const NotificationSetting = () => {
  const navigate = useNavigate();

  const {
    hasNotificationPageAccess,
    currentContractCountryId,
    loading: employeeLoading,
  } = useEmployeeAccess();

  useEffect(() => {
    if (employeeLoading) {
      return;
    }

    if (!hasNotificationPageAccess) {
      navigate('/404');
    }
  }, [employeeLoading, hasNotificationPageAccess, navigate]);

  if (!hasNotificationPageAccess) {
    return null;
  }

  if (employeeLoading) {
    return <Spinner />;
  }

  if (!currentContractCountryId) {
    return <NoCountryModal />;
  }

  return <NotificationSettingForm />;
};
