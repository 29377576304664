import { toast } from '@/shared/components';
import { ListEmployeeListCustomFiltersDocument } from '@/shared/graphql';
import { FilterProps } from '@/shared/hooks';
import { EmployeeListFilterType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { SaveCustomFilterForm } from './SaveCustomFilterForm';

export const SaveCustomFilterUnitModal = ({
  children,
  filters,
  className,
}: {
  children: React.ReactNode;
  filters: FilterProps<EmployeeListFilterType>;
  className?: string;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const onClick = () => {
    openModal({
      modalId,
      title: `Save current filter`,
      size: 'lg',
      children: (
        <SaveCustomFilterForm
          filters={filters}
          onSuccess={async () => {
            toast.success();
            client.refetchQueries({
              include: [ListEmployeeListCustomFiltersDocument],
            });

            closeModal(modalId);
          }}
          onFailure={() => toast.error()}
          onCancel={() => closeModal(modalId)}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
