import { EmployeeGender } from '@/shared/graphql';
import { StringUtils } from '@/shared/utils';
import { Select, SelectProps } from '@any-ui-react/core';

interface GenderSelectProps extends Omit<SelectProps, 'data'> {}

export const GenderSelect = ({ ...props }: GenderSelectProps) => {
  const types = Object.values(EmployeeGender).map((value) => ({
    label: StringUtils.capitalizeAll(value, '_'),
    value: value,
  }));

  return <Select data={types} {...props} />;
};
