import {
  EmployeeGender,
  EmployeeIdType,
  EmployeeMaritalStatus,
} from '@/shared/graphql';
import { CountryCode } from '@/shared/utils';
import { z } from 'zod';
import { EmergencyContact } from './emergency-contact';
import { EmployeeCompanyAsset } from './employee-company-asset';
import { EmployeeDependent } from './employee-dependent';
import { EmployeeVisa } from './employee-visa';

export const EmployeePersonalInformation = z.object({
  id: z.string().default(''),
  gender: z.nativeEnum(EmployeeGender).nullable().default(null),
  dateOfBirth: z.string().default(''),
  personalPhoneNumber: z.string().nullable().default(''),
  personalPhoneNumberCountryCode: z
    .nativeEnum(CountryCode)
    .nullable()
    .default(CountryCode.JP),
  personalEmail: z.string().nullable().default(null),
  currentAddress: z.string().nullable().default(null),
  permanentAddress: z.string().nullable().default(null),
  maritalStatus: z.nativeEnum(EmployeeMaritalStatus).nullable().default(null),
  emergencyContacts: z.array(EmergencyContact).default([]).catch([]),
  visas: z.array(EmployeeVisa).default([]).catch([]),
  dependents: z.array(EmployeeDependent).default([]).catch([]),
  companyAsset: z.array(EmployeeCompanyAsset).default([]).catch([]),
  residentialCountryId: z.string().default(''),
  // ID
  idType: z.nativeEnum(EmployeeIdType).nullable().default(null),
  idNumber: z.string().nullable().default(null),
  idIssuedDate: z.string().nullable().default(null),
  idExpiryDate: z.string().nullable().default(null),
  // Passport
  passportNumber: z.string().nullable().default(null),
  passportName: z.string().nullable().default(null),
  passportIssuedPlace: z.string().nullable().default(null),
  passportIssuedDate: z.string().nullable().default(null),
  passportExpiryDate: z.string().nullable().default(null),
  placeOfBirth: z.string().nullable().default(null),
  // Social Insurance
  taxCode: z.string().nullable().default(null),
  socialInsuranceNumber: z.string().nullable().default(null),
  pensionVoluntaryContributionRate: z.string().nullable().default(null),
  taxWithholdingRate: z.string().nullable().default(null),
  nhiDependents: z.number().nullable().default(null),
  socialInsuranceNotes: z.string().nullable().default(null),
  // Bank
  bankCountryCode: z.string().nullable().default(null),
  bankName: z.string().nullable().default(null),
  branchName: z.string().nullable().default(null),
  accountNumber: z.string().nullable().default(null),
  accountHolderName: z.string().nullable().default(null),
  // Consulting Fee
  consultingFeeBankCurrencyId: z.string().nullable().default(null),
  consultingFeeBankCountryCode: z.string().nullable().default(null),
  consultingFeeBankCountry: z.string().nullable().default(null),
  consultingFeeBankName: z.string().nullable().default(null),
  consultingFeeBranchName: z.string().nullable().default(null),
  consultingFeeAccountNumber: z.string().nullable().default(null),
  consultingFeeAccountHolderName: z.string().nullable().default(null),
});
export type EmployeePersonalInformationType = z.infer<
  typeof EmployeePersonalInformation
>;
