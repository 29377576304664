import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListJobsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  enabled: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ListJobsQuery = { __typename?: 'Query', listJobs: { __typename?: 'ListJobs', totalCount: number, items: Array<{ __typename?: 'Job', id: string, name: string, enabled: boolean, createdAt: any, updatedAt: any }> } };


export const ListJobsDocument = gql`
    query listJobs($pageNumber: Int!, $pageSize: Int!, $enabled: Boolean) {
  listJobs(pageNumber: $pageNumber, pageSize: $pageSize, enabled: $enabled) {
    items {
      id
      name
      enabled
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useListJobsQuery__
 *
 * To run a query within a React component, call `useListJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useListJobsQuery(baseOptions: Apollo.QueryHookOptions<ListJobsQuery, ListJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListJobsQuery, ListJobsQueryVariables>(ListJobsDocument, options);
      }
export function useListJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListJobsQuery, ListJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListJobsQuery, ListJobsQueryVariables>(ListJobsDocument, options);
        }
export function useListJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListJobsQuery, ListJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListJobsQuery, ListJobsQueryVariables>(ListJobsDocument, options);
        }
export type ListJobsQueryHookResult = ReturnType<typeof useListJobsQuery>;
export type ListJobsLazyQueryHookResult = ReturnType<typeof useListJobsLazyQuery>;
export type ListJobsSuspenseQueryHookResult = ReturnType<typeof useListJobsSuspenseQuery>;
export type ListJobsQueryResult = Apollo.QueryResult<ListJobsQuery, ListJobsQueryVariables>;