import { ContractType } from '@/shared/graphql';
import { z } from 'zod';

export const EmployeeContractHistory = z.object({
  id: z.string().default(''),
  effectiveDateStart: z.string().nullable().default(null),
  effectiveDateEnd: z.string().nullable().default(null),
  companyId: z.string().nullable().default(null),
  companyName: z.string().nullable().default(null),
  type: z.nativeEnum(ContractType).nullable().default(null),
  employeeCode: z.string().nullable().default(null),
  isOnSecondment: z.boolean().default(false),
  isMain: z.boolean().default(true),
});
export type EmployeeContractHistoryType = z.infer<
  typeof EmployeeContractHistory
>;
