import { createContext, useContext } from 'react';
import { AuthUser, AuthUserType } from '../types/auth/AuthUser';

export const AuthContextInitialState = AuthUser.parse({});

export type AuthContextType = {
  authUser: AuthUserType;
  setAuthUser: (authUser: AuthUserType) => void;
};

export const AuthContext = createContext<AuthContextType>({
  authUser: AuthContextInitialState,
  setAuthUser: () => console.warn('no Auth provider'),
});

export const useAuthContext = (): AuthContextType => useContext(AuthContext);
