import { create, enforce, test } from 'vest';

export interface LocationFormData {
  name: string;
  enabled: boolean;
  countryId: string | null;
}

export const locationFormValidationSuite = create(
  ({ name }: LocationFormData) => {
    test('name', 'Name is required', () => {
      enforce(name).isNotEmpty();
    });
  }
);
