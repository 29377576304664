import { EmployeeDependentType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { DependentForm } from './DependentForm';
export const DependentModal = ({
  children,
  onSuccess,
  dependent,
  className,
  isNewRecord = false,
}: {
  children: React.ReactNode;
  onSuccess: (data: EmployeeDependentType) => void;
  dependent?: EmployeeDependentType;
  className?: string;
  isNewRecord?: boolean;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${!isNewRecord ? 'Update' : 'Add New'} Dependent Information`,
      size: 'lg',
      children: (
        <DependentForm
          dependent={dependent}
          onSuccess={(data) => {
            onSuccess(data);
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
