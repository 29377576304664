import { z } from 'zod';

export const ReasonForLeaving = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  enabled: z.boolean().default(false),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
});
export type ReasonForLeavingType = z.infer<typeof ReasonForLeaving>;
