import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import { TableContainerHandlers } from '@any-ui-react/table';
import { useResizeObserver } from '@mantine/hooks';

/*
  Add new table and tHead that fixed on the top when showFloatingHeader === true
  1. Set new table width same with origin one
  2. Set th width same with th width from original one
  3. Trigger scroll horizontal from a table will trigger scroll to another
*/
export const useFloatingHeader = () => {
  const [showFloatingHeader, setShowFloatingHeader] = useState(false);
  const [floatingContainerWidth, setFloatingContainerWidth] = useState(0);
  const [tableRef, rect] = useResizeObserver<HTMLTableElement>();
  const floatingHeaderRef = useRef<HTMLTableElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const floatingContainerRef = useRef<HTMLDivElement>(null);
  const tableContainerRef = useRef<TableContainerHandlers>(null);
  const [thWidths, setThWidths] = useState<number[]>([]);

  const tdHeight = useMemo(() => {
    const value = 0;
    if (tableRef.current) {
      return (
        tableRef.current.childNodes[1]?.childNodes[0]
          ?.childNodes[0] as HTMLElement
      ).getBoundingClientRect().height;
    }
    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef.current]);

  // Toggle on of Floating header
  const toggleFloatingHeader = useCallback(
    ({ top, bottom }: { top: number; bottom: number }) => {
      // When scrolling from top to bottom and
      // the last 2td row is visible enough, floating header will be triggered.
      setShowFloatingHeader(top <= 0 && bottom > tdHeight * 2);

      // Set floating table header width same as the container one
      if (scrollContainerRef.current) {
        setFloatingContainerWidth(scrollContainerRef.current.offsetWidth);
      }
    },
    [tdHeight]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        toggleFloatingHeader(tableRef.current.getBoundingClientRect());
      }
      // Sync scroll horizontal
      if (scrollContainerRef.current && floatingHeaderRef.current) {
        floatingHeaderRef.current.scrollLeft =
          scrollContainerRef.current.scrollLeft;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [tableRef, toggleFloatingHeader]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      setFloatingContainerWidth(scrollContainerRef.current.offsetWidth);
    }
    const widths = Array.from(
      tableRef.current.querySelector('thead tr')?.childNodes ?? []
    ).map((th) => (th as HTMLElement).getBoundingClientRect().width);
    setThWidths(widths);
  }, [rect, tableRef]);

  let ignoreScrollEvents = false;
  const synScroll = (
    e: React.UIEvent<HTMLElement>,
    targetRef: React.RefObject<HTMLElement>
  ) => {
    const ignore = ignoreScrollEvents;
    ignoreScrollEvents = false;
    if (ignore) {
      return;
    }
    if (targetRef.current) {
      ignoreScrollEvents = true;
      targetRef.current.scrollLeft = (e.target as HTMLElement).scrollLeft;
    }
    if (tableContainerRef.current) {
      // This will trigger sticky column to show shadow
      tableContainerRef.current.handleScroll(e);
    }
  };

  const thProps = (index: number) => ({
    style: {
      minWidth: `${thWidths[index]}px`,
      maxWidth: `${thWidths[index]}px`,
    },
  });

  const floatingContainerRefProps = {
    ref: floatingContainerRef,
    style: {
      width: `${floatingContainerWidth}px`,
    },
  };

  const floatingHeaderRefProps = {
    ref: floatingHeaderRef,
    onScroll: (e: React.UIEvent<HTMLElement>) =>
      synScroll(e, scrollContainerRef),
    style: {
      borderCollapse: 'collapse',
      display: 'block',
      overflowX: 'auto',
      scrollbarWidth: 'none',
      width: '100%',
    } as React.CSSProperties,
  };

  const scrollContainerRefProps = {
    ref: scrollContainerRef,
    onScroll: (e: React.UIEvent<HTMLElement>) =>
      synScroll(e, floatingHeaderRef),
  };

  return {
    floatingContainerRefProps,
    floatingHeaderRefProps,
    scrollContainerRefProps,
    tableContainerRef,
    tableRef,
    showFloatingHeader,
    thProps,
  };
};
