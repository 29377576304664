import { CountryLazySelect, DEFAULT_PAGE_SIZE } from '@/shared/components';
import { FilterProps, useEmployeeAccess } from '@/shared/hooks';
import { CountryFilterType, SettingNotificationType } from '@/shared/types';
import { useFormContext } from 'react-hook-form';
import { notificationSettingFormValidationSuite } from '../notification-setting-form';

interface Props {
  filters: FilterProps<CountryFilterType>;
}

export const NotificationCountrySelect = ({ filters }: Props) => {
  const { hasNotificationCountryAccess } = useEmployeeAccess();
  const { setValue, clearErrors } = useFormContext<SettingNotificationType>();

  if (!hasNotificationCountryAccess) {
    return null;
  }

  return (
    <CountryLazySelect
      isClearable={false}
      isMulti={false}
      className='select-has-min-w-menu w-60'
      defaultQueryOptions={{
        variables: {
          keyword: null,
          pageNumber: 1,
          pageSize: DEFAULT_PAGE_SIZE,
          hasCompanies: true,
        },
      }}
      hasCompanies
      value={filters.current.countryId}
      placeholder='Select Country'
      onChange={(option) => {
        if (option) {
          filters.changeFilter('countryId', option.value);
          setValue('countryId', option.value);
          clearErrors();
          notificationSettingFormValidationSuite.reset();
        }
      }}
    />
  );
};
