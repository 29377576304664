import {
  EditPenIcon,
  EMPTY_DEFAULT,
  GreenCheckIcon,
  NoResult,
  Table,
  TableLayout,
  TableProps,
  TrashIcon,
  useDeleteRecordModal,
} from '@/shared/components';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeeContractHistoryType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';
import { useMemo } from 'react';
import { ContractHistoryModal } from '../contract-history-modal';

interface ContractHistoryTableProps {
  loading: boolean;
  items: EmployeeContractHistoryType[];
  onRemove: (id: string) => void;
  onUpdate: (data: EmployeeContractHistoryType) => Promise<boolean>;
}

export const ContractHistoryTable = ({
  items,
  loading,
  onRemove,
  onUpdate,
}: ContractHistoryTableProps) => {
  const { openDeleteRecordModal } = useDeleteRecordModal();

  const { hasJobContractHistoryEditAccess, hasJobContractHistoryDeleteAccess } =
    useEmployeeAccess();

  const layout = useMemo(
    () =>
      [
        ...(hasJobContractHistoryEditAccess
          ? [
              {
                headerRender: () => '',
                accessor: 'id',
                cellRender: (item: EmployeeContractHistoryType) => {
                  return (
                    <div className='flex'>
                      <ContractHistoryModal
                        className='ml-auto'
                        jobHistory={item}
                        onSuccess={onUpdate}
                      >
                        <EditPenIcon className='h-4 w-4 text-gray-7' />
                      </ContractHistoryModal>

                      {hasJobContractHistoryDeleteAccess && (
                        <TrashIcon
                          onClick={() => {
                            openDeleteRecordModal({
                              onSuccess: () => onRemove(item.id),
                            });
                          }}
                          className='ml-3 h-4 w-4 text-gray-7'
                        />
                      )}
                    </div>
                  );
                },
              },
            ]
          : []),
        {
          headerRender: () => 'Contract Start Date',
          accessor: 'effectiveDateStart',
          cellRender: ({ effectiveDateStart }) => effectiveDateStart,
        },
        {
          headerRender: () => 'Contract End Date',
          accessor: 'effectiveDateEnd',
          cellRender: ({ effectiveDateEnd }) =>
            effectiveDateEnd || EMPTY_DEFAULT,
        },
        {
          headerRender: () => ' Type',
          accessor: 'type',
          cellRender: ({ type }) => StringUtils.capitalizeAll(type || '', '_'),
        },
        {
          headerRender: () => 'Employee Code',
          accessor: 'employeeCode',
          cellRender: ({ employeeCode }) => employeeCode || EMPTY_DEFAULT,
        },
        {
          headerRender: () => 'Company',
          accessor: 'company',
          cellRender: ({ companyName }) => companyName,
        },
        {
          headerRender: () => 'Main/Sub',
          accessor: 'company',
          cellRender: ({ isMain }) => (isMain ? 'Main' : 'Sub'),
        },
        {
          headerRender: () => '出向',
          accessor: 'isOnSecondment',
          cellRender: (item: EmployeeContractHistoryType) => {
            return (
              <span>{item.isOnSecondment ? <GreenCheckIcon /> : '○'}</span>
            );
          },
        },
      ] as TableLayout<EmployeeContractHistoryType>[],
    [
      hasJobContractHistoryDeleteAccess,
      hasJobContractHistoryEditAccess,
      onRemove,
      onUpdate,
      openDeleteRecordModal,
    ]
  );

  const tableCommonProps: TableProps<EmployeeContractHistoryType> = {
    layout,
    loading,
    data: items || [],
    notFoundTemplate: (
      <NoResult
        title={'No data found'}
        titleClassName='text-sm text-gray-9'
        wrapperClassName='py-10'
      />
    ),
  };

  return (
    <>
      <Table<EmployeeContractHistoryType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </>
  );
};
