import { z } from 'zod';

export const VisaFormData = z.object({
  visaType: z.string().default(''),
  visaNumber: z.string().default(''),
  visaIssuedDate: z.string().nullable().default(null),
  visaExpiryDate: z.string().nullable().default(null),
  workPermitNumber: z.string().nullable().default(null),
  workPermitIssuedDate: z.string().nullable().default(null),
  workPermitExpiryDate: z.string().nullable().default(null),
});
export type VisaFormDataType = z.infer<typeof VisaFormData>;
