import { toast } from '@/shared/components';
import { ListCompaniesDocument } from '@/shared/graphql';
import { CompanyType } from '@/shared/types';
import { useModals } from '@any-ui-react/modals';
import { useApolloClient } from '@apollo/client';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { CompanyForm } from './CompanyForm';

export const CompanyModal = ({
  children,
  className,
  company,
}: {
  children: React.ReactNode;
  className?: string;
  company?: CompanyType;
}) => {
  const modalId = uuid();
  const { openModal, closeModal } = useModals();
  const client = useApolloClient();

  const onClick = () => {
    openModal({
      withinPortal: false,
      modalId,
      title: `${company ? 'Edit' : 'Add New'} Company`,
      size: 'lg',
      children: (
        <CompanyForm
          onSuccess={async () => {
            toast.success();
            await client.refetchQueries({
              include: [ListCompaniesDocument],
            });
            closeModal(modalId);
          }}
          onCancel={() => closeModal(modalId)}
          defaultValues={company}
        />
      ),
    });
  };

  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};
