import { EmployeeDetailType } from '@/shared/types';
import { StringUtils } from '@/shared/utils';

export const PersonalInformationDetails = ({
  employee,
}: {
  employee: EmployeeDetailType;
}) => {
  const employeePersonalInformation = employee.personalInformation;
  return (
    <div>
      <div className='form-fieldset'>
        <div>
          <p className='mb-2 font-semibold'>Gender</p>
          <p className='text-gray-7'>
            {StringUtils.capitalizeAll(
              employeePersonalInformation.gender ?? '-',
              '_'
            )}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Nationality</p>
          <p className='text-gray-7'>
            {employee.generalInformation.nationality}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Date of Birth</p>
          <p className='text-gray-7'>
            {employeePersonalInformation.dateOfBirth}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Personal Phone Number</p>
          <p className='text-gray-7'>
            {employeePersonalInformation.personalPhoneNumber ? (
              <>
                {employeePersonalInformation.personalPhoneNumberCountryCode}{' '}
                {employeePersonalInformation.personalPhoneNumber}
              </>
            ) : (
              '-'
            )}
          </p>
        </div>
        <div>
          <p className='mb-2 font-semibold'>Personal Email</p>
          <p className='text-gray-7'>
            {employeePersonalInformation.personalEmail || '-'}
          </p>
        </div>

        <div>
          <p className='mb-2 font-semibold'>Current Address</p>
          <p className='text-gray-7'>
            {employeePersonalInformation.currentAddress || '-'}
          </p>
        </div>

        <div>
          <p className='mb-2 font-semibold'>Permanent Address</p>
          <p className='text-gray-7'>
            {employeePersonalInformation.permanentAddress || '-'}
          </p>
        </div>
      </div>
    </div>
  );
};
