import { z } from 'zod';

export const BusinessUnit = z.object({
  id: z.string().default(''),
  name: z.string().default(''),
  enabled: z.boolean().default(false),
  createdAt: z.string().default(''),
  updatedAt: z.string().default(''),
  division: z.object({
    id: z.string().default(''),
    name: z.string().default(''),
  }),
  divisionId: z.string().default(''),
});
export type BusinessUnitType = z.infer<typeof BusinessUnit>;
