import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeVisaMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeVisaInput;
}>;


export type UpdateEmployeeVisaMutation = { __typename?: 'Mutation', updateEmployeeVisa: { __typename?: 'EmployeeVisaOutput', id: string } };


export const UpdateEmployeeVisaDocument = gql`
    mutation updateEmployeeVisa($input: UpdateEmployeeVisaInput!) {
  updateEmployeeVisa(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeVisaMutationFn = Apollo.MutationFunction<UpdateEmployeeVisaMutation, UpdateEmployeeVisaMutationVariables>;

/**
 * __useUpdateEmployeeVisaMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeVisaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeVisaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeVisaMutation, { data, loading, error }] = useUpdateEmployeeVisaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeVisaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeVisaMutation, UpdateEmployeeVisaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeVisaMutation, UpdateEmployeeVisaMutationVariables>(UpdateEmployeeVisaDocument, options);
      }
export type UpdateEmployeeVisaMutationHookResult = ReturnType<typeof useUpdateEmployeeVisaMutation>;
export type UpdateEmployeeVisaMutationResult = Apollo.MutationResult<UpdateEmployeeVisaMutation>;
export type UpdateEmployeeVisaMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeVisaMutation, UpdateEmployeeVisaMutationVariables>;