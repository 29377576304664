import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateReasonForLeavingMutationVariables = Types.Exact<{
  input: Types.UpdateReasonForLeavingInput;
}>;


export type UpdateReasonForLeavingMutation = { __typename?: 'Mutation', updateReasonForLeaving: { __typename?: 'ReasonForLeavingOutput', id: string } };


export const UpdateReasonForLeavingDocument = gql`
    mutation updateReasonForLeaving($input: UpdateReasonForLeavingInput!) {
  updateReasonForLeaving(input: $input) {
    id
  }
}
    `;
export type UpdateReasonForLeavingMutationFn = Apollo.MutationFunction<UpdateReasonForLeavingMutation, UpdateReasonForLeavingMutationVariables>;

/**
 * __useUpdateReasonForLeavingMutation__
 *
 * To run a mutation, you first call `useUpdateReasonForLeavingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReasonForLeavingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReasonForLeavingMutation, { data, loading, error }] = useUpdateReasonForLeavingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReasonForLeavingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReasonForLeavingMutation, UpdateReasonForLeavingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReasonForLeavingMutation, UpdateReasonForLeavingMutationVariables>(UpdateReasonForLeavingDocument, options);
      }
export type UpdateReasonForLeavingMutationHookResult = ReturnType<typeof useUpdateReasonForLeavingMutation>;
export type UpdateReasonForLeavingMutationResult = Apollo.MutationResult<UpdateReasonForLeavingMutation>;
export type UpdateReasonForLeavingMutationOptions = Apollo.BaseMutationOptions<UpdateReasonForLeavingMutation, UpdateReasonForLeavingMutationVariables>;