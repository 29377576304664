import { Table } from '@/shared/components';
import { useReportNumberOfEmployeeJobQuery } from '@/shared/graphql';
import { useMemo } from 'react';
import { FilterProps } from 'src/hooks/shared';
import { NumberOfEmployeesFilterType } from 'src/types/filters';

type ClientReportNumberOfEmployeeJob = {
  job: {
    name: string;
  };
  total: number;
  yearMonth: string;
};

export const NumberOfEmployeesJobTable = ({
  filters,
}: {
  filters: FilterProps<NumberOfEmployeesFilterType>;
}) => {
  const { data, loading } = useReportNumberOfEmployeeJobQuery({
    variables: {
      year: filters.current.year,
      contractTypes: filters.current.contractTypes,
    },
  });

  const yearMonths = data?.reportNumberOfEmployeeJob.yearMonthList;
  const jobs = useMemo(
    () => data?.reportNumberOfEmployeeJob.items.map((item) => item.job.name),
    [data?.reportNumberOfEmployeeJob.items]
  );

  const tableData: ClientReportNumberOfEmployeeJob[] = useMemo(
    () =>
      ([...new Set(jobs)] || []).map((job: string) => ({
        job: {
          name: job,
        },
        ...Object.fromEntries(
          (yearMonths || []).map((yearMonth) => [
            yearMonth,
            data?.reportNumberOfEmployeeJob.items.find(
              (item) => item.yearMonth === yearMonth && item.job.name === job
            )?.total ?? 0,
          ])
        ),
      })),
    [data?.reportNumberOfEmployeeJob.items, jobs, yearMonths]
  );

  const columns = useMemo(
    () => [
      {
        headerRender: () => null,
        accessor: 'businessUnit',
        cellRender: (row: ClientReportNumberOfEmployeeJob) => (
          <span>{row.job.name}</span>
        ),
      },
      ...(yearMonths || []).map((yearMonth) => ({
        headerRender: () => yearMonth,
        accessor: yearMonth,
        cellRender: (row: ClientReportNumberOfEmployeeJob) => (
          <div className='w-full text-right'>
            {row[yearMonth as keyof ClientReportNumberOfEmployeeJob].toString()}
          </div>
        ),
      })),
    ],
    [yearMonths]
  );

  return (
    <div className='flex flex-col'>
      <Table<ClientReportNumberOfEmployeeJob>
        layout={columns || []}
        data={tableData || []}
        loading={loading}
      />
    </div>
  );
};
