import { EmployeeJobHistoryFormDataType } from '@/shared/types';
import { isValid } from 'date-fns';
import { create, enforce, omitWhen, test } from 'vest';

export const jobHistoryFormValidationSuite = create(
  ({
    type,
    effectiveDateStart,
    effectiveDateEnd,
    level,
    countryId,
    locationId,
    divisionId,
    businessUnitId,
    departmentId,
    jobId,
    jobTitle,
    employmentEndDate,
  }: EmployeeJobHistoryFormDataType & { employmentEndDate: string }) => {
    test('type', 'Type is required', () => {
      enforce(type).isNotEmpty();
    });
    test('level', 'Level is required', () => {
      enforce(level).isNotEmpty();
    });
    test('locationId', 'Location is required', () => {
      enforce(locationId).isNotEmpty();
    });
    test('businessUnitId', 'Business Unit is required', () => {
      enforce(businessUnitId).isNotEmpty();
    });
    test('departmentId', 'Department is required', () => {
      enforce(departmentId).isNotEmpty();
    });
    test('jobId', 'Job is required', () => {
      enforce(jobId).isNotEmpty();
    });
    test('jobTitle', 'Job Title is required', () => {
      enforce(jobTitle).isNotEmpty();
    });

    const fromDate = new Date(effectiveDateStart ?? '');

    test(
      'effectiveDateStart',
      'Effective Date Start must be a valid date',
      () => {
        enforce(isValid(new Date(fromDate))).isTruthy();
      }
    );

    omitWhen(!effectiveDateEnd, () => {
      const toDate = new Date(effectiveDateEnd ?? '');

      test(
        'effectiveDateEnd',
        'Effective Date End must be a valid date',
        () => {
          enforce(isValid(new Date(toDate))).isTruthy();
        }
      );

      test(
        'effectiveDateStart',
        'Effective Date Start must be a less than end date',
        () => {
          const isEndDateGreater = toDate > fromDate;
          enforce(isEndDateGreater).isTruthy();
        }
      );

      test(
        'effectiveDateEnd',
        'Effective Date End must be a greater than start date',
        () => {
          const isStartDateLess = fromDate < toDate;
          enforce(isStartDateLess).isTruthy();
        }
      );

      omitWhen(!employmentEndDate, () => {
        const employeeEndDateParsed = new Date(employmentEndDate ?? '');
        test(
          'effectiveDateEnd',
          'Effective Date End must be less than Last Employment Date',
          () => {
            const isLessThanOrEqualToEndDate = toDate <= employeeEndDateParsed;
            enforce(isLessThanOrEqualToEndDate).isTruthy();
          }
        );
      });
    });
  }
);
