import { PropsWithChildren, ReactElement } from 'react';

import { TooltipProps } from '@any-ui-react/core';

import { TableSelection } from '../../../types';
import { NoResult } from '../no-result';
import { DEFAULT_PAGE_SIZE } from './constants';
import { TableDesktop, TableDesktopContainer } from './desktop';
import { TableMobile } from './mobile';
import { TableSelectionProvider } from './selection';
import { TableSkeleton } from './TableSkeleton';

export const checkboxRowId = '__SELECTOR__';

interface CellRenderCtx {
  expanded?: boolean;
  toggleDetailPanel?: () => void;
}

export interface TableLayout<T> {
  headerRender: () => React.ReactNode;
  cellRender: (
    row: T,
    rowIndex: number,
    ctx?: CellRenderCtx
  ) => React.ReactNode;
  checkboxTooltip?: (row: T) => TooltipProps;
  footer?: () => React.ReactNode;
  accessor: string;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  truncated?: boolean;
  thClassName?: string;
  tdClassName?: string;
  skeletonTemplate?: React.ReactNode;
  checkboxDisabled?: (row: T) => boolean;
}

export interface TableProps<T> {
  layout: ReadonlyArray<TableLayout<T>>;
  data?: ReadonlyArray<T>;
  skeletonCount?: number;
  loading?: boolean;
  notFoundTemplate?: React.ReactNode;
  wrapperClassName?: string;
  headerClassName?: string;
  rowClassName?: string;
  emptyContainerClassName?: string;
  numOfStickyCols?: number;
  selectable?: {
    byField: keyof T;
    aside?: React.ReactNode;
    total: number;
    rowDisabled?: (row: T) => boolean;
    allPagesSelectable?: boolean;
  };
  hoverable?: boolean;
  onClick?: (row: T) => void;
  mobileTemplate?: (element: T, index: number) => ReactElement;
  mobileSkeletonTemplate?: (index: number) => ReactElement;
  mobileTemplateFooter?: () => ReactElement;
  onSelectionChange?: (selection: TableSelection<T>) => void;
  selection?: TableSelection<T>;
  allowFloatingHeader?: boolean;
  page?: number;
  renderDetailPanel?: (row: T, index: number) => React.ReactNode;
  footer?: React.ReactNode;
}

export function Table<T extends Record<string, unknown>>({
  mobileTemplate,
  data = [],
  loading,
  notFoundTemplate,
  wrapperClassName = 'border-none shadow-none lg:rounded-b',
  emptyContainerClassName = 'border-gray-2 border-t text-center',
  skeletonCount = DEFAULT_PAGE_SIZE,
  page,
  selection,
  selectable,
  onSelectionChange,
  numOfStickyCols,
  footer,
  ...props
}: PropsWithChildren<TableProps<T>>): ReactElement {
  const isLargeScreen = true;

  const tableRender = () =>
    mobileTemplate && !isLargeScreen ? (
      <TableMobile<T> mobileTemplate={mobileTemplate} data={data} {...props} />
    ) : (
      <TableDesktopContainer<T>
        data={data}
        numOfStickyCols={numOfStickyCols}
        {...props}
      >
        <TableDesktop<T> data={data} {...props} />
      </TableDesktopContainer>
    );

  return (
    <TableSelectionProvider
      page={page}
      data={data}
      selection={selection}
      selectable={selectable}
      onSelectionChange={onSelectionChange}
    >
      <div className={wrapperClassName}>
        <TableSkeleton
          loading={loading}
          skeletonCount={skeletonCount}
          mobileTemplate={mobileTemplate}
          numOfStickyCols={numOfStickyCols}
          {...props}
        >
          {data.length === 0 ? (
            <div className={emptyContainerClassName}>
              {notFoundTemplate || (
                <NoResult
                  title={'Empty Data Title'}
                  description={'Empty Data Desc'}
                />
              )}
            </div>
          ) : (
            tableRender()
          )}
        </TableSkeleton>
      </div>
      {footer}
    </TableSelectionProvider>
  );
}
