import { create, enforce, test } from 'vest';

export interface JobFormData {
  name: string;
  enabled: boolean;
}

export const jobFormValidationSuite = create(({ name }: JobFormData) => {
  test('name', 'Name is required', () => {
    enforce(name).isNotEmpty();
  });
});
