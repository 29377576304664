import { z } from 'zod';
import { EmployeeExternalAccountFormData } from './employee-external-account-form-data';
import { EmployeeGeneralInformationFormData } from './employee-general-information-form-data';
import { EmployeeJobInformationFormData } from './employee-job-information-form-data';
import { EmployeePermissionFormData } from './employee-permission-form-data';
import { EmployeePersonalInformationFormData } from './employee-personal-information-form-data';

export const EmployeeAddFormData = EmployeeGeneralInformationFormData.merge(
  EmployeePersonalInformationFormData
)
  .merge(EmployeeExternalAccountFormData)
  .merge(EmployeeJobInformationFormData)
  .merge(EmployeePermissionFormData);
export type EmployeeAddFormDataType = z.infer<typeof EmployeeAddFormData>;
