import classNames from 'classnames';
import { Header } from './Header';
import { NavigationLinks } from './NavigationLinks';
import { UserMenu } from './UserMenu';

export const Navigation = () => {
  return (
    <aside
      className={classNames(
        'sticky top-0 h-screen w-60 shrink-0 overflow-y-auto bg-white  shadow-card',
        // styles
        {}
      )}
      aria-label='Sidebar'
    >
      <div className='flex h-screen flex-col'>
        <Header />

        {/* Workspace Select */}
        {/* <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.ON]}>
              <div className='mb-3 px-2'>
                <WorkspaceSelect />
              </div>
            </RestrictedWorkspaceMode> */}

        {/* Navigation */}
        {/* <NavigationLinks links={links} /> */}
        <NavigationLinks />
        {/* User Menu */}

        <hr className='mt-auto border-gray-2' />

        <UserMenu />
      </div>
    </aside>
  );
};
