import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListCurrenciesQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  keyword: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ListCurrenciesQuery = { __typename?: 'Query', listCurrencies: { __typename?: 'ListCurrencies', totalCount: number, items: Array<{ __typename?: 'Currency', id: string, name: string }> } };


export const ListCurrenciesDocument = gql`
    query listCurrencies($pageNumber: Int!, $pageSize: Int!, $keyword: String) {
  listCurrencies(pageNumber: $pageNumber, pageSize: $pageSize, keyword: $keyword) {
    items {
      id
      name
    }
    totalCount
  }
}
    `;

/**
 * __useListCurrenciesQuery__
 *
 * To run a query within a React component, call `useListCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCurrenciesQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useListCurrenciesQuery(baseOptions: Apollo.QueryHookOptions<ListCurrenciesQuery, ListCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCurrenciesQuery, ListCurrenciesQueryVariables>(ListCurrenciesDocument, options);
      }
export function useListCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCurrenciesQuery, ListCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCurrenciesQuery, ListCurrenciesQueryVariables>(ListCurrenciesDocument, options);
        }
export function useListCurrenciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCurrenciesQuery, ListCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCurrenciesQuery, ListCurrenciesQueryVariables>(ListCurrenciesDocument, options);
        }
export type ListCurrenciesQueryHookResult = ReturnType<typeof useListCurrenciesQuery>;
export type ListCurrenciesLazyQueryHookResult = ReturnType<typeof useListCurrenciesLazyQuery>;
export type ListCurrenciesSuspenseQueryHookResult = ReturnType<typeof useListCurrenciesSuspenseQuery>;
export type ListCurrenciesQueryResult = Apollo.QueryResult<ListCurrenciesQuery, ListCurrenciesQueryVariables>;