import { CardTitle } from '@/shared/components';
import { SettingNotificationType } from '@/shared/types';
import { useFormContext } from 'react-hook-form';
import { NotificationItem } from '../notification-item';

export const ServiceCompletion = () => {
  const { watch } = useFormContext<SettingNotificationType>();
  const [sixMonthServiceCompletion] = watch(['sixMonthServiceCompletion']);

  return (
    <div className='border-t py-6'>
      <CardTitle>Japan Specific</CardTitle>

      <div className='flex flex-col space-y-6'>
        <NotificationItem
          label='6-Month service completion (Japan part-time)'
          name='sixMonthServiceCompletion'
          value={sixMonthServiceCompletion}
          withDate={false}
        />
      </div>
    </div>
  );
};
