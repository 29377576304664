import { Card, PageTitle } from '@/shared/components';
import { useNavigate } from 'react-router-dom';
import { EmployeeAddForm } from './components';

export const EmployeeAdd = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle text='Add Employee' onBack={() => navigate('/employees')} />
      <Card>
        <p className='mb-6'>
          If you have multiple records to add, use{' '}
          <span className='text-blue-6'>CSV import</span>.
        </p>
        <EmployeeAddForm />
      </Card>
    </>
  );
};
