import { toast } from '@/shared/components';
import {
  UpdateNotificationSettingInput,
  useUpdateNotificationSettingMutation,
} from '@/shared/graphql';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';

export const useUpdateNotificationSetting = () => {
  const [updateNotificationSettingMutation] =
    useUpdateNotificationSettingMutation();

  const updateNotificationSetting = useCallback(
    async ({ data }: { data: UpdateNotificationSettingInput }) => {
      await updateNotificationSettingMutation({
        variables: {
          input: data,
        },
        onCompleted: () => toast.success(),
        onError: (error: ApolloError) => toast.error(error.message),
      });
    },
    [updateNotificationSettingMutation]
  );

  return { updateNotificationSetting };
};
