import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTypeOfAttritionMutationVariables = Types.Exact<{
  input: Types.UpdateTypeOfAttritionInput;
}>;


export type UpdateTypeOfAttritionMutation = { __typename?: 'Mutation', updateTypeOfAttrition: { __typename?: 'TypeOfAttritionOutput', id: string } };


export const UpdateTypeOfAttritionDocument = gql`
    mutation updateTypeOfAttrition($input: UpdateTypeOfAttritionInput!) {
  updateTypeOfAttrition(input: $input) {
    id
  }
}
    `;
export type UpdateTypeOfAttritionMutationFn = Apollo.MutationFunction<UpdateTypeOfAttritionMutation, UpdateTypeOfAttritionMutationVariables>;

/**
 * __useUpdateTypeOfAttritionMutation__
 *
 * To run a mutation, you first call `useUpdateTypeOfAttritionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTypeOfAttritionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTypeOfAttritionMutation, { data, loading, error }] = useUpdateTypeOfAttritionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTypeOfAttritionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTypeOfAttritionMutation, UpdateTypeOfAttritionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTypeOfAttritionMutation, UpdateTypeOfAttritionMutationVariables>(UpdateTypeOfAttritionDocument, options);
      }
export type UpdateTypeOfAttritionMutationHookResult = ReturnType<typeof useUpdateTypeOfAttritionMutation>;
export type UpdateTypeOfAttritionMutationResult = Apollo.MutationResult<UpdateTypeOfAttritionMutation>;
export type UpdateTypeOfAttritionMutationOptions = Apollo.BaseMutationOptions<UpdateTypeOfAttritionMutation, UpdateTypeOfAttritionMutationVariables>;