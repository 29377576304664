import { AppLayout, AuthLayout, ProtectedRoute } from '@/shared/components';
import { EmployeeRole } from '@/shared/graphql';
import { AuthUserType } from '@/shared/types';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {
  DemographicReport,
  EmployeeAdd,
  EmployeeEdit,
  EmployeeList,
  Evaluation,
  GeneralList,
  Home,
  Login,
  Logs,
  NotificationSetting,
  NumberOfEmployees,
  PerformanceReview,
  PerformanceReviewStage,
  ProbationReview,
  Report,
  Setting,
  SignInCallback,
} from '../pages';

export const getRouter = ({ user }: { user: AuthUserType }) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<AppLayout />}>
          <Route
            element={<ProtectedRoute user={user} redirectPath={'/login'} />}
          >
            <Route path='/' element={<Home />} />
            <Route path='/employees' element={<EmployeeList />} />
            <Route path='/employees/edit/:id' element={<EmployeeEdit />} />
            <Route path='/employees/add' element={<EmployeeAdd />} />
            <Route path='/evaluation' element={<Evaluation />} />
            <Route
              path='/evaluation/performance-review'
              element={<PerformanceReview />}
            />
            <Route path='/probation-review' element={<ProbationReview />} />
            <Route path='/report' element={<Report />} />
            <Route
              path='/report/number-of-employees'
              element={<NumberOfEmployees />}
            />
            <Route
              path='/report/demographic-report'
              element={<DemographicReport />}
            />

            <Route
              element={
                <ProtectedRoute
                  user={user}
                  redirectPath={'/'}
                  allowedTypes={[
                    EmployeeRole.SuperAdmin,
                    EmployeeRole.AdminPrivate,
                    EmployeeRole.AdminStandard,
                  ]}
                />
              }
            >
              <Route path='/setting' element={<Setting />} />
              <Route
                path='/setting/performance-review-stage'
                element={<PerformanceReviewStage />}
              />
              <Route
                path='/setting/notification'
                element={<NotificationSetting />}
              />
              <Route path='/setting/general' element={<GeneralList />} />
              <Route path='/setting/logs' element={<Logs />} />
            </Route>
          </Route>
        </Route>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/auth/callback' element={<SignInCallback />} />
        </Route>
      </Route>
    )
  );
};
