import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListReasonForLeavingsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  enabled: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ListReasonForLeavingsQuery = { __typename?: 'Query', listReasonForLeavings: { __typename?: 'ListReasonForLeavings', totalCount: number, items: Array<{ __typename?: 'ReasonForLeaving', id: string, name: string, enabled: boolean, createdAt: any, updatedAt: any }> } };


export const ListReasonForLeavingsDocument = gql`
    query listReasonForLeavings($pageNumber: Int!, $pageSize: Int!, $enabled: Boolean) {
  listReasonForLeavings(
    pageNumber: $pageNumber
    pageSize: $pageSize
    enabled: $enabled
  ) {
    items {
      id
      name
      enabled
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useListReasonForLeavingsQuery__
 *
 * To run a query within a React component, call `useListReasonForLeavingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReasonForLeavingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReasonForLeavingsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useListReasonForLeavingsQuery(baseOptions: Apollo.QueryHookOptions<ListReasonForLeavingsQuery, ListReasonForLeavingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReasonForLeavingsQuery, ListReasonForLeavingsQueryVariables>(ListReasonForLeavingsDocument, options);
      }
export function useListReasonForLeavingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReasonForLeavingsQuery, ListReasonForLeavingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReasonForLeavingsQuery, ListReasonForLeavingsQueryVariables>(ListReasonForLeavingsDocument, options);
        }
export function useListReasonForLeavingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListReasonForLeavingsQuery, ListReasonForLeavingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListReasonForLeavingsQuery, ListReasonForLeavingsQueryVariables>(ListReasonForLeavingsDocument, options);
        }
export type ListReasonForLeavingsQueryHookResult = ReturnType<typeof useListReasonForLeavingsQuery>;
export type ListReasonForLeavingsLazyQueryHookResult = ReturnType<typeof useListReasonForLeavingsLazyQuery>;
export type ListReasonForLeavingsSuspenseQueryHookResult = ReturnType<typeof useListReasonForLeavingsSuspenseQuery>;
export type ListReasonForLeavingsQueryResult = Apollo.QueryResult<ListReasonForLeavingsQuery, ListReasonForLeavingsQueryVariables>;