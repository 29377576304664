import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReasonForLeavingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetReasonForLeavingQuery = { __typename?: 'Query', reasonForLeaving: { __typename?: 'ReasonForLeaving', id: string, name: string } | null };


export const GetReasonForLeavingDocument = gql`
    query getReasonForLeaving($id: ID!) {
  reasonForLeaving(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetReasonForLeavingQuery__
 *
 * To run a query within a React component, call `useGetReasonForLeavingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonForLeavingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonForLeavingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReasonForLeavingQuery(baseOptions: Apollo.QueryHookOptions<GetReasonForLeavingQuery, GetReasonForLeavingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReasonForLeavingQuery, GetReasonForLeavingQueryVariables>(GetReasonForLeavingDocument, options);
      }
export function useGetReasonForLeavingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReasonForLeavingQuery, GetReasonForLeavingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReasonForLeavingQuery, GetReasonForLeavingQueryVariables>(GetReasonForLeavingDocument, options);
        }
export function useGetReasonForLeavingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReasonForLeavingQuery, GetReasonForLeavingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReasonForLeavingQuery, GetReasonForLeavingQueryVariables>(GetReasonForLeavingDocument, options);
        }
export type GetReasonForLeavingQueryHookResult = ReturnType<typeof useGetReasonForLeavingQuery>;
export type GetReasonForLeavingLazyQueryHookResult = ReturnType<typeof useGetReasonForLeavingLazyQuery>;
export type GetReasonForLeavingSuspenseQueryHookResult = ReturnType<typeof useGetReasonForLeavingSuspenseQuery>;
export type GetReasonForLeavingQueryResult = Apollo.QueryResult<GetReasonForLeavingQuery, GetReasonForLeavingQueryVariables>;