import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { ActionIcon } from '@any-ui-react/core';

import { ChevronLeftIcon } from '../../icons';
import { SkeletonLoader } from '../../skeleton-loader';

export interface PageTitleProps {
  text?: string;
  aside?: () => ReactNode;
  onBack?: () => void;
  loading?: boolean;
}

export const PageTitle = ({
  text = '',
  onBack,
  aside,
  loading,
}: PageTitleProps) => {
  return (
    <>
      <Helmet>
        <title>{`AnyMind MED - ${text}`}</title>
      </Helmet>
      <div className='flex min-h-[2rem] items-center justify-between p-4 lg:mb-4 lg:p-0'>
        <div className='flex items-center truncate'>
          <SkeletonLoader loading={loading} width='12rem' height='1.125rem'>
            {onBack && (
              <ActionIcon
                className='card mr-4 bg-white shadow-none focus:outline-none'
                onClick={onBack}
                variant='default'
              >
                <ChevronLeftIcon className='h-4 w-4' />
              </ActionIcon>
            )}
            {text && <h3 className='truncate text-lg font-semibold'>{text}</h3>}
          </SkeletonLoader>
        </div>
        {aside?.()}
      </div>
    </>
  );
};
