import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportNumberOfEmployeeJobQueryVariables = Types.Exact<{
  year: Types.Scalars['Year']['input'];
  contractTypes: Types.InputMaybe<Array<Types.ContractTypeForReport> | Types.ContractTypeForReport>;
}>;


export type ReportNumberOfEmployeeJobQuery = { __typename?: 'Query', reportNumberOfEmployeeJob: { __typename?: 'ReportNumberOfEmployeeByJob', yearMonthList: Array<any>, items: Array<{ __typename?: 'ReportNumberOfEmployeeByJobItem', total: number, yearMonth: any, job: { __typename?: 'Job', name: string } }> } };


export const ReportNumberOfEmployeeJobDocument = gql`
    query ReportNumberOfEmployeeJob($year: Year!, $contractTypes: [ContractTypeForReport!]) {
  reportNumberOfEmployeeJob(year: $year, contractTypes: $contractTypes) {
    items {
      job {
        name
      }
      total
      yearMonth
    }
    yearMonthList
  }
}
    `;

/**
 * __useReportNumberOfEmployeeJobQuery__
 *
 * To run a query within a React component, call `useReportNumberOfEmployeeJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportNumberOfEmployeeJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportNumberOfEmployeeJobQuery({
 *   variables: {
 *      year: // value for 'year'
 *      contractTypes: // value for 'contractTypes'
 *   },
 * });
 */
export function useReportNumberOfEmployeeJobQuery(baseOptions: Apollo.QueryHookOptions<ReportNumberOfEmployeeJobQuery, ReportNumberOfEmployeeJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportNumberOfEmployeeJobQuery, ReportNumberOfEmployeeJobQueryVariables>(ReportNumberOfEmployeeJobDocument, options);
      }
export function useReportNumberOfEmployeeJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportNumberOfEmployeeJobQuery, ReportNumberOfEmployeeJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportNumberOfEmployeeJobQuery, ReportNumberOfEmployeeJobQueryVariables>(ReportNumberOfEmployeeJobDocument, options);
        }
export function useReportNumberOfEmployeeJobSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportNumberOfEmployeeJobQuery, ReportNumberOfEmployeeJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportNumberOfEmployeeJobQuery, ReportNumberOfEmployeeJobQueryVariables>(ReportNumberOfEmployeeJobDocument, options);
        }
export type ReportNumberOfEmployeeJobQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeJobQuery>;
export type ReportNumberOfEmployeeJobLazyQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeJobLazyQuery>;
export type ReportNumberOfEmployeeJobSuspenseQueryHookResult = ReturnType<typeof useReportNumberOfEmployeeJobSuspenseQuery>;
export type ReportNumberOfEmployeeJobQueryResult = Apollo.QueryResult<ReportNumberOfEmployeeJobQuery, ReportNumberOfEmployeeJobQueryVariables>;