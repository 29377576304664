import { NoResult, Table, TableLayout, TableProps } from '@/shared/components';
import { EmployeeDirectReportType } from '@/shared/types';
import { useMemo } from 'react';

export const DirectReports = ({
  directReports,
}: {
  directReports: EmployeeDirectReportType[];
}) => {
  const layout = useMemo(
    () =>
      [
        {
          headerRender: () => 'Name',
          accessor: 'name',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.name}</span>;
          },
        },
        {
          headerRender: () => 'Company',
          accessor: 'company',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.company}</span>;
          },
        },

        {
          headerRender: () => 'Location',
          accessor: 'location',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.location || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Division',
          accessor: 'division',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.division || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Business Unit',
          accessor: 'businessUnit',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.businessUnit || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Job',
          accessor: 'job',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.job || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Level',
          accessor: 'level',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.level || '-'}</span>;
          },
        },
        {
          headerRender: () => 'Job Title',
          accessor: 'jobTitle',
          cellRender: (directReport: EmployeeDirectReportType) => {
            return <span>{directReport.jobTitle || '-'}</span>;
          },
        },
      ] as TableLayout<EmployeeDirectReportType>[],
    []
  );

  const tableCommonProps: TableProps<EmployeeDirectReportType> = {
    layout,
    loading: false,
    data: directReports || [],
    notFoundTemplate: (
      <NoResult
        title={'There are no Direct Reports'}
        description={'No Direct Reports found'}
      />
    ),
  };

  return (
    <div className='mt-10'>
      <h1 className='mb-4 text-lg font-semibold'>Direct Reports</h1>
      <Table<EmployeeDirectReportType>
        {...tableCommonProps}
        page={1}
        allowFloatingHeader
      />
    </div>
  );
};
