import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTypeOfAttritionQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetTypeOfAttritionQuery = { __typename?: 'Query', typeOfAttrition: { __typename?: 'TypeOfAttrition', id: string, name: string } | null };


export const GetTypeOfAttritionDocument = gql`
    query getTypeOfAttrition($id: ID!) {
  typeOfAttrition(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetTypeOfAttritionQuery__
 *
 * To run a query within a React component, call `useGetTypeOfAttritionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypeOfAttritionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTypeOfAttritionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTypeOfAttritionQuery(baseOptions: Apollo.QueryHookOptions<GetTypeOfAttritionQuery, GetTypeOfAttritionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTypeOfAttritionQuery, GetTypeOfAttritionQueryVariables>(GetTypeOfAttritionDocument, options);
      }
export function useGetTypeOfAttritionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTypeOfAttritionQuery, GetTypeOfAttritionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTypeOfAttritionQuery, GetTypeOfAttritionQueryVariables>(GetTypeOfAttritionDocument, options);
        }
export function useGetTypeOfAttritionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTypeOfAttritionQuery, GetTypeOfAttritionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTypeOfAttritionQuery, GetTypeOfAttritionQueryVariables>(GetTypeOfAttritionDocument, options);
        }
export type GetTypeOfAttritionQueryHookResult = ReturnType<typeof useGetTypeOfAttritionQuery>;
export type GetTypeOfAttritionLazyQueryHookResult = ReturnType<typeof useGetTypeOfAttritionLazyQuery>;
export type GetTypeOfAttritionSuspenseQueryHookResult = ReturnType<typeof useGetTypeOfAttritionSuspenseQuery>;
export type GetTypeOfAttritionQueryResult = Apollo.QueryResult<GetTypeOfAttritionQuery, GetTypeOfAttritionQueryVariables>;