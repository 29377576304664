import { DayPicker } from '@/shared/components';
import { useEmployeeDetailContext } from '@/shared/contexts';
import { useEmployeeAccess } from '@/shared/hooks';
import { EmployeePersonalInformationFormDataType } from '@/shared/types';
import { DateUtils } from '@/shared/utils';
import { TextInput } from '@any-ui-react/core';
import { useFormContext } from 'react-hook-form';

export const Passport = () => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<EmployeePersonalInformationFormDataType>();

  const [passportIssuedDate, passportExpiryDate] = watch([
    'passportIssuedDate',
    'passportExpiryDate',
  ]);

  const { employeeDetail } = useEmployeeDetailContext();
  const isNewRecord = !employeeDetail.id;
  const { hasPersonalInformationEditAccess } = useEmployeeAccess();
  const isEditable = hasPersonalInformationEditAccess || isNewRecord;

  return (
    <div className='mb-6'>
      <fieldset className='form-fieldset'>
        <TextInput
          {...register('passportNumber')}
          disabled={!isEditable}
          placeholder='Enter Passport Number'
          label='Passport Number'
        />
        <TextInput
          {...register('passportName')}
          disabled={!isEditable}
          required
          placeholder='Enter Passport Name'
          label='Passport Name'
          error={errors.passportName?.message}
        />
        <TextInput
          {...register('passportIssuedPlace')}
          disabled={!isEditable}
          required
          placeholder='Enter Passport Issued Place'
          label='Passport Issued Place'
          error={errors.passportIssuedPlace?.message}
        />
        <DayPicker
          error={errors.passportIssuedDate?.message}
          disabled={!isEditable}
          required
          placeholder='Select Passport Issued Date'
          label='Passport Issued Date'
          value={
            passportIssuedDate
              ? new Date(passportIssuedDate).toISOString()
              : undefined
          }
          onChange={(value) => {
            setValue(
              'passportIssuedDate',
              DateUtils.formatDate(
                new Date(value || '').toISOString(),
                'yyyy-MM-dd'
              ),
              {
                shouldDirty: true,
              }
            );
          }}
        />
        <DayPicker
          required
          error={errors.passportExpiryDate?.message}
          disabled={!isEditable}
          placeholder='Select Passport Expiry Date'
          label='Passport Expiry Date'
          value={
            passportExpiryDate
              ? new Date(passportExpiryDate).toISOString()
              : undefined
          }
          onChange={(value) => {
            setValue(
              'passportExpiryDate',
              DateUtils.formatDate(
                new Date(value || '').toISOString(),
                'yyyy-MM-dd'
              ),
              {
                shouldDirty: true,
              }
            );
          }}
        />
        <TextInput
          {...register('placeOfBirth')}
          disabled={!isEditable}
          placeholder='Enter Place of Birth'
          label='Place of Birth'
          required
          error={errors.placeOfBirth?.message}
        />
      </fieldset>
    </div>
  );
};
