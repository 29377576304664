import { Card, DEFAULT_PAGE_SIZE, PageTitle } from '@/shared/components';
import { useAuthContext } from '@/shared/contexts';
import {
  EmployeeExternalAccountStatus,
  EmployeeExternalAccountType,
  EmployeeRole,
  useListEmployeesQuery,
} from '@/shared/graphql';
import { useFilters } from '@/shared/hooks';
import {
  Employee,
  EmployeeListFilter as EmployeeListFilterParser,
  EmployeeListFilterType,
  EmployeeType,
  TableSelection,
} from '@/shared/types';
import { Button, TablePagination } from '@any-ui-react/core';
import { useWindowScroll } from '@mantine/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeeListFilter, EmployeeListTable } from './components';

export const EmployeeList = () => {
  const [, scrollTo] = useWindowScroll();
  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const filters = useFilters<EmployeeListFilterType>(
    {
      id: '',
      name: '',
      page: 1,
      keyword: null,
      statuses: [],
      hiredDate: null,
      contractTypes: [],
      levels: [],
      companyIds: [],
      locationIds: [],
      divisionIds: [],
      businessUnitIds: [],
      managerIds: [],
      departmentIds: [],
      jobIds: [],
      nationalityIds: [],
      roles: [],
      isLeave: false,
      isOnSecondment: false,
      managerIncludesAllConnections: false,
      isOpenFilter: false,
      externalAccountGoogle: [],
      externalAccountGreenhouse: [],
      externalAccountHubspot: [],
      externalAccountSlack: [],
    },
    {
      parser: EmployeeListFilterParser.parse,
    }
  );
  const { page, ...currentFilter } = filters.current;
  const [selection, setSelection] = useState<TableSelection<EmployeeType>>({
    items: [],
    allPages: false,
  });
  const { data: employeeListData, loading } = useListEmployeesQuery({
    variables: {
      pageNumber: page,
      pageSize: 200,
      filter: {
        keyword: currentFilter.keyword,
        departmentIds: currentFilter.departmentIds,
        companyIds: currentFilter.companyIds,
        businessUnitIds: currentFilter.businessUnitIds,
        excludeAuthUser: false,
        contractTypes: currentFilter.contractTypes,
        jobIds: currentFilter.jobIds,
        levels: currentFilter.levels,
        locationIds: currentFilter.locationIds,
        nationalityIds: currentFilter.nationalityIds,
        managerIds: currentFilter.managerIds,
        roles: currentFilter.roles,
        managerIncludesAllConnections:
          currentFilter.managerIncludesAllConnections,
        statuses: currentFilter.statuses,
        hiredDate: currentFilter.hiredDate,
        employmentEndDate: currentFilter.employeeEndDate,
        externalAccount: {
          google: currentFilter.externalAccountGoogle,
          greenhouse: currentFilter.externalAccountGreenhouse,
          hubspot: currentFilter.externalAccountHubspot,
          slack: currentFilter.externalAccountSlack,
        },
        divisionIds: [],
        isLeave: currentFilter.isLeave,
        isOnSecondment: currentFilter.isOnSecondment,
      },
    },
  });

  return (
    <>
      <PageTitle
        text='Employees'
        aside={() => (
          <>
            {![EmployeeRole.Basic, EmployeeRole.Advanced].includes(
              authUser.role
            ) && (
              <Button
                className='ml-auto bg-blue-6'
                onClick={() => navigate('/employees/add')}
                type='submit'
              >
                Add Employee
              </Button>
            )}
          </>
        )}
      />
      <Card withPadding={false}>
        <EmployeeListFilter filters={filters} />
        <EmployeeListTable
          items={
            employeeListData?.listEmployees.items.map((item) =>
              Employee.parse({
                preferredName: item.generalInformation.preferredName,
                legalFullName: item.generalInformation.legalFullName,
                employeeId: item.id ?? '',
                email: item.generalInformation.companyEmail?.fullAddress ?? '',
                hiredDate: item.generalInformation.hiredDate,
                contractType: item.currentContract?.contractType,
                officeLocation: item.currentPosition?.location.name,
                company: item.currentContract?.company.name,
                businessUnit: item.currentPosition?.businessUnit.name,
                department: item.currentPosition?.department.name,
                jobTitle: item.currentPosition?.jobTitle,
                job: item.currentPosition?.job.name,
                level: item.currentPosition?.level,
                dottedLineManager:
                  item.generalInformation.dottedLineManager?.generalInformation
                    .preferredName,
                primaryManager:
                  item.generalInformation.primaryManager?.generalInformation
                    .preferredName,
                nationality: item.generalInformation?.nationality.name,
                isGoogleEnabled:
                  item?.externalAccounts?.find(
                    (account) =>
                      account.accountType === EmployeeExternalAccountType.Google
                  )?.status === EmployeeExternalAccountStatus.Active,
                isGreenhouseEnabled:
                  item?.externalAccounts?.find(
                    (account) =>
                      account.accountType ===
                      EmployeeExternalAccountType.Greenhouse
                  )?.status === EmployeeExternalAccountStatus.Active,
                lastWorkingDate: item.offBoarding?.lastWorkingDate,
                terminationDate: item.offBoarding?.employmentEndDate,
                typeOfAttrition: item.offBoarding?.typeOfAttrition?.name,
                reasonForLeaving: item.offBoarding?.reasonForLeaving?.name,
                ...item,
              })
            ) ?? []
          }
          loading={loading}
          selection={selection}
          setSelection={setSelection}
          total={employeeListData?.listEmployees.totalCount || 0}
        />
      </Card>
      <TablePagination
        className='px-4 py-3 lg:px-0'
        value={page}
        rowsPerPage={DEFAULT_PAGE_SIZE}
        withItems={true}
        count={employeeListData?.listEmployees.totalCount || 0}
        onChange={(newPage) => {
          filters.changeFilter('page', newPage, false);
          scrollTo({ y: 0 });
        }}
      />
    </>
  );
};
