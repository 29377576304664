import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmployeeEmergencyContactMutationVariables = Types.Exact<{
  input: Types.CreateEmployeeEmergencyContactInput;
}>;


export type CreateEmployeeEmergencyContactMutation = { __typename?: 'Mutation', createEmployeeEmergencyContact: { __typename?: 'EmployeeEmergencyContactOutput', id: string } };


export const CreateEmployeeEmergencyContactDocument = gql`
    mutation createEmployeeEmergencyContact($input: CreateEmployeeEmergencyContactInput!) {
  createEmployeeEmergencyContact(input: $input) {
    id
  }
}
    `;
export type CreateEmployeeEmergencyContactMutationFn = Apollo.MutationFunction<CreateEmployeeEmergencyContactMutation, CreateEmployeeEmergencyContactMutationVariables>;

/**
 * __useCreateEmployeeEmergencyContactMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeEmergencyContactMutation, { data, loading, error }] = useCreateEmployeeEmergencyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeEmergencyContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeEmergencyContactMutation, CreateEmployeeEmergencyContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeEmergencyContactMutation, CreateEmployeeEmergencyContactMutationVariables>(CreateEmployeeEmergencyContactDocument, options);
      }
export type CreateEmployeeEmergencyContactMutationHookResult = ReturnType<typeof useCreateEmployeeEmergencyContactMutation>;
export type CreateEmployeeEmergencyContactMutationResult = Apollo.MutationResult<CreateEmployeeEmergencyContactMutation>;
export type CreateEmployeeEmergencyContactMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeEmergencyContactMutation, CreateEmployeeEmergencyContactMutationVariables>;