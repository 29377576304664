import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCompanyEmailDomainMutationVariables = Types.Exact<{
  input: Types.CreateCompanyEmailDomainInput;
}>;


export type CreateCompanyEmailDomainMutation = { __typename?: 'Mutation', createCompanyEmailDomain: { __typename?: 'CompanyEmailDomainOutput', id: string } };


export const CreateCompanyEmailDomainDocument = gql`
    mutation createCompanyEmailDomain($input: CreateCompanyEmailDomainInput!) {
  createCompanyEmailDomain(input: $input) {
    id
  }
}
    `;
export type CreateCompanyEmailDomainMutationFn = Apollo.MutationFunction<CreateCompanyEmailDomainMutation, CreateCompanyEmailDomainMutationVariables>;

/**
 * __useCreateCompanyEmailDomainMutation__
 *
 * To run a mutation, you first call `useCreateCompanyEmailDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyEmailDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyEmailDomainMutation, { data, loading, error }] = useCreateCompanyEmailDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyEmailDomainMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyEmailDomainMutation, CreateCompanyEmailDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyEmailDomainMutation, CreateCompanyEmailDomainMutationVariables>(CreateCompanyEmailDomainDocument, options);
      }
export type CreateCompanyEmailDomainMutationHookResult = ReturnType<typeof useCreateCompanyEmailDomainMutation>;
export type CreateCompanyEmailDomainMutationResult = Apollo.MutationResult<CreateCompanyEmailDomainMutation>;
export type CreateCompanyEmailDomainMutationOptions = Apollo.BaseMutationOptions<CreateCompanyEmailDomainMutation, CreateCompanyEmailDomainMutationVariables>;