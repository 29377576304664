import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendInvitationEmailsMutationVariables = Types.Exact<{
  input: Types.SendInvitationEmailsInput;
}>;


export type SendInvitationEmailsMutation = { __typename?: 'Mutation', sendInvitationEmails: boolean };


export const SendInvitationEmailsDocument = gql`
    mutation sendInvitationEmails($input: SendInvitationEmailsInput!) {
  sendInvitationEmails(input: $input)
}
    `;
export type SendInvitationEmailsMutationFn = Apollo.MutationFunction<SendInvitationEmailsMutation, SendInvitationEmailsMutationVariables>;

/**
 * __useSendInvitationEmailsMutation__
 *
 * To run a mutation, you first call `useSendInvitationEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationEmailsMutation, { data, loading, error }] = useSendInvitationEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationEmailsMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationEmailsMutation, SendInvitationEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvitationEmailsMutation, SendInvitationEmailsMutationVariables>(SendInvitationEmailsDocument, options);
      }
export type SendInvitationEmailsMutationHookResult = ReturnType<typeof useSendInvitationEmailsMutation>;
export type SendInvitationEmailsMutationResult = Apollo.MutationResult<SendInvitationEmailsMutation>;
export type SendInvitationEmailsMutationOptions = Apollo.BaseMutationOptions<SendInvitationEmailsMutation, SendInvitationEmailsMutationVariables>;