import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDivisionMutationVariables = Types.Exact<{
  input: Types.CreateDivisionInput;
}>;


export type CreateDivisionMutation = { __typename?: 'Mutation', createDivision: { __typename?: 'DivisionOutput', id: string } };


export const CreateDivisionDocument = gql`
    mutation createDivision($input: CreateDivisionInput!) {
  createDivision(input: $input) {
    id
  }
}
    `;
export type CreateDivisionMutationFn = Apollo.MutationFunction<CreateDivisionMutation, CreateDivisionMutationVariables>;

/**
 * __useCreateDivisionMutation__
 *
 * To run a mutation, you first call `useCreateDivisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDivisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDivisionMutation, { data, loading, error }] = useCreateDivisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDivisionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDivisionMutation, CreateDivisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDivisionMutation, CreateDivisionMutationVariables>(CreateDivisionDocument, options);
      }
export type CreateDivisionMutationHookResult = ReturnType<typeof useCreateDivisionMutation>;
export type CreateDivisionMutationResult = Apollo.MutationResult<CreateDivisionMutation>;
export type CreateDivisionMutationOptions = Apollo.BaseMutationOptions<CreateDivisionMutation, CreateDivisionMutationVariables>;