import { z } from 'zod';

export const AddUserEmployeeFilter = z.object({
  page: z.number().catch(1),
  keyword: z.string().nullable().optional().catch(null),
  companyIds: z.array(z.string()).nullable().optional().catch(null),
  businessUnitId: z.string().nullable().optional().catch(null),
  departmentId: z.string().nullable().optional().catch(null),
});
export type AddUserEmployeeFilterType = z.infer<typeof AddUserEmployeeFilter>;
