import { format } from 'date-fns';

export const formatDatetime = (rawDate: string) => {
  try {
    return format(new Date(rawDate), 'PP HH:mm:ss');
  } catch (e) {
    console.error(e);
    return '-';
  }
};

export const formatDate = (rawDate: string) => {
  try {
    return format(new Date(rawDate), 'PP');
  } catch (e) {
    console.error(e);
    return '-';
  }
};
