import { EmployeeExternalAccountStatus } from '@/shared/graphql';
import { ObjectUtils, StringUtils } from '@/shared/utils';
import {
  ActionMeta,
  MultiValue,
  PropsValue,
  Select,
  useMultiSelect,
} from '@any-ui-react/select';

interface SelectProps {
  isMulti?: boolean;
  value:
    | string
    | number
    | boolean
    | PropsValue<{ label: string; value: EmployeeExternalAccountStatus }>
    | undefined;
  onChange?:
    | ((
        newValue: MultiValue<{
          label: string;
          value: EmployeeExternalAccountStatus;
        }>,
        actionMeta: ActionMeta<{
          label: string;
          value: EmployeeExternalAccountStatus;
        }>
      ) => void)
    | undefined;
  error?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
}

export const EmployeeExternalAccountStatusSelect = ({
  onChange,
  value,
  isMulti = true,
  ...rest
}: SelectProps) => {
  const statuses =
    ObjectUtils.arrayFromEnumValues<EmployeeExternalAccountStatus>(
      EmployeeExternalAccountStatus
    );

  const options = statuses.map((item) => ({
    value: item,
    label: StringUtils.capitalizeAll(item, '_'),
  }));

  const multiSelectProps = useMultiSelect({
    getSelectedLabel: (count) => `Status (${count})`,
    options: options,
  });

  const selectProps = isMulti ? multiSelectProps : { options: options };

  return (
    <Select
      placeholder='Status'
      isSearchable={true}
      isClearable={true}
      onChange={onChange}
      value={value}
      {...rest}
      {...selectProps}
    />
  );
};
