import {
  NotificationDate,
  NotificationUser,
  UpdateNotificationOptionCustomDatesInput,
  UpdateNotificationOptionStaticDatesInput,
} from '@/shared/graphql';
import {
  NotificationDateLabels,
  NotificationUserLabels,
  SettingNotificationType,
} from '@/shared/types';
import { Input, OnOffSwitch } from '@any-ui-react/core';
import { Select } from '@any-ui-react/select';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  label: string;
  value?:
    | UpdateNotificationOptionStaticDatesInput
    | UpdateNotificationOptionCustomDatesInput;
  name: keyof Omit<SettingNotificationType, 'countryId'>;
  withDate?: boolean;
}

const ORDERED_NOTIFICATION_USERS = [
  NotificationUser.TheEmployee,
  NotificationUser.PrimaryManager,
  NotificationUser.AdminStandard,
  NotificationUser.AdminPrivate,
  NotificationUser.SuperAdmin,
];

const ORDERED_NOTIFICATION_DATES = [
  NotificationDate.OnTheDay,
  NotificationDate.Before_14Days,
  NotificationDate.Before_30Days,
  NotificationDate.Before_45Days,
  NotificationDate.Before_90Days,
  NotificationDate.Before_6Months,
];

export const NotificationItem = ({ label, name, withDate = true }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SettingNotificationType>();

  const userOptions = ORDERED_NOTIFICATION_USERS.map((user) => ({
    value: user,
    label: NotificationUserLabels[user],
  }));

  const dateOptions = ORDERED_NOTIFICATION_DATES.map((date) => ({
    value: date,
    label: NotificationDateLabels[date],
  }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        const customValue = value as UpdateNotificationOptionCustomDatesInput;
        return (
          <div className='flex flex-col space-y-2'>
            <OnOffSwitch
              key={label}
              label={label}
              checked={value?.enabled ?? false}
              onChange={(e) =>
                onChange({ ...value, enabled: e.currentTarget.checked })
              }
              classNames={{
                label: 'font-semibold text-base',
              }}
            />

            {Boolean(value?.enabled) && (
              <div className='flex w-full flex-col space-y-2 pl-[72px]'>
                <div className='flex w-full space-x-2'>
                  <Input.Label className='py-1'>Notified Users</Input.Label>
                  <Select
                    placeholder='Select Notified Users'
                    isMulti={true}
                    options={userOptions}
                    menuPortalTarget={document.body}
                    value={value?.notifiedUsers.map((user) => ({
                      value: user,
                      label: NotificationUserLabels[user],
                    }))}
                    onChange={(options) =>
                      onChange({
                        ...value,
                        notifiedUsers: options.map((option) => option.value),
                      })
                    }
                    wrapper={{
                      className: 'flex-1',
                    }}
                    error={errors?.[name]?.notifiedUsers?.message}
                  />
                </div>

                {withDate && (
                  <div className='flex w-full space-x-2 py-1'>
                    <Input.Label className='py-1'>Notified Dates</Input.Label>
                    <Select
                      placeholder='Select Notified Dates'
                      isMulti={true}
                      options={dateOptions}
                      menuPortalTarget={document.body}
                      value={customValue?.notifiedDates?.map((date) => ({
                        value: date,
                        label: NotificationDateLabels[date],
                      }))}
                      onChange={(options) =>
                        onChange({
                          ...value,
                          notifiedDates: options.map((option) => option.value),
                        })
                      }
                      wrapper={{
                        className: 'flex-1',
                      }}
                      error={
                        (
                          errors[name] as {
                            notifiedDates?: { message: string };
                          }
                        )?.notifiedDates?.message
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
