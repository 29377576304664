import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBusinessUnitMutationVariables = Types.Exact<{
  input: Types.UpdateBusinessUnitInput;
}>;


export type UpdateBusinessUnitMutation = { __typename?: 'Mutation', updateBusinessUnit: { __typename?: 'BusinessUnitOutput', id: string } };


export const UpdateBusinessUnitDocument = gql`
    mutation updateBusinessUnit($input: UpdateBusinessUnitInput!) {
  updateBusinessUnit(input: $input) {
    id
  }
}
    `;
export type UpdateBusinessUnitMutationFn = Apollo.MutationFunction<UpdateBusinessUnitMutation, UpdateBusinessUnitMutationVariables>;

/**
 * __useUpdateBusinessUnitMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUnitMutation, { data, loading, error }] = useUpdateBusinessUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUnitMutation, UpdateBusinessUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessUnitMutation, UpdateBusinessUnitMutationVariables>(UpdateBusinessUnitDocument, options);
      }
export type UpdateBusinessUnitMutationHookResult = ReturnType<typeof useUpdateBusinessUnitMutation>;
export type UpdateBusinessUnitMutationResult = Apollo.MutationResult<UpdateBusinessUnitMutation>;
export type UpdateBusinessUnitMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUnitMutation, UpdateBusinessUnitMutationVariables>;