import { LoadableComponent } from '@loadable/component';
import classNames from 'classnames';
import { memo, SVGProps } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const NavigationLink = memo(
  ({
    name,
    href,
    icon: Icon,
    leftIcon: LeftIcon,
    className = '',
  }: {
    name: string;
    href: string;
    icon?: LoadableComponent<SVGProps<SVGSVGElement>>;
    leftIcon?: LoadableComponent<SVGProps<SVGSVGElement>>;
    className?: string;
  }) => {
    const location = useLocation();

    const pathname = location.pathname;
    const isActivePath = pathname === href;
    const containsPath = pathname.startsWith(href);

    return (
      <Link
        to={href}
        className={classNames(
          `mb-1.5 flex h-8 items-center rounded-sm py-1.5 pl-2 hover:bg-gray-0 ${className}`,
          { 'bg-gray-0': isActivePath }
        )}
      >
        {Icon && (
          <span className='h-6 w-6'>
            <Icon
              width={24}
              height={24}
              className={classNames({
                'fill-gray-7': !containsPath,
                'fill-blue-7': containsPath,
              })}
            />
          </span>
        )}
        <span
          className={classNames('pl-2', {
            'text-blue-6': isActivePath,
            'text-gray-9': !isActivePath,
          })}
        >
          {name}
        </span>

        {LeftIcon && (
          <span className='ml-auto mr-1.5 flex h-6 w-6 items-center'>
            <LeftIcon width={16} height={16} />
          </span>
        )}
      </Link>
    );
  }
);
