import { ReactNode } from 'react';

import classNames from 'classnames';

type NoResultProps = {
  title: string;
  description?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  button?: ReactNode;
};

export const NoResult = ({
  title,
  description,
  wrapperClassName = 'py-40',
  titleClassName,
  descriptionClassName,
  button,
}: NoResultProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center gap-4',
        wrapperClassName
      )}
    >
      <div className={classNames('text-lg', titleClassName)}>{title}</div>
      {description && (
        <div
          className={classNames(
            'text-2xs text-gray-7 lg:text-sm',
            descriptionClassName
          )}
        >
          {description}
        </div>
      )}
      {button}
    </div>
  );
};
